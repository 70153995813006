<nav aria-label="breadcrumb" *ngIf="navigations && navigations.length > 0">
  <ol class="breadcrumb breadcrumb-arrow">
    <li><a [routerLink]="'/'" tooltip="{{ 'label.tohome' | translate}}" [placement]="'bottom'"><i class="fa fa-lg fa-home"></i></a></li>
    <li *ngFor="let n of navigations; index as i; let last = last">
      <a *ngIf="n.link !== ''" [routerLink]="'/' + n.link">{{'nav.' + n.name.replace(' ', '-') | lowercase | translate}}</a>
      <span *ngIf="n.link === ''">{{'nav.' + n.name | translate}}</span>
    </li>
  </ol>
</nav>



