import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-interrupted-payment',
  templateUrl: './interrupted-payment.component.html',
  styleUrls: ['./interrupted-payment.component.scss']
})
export class InterruptedPaymentComponent implements OnInit {

  orderId: number;
  transactionId: string;

  constructor(private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.queryParams.orderId;
    this.transactionId = this.activatedRoute.snapshot.queryParams.transactionId;
  }

}
