export class ActiveFilter {
  priceRange: DoubleRange;
  search: string;
  categoryId: number;
  materials: Array<string>;
  colorCodes: Array<string>;
  sizes: Array<string>;
  brands: Array<number>;

  constructor() {
    this.priceRange = {};
    // this.properties = {};
  }
}
