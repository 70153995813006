import { WebshopSettingsActions, WebshopSettingsActionTypes } from './WebshopSettingsActions';
import { WebshopSettings } from '../../model/WebshopSettings';

export interface WebshopSettingsState {
  settings: WebshopSettings;
}

export const initialState: WebshopSettingsState = {
  settings: null
};

export function webshopSettingsReducer(state: WebshopSettingsState = initialState, action: WebshopSettingsActions): WebshopSettingsState {
  switch (action.type) {
    case WebshopSettingsActionTypes.InitWebshopSettings: {
      return {
        ...state,
        settings: action.payload
      };
    }
  }
  return state;
}

export const initSettings = (state: WebshopSettingsState) => state.settings;

