import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { Subscription } from 'rxjs';
import { Address } from '../../../model/Address';
import { ProfileService } from '../../../service/profile.service';
import { FormService } from '../../../service/form.service';
import { ModifyAddress } from '../../../store/user/UserActions';

@Component({
  selector: 'app-modify-address',
  templateUrl: './modify-address.component.html'
})
export class ModifyAddressComponent implements OnInit, OnDestroy {

  address: Address;
  form: FormGroup;
  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly fb: FormBuilder,
              private readonly toastrService: ToastrService,
              private readonly profileService: ProfileService,
              private readonly formService: FormService,
              private readonly userStore: Store<UserState>,
              public readonly modalRef: BsModalRef) {
  }

  ngOnInit(): void {
    this.form = this.formService.getAddressForm(this.address);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  save(): void {
    this.profileService.updateAddress(this.form.getRawValue()).subscribe(() => {
      this.userStore.dispatch(new ModifyAddress(this.form.getRawValue()));
      this.toastrService.success('Cím mentve');
      this.modalRef.hide();
    });
  }

  setPhonePrefix(event) {
    if(event.target['value'] == '') {
      event.target['value'] = event.target['value'] + "+36";
    }
  }

  checkPhoneNumber(event) {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
    const tempValue = event.target['value'];
    if (validkeys.indexOf(event.key) < 0) {
      event.target['value'] = tempValue.replace(/[^0-9+]/g, "");
    }
    this.setPhonePrefix(event);
  }
}
