import { Component, OnInit } from '@angular/core';
import { UserReview } from '../../../model/UserReview';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
registerLocaleData(localeHu, 'hu');

@Component({
  selector: 'app-user-ratings',
  templateUrl: './user-ratings.component.html'
})
export class UserRatingsComponent implements OnInit {

  ratings: Array<UserReview>;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.ratings = this.activatedRoute.snapshot.data.preload;
  }

  calculateAverageRating(product) {
    return Number((product.reviews.reduce((a, b) => a + b.rating, 0) / product.reviews.length).toFixed(2));
  }
}
