<div class="container-fluid product-detail">

    <div class="row">
      <div [ngClass]="productsInCart.length > 0 ? 'col-xxl-19' : 'col-xxl-24'">

        <div class="row">
          <div class="col-xxl-8 d-flex align-items-center">
            <app-site-navigation *ngIf="siteNavigation"></app-site-navigation>
          </div>

          <div class="col-xxl-12 d-flex justify-content-center">
            <h1>{{model?.name}}</h1>
          </div>

          <div class="col d-flex justify-content-xxl-end justify-content-center align-items-center rating">
            <ngx-input-star-rating [(ngModel)]="averageRating" [disabled]="true" tooltip="Értékelés"  [delay]="500"></ngx-input-star-rating>
          </div>

        </div>

      <div class="row prod-card w-100 m-0" *ngIf="model">

        <div class="col-24 col-lg-2 col-xxxl-3 p-0 order-2 order-lg-1 d-none d-xl-block" *ngIf="model.photos.length > 1">
          <div class="gallery-container bg-light">
          <div class="scroll-block">
            <img *ngFor="let p of model.photos" src="{{imagePrefix}}{{p.original}}" class="img-fluid" [ngClass]="activeImage === p ? 'active' : ''" (click)="setActiveImage(p)" appHideMissing>
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-xxxl-6 order-1 order-lg-2">

          <div class="prod-detail-img-container-lg">
            <ng-img-magnifier [thumbImage]="imagePrefix + activeImage.original"
                              [fullImage]="imagePrefix + fullSizeImage"
                              [resultHeight]="resultHeightLg"
                              [resultWidth]="resultWidthLg"
                              [top]="resultTop" [right]="resultRightLg"
                              (click)="open()">
            </ng-img-magnifier>
          </div>

          <div class="prod-detail-img-container">
            <ng-img-magnifier [thumbImage]="imagePrefix + activeImage.original"
                              [fullImage]="imagePrefix + fullSizeImage"
                              [resultHeight]="resultHeight"
                              [resultWidth]="resultWidth"
                              [top]="resultTop" [right]="resultRight"
                              (click)="open()">
            </ng-img-magnifier>
          </div>

<!--          <div *ngFor="let image of _albums; let i=index">-->
<!--            <img [src]="image.thumb" (click)="open(i)" />-->
<!--          </div>-->

<!--          <div class="d-flex justify-content-center">-->
<!--          <div class="starburst">-->
<!--            <div *ngIf="product.salePrice" class="text" #priceContainer>-->
<!--              <span class="salesprice"><s>{{product.grossPrice}} Ft</s></span>-->
<!--              <span class="terms">helyett</span>-->
<!--              <span class="offerprice" [fittext]="true" [modelToWatch]="product" [activateOnResize]="true" [container]="priceContainer">15 400 Ft</span>-->
<!--            </div>-->
<!--            <div *ngIf="!product?.salePrice" class="text d-flex align-items-center" #priceContainer> <span class="normalprice" [fittext]="true" [modelToWatch]="product" [activateOnResize]="true" [container]="priceContainer">{{product.grossPrice | number}} Ft</span></div>-->
<!--          </div>-->
<!--          </div>-->
        </div>

        <div class="col-lg-15 order-3 order-lg-3" *ngIf="model">
          <div class="prod-detail-content-container">

            <div *ngIf="user" class="container-favourite-button">

              <span [ngClass]="!user ? 'disabled-favourite-button' : ''" *ngIf="!favouriteProducts || !favouriteProducts.includes(model.id)" class="fa-stack fa-lg" (click)="addToFavouriteProducts()" tooltip="{{ 'label.add.to.favourites' | translate}}">
                 <i class="fa fa-circle fa-stack-2x"></i>
                 <i class="fa fa-heart-add fa-stack-1x"></i>
              </span>

              <span [ngClass]="!user ? 'disabled-favourite-button' : ''" *ngIf="favouriteProducts && favouriteProducts.includes(model.id)" class="fa-stack fa-lg" (click)="deleteFromFavouriteProducts()" tooltip="{{ 'label.remove.from.favourites' | translate}}">
                 <i class="fa fa-circle fa-stack-2x"></i>
                 <i class="fa fa-heart-remove fa-stack-1x"></i>
              </span>

            </div>


            <mat-tab-group>
              <mat-tab label="Leírás">

                <div class="mat-content-container">
                  <p [innerHTML]="model?.description"></p>
                </div><!-- mat-content-container end-->
              </mat-tab>
              <mat-tab label="Szállítás és fizetés">

                <p>Vásárlóink az alábbi átvételi módok közül választhatnak:</p>

                <ul><li>csomag kiszállítása futárszolgálattal a vásárló által megadott címre</li>
                  <li>csomag kiszállítása futárszolgálattal egy - a vásárló által megadott - átvételi pontra</li>
                </ul>

                <p>A megrendelt termékek kiszállítását a GLS- Hungary végzi. A csomagot a megrendelését vagy a banki utalás beérkezését követő 1-3 munkanapon belül fogja megkapni. 25.000 Ft alatti rendelés esetén a szállítási díj 1 460 Ft.<br><button (click)="openDeliveryModal()" class="btn btn-link"><i class="fa fa-chevron-right"></i> További szállítási információk</button></p>

              </mat-tab>
              <mat-tab label="Vélemények">
                <div *ngIf="!user" class="container-review">
                  <div>Jelentkezz be, hogy értékeld a terméket</div>
                </div>
                <div class="container-review">
                  <form [formGroup]="reviewForm" (ngSubmit)="submitReview()" *ngIf="user && user.reviews && !user.reviews.includes(model.id)">
                    <h2>Értékeld a terméket!</h2>

                    <ngx-input-star-rating formControlName="rating" class="me-2"></ngx-input-star-rating>
                    <div>
                      <label for="rating">Kattints az egyik csillagra és értékeld a terméket!</label>
                      <textarea id="rating" class="form-control review-txt" placeholder="Értékelés" formControlName="review"></textarea>
                      <div class="alert alert-danger" *ngIf="reviewForm.get('review').hasError('minlength')">
                        Értékelést szükséges írni!
                      </div>
                    </div>


                    <div class="row mt-3">
                      <div class="col-12"><button type="submit" class="btn btn-rounded btn-primary w-100 text-light" [disabled]="reviewForm.invalid">Elküld
                      </button></div>
                      <div class="col-12" *ngIf="!model.reviews || model.reviews.length === 0">Legyél az első aki értékeli ezt a terméket!</div>
                    </div>

                  </form>

                  <div class="mt-5" *ngIf="model.reviews && model.reviews.length > 0">
                    <h2 class="pt-5">Értékelések és vélemények:</h2>
                    <hr>
                    <app-review class="mb-3" *ngFor="let r of model.reviews" [review]="r"></app-review>
                  </div>

                </div>
              </mat-tab>
            </mat-tab-group>


            <div class="price-container" *ngIf="!selectedProduct?.price">
              <h5>
                <span>A termék nem kapható!</span>
              </h5>
            </div>


            <!-- PROD DETAILS -->
            <div *ngIf="selectedProduct?.price">

            <!-- PROD DETAILS -->
            <div class="price-container">
              <figure class="starburst d-none d-sm-block" *ngIf="model.discount > 0">
                <div class="burst-one"></div>
                <div class="burst-two"></div>
                <svg class="textcircle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 200">
                  <svg>
                    <text x="40" y="130" fill="fff" class="star-text">{{model.discount}}%</text>
                  </svg>
                </svg>
              </figure>

              <b class="text-center w-100 d-block" *ngIf="model.discount > 0"><s>{{selectedProduct ? ((selectedProduct.price / 100 * (100 + model.discount)) | number:'1.0-0' | thousandSeparator).replace(',', ' ') : (model.price / 100 * (100 + model.discount)) | thousandSeparator }}</s> Ft helyett</b>
            <h5>
              Ára:
              <span>

                {{selectedProduct ? (selectedProduct.price | thousandSeparator) : model.price}} Ft</span>
            </h5>
            </div>

            <h5 *ngIf="colorData.length > 0 && model.active" class="text-center mb-5">Szín: {{colorSelected.name}}</h5>
            <div *ngIf="colorData.length > 0 && model.active" class="d-flex justify-content-center colors-container mb-5">

              <ng-container *ngFor="let color of colorData">
                <div class="color-chooser-container">
                  <img src="/api/product/icons/{{color.icon}}" class="color-chooser" [ngClass]="{ 'active': colorSelected.name == color.name }" tooltip="{{color.name}}" placement="bottom" alt="{{color.name}}" (click)="setColor(color)" appHideMissing>
                </div>
              </ng-container>
            </div>

            <div class="row pt-5" *ngIf="sizeData.length > 0 && model.active">
              <div class="col">
            <button class="btn btn-link mb-4" (click)="openSizeChartModal()"> <i class="fa fa-2x fa-size"></i>Mérettáblázat</button>
              </div>
              <div class="col">
            <h5 class="text-end text-md-center text-lg-end text-xxl-center mb-5">Méret: <span>{{sizeSelected.name}}</span></h5>
              </div>
              <div class="col d-none d-md-flex d-lg-none d-xxl-flex"></div>
            </div>

            <div class="button-size row g-3 d-flex justify-content-start" *ngIf="sizeData.length > 0 && model.active">
              <ng-container *ngFor="let item of sizeData">
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-8 col-xxl-6 col-xxxl-4">
                  <button *ngIf="!item.available" class="btn not-aviable" (click)="openMissingProductModal(item)" tooltip="Hiányzó termék. Kattints ha tudni szeretnéd mikor lesz a termék elérhető!" placement="bottom">{{item.name}}</button>
                  <button *ngIf="item.available" [ngClass]="sizeSelected.name === item.name ? 'active' : ''" class="btn btn-primary" (click)="setSize(item)">{{item.name}}</button>
                </div>
              </ng-container>
            </div>

              <div class="row" *ngIf="model.active">
                <div class="col-24 mt-5">
                  <h5 class="mt-5 mb-0 text-center availability">Elérhetőség: {{selectedProduct.count}} db</h5>
                </div>
              </div>

            <div class="quantity-container">

            <div class="row" *ngIf="model.active">
              <div class="col-12 text-end"><h5>Mennyiség:</h5></div>
              <div class="col-12">
                <div class="btn-group btn-quantity mb-0" role="group" aria-label="Mennyiseg">
                  <button [disabled]="amountByProduct[selectedProduct.id] < 2" (click)="decreaseAmountForProduct(selectedProduct.id)" type="button" class="btn btn-sm btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>
                  <input [max]="selectedProduct.count" [ngClass]="selectedProduct.count < amountByProduct[selectedProduct.id] ? 'ng-invalid' : ''" class="form-control form-control-sm price-input" [(ngModel)]="amountByProduct[selectedProduct.id]" type="number" (click)="$event.target.select()" />
                  <button [disabled]="selectedProduct.count <= amountByProduct[selectedProduct.id]" (click)="increaseAmountForProduct(selectedProduct.id)" type="button" class="btn btn-sm btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>
                </div>
              </div>
            </div>
            </div>

            <div class="d-flex justify-content-center">
              <span *ngIf="!model.active">Ez a termék jelenleg nem kapható.</span>
            <button class="btn btn-rounded btn-primary btn-lg text-nowrap w-75" (click)="addToCart()" [disabled]="selectedProduct.count === 0 || selectedProduct.count < amountByProduct[selectedProduct.id] || amountByProduct[selectedProduct.id] < 1 || selectedProduct.count < amountByProduct[selectedProduct.id] + cart[selectedProduct.id]?.amount || !model.active"><i class="fa fa-lg fa-cart-plus"></i>
              <span class="add-card-txt">Kosárba</span></button>
            </div>

            </div>

          </div>
        </div>
      </div>
      </div>

      <div class="col-lg-24 col-xxl-5 cart-total-container" *ngIf="productsInCart.length > 0">

        <div class="cart-total">
          <h5><span class="fa fa-shopping-cart"></span> Kosár összesen</h5>

          <div class="row">
            <div class="col-8">
              <b>Részösszeg</b>
            </div>
            <div class="col-16 text-end">
              {{subTotal | thousandSeparator}} Ft
            </div>
          </div>
          <div class="col-24 divider"></div>

          <div class="row">
            <div class="col-8">
              <b>Szállítás</b>
            </div>
            <div class="col-16 text-end">
              {{subTotal < settings.freeShippingPrice ? ((settings.shippingPrice | thousandSeparator) + ' Ft') : 'Ingyenes'}}
            </div>
          </div>
          <div class="col-24 divider"></div>

          <div class="row lead">
            <div class="col-8">
              Összeg
            </div>
            <div class="col-16 text-end text-primary">
              {{subTotal < settings.freeShippingPrice ? ((subTotal + settings.shippingPrice) | thousandSeparator) : subTotal | thousandSeparator}} Ft
            </div>
          </div>
          <div class="col-24 divider"></div>

          <p class="text-center">
            <button class="btn btn-outline-dark btn-rounded mx-auto" (click)="navigateToCheckout()"><i
              class="fa fa-chevron-right"></i> Tovább a pénztárhoz
            </button>
          </p>

        </div>

      </div><!-- col END-->

  </div><!--  cart total END-->



</div>
<!--  container-fluid END-->


<div>
</div>

<div class="container-fluid headline-block bg-light">
  <h2>Hasonló termékek</h2>
</div>

<div class="container-fluid">

  <div class="product-list">
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-6 col-xxl-4 mb-5 d-flex" *ngFor="let sm of model.similarModels">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header d-flex justify-content-center">
            <img src="/api/product{{sm.image.original}}" class="card-img-prod" alt="" appHideMissing>
          </div>
          <div class="card-body">
            <h3 class="card-title"><a [routerLink]="'/termek/' + sm.link" class="stretched-link text-decoration-none text-dark">{{sm.name}}</a></h3>
          </div>
          <div class="card-footer text-center">

            <p class="card-text"><span class="offerprice">{{sm.price | thousandSeparator}} Ft</span></p>
            <!--          <button class="btn btn-outline-primary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>


</div>




<!-- DELIVERY MODAL ------------------------------------------ -->
<ng-template #deliveryModal>

  <app-delivery></app-delivery>

</ng-template>

<!-- MISSING PRODUCT MODAL ------------------------------------------ -->
<ng-template #missingProduct>

  <app-delivery></app-delivery>

</ng-template>
