<div class="row data-form" [formGroup]="companyForm">
  <div class="col-lg-8">
    <div class="form-floating">
      <input class="form-control" id="companyNameInput" placeholder="Cégnév" type="text" formControlName="name" data-lpignore="true">
      <label for="companyNameInput">Cégnév</label>

      <div *ngIf="companyForm.get('name').invalid &&
        companyForm.get('name').errors &&
        (companyForm.get('name').dirty || companyForm.get('name').touched)">
        <small class="message"
          *ngIf="companyForm.get('name').hasError('required')">
          {{'error.company.missing.name' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('name').hasError('minlength')">
          {{'error.company.minlength' | translate}}
        </small>
      </div>

    </div>
  </div>

  <div class="col-lg-8">
    <div class="form-floating">
      <input class="form-control" mask="00000000-0-00" [showMaskTyped]="true" id="companyTaxnumberInput" placeholder="Adószám" type="text" formControlName="taxNumber" data-lpignore="true">
      <label for="companyTaxnumberInput">Adószám</label>
      <div *ngIf="companyForm.get('taxNumber').invalid &&
        companyForm.get('taxNumber').errors &&
        (companyForm.get('taxNumber').dirty || companyForm.get('taxNumber').touched)">
        <small class="message"
          *ngIf="companyForm.get('taxNumber').hasError('required')">
          {{'error.missing.taxnumber' | translate}}
        </small>
      </div>
    </div>
  </div>

  <div class="col-lg-8">
    <div class="form-floating">
      <input class="form-control" id="companyPhoneNumberInput" placeholder="Telefonszám" type="string" formControlName="phoneNumber" data-lpignore="true" maxlength="20" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
      <label for="companyPhoneNumberInput">Telefonszám</label>
      <div *ngIf="companyForm.get('phoneNumber').invalid &&
        companyForm.get('phoneNumber').errors &&
        (companyForm.get('phoneNumber').dirty || companyForm.get('phoneNumber').touched)">
        <small class="message"
          *ngIf="companyForm.get('phoneNumber').hasError('required')">
          {{'error.missing.phone' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('phoneNumber').hasError('minlength')">
          {{'error.length.phone' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('phoneNumber').hasError('maxlength')">
          {{'error.length.phone' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('phoneNumber').hasError('pattern')">
          {{'error.format.phone' | translate}}
        </small>
      </div>
    </div>
  </div>


  <div class="col-sm-8 col-lg-6 col-xl-4">
    <div class="form-floating">
      <input class="form-control"
             id="companyPostalCodeInput"
             placeholder="Irányítószám"
             type="number"
             maxlength="4"
             autocapitalize="none"
             autocorrect="off"
             autocomplete="off"
             formControlName="postalCode"
             data-lpignore="true">
      <label for="companyPostalCodeInput">Irányítószám</label>
      <div *ngIf="companyForm.get('postalCode').invalid &&
        companyForm.get('postalCode').errors &&
        (companyForm.get('postalCode').dirty || companyForm.get('postalCode').touched)">
        <small class="message"
          *ngIf="companyForm.get('postalCode').hasError('required')">
          {{'error.missing.postal.code' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('postalCode').hasError('pattern')">
          {{'error.need.4.number' | translate}}
        </small>
        <small class="delayed-message"
               *ngIf="companyForm.get('postalCode').hasError('minlength')">
          {{'error.need.4.number' | translate}}
        </small>
        <small class="delayed-message"
               *ngIf="companyForm.get('postalCode').hasError('maxlength')">
          {{'error.need.4.number' | translate}}
        </small>
      </div>
    </div>
  </div>

  <div class="col-sm-16 col-lg-8 col-xl-8">
    <div class="form-floating">
      <input class="form-control"
             id="companyCityInput"
             autocapitalize="none"
             autocorrect="off"
             autocomplete="off"
             type="text"
             placeholder="Település"
             formControlName="city"
             data-lpignore="true">
      <label for="companyCityInput">Település</label>
      <div *ngIf="companyForm.get('city').invalid &&
        companyForm.get('city').errors &&
        (companyForm.get('city').dirty || companyForm.get('city').touched)">
        <small class="message"
          *ngIf="companyForm.get('city').hasError('required')">
          {{'error.missing.city' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('city').hasError('minlength')">
          {{'error.min.2.character' | translate}}
        </small>
      </div>

    </div>
  </div>

  <div class="col-lg-10 col-xl-12">
    <div class="form-floating">
      <input class="form-control"
             id="companyAddressInput"
             placeholder="Postacím"
             type="text"
             autocapitalize="none"
             autocorrect="off"
             autocomplete="off"
             formControlName="address"
             data-lpignore="true">
      <label for="companyAddressInput">Postacím</label>
      <div *ngIf="companyForm.get('address').invalid &&
        companyForm.get('address').errors &&
        (companyForm.get('address').dirty || companyForm.get('address').touched)">
        <small class="message"
          *ngIf="companyForm.get('address').hasError('required')">
          {{'error.missing.address' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="companyForm.get('address').get('address').hasError('minlength')">
          {{'error.min.2.character' | translate}}
        </small>
      </div>
    </div>
  </div>

  <div class="col-24">
    <div class="hstack gap-3 d-flex justify-content-center mt-5">
      <button class="btn btn-outline-dark" *ngIf="!modifyState" (click)="enableForm()">Szerkesztés</button>
      <button class="btn btn-outline-primary" *ngIf="modifyState" (click)="disableForm()">Mégse</button>
      <button class="btn btn-primary" type="submit" *ngIf="modifyState" [disabled]="!companyForm.dirty || companyForm.invalid" (click)="save()">Mentés</button>
    </div>
  </div>


</div>
