import { UserActions, UserActionTypes } from './UserActions';
import { Profile } from '../../model/Profile';
import { Order } from '../../model/Order';
import { User } from '../../model/User';

export interface UserState {
  isLoggedIn: boolean;
  user: User;
  favouriteProducts: Array<number>;
  profile: Profile;
  orders: Array<Order>;
  reviews: Array<number>;
}

export const initialState: UserState = {
  isLoggedIn: false,
  user: null,
  favouriteProducts: [],
  profile: null,
  orders: [],
  reviews: []
};

export function userReducer(state: UserState = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.Login: {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
        favouriteProducts: action.payload.favouriteProducts
        // reviews: action.payload.reviews
      };
    }
    case UserActionTypes.Logout: {
      return {
        ...state,
        isLoggedIn: false,
        orders: null,
        profile: null,
        reviews: null,
        user: null
      };
    }
    case UserActionTypes.InitProfile: {
      return {
        ...state,
        profile: action.payload
        // TODO fix circular method call
        // user: {
        //   ...state.user,
        //   discount: action.payload.personalData.discount
        // }
      };
    }
    case UserActionTypes.AddFavouriteProduct: {
      return {
        ...state,
        favouriteProducts: [
          ...state.favouriteProducts,
          action.payload
        ],
        user: {
          ...state.user,
          favouriteProducts: [
            ...state.favouriteProducts,
            action.payload
          ]
        }
      };
    }
    case UserActionTypes.RemoveFavouriteProduct: {
      return {
        ...state,
        favouriteProducts: state.favouriteProducts.filter(fp => fp !== action.payload),
        user: {
          ...state.user,
          favouriteProducts: state.user.favouriteProducts.filter(fp => fp !== action.payload)
        }
      };
    }
    case UserActionTypes.UpdatePersonalData: {
      return {
        ...state,
        profile: {
          ...state.profile,
          personalData: action.payload
        }
      };
    }
    case UserActionTypes.UpdateCompanyData: {
      return {
        ...state,
        profile: {
          ...state.profile,
          company: action.payload
        }
      };
    }
    case UserActionTypes.InitOrders: {
      return {
        ...state,
        orders: action.payload
      };
    }
    case UserActionTypes.InitRatings: {
      return {
        ...state,
        reviews: action.payload
      };
    }
    case UserActionTypes.InitFavouriteProducts: {
      return {
        ...state,
        favouriteProducts: action.payload
      };
    }
    case UserActionTypes.AddAddress: {
      return {
        ...state,
        profile: {
          ...state.profile,
          addresses: [
            action.payload,
            ...state.profile.addresses
          ]
        }
      };
    }
    case UserActionTypes.AddUserReview: {
      return {
        ...state,
        user: {
          ...state.user,
          reviews: [
            ...state.user.reviews,
            action.review
          ]
        }
      };
    }
    case UserActionTypes.RemoveAddress: {
      return {
        ...state,
        profile: {
          ...state.profile,
          addresses: state.profile.addresses.filter(a => a.id !== action.payload)
        }
      };
    }
    case UserActionTypes.ModifyAddress: {
      return {
        ...state,
        profile: {
          ...state.profile,
          addresses: state.profile.addresses.map(a => a.id === action.payload.id ?
            {
              ...a,
              address: action.payload.address,
              city: action.payload.city,
              postalCode: action.payload.postalCode,
              name: action.payload.name,
              phoneNumber: action.payload.phoneNumber
              // country: action.payload.country
            } : a)
        }
      };
    }
  }
  return state;
}

export const isLoggedIn = (state: UserState) => state.isLoggedIn;
export const initUser = (state: UserState) => state.user;
export const initProfile = (state: UserState) => state.profile;
export const initOrders = (state: UserState) => state.orders;
export const initReviews = (state: UserState) => state.reviews;
export const initFavouriteProducts = (state: UserState) => state.favouriteProducts;
