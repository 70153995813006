<h3>Rendeléseim</h3>
<!--<button type="button" class="btn btn-sm btn-info text-white" (click)="downloadInvoice('o.invoiceId')"><i class="fa fa-lg fa-file-o me-2"></i> Számla letöltése</button>-->
  <div class="row w-100 mb-3">
    <div class="col">Dátum</div>
    <div class="col text-center text-nowrap">Rendelési azonosító</div>
    <div class="col text-center text-nowrap">Státusz</div>
    <div class="col text-end pe-0">Végösszeg</div>
  </div>

  <accordion [isAnimated]="true">
    <accordion-group [panelClass]="customAccordion" *ngFor="let o of orders" [(isOpen)]="o.isOpen">
      <div class="w-100 shadow-none pt-2" accordion-heading tooltip="Kattints a rendelés megtekintéséhez!" [delay]="500">


        <div class="row">
          <div class="col">{{o.creationDate | date:'yyyy.MM.dd. h:mm:ss'}}</div>
          <div class="col text-center">{{o.id}}</div>
          <div class="col text-center">{{'label.order.status.' + o.status | translate}}</div>
          <div class="col d-flex justify-content-end text-nowrap">{{o.total}} Ft</div>
        </div>
      </div>

      <div class="orders-container">
        <div class="product" *ngFor="let p of o.orderedProducts">
<!--          <div class="product-image">-->
<!--            <img src="/api/product{{p.product.model.image.original}}" alt="product">-->
<!--          </div>-->

          <div class="product-details">
            <span class="mt-0 mb-0 product-title"><a [routerLink]="'/termek/' + p.product.model.link">{{p.product.model.name}}</a></span>
          </div>

          <div class="product-line-price">
            {{p.quantity}} x <span class="text-danger">{{p.product.price}} Ft</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" *ngIf="o.status === OrderStatus.PAYMENT_IN_PROGRESS">
        <span>Ez a rendelés még nincs fizetve, <a href="https://cmsforms.rootor.eu/payment-conte/payment.php?id={{o.id}}">ide</a> kattintva lehet fizetni és véglegesíteni a rendelést.</span>
      </div>

<!--      <div class="d-flex justify-content-center" *ngIf="o.invoiceId">-->
<!--        <button type="button" class="btn btn-sm btn-info text-white" (click)="downloadInvoice(o.invoiceId)"><i class="fa fa-lg fa-file-o me-2"></i> Számla letöltése</button>-->
<!--      </div>-->

    </accordion-group>
  </accordion>


<!--  <app-my-order-item *ngFor="let order of orders" [order]="order"></app-my-order-item>-->

