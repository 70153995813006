import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MasterheadComponent } from './masterhead/masterhead.component';
import { AdheadComponent } from './adhead/adhead.component';
import { CategoriesComponent } from '../shopping/categories/categories.component';
import { FooterComponent } from './footer/footer.component';
import { AdtopComponent } from './adtop/adtop.component';
import { ProductListComponent } from '../shopping/product-list/product-list.component';
import { ProductDetailsComponent } from '../shopping/product-details/product-details.component';
import { CheckoutComponent } from '../shopping/checkout/checkout.component';
import { ProfileComponent } from '../user/profile/profile.component';
import { SharedModule } from '../shared/shared.module';
import { ShoppingModule } from '../shopping/shopping.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SubCategoriesComponent } from '../shopping/sub-categories/sub-categories.component';
import { CheckoutGuard } from '../../service/checkout.guard';
import { ProductListResolver } from '../../service/product-list-resolver';
import { LoginComponent } from '../user/login/login.component';
import { RegisterComponent } from '../user/register/register.component';
import { UserModule } from '../user/user.module';
import { AccountComponent } from '../user/account/account.component';
import { MyOrdersComponent } from '../user/my-orders/my-orders.component';
import { UserRatingsComponent } from '../user/user-ratings/user-ratings.component';
import { ProfileResolver } from '../../service/profile-resolver.service';
import { ContactComponent } from '../user/contact/contact.component';
import { MyOrderResolverService } from '../../service/my-order-resolver.service';
import { RatingResolverService } from '../../service/rating-resolver.service';
import { MyOrderDetailsComponent } from '../user/my-order-details/my-order-details.component';
import { MyOrderDetailsResolver } from '../../service/my-order-details-resolver.service';
import { LoginGuard } from '../../service/login.guard';
import { AuthenticationGuard } from '../../service/authentication.guard';
import { FavouriteProductsComponent } from '../shopping/favourite-products/favourite-products.component';
import { FavouriteProductsResolver } from '../../service/favourite-products-resolver.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SidenavLeftComponent } from './sidenav-left/sidenav-left.component';
import { Preload } from '../../model/Preload';
import { ProductDetailsResolver } from '../../service/product-details-resolver';
import { CheckoutResolver } from '../../service/checkout-resolver';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { Category } from '../../model/Category';
import { HomePageResolver } from '../../service/home-page.resolver';
import { OrderSuccessComponent } from '../shopping/order-success/order-success.component';
import { OrderUnsuccessfulComponent } from '../shopping/order-unsuccessful/order-unsuccessful.component';
import { CategoryOrderPipe } from '../../service/category-order.pipe';
import { PrivacyComponent } from './privacy/privacy.component';
import { InterruptedPaymentComponent } from '../shopping/interrupted-payment/interrupted-payment.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: CategoriesComponent,
        resolve: {
          preload: HomePageResolver
        },
        data: {
          isAdVisible: true
        }
      },
      // static content
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      {
        path: 'termek/:id',
        component: ProductDetailsComponent,
        resolve: {
          preload: ProductDetailsResolver
        },
        data: {
          isAdVisible: false,
          animation: 'Test'
        }
      },
      {
        path: 'kapcsolat',
        component: ContactComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'kereses',
        component: ProductListComponent,
        resolve: {
          preload: ProductListResolver
        },
        data: {
          isAdVisible: true
        }
      },
      {
        path: 'megszakitott-fizetes',
        component: InterruptedPaymentComponent,
        data: {
          isAdVisible: true
        }
      },
      {
        path: 'bejelentkezes',
        component: LoginComponent,
        canActivate: [LoginGuard],
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'regisztracio',
        component: RegisterComponent,
        canActivate: [LoginGuard],
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'sikeres-megrendeles',
        component: OrderSuccessComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'sikertelen-fizetes',
        component: OrderUnsuccessfulComponent,
        // resolve: {
        //   preload: OrderUnsuccessfulResolver
        // },
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'penztar',
        component: CheckoutComponent,
        // canActivate: [CheckoutGuard],
        resolve: {
          preload: CheckoutResolver
        },
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: {
          isAdVisible: true
        }
      },
      {
        path: 'profil',
        component: ProfileComponent,
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: 'sajat-fiok',
            component: AccountComponent,
            resolve: {
              preload: ProfileResolver
            }
          },
          {
            path: 'rendeleseim',
            component: MyOrdersComponent,
            resolve: {
              preload: MyOrderResolverService
            }
          },
          {
            path: 'rendeleseim/:id',
            component: MyOrderDetailsComponent,
            resolve: {
              preload: MyOrderDetailsResolver
            }
          },
          {
            path: 'ertekelesek',
            component: UserRatingsComponent,
            resolve: {
              preload: RatingResolverService
            }
          },
          {
            path: 'kedvencek',
            component: FavouriteProductsComponent,
            resolve: {
              preload: FavouriteProductsResolver
            }
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'sajat-fiok'
          }
        ],
        data: {
          isAdVisible: true
        }
      },
      // {
      //   path: 'akciok',
      //   component: DiscountProductListingComponent,
      //   data: {
      //     isAdVisible: true
      //   },
      //   resolve: {
      //     preload: DiscountProductListResolver
      //   }
      // },
      {
        path: ':category/:subCategory',
        component: SubCategoriesComponent,
        data: {
          isAdVisible: true
        },
        resolve: {
          preload: ProductListResolver
        }
      },
      {
        component: ProductListComponent,
        matcher: subCategoryMatcher,
        data: {
          isAdVisible: true
        },
        resolve: {
          preload: ProductListResolver
        }
      },
      // {
      //   path: ':category/:productId',
      //   component: ProductDetailsComponent,
      //   resolve: {
      //     preload: ProductDetailsResolver
      //   },
      //   data: {
      //     isAdVisible: false
      //   }
      // },
      // {
      //   path: ':category/:subCategory/:id',
      //   component: ProductDetailsComponent,
      //   resolve: {
      //     preload: ProductDetailsResolver
      //   },
      //   data: {
      //     isAdVisible: false
      //   }
      // },
      {
        path: ':category/:subCategory/:productId',
        component: ProductDetailsComponent,
        resolve: {
          preload: ProductDetailsResolver
        },
        data: {
          isAdVisible: false
        }
      },
      {
        path: ':category/:subCategory/:subSubCategory/:productId',
        resolve: {
          preload: ProductDetailsResolver
        },
        component: ProductDetailsComponent,
        data: {
          isAdVisible: false
        }
      },
      {
        path: 'szallitas',
        pathMatch: 'full',
        redirectTo: ''
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'not-found'
      }
    ]
  }
];

export let preload: Preload = new Preload();

export function initPreload(preloadData): void {
  preload = preloadData;
}

function getCategoryByLink(categoryLink: string): Category {
  // console.log(categoryLink)
  // for (const c of preload.categories) {
  //   if (c.link === categoryLink) {
  //     return c;
  //   }
  //   for (const sc of c.children) {
  //     if (sc.link === categoryLink) {
  //       return sc;
  //     }
  //     for (const ssc of sc.children) {
  //       if (ssc.link === categoryLink) {
  //         return ssc;
  //       }
  //     }
  //   }
  // }
  return preload.categories.find(c => c.link === categoryLink);
}

function getCategoryById(id: number): Category {
  return preload.categories.find(c => c.id === id);
}

// function isSubCategoryLink(categoryLink: string, subCategoryLink: string): boolean {
//   const mainCategory = getCategoryByLink(categoryLink);
//   if (!mainCategory) {
//     return false;
//   }
//   const subCategory = mainCategory.children.find(sc => sc.link === subCategoryLink);
//   if (!subCategory) {
//     return false;
//   }
//
//   return true;
// }

function isSubSubCategoryLink(categoryLink: string, subCategoryLink: string, subSubCategoryLink: string): boolean {
  const mainCategory = getCategoryByLink(categoryLink);
  if (!mainCategory) {
    return false;
  }
  const subCategory = mainCategory.children.find(sc => sc.link === subCategoryLink);
  if (!subCategory) {
    return false;
  }
  const subCategoryFull = getCategoryById(subCategory.id);
  const subSubCategory = subCategoryFull.children?.find(ssc => ssc.link === subSubCategoryLink);
  if (!subSubCategory) {
    return false;
  }

  return true;
}

// function isDedicatedFilterLink(categoryLink: string, subCategoryLink: string, subSubLink: string): boolean {
//   const mainCategory = preload.categories.find(c => c.link === categoryLink);
//   if (!mainCategory) {
//     return false;
//   }
//   const subCategory = mainCategory.children.find(sc => sc.link === subCategoryLink);
//   if (!subCategory) {
//     return false;
//   }
//
//   const dedicatedFilters = preload.filters.dedicatedFilters[subCategory.id];
//   if (!dedicatedFilters) {
//     return false;
//   }
//   const subLink = preload.filters.dedicatedFilters[subCategory.id].find(s => s.link === subSubLink);
//   return !!subLink;
// }

export function subCategoryMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 3 && isSubSubCategoryLink(url[0].path, url[1].path, url[2].path)) {
    return {consumed: url, posParams: { category: url[0], subCategory: url[1], subSubCategory: url[2]}};
  }
  return null;
}
@NgModule({
  declarations: [
    MasterheadComponent,
    HomeComponent,
    AdheadComponent,
    FooterComponent,
    AdtopComponent,
    NotFoundComponent,
    SearchResultComponent,
    SidenavLeftComponent,
    CategoryOrderPipe,
    PrivacyComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ShoppingModule,
    UserModule,
    MatFormFieldModule,
    MatInputModule,
    NgxPageScrollModule
  ],
  providers: [
    CheckoutGuard,
    ProductListResolver,
    ProductDetailsResolver
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
