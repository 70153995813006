export class Json<K extends string | number, V> {
  // [k: number]: V | Array<V>;
  // [k: string]: V | Array<V>;
  [k: number]: V;
  [k: string]: V;

  // constructor(value?: Array<V> | Object, keyProp?: string, valueFn?: Function) {
  //   const keyPropValue = (item) => {
  //     const splitted = keyProp.split('.');
  //     let val = null;
  //     if (splitted.length === 1) {
  //       return item[keyProp];
  //     }
  //     splitted.forEach(s => {
  //       val = val ? val[s] : item[s];
  //     });
  //     return item[val];
  //   };
  //   if (value && Array.isArray(value) && value.length > 0 && keyProp && typeof keyProp === 'string' && keyProp.length > 0) {
  //     for (const item of value) {
  //       const finalValue = valueFn ? valueFn(item) : item;
  //       if (this[keyPropValue(item)] !== undefined && this[keyPropValue(item)] !== null) {
  //         if (Array.isArray(this[keyPropValue(item)])) {
  //           if (Array.isArray(finalValue)) {
  //             // @ts-ignore
  //             this[keyPropValue(item)].push(finalValue[0]);
  //           } else {
  //             // @ts-ignore
  //             this[keyPropValue(item)].push(finalValue);
  //           }
  //         } else {
  //           // // @ts-ignore
  //           // this[item[keyProp]] = new Array(this[item[keyProp]]);
  //           // // @ts-ignore
  //           // this[item[keyProp]].push(finalValue);
  //
  //           // @ts-ignore
  //           this[keyPropValue(item)] = new Array(this[keyPropValue(item)]);
  //           // @ts-ignore
  //           this[keyPropValue(item)].push(finalValue);
  //         }
  //       } else {
  //         this[keyPropValue(item)] = finalValue;
  //       }
  //     }
  //     return;
  //   }
  //
  //   if (value && typeof value === 'object') {
  //     if (valueFn !== undefined && valueFn !== null) {
  //       Object.keys(value).forEach(k => {
  //         this[k] = valueFn(value[k]);
  //       });
  //     } else {
  //       Object.keys(value).forEach(k => {
  //         this[k] = value[k];
  //       });
  //     }
  //     return;
  //   }
  // }


  constructor(value?: Array<V> | Object, keyProp?: string, valueFn?: Function) {
    if (value && Array.isArray(value) && value.length > 0 && keyProp && typeof keyProp === 'string' && keyProp.length > 0) {
      for (const item of value) {
        const finalValue = valueFn ? valueFn(item) : item;
        if (this[item[keyProp]] !== undefined && this[item[keyProp]] !== null) {
          if (Array.isArray(this[item[keyProp]])) {
            if (Array.isArray(finalValue)) {
              // @ts-ignore
              this[item[keyProp]].push(finalValue[0]);
            } else {
              // @ts-ignore
              this[item[keyProp]].push(finalValue);
            }
          } else {
            // @ts-ignore
            this[item[keyProp]] = new Array(this[item[keyProp]]);
            // @ts-ignore
            this[item[keyProp]].push(finalValue);
          }
        } else {
          this[item[keyProp]] = finalValue;
        }
      }
      return;
    }

    if (value && typeof value === 'object') {
      if (valueFn !== undefined && valueFn !== null) {
        Object.keys(value).forEach(k => {
          this[k] = valueFn(value[k]);
        });
      } else {
        Object.keys(value).forEach(k => {
          this[k] = value[k];
        });
      }
      return;
    }
  }
// }


// constructor(value?: Array<V> | Object, keyProp?: string, valueFn?: Function) {
  //   const keyPropValue = (item) => {
  //     const splitted = keyProp.split('.');
  //     let val = null;
  //     if (splitted.length === 1) {
  //       return this[item[keyProp]];
  //     }
  //     splitted.forEach(s => {
  //       val = val ? val[s] : item[s];
  //     });
  //     return this[item[val]];
  //   };
  //   const assignValue = (item, newValue) => {
  //     const splitted = keyProp.split('.');
  //     let val = null;
  //     if (splitted.length === 1) {
  //       this[item[keyProp]] = newValue;
  //       return;
  //     }
  //     splitted.forEach(s => {
  //       val = val ? val[s] : item[s];
  //     });
  //     this[item[val]] = newValue;
  //   };
  //   if (value && Array.isArray(value) && value.length > 0 && keyProp && typeof keyProp === 'string' && keyProp.length > 0) {
  //     const keyProps = keyProp.split('.');
  //     for (const item of value) {
  //       const finalValue = valueFn ? valueFn(item) : item;
  //       if (this[item[keyProp]] !== undefined && this[item[keyProp]] !== null) {
  //         if (Array.isArray(this[item[keyProp]])) {
  //           if (Array.isArray(finalValue)) {
  //             // @ts-ignore
  //             // this[item[keyProp]].push(finalValue[0]);
  //             keyPropValue(item).push(finalValue[0]);
  //             // this[item[keyPropValue()]]
  //           } else {
  //             // @ts-ignore
  //             keyPropValue(item).push(finalValue);
  //             // this[item[keyProp]].push(finalValue);
  //           }
  //         } else {
  //           // @ts-ignore
  //           // this[item[keyProp]] = new Array(this[item[keyProp]]);
  //           assignValue(item, new Array(this[keyPropValue(item)]));
  //           // keyPropValue(item) = new Array(this[keyPropValue(item));
  //           // @ts-ignore
  //           // this[item[keyProp]].push(finalValue);
  //           keyPropValue(item).push(finalValue);
  //         }
  //       } else {
  //         // @ts-ignore
  //         // this[item[keyProp]] = finalValue;
  //         // keyPropValue(item) = finalValue;
  //         assignValue(item, finalValue);
  //       }
  //     }
  //     return;
  //   }
  //
  //   if (value && typeof value === 'object') {
  //     if (valueFn !== undefined && valueFn !== null) {
  //       Object.keys(value).forEach(k => {
  //         this[k] = valueFn(value[k]);
  //       });
  //     } else {
  //       Object.keys(value).forEach(k => {
  //         this[k] = value[k];
  //       });
  //     }
  //     return;
  //   }
  // }
}
