import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-warranty-info-modal',
  templateUrl: './warranty-info-modal.component.html'
})
export class WarrantyInfoModalComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelWarrantyInfoModal() {
    this.modalRef.hide();
  }

}
