import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserReview } from '../model/UserReview';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(private http: HttpClient) {
  }

  getReviews(): Observable<Array<UserReview>> {
    return this.http.get<Array<UserReview>>('/api/review');
  }

  reviewProduct(review: any): Observable<void> {
    return this.http.post<void>('/api/review/add', review);
  }
}
