import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../model/Category';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: Array<Category>, query: string): unknown {
    const trimmed = query ? query.trim() : '';
    // if (trimmed.length === 0 )
    return value.filter(c => c.name.toLowerCase().includes(trimmed.toLowerCase())).filter((c, index) => index < 6);
  }

}
