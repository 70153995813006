import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentState, initAds, initCarousel } from './ContentReducer';

export const selectContentState = createFeatureSelector<ContentState>('content');

// export const initFishRulesSelector = createSelector(
//   selectContentState,
//   initRules
// );

export const initCarouselSelector = createSelector(
  selectContentState,
  initCarousel
);

export const initAdsSelector = createSelector(
  selectContentState,
  initAds
);

// export const initFish1Selector = createSelector(
//   selectContentState,
//
// )
//
//
// export const initCartSelector = createSelector(
//   selectContentState,
//   initFish0
// );
