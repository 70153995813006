import { PropertyActions, PropertyActionTypes } from './PropertyActions';
import { Properties } from '../../model/Properties';


export interface PropertyState {
  properties: Properties;
}

export const initialState: PropertyState = {
  properties: {}
};

export function propertyReducer(state: PropertyState = initialState, action: PropertyActions): PropertyState {
  switch (action.type) {
    case PropertyActionTypes.InitProperties:
      return {
        ...state,
        properties: action.properties
      };
  }
  return state;
}

export const getProperties = (state: PropertyState) => state.properties;
