<div *ngIf="productsInCart.length > 0" class="check-out">

  <section class="cart-checkout">
    <div class="d-flex justify-content-between">
      <h3 class="mb-0 mb-sm-5">Rendelés összesítés</h3>
      <button class="btn btn-sm btn-outline-primary btn-rounded" (click)="openCart()"><i
        class="fa fa-shopping-cart"></i> Kosár szerkesztése
      </button>
    </div>
    <hr>

    <div class="shopping-cart">

      <div *ngFor="let p of productsInCart" class="pt-4" [ngClass]="{'not-enough-product': productsById[p.productId].count < p.amount, 'inactive-product': !models[p.parentProductId].active}">
        <div class="product">
          <div class="product-image">
            <div class="product-image-container">
              <img src="/api/product{{models[p.parentProductId].image.original}}" alt="{{models[p.parentProductId].name}}" appHideMissing>
            </div>
          </div>
          <div class="product-details">
            <span class="mt-0 mb-0 product-title">{{models[p.parentProductId].name}}</span>
            {{p.amount}} x <span class="text-danger">{{productsById[p.productId].price | thousandSeparator}}
            Ft</span>
          </div>

          <div class="product-line-price text-nowrap">{{p.amount * productsById[p.productId].price | thousandSeparator}} Ft</div>
        </div>
      </div>

      <div class="totals">
        <div class="totals-item" *ngIf="user?.discount">
          <label>{{user.discount}}% kedvezmény:</label>
          <div class="totals-value text-nowrap" style="">- {{discount | thousandSeparator}} Ft</div>
        </div>
        <div class="totals-item">
          <label>Részösszeg:</label>
          <div class="totals-value text-nowrap" id="cart-subtotal" style="">{{subTotal | thousandSeparator}} Ft</div>
        </div>
      </div>
    </div>
  </section>

</div>





