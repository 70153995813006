import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from '../../../model/Order';
import { PaymentType } from '../../../model/PaymentType';
import { OrderService } from '../../../service/order.service';
import { OrderStatus } from '../../../model/OrderStatus';

export class MyOrder extends Order {
  isOpen: boolean;
}

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html'
})
export class MyOrdersComponent implements OnInit {

  orders: Array<MyOrder>;
  customAccordion = 'custom-accordion';
  OrderStatus = OrderStatus;

  constructor(private activatedRoute: ActivatedRoute,
              private readonly orderService: OrderService) {
  }

  ngOnInit(): void {
    this.orders = this.activatedRoute.snapshot.data.preload;
    this.orders.forEach(o => {
      if (o.paymentType === PaymentType.BANK_TRANSFER && !o.transactionId) {
        o.isOpen = true;
      }
    });
  }

  downloadInvoice(invoiceId: string): void {
    this.orderService.downloadOrder(invoiceId).subscribe((resp) => {
      // TODO download invoice
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', Date.now() + '.pdf');
      document.body.appendChild(link);
      link.click();

    });
  }
}

