import { OrderedProduct } from './OrderedProduct';
import { PaymentType } from './PaymentType';
import { OrderStatus } from './OrderStatus';

export class Order {

  // id: string;
  // orderedProducts: Array<OrderedProduct>;
  // creationDate: Date;
  // // bruttó összeg
  // total: number;
  // status: string;
  // address: Address;
  // billingAddress: Address;
  // comment?: string;

  id: string;
  orderedProducts: Array<OrderedProduct>;
  status: OrderStatus;
  grossPrice: number;
  customerName: string;
  customerEmail: string;
  customerPhoneNumber: string;
  paymentType: PaymentType;
  shippingName: string;
  shippingPhoneNumber: string;
  shippingPostalCode: string;
  shippingCity: string;
  shippingAddress: string;
  billingName: string;
  billingPhoneNumber: string;
  billingTaxNumber?: string;
  billingPostalCode: string;
  billingCity: string;
  billingAddress: string;
  creationDate: Date;
  buyAsCompany: boolean;
  message: string;
  total: number;
  invoiceId: string;

  transactionId?: string;

}
