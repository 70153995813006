import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../../../model/Category';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { HomePageData } from '../../../model/HomePageData';
import { ContentState } from '../../../store/content/ContentReducer';
import { initAdsSelector, initCarouselSelector } from '../../../store/content/ContentSelector';
import { CarouselSchema } from '../../../model/CarouselSchema';
import { AdsSchema } from '../../../model/AdsSchema';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  animations: [
    trigger('toggleSubCats', [
      state(
        'initial',
        style({
          transform: 'translateY(85%)',
          // height: "0",
          overflow: 'hidden'
        })
      ),
      state(
        'final',
        style({
          transform: 'translateY(0)',
          height: 'auto',
        })
      ),

      transition('initial=>final', [
        style({transform: 'translateY(85%)'}),
        animate('0.5s 300ms ease-in')
      ]),
      transition('final=>initial', [
        animate('0.3s ease-out',
          style({transform: 'translateY(85%)'}))
      ])
    ]),

    trigger('toggleSubCatsxxx', [
      state('initial', style({transform: 'translateY(0)'})),
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('0.5s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({transform: 'translateY(100%)'}))
      ])
    ]),

    // trigger("toggleSubCatsLinks", [
    //   state("initial", style({ transform: "translateX(0)" })),
    //   transition(":enter", [
    //     style({ transform: "translateX(-100%)" }),
    //     animate("0.5s 300ms ease-in")
    //   ]),
    //   transition(":leave", [
    //     animate("0.3s ease-out", style({ transform: "translateX(100%)" }))
    //   ])
    // ])

  ]
})
export class CategoriesComponent implements OnInit {

  currentState = 'initial';

  carouselSlides: CarouselSchema[];
  ads: AdsSchema;

  image: string;

  parallaxImage: string;
  // parallaxTitle: string;
  // parallaxBtnTxt: string;
  // parallaxUrl: string;

  allCategories: Category[] = [];
  categories: Category[] = [];

  homePageData: HomePageData;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly contentStore: Store<ContentState>,
              private readonly categoryStore: Store<CategoryState>) { }

  ngOnInit(): void {
    this.homePageData = this.activatedRoute.snapshot.data.preload;
    if (this.activatedRoute.routeConfig.path.includes('subcategory')) {
      this.image = '/assets/images/categories/cat2.jpg';
    } else {
      this.image = '/assets/images/categories/cat1.jpg';
    }

    this.contentStore.select(initCarouselSelector).subscribe((carousel) => {
      this.carouselSlides = carousel;
    });

    this.contentStore.select(initAdsSelector).subscribe((ads) => {
      this.ads = ads;
    });

    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.allCategories = categories;
      if (this.activatedRoute.routeConfig.path === '') {
        this.categories = this.allCategories;
      }
    });
    this.activatedRoute.params.subscribe(params => {
      // TODO call server: load subcategories
      const category = params['category'];
      // const subCategory = params['subCategory'];
      if (category /*&& subCategory*/) {
        // this.categories = [
        //   new Category('qwe', 'Test subcategory', 'horgaszbot', [])
        // ];
        const actualCategory = this.allCategories.find(c => c.link === category);
        if (!actualCategory) {
          this.router.navigateByUrl('not-found');
          return;
        }
        this.categories = actualCategory.children;
      }
    });
  }

  openSubCats(): void {
    this.currentState = 'final';
  }

  closeSubCats(): void {
    this.currentState = 'initial';
  }


  navigateToSubCategory(link: string): void {
    this.router.navigateByUrl(link);
  }
}
