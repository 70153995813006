<div class="row">
  <div class="col-6">
    Rendelési azonosító: {{order.id}}
  </div>

  <div class="col">Rendelve: {{order.creationDate | date}} | Végösszeg: {{order.total}} Ft</div>

  <div class="col-6">
    <button class="btn btn-sm btn-outline-primary" [routerLink]="order.id"><i class="fa fa-chevron-right"></i> Részletek</button>
  </div>
</div>
