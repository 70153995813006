import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list/product-list.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductComponent } from './product/product.component';
import { CategoryCardComponent } from './category-card/category-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';
import { SubCategoriesComponent } from './sub-categories/sub-categories.component';
import { SubCategoryCardComponent } from './sub-category-card/sub-category-card.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CategoriesComponent } from './categories/categories.component';
import { FilterModule } from './filter/filter.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SubCategoriesListComponent } from './sub-categories-list/sub-categories-list.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { ReviewComponent } from './review/review.component';
import { FavouriteProductsComponent } from './favourite-products/favourite-products.component';
import { ProductCommonListingComponent } from './product-common-listing/product-common-listing.component';
import { CheckoutShoppingCartComponent } from './checkout-shopping-cart/checkout-shopping-cart.component';
import { RelatedProductsComponent } from './related-products/related-products.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MissingProductComponent } from './missing-product/missing-product.component';
import { SizeChartComponent } from './size-chart/size-chart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckoutAddressComponent } from './checkout-address/checkout-address.component';
import { ShoppingInfoComponent } from './shopping-info/shopping-info.component';
import { DiscountProductListingComponent } from './discount-product-listing/discount-product-listing.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { OrderUnsuccessfulComponent } from './order-unsuccessful/order-unsuccessful.component';
import { TermsInfoModalComponent } from './shopping-info/terms-info-modal.component';
import { DeliveryInfoModalComponent } from './shopping-info/delivery-info-modal.component';
import { WarrantyInfoModalComponent } from './shopping-info/warranty-info-modal.component';
import { RegionalInfoModalComponent } from './shopping-info/regional-info-modal.component';
import { ContactFormModalComponent } from './shopping-info/contact-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxMaskModule } from 'ngx-mask';
import { CardPaymentConfirmComponent } from './card-payment-confirm/card-payment-confirm.component';
import { InterruptedPaymentComponent } from './interrupted-payment/interrupted-payment.component';

const components = [
  ProductListComponent,
  CheckoutComponent,
  ShoppingCartComponent,
  ProductDetailsComponent,
  ProductComponent,
  CategoryCardComponent,
  SubCategoriesComponent,
  CategoriesComponent,
  SubCategoryCardComponent
];

@NgModule({
  declarations: [
    components,
    SubCategoriesListComponent,
    BrandListComponent,
    ReviewComponent,
    FavouriteProductsComponent,
    ProductCommonListingComponent,
    CheckoutShoppingCartComponent,
    RelatedProductsComponent,
    DeliveryComponent,
    MissingProductComponent,
    SizeChartComponent,
    CheckoutAddressComponent,
    ShoppingInfoComponent,
    DiscountProductListingComponent,
    OrderSuccessComponent,
    OrderUnsuccessfulComponent,
    TermsInfoModalComponent,
    DeliveryInfoModalComponent,
    WarrantyInfoModalComponent,
    RegionalInfoModalComponent,
    ContactFormModalComponent,
    CardPaymentConfirmComponent,
    InterruptedPaymentComponent
  ],
  exports: [
    components
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    TranslateModule,
    SharedModule,
    TooltipModule,
    FilterModule,
    NgxSliderModule,
    AccordionModule,
    CarouselModule,
    NgSelectModule,
    MatDialogModule,
    RecaptchaModule,
    NgxMaskModule
  ]
})
export class ShoppingModule {
}
