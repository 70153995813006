import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-card-payment-confirm',
  templateUrl: './card-payment-confirm.component.html'
})
export class CardPaymentConfirmComponent implements OnInit {

  constructor(private readonly modalService: BsModalService) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.modalService.hide();
  }
}
