import { Action } from '@ngrx/store';
import { ProductInCart } from '../../model/ProductInCart';
import { CartProduct } from '../../model/User';

export enum CartActionTypes {
  InitCart = 'InitCart',
  AddProductToCart = 'Add product to cart',
  RemoveProductFromCart = 'Remove product',
  ChangeAmountOfProduct = 'Change amount ',
  EmptyCart = 'Empty cart',
  MergeCart = 'Merge cart'
}

export class InitCart implements Action {
  readonly type = CartActionTypes.InitCart;

  constructor(public payload: Array<ProductInCart>) {
  }
}

export class MergeCart {
  readonly type = CartActionTypes.MergeCart;

  constructor(public payload: Array<CartProduct>) {
  }
}

export class AddProductToCart implements Action {
  readonly type = CartActionTypes.AddProductToCart;

  constructor(public productId: number, public productInCart: ProductInCart) {
  }
}

export class RemoveProductFromCart implements Action {
  readonly type = CartActionTypes.RemoveProductFromCart;

  constructor(public productId: number) {
  }
}

export class ChangeAmountOfProduct implements Action {
  readonly type = CartActionTypes.ChangeAmountOfProduct;

  constructor(public productId: number, public amount: number) {
  }
}

export class EmptyCart implements Action {
  readonly type = CartActionTypes.EmptyCart;

  constructor() {
  }
}


export type CartActions =
  InitCart |
  AddProductToCart |
  RemoveProductFromCart |
  ChangeAmountOfProduct |
  EmptyCart |
  MergeCart;
