import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../../service/product.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../model/User';

@Component({
  selector: 'app-missing-product',
  templateUrl: './missing-product.component.html'
})
export class MissingProductComponent implements OnInit {

  form: FormGroup;
  productId: number;
  user?: User;

  constructor(public modalRef: BsModalRef,
              private readonly toastrService: ToastrService,
              private readonly productService: ProductService,
              private readonly translateService: TranslateService,
              private readonly fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (this.user) {
      this.form = this.fb.group({
        productId: new FormControl(this.productId),
        email: new FormControl({value: this.user.email, disabled: true}, [Validators.required, Validators.email])
      });
    } else {
      this.form = this.fb.group({
        productId: new FormControl(this.productId),
        name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email])
      });
    }
  }

  submit(): void {
    this.productService.subscribeToMissingProduct(this.form.getRawValue()).subscribe({
      next: () => {
        this.toastrService.success('Sikeresen feliratkozva');
      },
      error: (err) => {
        this.toastrService.error(this.translateService.instant(err.error.message));
      },
      complete: () => {
        this.modalRef.hide();
      }
    });
  }

  cancelMissingProductModal(): void {
    this.modalRef.hide();
  }
}
