import { Action } from '@ngrx/store';

export enum CheckoutActionTypes {
  InitCheckout = 'Init checkout',
  ResetCheckout = 'Reset checkout'
}

export class InitCheckout implements Action {
  readonly type = CheckoutActionTypes.InitCheckout;

  constructor(public payload: any) {
  }
}

export class ResetCheckout {
  readonly type = CheckoutActionTypes.ResetCheckout;

  constructor() {
  }
}


export type CheckoutActions =
  InitCheckout |
  ResetCheckout;
