<div class="container mt-5 text-center check-out-container">
  <h3 class="mt-5">
    Ön megszakította a fizetést.
  </h3>
  <p class="pb-5 mb-5">Megrendelését fogadtuk, <a href="https://cmsforms.rootor.eu/payment-conte/payment.php?id={{orderId}}">ide</a> kattintva tudja megpróbálni újból a fizetést</p>
  <h2>Rendelés azonosító: <b>{{orderId}}</b></h2>
  <h2>
    SimplePay tranzakció azonosító: {{transactionId}}
  </h2>
</div>
