import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Category } from '../../../model/Category';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ProductService } from '../../../service/product.service';
import { FilterState } from '../../../store/filter/FilterReducer';
import { initFiltersSelector, initOrderingsSelector } from '../../../store/filter/FilterSelector';
import { FilterService } from '../../../service/filter.service';
import { AbstractProductListingComponent } from '../abstract-product-listing/abstract-product-listing.component';
import { AddProduct } from '../../../store/product/ProductActions';
import { UtilsService } from '../../../service/utils.service';
import { ProductState } from '../../../store/product/ProductReducer';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html'
})
export class SubCategoriesComponent extends AbstractProductListingComponent implements OnInit {

  mainCategory: Category;
  subCategory: Category;

  allCategories: Category[] = [];
  subCategories: Category[] = [];

  categoryParam: string;

  title: string;

  constructor(private readonly router: Router,
              private readonly categoryStore: Store<CategoryState>,
              private readonly filterStore: Store<FilterState>,
              private readonly productService: ProductService,
              readonly productStore: Store<ProductState>,
              readonly filterService: FilterService,
              readonly activatedRoute: ActivatedRoute) {
    super(filterService, productStore, activatedRoute);
  }

  ngOnInit(): void {
    // this.isFirstLoading = true;
    this.subscriptions.push(
      this.categoryStore.select(initCategoriesSelector).subscribe((categories) => {
        this.categories = categories;
      })
    );
    this.subscriptions.push(
      this.activatedRoute.data.subscribe((data) => {
        this.products = data.preload.result;
        this.reInitPagination(data.preload.pagination.totalItems, data.preload.pagination.itemsPerPage, data.preload.pagination.currentPage);
        this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(data.preload.result)));
      })
    );
    this.filterStore.select(initFiltersSelector).subscribe((allFilters) => {
      this.allFilters = allFilters;
    });

    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.allCategories = categories;
      if (this.activatedRoute.routeConfig.path === '') {
        this.subCategories = this.allCategories;
      }
    });

    this.subscriptions.push(
      this.filterStore.select(initOrderingsSelector).subscribe((orderings) => {
        this.orderings = orderings;
        this.orderBy = this.orderings[0];
      })
    );

    this.products = this.activatedRoute.snapshot.data['preload'].result;
    this.addProductsToStore(this.products);
    this.initPagination();
    this.initCategoryAndFilter();

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (!this.isFirstLoading) {
          this.queryParamsChanged = true;
        }
        this.updatePagination(params);
        if (this.isFirstLoading) {
          this.isFirstLoading = false;
          return;
        }

        if (Object.keys(params).length > 0) {
          console.log('QUERY PARAMS CHANGED');
          this.productService.getProductsCommonVersion({categoryId: this.subCategory.id, ...this.getProperties()}, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType(), this.getPriceRangeQuery()).subscribe((resp) => {
            this.products = resp.result;
            this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
          });
        } else if (!this.paramsChanged && this.categoryParam === this.mainCategory.link) {
          console.log('NO PARAMS');
          // TODO in case of searching, call this.productService.searchProducts
          this.productService.getProductsCommonVersion({categoryId: this.subCategory.id}, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
            this.products = resp.result;
            this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
            this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
          });
        }
        this.paramsChanged = false;
      })
    );

    this.activatedRoute.params.subscribe(params => {
      if (this.isFirstLoading) {
        return;
      }
      this.initCategoriesAndFilters(params);
      this.paramsChanged = true;
    });
  }

  navigateToSubCategory(link: string): void {
    this.router.navigateByUrl(link);
  }

  navigate(): void {
    this.router.navigateByUrl(`${this.mainCategory.link}/${this.subCategory.link}${this.queryParams}`);
  }

  initCategoryAndFilter(): void {
    this.mainCategory = this.allCategories.find(c => c.link === this.activatedRoute.snapshot.params['category']);
    this.subCategory = this.mainCategory.children.find(c => c.link === this.activatedRoute.snapshot.params['subCategory']);
    this.subCategory = this.allCategories.find(c => c.id === this.subCategory.id);
    this.subCategories = this.subCategory.children;
    this.filter = this.activatedRoute.snapshot.data.preload.filters;
    this.activeFilter = this.filterService.initActiveFilter(this.filter, this.activatedRoute.snapshot.queryParams);
    this.title = this.subCategory.link;
  }

  initCategoriesAndFilters(params: Params): void {
    this.categoryParam = params['category'];

    if (this.activatedRoute.snapshot.queryParams['rendezes']) {
      this.orderBy = this.orderings.find(o => o.link === this.activatedRoute.snapshot.queryParams['rendezes']);
    }

    this.mainCategory = this.categories.find(c => c.link === this.categoryParam);

    if (this.isFirstLoading && this.categoryParam) {
      console.log('FIRST LOADING')
      if (this.categoryParam) {
        this.initCategoryAndFilter();
      } else {
        this.title = 'Keresés';
      }
      this.queryParamsChanged = false;
      return;
    }

    if (this.categoryParam) {
      this.initCategoryAndFilter();
      this.updatePagination(params);

      if (!this.queryParamsChanged && !this.isFirstLoading) {
        // console.log('CATEGORY CHANGED')
        // // TODO cancel request if the filters changed in a while
        // this.productService.getProductsCommonVersion({categoryId: this.subCategory.id, ...this.getProperties()}, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType(), this.getPriceRangeQuery()).subscribe((resp) => {
        //   this.products = resp.result;
        //   this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
        //   this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
        // });
      } else {
        this.queryParamsChanged = false;
      }
      return;
    }

    console.log('HIBA')
    const manufacturer = params['manufacturer'];

    if (manufacturer) {
      this.subscriptions.push(
        this.productService.getProductsCommonVersion({manufacturer: manufacturer}, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
          this.products = resp.result;
          this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
          this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
        })
      );
      this.title = 'results';
      return;
    }
  }
}
