import Amplify from '@aws-amplify/auth';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { AddressComponent } from './address/address.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from '../shared/shared.module';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { AccountComponent } from './account/account.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { UserRatingsComponent } from './user-ratings/user-ratings.component';
import { CompanyComponent } from './company/company.component';
import { MyOrderItemComponent } from './my-order-item/my-order-item.component';
import { MyOrderDetailsComponent } from './my-order-details/my-order-details.component';
// import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import { ModifyAddressComponent } from './modify-address/modify-address.component';
import { AddNewAddressComponent } from './add-new-address/add-new-address.component';
import { NgxMaskModule } from 'ngx-mask';

Amplify.configure({Auth: {
    mandatorySignIn: true,
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_vcnZFdcWD',
    userPoolWebClientId: '1h3hrisb1ji4bige86v0aac5v7',
    identityPoolId: 'eu-west-1:f65ac5cb-98c1-472b-a19f-20647b06d15b'
  }
});


@NgModule({
  declarations: [
    ProfileComponent,
    AddressComponent,
    ContactComponent,
    LoginComponent,
    RegisterComponent,
    PersonalDataComponent,
    AccountComponent,
    MyOrdersComponent,
    UserRatingsComponent,
    MyOrderItemComponent,
    CompanyComponent,
    MyOrderDetailsComponent,
    ModifyAddressComponent,
    AddNewAddressComponent
  ],
  imports: [
    SharedModule,
    // SocialLoginModule,
    AccordionModule,
    NgxMaskModule
  ],
  providers: [
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       // {
    //       //   id: GoogleLoginProvider.PROVIDER_ID,
    //       //   provider: new GoogleLoginProvider(
    //       //     '323846816947-pd0uljsmgsm5t7p83tg1qeusmanktr1p.apps.googleusercontent.com'
    //       //   )
    //       // },
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider('739353570092644')
    //       }
    //     ]
    //   } as SocialAuthServiceConfig,
    // }
  ]
})
export class UserModule { }
