<div class="shape-divider-top shape-divider-pink d-none d-lg-inline">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
  </svg>
</div>

<div class="container-fluid masterhead" id="home">

  <!--  MENU ROW-->
  <div class="row w-100">
    <div class="col-10 col-sm-14 col-md-10 col-lg-6 order-1 d-flex justify-content-between" [ngClass]="scrollClass ? 'scrolled-logo' : 'static-logo'">
      <img (click)="navigate(HOME_LINK)" *ngIf="!scrollClass" src="assets/images/logo-white-lg.png" class="logo" alt="Conte.hu webshop"
           tooltip="{{ 'label.tohome' | translate}}" [delay]="1000" [placement]="'bottom'">

      <img (click)="navigate(HOME_LINK)" *ngIf="scrollClass" src="assets/images/logo-white.png" class="logo" alt="Conte.hu webshop"
           tooltip="{{ 'label.tohome' | translate}}" [delay]="1000" [placement]="'bottom'">

      <div class="phone-container pt-lg-5 text-md-center">
        <a href="tel:+36304480063">
        <i class="fa fa-mobile-phone text-light mt-4 d-none d-md-block"></i>
        <span class="lead text-nowrap d-none d-sm-inline pt-5 pt-md-0 ps-4 ps-lg-0">30-44 80 063</span></a>
      </div>

    </div>
    <div class="col-lg-12 order-3 order-lg-2 d-flex flex-column" [ngClass]="showSearchInput ? 'show-input' : ''">
      <nav class="navbar navbar-expand-lg order-lg-2" [ngClass]="scrollClass ? 'scrolled-navbar' : ''">
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="mainNav">
          <div class="navbar-nav mx-auto">

            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-women"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-women">{{ 'nav.women' | translate}} <i class="fa fa-angle-down"></i>
              </button>

              <div id="dropdown-women" *dropdownMenu class="dropdown-menu shadow pb-4" role="menu"
                   aria-labelledby="button-women">
                <div class="mega-content p-5">
                  <!--  MEGA CONTENT ROW -->
                  <div class="row">
                    <div class="col-24 col-lg-18">
                      <div class="row">
                        <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-4"
                             *ngFor="let c of categories[0].children[0].children | categoryOrder">
                          <h5><a [routerLink]="'/women/' + c.link + '/'">{{ 'nav.' + c.link | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item" *ngFor="let cc of c.children | categoryOrder"
                                                   [routerLink]="'/women/' + c.link + '/' + cc.link + '/'">{{ 'nav.' + cc.link | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-none d-lg-block col-lg-6">

                      <div class="card">
                        <img src="/api/product/content/images/ads/{{ ads.navWomenImage }}" class="card-img" alt="Női">
                        <div class="card-img-overlay">
                          <div>
                            <h3 class="card-title"><a href="{{ ads.navWomenUrl }}" class="stretched-link">{{ ads.navWomenTitle }}</a></h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- MEN ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-men"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-men">{{ 'nav.men' | translate}} <i class="fa fa-angle-down"></i>
              </button>

              <div id="dropdown-men" *dropdownMenu class="dropdown-menu shadow" role="menu"
                   aria-labelledby="button-men">
                <div class="mega-content p-5">
                  <!--  MEGA CONTENT ROW -->
                  <div class="row">
                    <div class="col-24 col-lg-18">
                      <div class="row">
                        <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-6"
                             *ngFor="let c of categories[0].children[1].children | categoryOrder">
                          <h5><a [routerLink]="'/men/' + c.link + '/'">{{ 'nav.' + c.link | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item" *ngFor="let cc of c.children | categoryOrder"
                                                   [routerLink]="'/men/' + c.link + '/' + cc.link + '/'">{{ 'nav.' + cc.link | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-none d-lg-block col-lg-6">

                      <div class="card">
                        <img src="/api/product/content/images/ads/{{ ads.navMenImage }}" class="card-img" alt="Férfi">
                        <div class="card-img-overlay">
                          <div>
                            <h3 class="card-title"><a href="{{ ads.navMenUrl }}" class="stretched-link">{{ ads.navMenTitle }}</a></h3>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CHILDREN ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-kids"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-men">{{ 'nav.kids' | translate}} <i class="fa fa-angle-down"></i>
              </button>

              <div id="dropdown-kids" *dropdownMenu class="dropdown-menu shadow" role="menu"
                   aria-labelledby="button-men">
                <div class="mega-content p-5">
                  <!--  MEGA CONTENT ROW -->
                  <div class="row">
                    <div class="col-24 col-lg-18">
                      <div class="row">
                        <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-6"
                             *ngFor="let c of categories[0].children[2].children | categoryOrder">
                          <h5><a [routerLink]="'/kids/' + c.link + '/'">{{ 'nav.' + c.link | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item" *ngFor="let cc of c.children | categoryOrder"
                                                   [routerLink]="'/kids/' + c.link + '/' + cc.link + '/'">{{ 'nav.' + cc.link | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-none d-lg-block col-lg-6">

                      <div class="card">
                        <img src="/api/product/content/images/ads/{{ ads.navKidsImage }}" class="card-img" alt="Gyermek">
                        <div class="card-img-overlay">
                          <div>
                            <h3 class="card-title"><a href="{{ ads.navKidsUrl }}" class="stretched-link">{{ ads.navKidsTitle }}</a></h3>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="btn-group btn-group-sm" dropdown>-->
<!--              <button id="button-equipment"-->
<!--                      dropdownToggle-->
<!--                      matRipple-->
<!--                      [matRippleUnbounded]="false"-->
<!--                      [matRippleCentered]="true"-->
<!--                      [matRippleRadius]="200"-->
<!--                      type="button"-->
<!--                      class="btn btn-sm btn-link dropdown-toggle"-->
<!--                      aria-controls="dropdown-men">{{ 'nav.equipment' | translate}} <i class="fa fa-angle-down"></i>-->
<!--              </button>-->


<!--              <div id="dropdown-equipment" *dropdownMenu class="dropdown-menu shadow" role="menu"-->
<!--                   aria-labelledby="button-men">-->
<!--                <div class="mega-content p-5">-->
<!--                  &lt;!&ndash;  MEGA CONTENT ROW &ndash;&gt;-->
<!--                  <div class="row">-->
<!--                    <div class="col-24 col-lg-18">-->
<!--                      <div class="row">-->
<!--                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7"-->
<!--                             *ngFor="let c of categories[0].children[3].children">-->
<!--                          <h5><a [routerLink]="'/equipment/' + c.link + '/'">{{ 'nav.' + c.link | translate}}</a></h5>-->
<!--                          <ul>-->
<!--                            <li role="menuitem"><a class="dropdown-item" *ngFor="let cc of c.children"-->
<!--                                                   [routerLink]="'/equipment/' + c.link + '/' + cc.link + '/'">{{ 'nav.' + cc.link | translate}}</a>-->
<!--                            </li>-->
<!--                          </ul>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-none d-lg-block col-lg-6">-->

<!--                      <div class="card">-->
<!--                        <img src="assets/images/megamenu/women_800x1070.jpg" class="card-img" alt="Men">-->
<!--                        <div class="card-img-overlay">-->
<!--                          <div>-->
<!--                            <h3 class="card-title">Be special Without rival</h3>-->
<!--                            &lt;!&ndash;                            <p class="card-text">Without rival</p>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                                            <button class="btn btn-success">Akarom a kedvezményt!</button>&ndash;&gt;-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;                        <img src="assets/images/megamenu/women_800x1070.jpg" class="img-fluid" alt="Women">&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <div class="btn-group btn-group-sm">
              <button type="button"
                      class="btn btn-sm btn-link nav-link text-nowrap"
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      (click)="openShoppingInfoModal()"><i class="fa fa-chevron-right"></i> Vásárlási információk
              </button>
            </div>


          </div>

        </div> <!-- navbar-collapse.// -->
      </nav>
      <div class="search-bar order-lg-1 mt-0 mt-lg-5" [ngClass]="scrollClass ? '' : 'search-bar-top'"
           (click)="showSearchResult = true" (clickOutside)="showSearchResult = false">
        <input class="search-input"
               autocomplete="off"
               type="text"
               name="search"
               maxlength="80"
               placeholder="Mit keres? Pl. női harisnya"
               [(ngModel)]="query"
               (ngModelChange)="search($event)">
        <a [routerLink]="'kereses'" [queryParams]="{query: query}" [queryParamsHandling]="'merge'" (click)="closeSearchResultPanel()"><span class="search-icon"><i class="fa fa-search"></i></span></a>
      </div>

    </div>

    <div class="col-14 col-sm-10 col-md-14 col-lg-6 d-flex justify-content-end cart order-2 order-lg-3"
         [ngClass]="scrollClass ? 'scrolled-cart' : 'align-items-center'">
<!--              <span class="fa-stack fa-sm" [ngClass]="scrollClass ? '' : 'd-none'">-->
<!--                 <i class="fa fa-circle-o fa-stack-2x"></i>-->
<!--                 <i class="fa fa-search fa-stack-1x" (click)="showSearch()"></i>-->
<!--              </span>-->


      <i *ngIf="!user" class="fa fa-user-o" (click)="openLogin();"
         tooltip="{{ 'label.login.registry' | translate}}" [delay]="500"></i>
      <div *ngIf="user" class="user-dropdown btn-group" dropdown #dp="bs-dropdown">
        <i class="fa fa-user-o dropdown-toggle text-primary" id="button-user" dropdownToggle aria-controls="dropdown-user"></i>
        <ul id="dropdown-user" *dropdownMenu class="dropdown-menu pt-0 pb-0" role="menu" aria-labelledby="button-user">
          <li role="menuitem ps-0"><span class="menu-headline">{{ 'label.myprofil' | translate}}</span></li>
          <li role="menuitem"><a class="dropdown-item"
                                 [routerLink]="'profil/sajat-fiok'">{{ 'label.mydata' | translate}}</a></li>
          <li role="menuitem"><a class="dropdown-item"
                                 [routerLink]="'profil/rendeleseim'">{{ 'label.myorders' | translate}}</a></li>
          <li role="menuitem"><a class="dropdown-item"
                                 [routerLink]="'profil/ertekelesek'">{{ 'label.myratings' | translate}}</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" (click)="logout()">{{ 'label.logout' | translate}}</a></li>
        </ul>
      </div>

      <i class="fa fa-heart-o" tooltip="{{ 'label.show.favourites' | translate}}" [delay]="500"
         [routerLink]="'profil/kedvencek'" *ngIf="user"></i>

      <i tooltip="A kosár üres" [delay]="500" class="fa fa-shopping-cart" aria-hidden="true"
         *ngIf="contOfItem == 0"></i>
      <i tooltip="A kosár tartalma" [delay]="500" (click)="cartClicked.emit()" class="fa fa-shopping-cart"
         aria-hidden="true" *ngIf="contOfItem > 0"></i>
      <span class="badge rounded-pill bg-primary" (click)="contOfItem > 0 ? cartClicked.emit() : null">{{contOfItem}}</span>

      <i class="fa fa-reorder d-lg-none" (click)="toggleNavbar()"></i>
    </div>

    <div class="col-24 order-4">
      <app-search-result class="search-result"
                         *ngIf="searchResult && showSearchResult && query && query.length > 0"
                         [categories]="searchedCategories"
                         [products]="searchResult.products"
                         [manufacturers]="searchResult.manufacturers"
                         [query]="query"
                         (close)="showSearchResult = false"
      ></app-search-result>
    </div>

  </div>
  <!-- // MENU ROW end-->
</div>
