<div class="container-fluid check-out-container">

  <div class="row ms-lg-2">
    <form #checkoutForm class="col-24 col-lg-14 p-0" [formGroup]="fullForm" method="POST" action="https://cmsforms.rootor.eu/payment_dev.php">

      <mat-horizontal-stepper class="stepper" linear #stepper (selectionChange)="nextStep($event)" [selectedIndex]="storedCheckout?.stepIndex">
        <!--        <mat-step [stepControl]="fullForm" [editable]="true">-->
        <mat-step [formGroupName]="'personalData'" [completed]="fullForm.get('personalData').valid && !notInStockProduct">
          <ng-template matStepLabel>Elérhetőség</ng-template>

          <div class="d-flex justify-content-between">
            <h3>Hol érhetünk el?</h3>
            <button class="btn btn-sm btn-outline-primary btn-rounded align-self-start" (click)="openLogin();" *ngIf="!user">
              <i class="fa fa-sign-in"></i>
              Bejelentkezés
            </button>
          </div>
          <div class="row g-4 mb-3">

            <div class="col-24 col-md">
              <div class="form-floating mb-2">
                <input type="email"
                       class="form-control"
                       id="emailInput"
                       name="emailInput"
                       autocorrect="off"
                       autocomplete="off"
                       data-lpignore="true"
                       placeholder="{{'address.email' | translate}}"
                       maxlength="50"
                       formControlName="email"
                       [ngClass]="fullForm.get('personalData').get('email').invalid ? 'invalid' : ''">
                <label for="emailInput">{{'address.email' | translate}}*</label>

                <div *ngIf="fullForm.get('personalData').get('email').invalid &&
        fullForm.get('personalData').get('email').errors &&
        (fullForm.get('personalData').get('email').dirty || fullForm.get('personalData').get('email').touched)">

                  <small class="message"  *ngIf="fullForm.get('personalData').hasError('email') && !fullForm.get('personalData').hasError('required')">
                    {{'error.missing.email' | translate}}
                  </small>
                  <small class="delayed-message" *ngIf="fullForm.get('personalData').get('email').hasError('email')">
                    {{'error.invalid.email.format' | translate}}
                  </small>
                </div>

              </div>
            </div>

            <div class="col-24 col-md">
              <div class="form-floating mb-2">
                <input type="text"
                       class="form-control"
                       id="nameInput"
                       placeholder="Név"
                       autocorrect="off"
                       autocomplete="off"
                       data-lpignore="true"
                       formControlName="name">
                <label for="nameInput">Név*</label>

                <div *ngIf="fullForm.get('personalData').get('name').invalid &&
        fullForm.get('personalData').get('name').errors &&
        (fullForm.get('personalData').get('name').dirty || fullForm.get('personalData').get('name').touched)">
                  <small class="message" *ngIf="fullForm.get('personalData').get('name').hasError('required')">
                    {{'error.missing.name' | translate}}
                  </small>
                  <small class="delayed-message" *ngIf="fullForm.get('personalData').get('name').hasError('minlength')">
                    {{'error.name.minlength' | translate}}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="row g-4">
            <div class="col-24 col-md">
              <div class="form-floating">
                <input type="text"
                       class="form-control"
                       maxlength="20"
                       autocorrect="off"
                       autocomplete="off"
                       data-lpignore="true"
                       id="phoneInput"
                       placeholder="Telefonszám"
                       formControlName="phoneNumber"
                       (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
                <label for="phoneInput">Telefonszám*</label>
                <div *ngIf="fullForm.get('personalData').get('phoneNumber').invalid &&
        fullForm.get('personalData').get('phoneNumber').errors &&
        (fullForm.get('personalData').get('phoneNumber').dirty || fullForm.get('personalData').get('phoneNumber').touched)">
                  <small class="message" *ngIf="fullForm.get('personalData').get('phoneNumber').hasError('required')">
                    {{'error.missing.phone' | translate}}
                  </small>
                  <small class="delayed-message" *ngIf="fullForm.get('personalData').get('phoneNumber').hasError('pattern')">
                    {{'error.format.phone' | translate}}
                  </small>
                </div>
              </div>
            </div>

            <div class="col-24 col-md d-flex align-items-center">
              <div class="form-text"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i> Helyes formátum: +3612345678
              </div>
            </div>


            <div class="col-24 form-check mb-4 mt-5" *ngIf="!user">
              <input class="form-check-input" type="checkbox" id="newsletterCheck" formControlName="subscribeToNewsletter">
              <label class="form-check-label" for="newsletterCheck">
                Szeretnék hírlevelet kapni az akciókról, újdonságokról.
              </label>
            </div>

          </div>

          <div class="d-flex justify-content-sm-end mt-5 mt-sm-0">

            <span class="" *ngIf="notInStockProduct"><i class="fa fa-lg fa-exclamation-circle text-danger"></i> Egy vagy több termék nem elérhető, töröld a kosaradból a rendelés folytatásához.
            </span>

            <button [disabled]="fullForm.get('personalData').invalid || notInStockProduct" matStepperNext class="btn btn-primary text-white flex-grow-1 flex-sm-grow-0"><i class="fa fa-chevron-right"></i> Tovább a szállítási módokhoz
            </button>
          </div>

        </mat-step>
        <mat-step [completed]="user ? fullForm.get('selectedShippingAddressId').valid : fullForm.get('shippingAddress').valid && !notInStockProduct">
          <ng-template matStepLabel>Szállítás</ng-template>

          <div class="row g-4 pb-5">
            <div class="col-lg-24">
              <div class="checkout-over text-truncate" (click)="move(0)" tooltip="Elérhetőség szerkesztése" [placement]="'top'">
                <h6><i class="fa fa-pencil"></i> Elérhetőség</h6>
                {{fullForm.value.personalData.name + ' ' + fullForm.value.personalData.email + ' ' + fullForm.value.personalData.phoneNumber}}
              </div>
            </div>
          </div>

          <h3 class="mt-5 mb-2">Hova szállítsuk a terméket?</h3>

          <div class="col-24 pt-4">

            <accordion [isAnimated]="true" [closeOthers]="oneAtATime">
              <accordion-group [panelClass]="customAccordion" [(isOpen)]="isOpen[1]" [isDisabled]="true">
                <div class="w-100 shadow-none pt-4" accordion-heading (click)="deliveryCheck2()">
                  <span *ngIf="isLoaded" class="badge badge-primary bg-info float-right pull-right">{{checkoutShoppingCartComponent.subTotal < settings.freeShippingPrice ? ((settings.shippingPrice | thousandSeparator) + ' Ft') : 'Ingyenes'}}</span>
                  <div class="form-check mb-4 pull-left float-left w-75">
                    <input class="form-check-input" type="radio" id="delivery2" [checked]="'true'">
                    <!--                    <input class="form-check-input" type="checkbox" id="delivery2" [checked]="deliveryIsChecked2">-->
                    <label class="form-check-label">
                      Házhozszállítás GLS futárszolgálattal magyarországi címre<br>
                      <span class="form-text">Fizetés internetes bankkártyás fizetéssel, vagy Paypal felületen, vagy a csomag átvételekor</span>
                    </label>

                  </div>

                </div>
                <img src="/assets/images/gls-logo.png" alt="GLS" class="pull-right">
                <p>Csomagját a GLS futárszolgálat munkatársai fogják kiszállítani Önnek a megrendelés beérkezését követő
                  1-3 munkanapon belül. A 20.000 Ft alatti rendelések szállítási díja 2.500 Ft. A 20.000 Ft feletti
                  rendelések szállítási díját mi fizetjük Ön helyett. Az árak tartalmazzák az áfa értékét. Választható
                  fizetési módok:
                </p>
                <ul>
                  <li>fizetés a csomag átvételekor (utánvét)</li>
                  <li>internetes bankkártyás előrefizetés</li>
                  <li>internetes Paypal előrefizetés</li>
                </ul>

                <h3 *ngIf="user">Szállítási cím kiválasztása:</h3>

                <div class="d-flex justify-content-between" *ngIf="!user">
                  <h3 class="mb-5">Szállítási cím:</h3>
<!--                  <div class="form-text pt-5 pe-5"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i> Helyes formátum: +3612345678-->
<!--                  </div>-->
                </div>

                <div class="pb-4" *ngIf="profile && profile.addresses && profile.addresses.length > 0">
                  <div class="row" *ngFor="let a of profile.addresses">
                    <div class="col-2">
                      <div class="form-check mt-5 ms-5">
                        <input class="form-check-input" type="radio" name="selectedShippingAddressId" formControlName="selectedShippingAddressId" [value]="a.id">
                      </div>
                    </div>
                    <div class="col-22">
                      <app-checkout-address [isUpdateEnabled]="true" [address]="a"></app-checkout-address>
                    </div>
                    <div class="col-24">
                      <hr>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="user">
                  <div class="col">
                    <button class="btn btn-default" (click)="openNewAddressModal()">Új szállítási cím hozzáadása</button>
                  </div>
                </div>


                <div class="row g-3 pb-4" *ngIf="!user" [formGroupName]="'shippingAddress'">
                  <div class="col-lg-12">

                    <div class="form-floating">
                      <input type="text" maxlength="50" class="form-control mb-4" placeholder="Név" formControlName="name">
                      <label>Név</label>

                      <div *ngIf="fullForm.get('shippingAddress').get('name').invalid &&
        fullForm.get('shippingAddress').get('name').errors &&
        (fullForm.get('shippingAddress').get('name').dirty || fullForm.get('shippingAddress').get('name').touched)">
                        <small class="message" *ngIf="fullForm.get('shippingAddress').get('name').hasError('required')">
                          {{'error.missing.name' | translate}}
                        </small>
                        <small class="delayed-message"
                               *ngIf="fullForm.get('shippingAddress').get('name').hasError('minlength')">
                          {{'error.min.3.character' | translate}}
                        </small>
                      </div>

                    </div>
                  </div>

                  <div class="col-lg-12">

                    <div class="form-floating">
                      <input type="text"
                             class="form-control mb-4"
                             placeholder="Telefonszám"
                             formControlName="phoneNumber"
                             maxlength="20"
                             (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
                      <label>Telefonszám*</label>

                      <div *ngIf="fullForm.get('shippingAddress').get('phoneNumber').invalid &&
        fullForm.get('shippingAddress').get('phoneNumber').errors &&
        (fullForm.get('shippingAddress').get('phoneNumber').dirty || fullForm.get('shippingAddress').get('phoneNumber').touched)">
                        <small class="message" *ngIf="fullForm.get('shippingAddress').get('phoneNumber').hasError('required')">
                          {{'error.missing.phone' | translate}}
                        </small>
                        <small class="delayed-message" *ngIf="fullForm.get('shippingAddress').get('phoneNumber').hasError('minlength')">
                          {{'error.length.phone' | translate}}
                        </small>
                        <small class="message" *ngIf="fullForm.get('shippingAddress').get('phoneNumber').hasError('maxlength')">
                          {{'error.length.phone' | translate}}
                        </small>
                        <small class="delayed-message" *ngIf="fullForm.get('shippingAddress').get('phoneNumber').hasError('pattern')">
                          {{'error.format.phone' | translate}}
                        </small>
                      </div>

                    </div>
                  </div>
                  <div class="col-sm-8 col-lg-6 col-xl-4">
                    <div class="form-floating">
                      <input type="number"
                             class="form-control mb-4 mb-lg-0"
                             placeholder="Irányítószám"
                             formControlName="postalCode"
                             maxlength="4">
                      <label>Irányítószám</label>
                      <div *ngIf="fullForm.get('shippingAddress').get('postalCode').invalid &&
        fullForm.get('shippingAddress').get('postalCode').errors &&
        (fullForm.get('shippingAddress').get('postalCode').dirty || fullForm.get('shippingAddress').get('postalCode').touched)">
                        <small class="message" *ngIf="fullForm.get('shippingAddress').get('postalCode').hasError('required')">
                          {{'error.missing.postal.code' | translate}}
                        </small>
                        <small class="delayed-message" *ngIf="fullForm.get('shippingAddress').get('postalCode').hasError('pattern')">
                          {{'error.need.4.number' | translate}}
                        </small>
                        <small class="delayed-message" *ngIf="fullForm.get('shippingAddress').get('postalCode').hasError('minlength')">
                          {{'error.need.4.number' | translate}}
                        </small>
                        <small class="delayed-message" *ngIf="fullForm.get('shippingAddress').get('postalCode').hasError('maxlength')">
                          {{'error.need.4.number' | translate}}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-16 col-lg-8 col-xl-8">
                    <div class="form-floating">
                      <input type="text" class="form-control mb-4  mb-lg-0" placeholder="Település" formControlName="city" maxlength="30">
                      <label>Település</label>
                      <div *ngIf="fullForm.get('shippingAddress').get('city').invalid &&
        fullForm.get('shippingAddress').get('city').errors &&
        (fullForm.get('shippingAddress').get('city').dirty || fullForm.get('shippingAddress').get('city').touched)">
                        <small class="message" *ngIf="fullForm.get('shippingAddress').get('city').hasError('required')">
                          {{'error.missing.city' | translate}}
                        </small>
                        <small class="delayed-message"
                                *ngIf="fullForm.get('shippingAddress').get('city').hasError('minlength')">
                          {{'error.min.2.character' | translate}}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-10 col-xl-12">
                    <div class="form-floating">
                      <input type="text" class="form-control" placeholder="Cím (utca, házszám)" formControlName="address" maxlength="30">
                      <label>Cím (utca, házszám)</label>

                      <div *ngIf="fullForm.get('shippingAddress').get('address').invalid &&
        fullForm.get('shippingAddress').get('address').errors &&
        (fullForm.get('shippingAddress').get('address').dirty || fullForm.get('shippingAddress').get('address').touched)">
                      <small class="message" *ngIf="fullForm.get('shippingAddress').get('address').hasError('required')">
                        {{'error.missing.address' | translate}}
                      </small>
                      <small class="delayed-message" *ngIf="fullForm.get('shippingAddress').get('address').hasError('minlength')">
                        {{'error.min.2.character' | translate}}
                      </small>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="user">
                    <button class="btn btn-primary" (click)="addNewShippingAddress = false">Mégsem</button>
                    <button class="btn btn-dark" (click)="addNewAddress()">Hozzáadás</button>
                  </div>
                </div>
              </accordion-group>
            </accordion>

            <div class="d-flex justify-content-sm-end mt-5">
              <button matStepperNext class="btn btn-primary text-white flex-grow-1 flex-sm-grow-0" [disabled]="addNewShippingAddress || (user ? fullForm.get('selectedShippingAddressId').invalid : fullForm.get('shippingAddress').invalid)"><i class="fa fa-chevron-right"></i> Tovább az fizetéshez
              </button>
            </div>

          </div>
        </mat-step>
        <mat-step [editable]="true" [completed]="!(fullForm.get('paymentType').invalid || (companyChecked ? fullForm.get('company').invalid : (user ? fullForm.get('selectedBillingAddressId').invalid : fullForm.get('billingAddress').invalid))) && !notInStockProduct && (fullForm.get('paymentType').value === PaymentType.BANK_TRANSFER ? fullForm.get('isCardPaymentConfirmed').value === true : true)">

          <div class="row g-4 mt-2">
            <div class="col-lg-12">
              <div class="checkout-over text-truncate" (click)="move(0)" tooltip="Elérhetőség szerkesztése" [placement]="'top'">
                <h6><i class="fa fa-pencil"></i> Elérhetőség</h6>
                {{fullForm.value.personalData.name + ' ' + fullForm.value.personalData.email + ' ' + fullForm.value.personalData.phoneNumber}}
              </div>
            </div>
            <div class="col-lg-12">
              <div class="checkout-over text-truncate" (click)="move(1)" tooltip="Szállítási cím szerkesztése" [placement]="'top'">
                <h6><i class="fa fa-pencil"></i> Szállítás</h6>
                {{user ? (getAddressById(fullForm.value.selectedShippingAddressId)?.postalCode + ' ' + getAddressById(fullForm.value.selectedShippingAddressId)?.city + ' ' + getAddressById(fullForm.value.selectedShippingAddressId)?.address) : (fullForm.value.shippingAddress.postalCode + ' ' + fullForm.value.shippingAddress.city + ' ' + fullForm.value.shippingAddress.address)}}
              </div>
            </div>
          </div>

          <h3 class="mt-5">Válassz fizetési módot</h3>

          <ng-template matStepLabel>Fizetés</ng-template>
            <div class="pt-4">
              <accordion [isAnimated]="true" [closeOthers]="oneAtATime">
                <accordion-group [panelClass]="customAccordion">
                  <div class="w-100 shadow-none pt-4" accordion-heading>
                    <span class="badge badge-primary bg-info pull-right">Ingyenes</span>
                    <div class="form-check mb-4 pull-left w-75">
                      <input class="form-check-input" type="radio" id="payment1" name="paymentType" [value]="PaymentType.CASH_ON_DELIVERY" (click)="$event.stopPropagation();setPaymentType(PaymentType.CASH_ON_DELIVERY);" [(ngModel)]="cashOnDeliveryCheckbox" [ngModelOptions]="{standalone: true}">
                      <label class="form-check-label" for="payment1">
                        Utánvétel - Fizetés a csomag átvételekor
                      </label>
                    </div>
                  </div>
                  <i class="fa fa-3x fa-credit-card me-4 pull-right" tooltip="Kártyás fizetés" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
                  <i class="fa fa-3x fa-money pull-right" tooltip="Készpénzes fizetés" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
                  <p>Házhoz szállítás esetén a számla végösszegét a csomag átvételekor a GLS futárszolgálat
                    munkatársának kell megfizetni. Amennyiben a csomagot GLS csomagpontba kérte, a csomagpont
                    kiválasztásakor információt kap arról, hogy lehetséges - e a csomagpontban a kártyás fizetés.
                  </p>

                </accordion-group>
                <accordion-group [panelClass]="customAccordion">
                  <div class="w-100 shadow-none pt-4" accordion-heading>
                    <span class="badge badge-primary bg-info pull-right">Ingyenes</span>
                    <div class="form-check mb-4 pull-left w-75">
                      <input class="form-check-input" type="radio" id="payment2" name="paymentType" [value]="PaymentType.BANK_TRANSFER" (click)="$event.stopPropagation();setPaymentType(PaymentType.BANK_TRANSFER);" [(ngModel)]="bankTransferCheckbox" [ngModelOptions]="{standalone: true}">
                      <label class="form-check-label" for="payment2">
                        Bankkártyás fizetés
                      </label>
                    </div>
                  </div>
                  <i class="fa fa-3x fa-credit-card me-4 pull-right" tooltip="Kártyás fizetés"
                     [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
                  <p>Az internetes bankkártyás fizetés során a fizetési folyamat végén átirányítjuk az OTP Bank
                    internetes fizetési oldalára, majd a fizetés végén visszairányítjuk webáruházunkba. Használható
                    kártyatípusok: MasterCard , Maestro, Visa
                  </p>
                  <p class="p-2 p-lg-3 text-info border border-info"><i
                    class="fa fa-lg fa-exclamation-triangle text-danger"></i> Felhívjuk figyelmét, hogy az internetes
                    bankkártyás fizetés esetén kötelező a kétlépcsős azonosítás elvégzése. Ezt SMS-ben kapott kód
                    megadásával, vagy mobilaplikáció segítségével tudja megtenni. Ennek részleteiről a bankkártyát
                    kibocsátó pénzintézet tud bővebb felvilágosítást adni.</p>
                </accordion-group>

                <accordion-group [panelClass]="customAccordion" *ngIf="user?.retail">
                  <div class="w-100 shadow-none pt-4" accordion-heading>
                    <div class="form-check mb-4 pull-left w-75">
                      <input class="form-check-input" type="radio" id="payment3" name="paymentType" [value]="PaymentType.DEFERRED_PAYMENT" (click)="$event.stopPropagation();setPaymentType(PaymentType.DEFERRED_PAYMENT);" [(ngModel)]="deferredPaymentCheckbox" [ngModelOptions]="{standalone: true}">
                      <label class="form-check-label" for="payment3">
                        Halasztott átutalás
                      </label>
                    </div>
                  </div>
<!--                  <i class="fa fa-3x fa-file-o pull-right" tooltip="Halasztott átutalás viszonteladók részére" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>-->
                  <p>Viszonteladók részére. Előre egyeztetett dátumra az összeget jóvá kell írni a kedvezményezett számláján.</p>

                </accordion-group>

              </accordion>

              <h3 class="mt-5 pt-3">Számlázási cím</h3>

              <div class="d-flex justify-content-between">
                <div>
              <div class="form-check mb-4" *ngIf="!user">
                <input class="form-check-input" type="checkbox" id="billingAddressCheck" [disabled]="companyChecked" [(ngModel)]="isBillingAddressSame" (ngModelChange)="billingAddressCheck($event)" [ngModelOptions]="{standalone: true}">
                <label class="form-check-label" for="billingAddressCheck" [ngClass]="companyChecked ? 'disabled' : ''">
                  A szállítási cím megegyezik a számlázási címmel
                </label>
              </div>
              <div class="form-check mb-5">
                <input class="form-check-input" type="checkbox" id="companyCheck" [(ngModel)]="companyChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="companyCheck($event)">
                <label class="form-check-label" for="companyCheck">
                  Cégként vásárolok
                </label>
              </div>
                </div>
              </div>

<!--              <div class="pb-4" *ngIf="!companyChecked && profile && profile.addresses && profile.addresses.length > 0">-->
<!--                <div class="row" *ngFor="let a of profile.addresses">-->
<!--                  <div class="col-1">-->
<!--                    <input class="form-check" type="radio" name="selectedBillingAddressId" formControlName="selectedBillingAddressId" [value]="a.id">-->
<!--                  </div>-->
<!--                  <div class="col-23">-->
<!--                    <app-checkout-address [isUpdateEnabled]="false" [address]="a"></app-checkout-address>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <div class="pb-4" *ngIf="!companyChecked && profile && profile.addresses && profile.addresses.length > 0">
                <div class="row" *ngFor="let a of profile.addresses">
                  <div class="col-2">
                    <div class="form-check mt-5 ms-5">
                      <input class="form-check-input" type="radio" name="selectedBillingAddressId" formControlName="selectedBillingAddressId" [value]="a.id">
                    </div>
                  </div>
                  <div class="col-24">
                    <app-checkout-address [isUpdateEnabled]="false" [address]="a"></app-checkout-address>
                  </div>
                  <div class="col-24">
                    <hr>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="user && !companyChecked">
                <div class="col">
                  <button class="btn btn-sm btn-outline-dark" *ngIf="!addNewShippingAddress" (click)="openNewAddressModal()">Új cím hozzáadása <i class="fa fa-plus"></i> </button>
                </div>
              </div>

              <div class="row g-4 mb-3" *ngIf="!user || companyChecked" [formGroup]="companyChecked ? fullForm.get('company') : fullForm.get('billingAddress')">

                <div class="col-24">
                  <accordion [isAnimated]="true">
                    <accordion-group [isOpen]="!companyChecked" [panelClass]="customCompanyPanelClass">
                      <div class="form-floating mb-5">
                        <input type="text" class="form-control" placeholder="Név" formControlName="name" maxlength="50">
                        <label>Név</label>

                        <div *ngIf="fullForm.get('billingAddress').get('name').invalid &&
        fullForm.get('billingAddress').get('name').errors &&
        (fullForm.get('billingAddress').get('name').dirty || fullForm.get('billingAddress').get('name').touched)">
                          <small class="message" *ngIf="fullForm.get('billingAddress').get('name').hasError('required')">
                            {{'error.missing.name' | translate}}
                          </small>
                          <small class="delayed-message" *ngIf="fullForm.get('billingAddress').get('name').hasError('minlength')">
                            {{'error.min.3.character' | translate}}
                          </small>
                        </div>

                      </div>
                    </accordion-group>
                    <accordion-group [isOpen]="companyChecked" heading="Cégadatok" [panelClass]="customCompanyPanelClass">
                      <div class="row g-3" *ngIf="companyChecked">
                        <div class="col-xl-12">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="companyNameInput" placeholder="Cégnév" formControlName="name">
                            <label for="companyNameInput">Cégnév</label>
                            <div *ngIf="fullForm.get('company').get('name').invalid &&
        fullForm.get('company').get('name').errors &&
        (fullForm.get('company').get('name').dirty || fullForm.get('company').get('name').touched)">
                              <small class="message" *ngIf="fullForm.get('company').get('name').hasError('required')">
                                {{'error.company.missing.name' | translate}}
                              </small>
                              <small class="delayed-message" *ngIf="fullForm.get('company').get('name').hasError('minlength')">
                                {{'error.min.3.character' | translate}}
                              </small>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 col-xl-6" *ngIf="companyChecked">
                          <div class="form-floating">
                            <input type="text" mask="00000000-0-00" [showMaskTyped]="true" class="form-control" id="taxNumber" placeholder="Adószám" formControlName="taxNumber">
                            <label for="taxNumber">Adószám</label>
                            <div *ngIf="fullForm.get('company').get('taxNumber').invalid &&
        fullForm.get('company').get('taxNumber').errors &&
        (fullForm.get('company').get('taxNumber').dirty || fullForm.get('company').get('taxNumber').touched)">
                              <small class="message" *ngIf="fullForm.get('company').get('taxNumber').hasError('required')">
                                {{'error.missing.taxnumber' | translate}}
                              </small>
                            </div>

                          </div>
                        </div>

                        <div class="col-md-12 col-xl-6" *ngIf="companyChecked">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="companyPhoneNumber" placeholder="Telefonszám" formControlName="phoneNumber" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
                            <label for="companyPhoneNumber">Telefonszám</label>

                            <div *ngIf="fullForm.get('company').get('phoneNumber').invalid &&
        fullForm.get('company').get('phoneNumber').errors &&
        (fullForm.get('company').get('phoneNumber').dirty || fullForm.get('company').get('phoneNumber').touched)">
                              <small class="message" *ngIf="fullForm.get('company').get('phoneNumber').hasError('required')">
                                {{'error.missing.phone' | translate}}
                              </small>
                              <small class="delayed-message" *ngIf="fullForm.get('company').get('phoneNumber').hasError('pattern')">
                                {{'error.format.phone' | translate}}
                              </small>
                            </div>

                          </div>
                        </div>
                      </div>
                    </accordion-group>
                  </accordion>
                </div>

                <div class="col-xl-6" *ngIf="!companyChecked">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="billingPhoneNumber" placeholder="Telefonszám" formControlName="phoneNumber" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
                    <label for="billingPhoneNumber">Telefonszám</label>
                    <div *ngIf="fullForm.get('billingAddress').get('phoneNumber').invalid &&
        fullForm.get('billingAddress').get('phoneNumber').errors &&
        (fullForm.get('billingAddress').get('phoneNumber').dirty || fullForm.get('billingAddress').get('phoneNumber').touched)">
                      <small class="message" *ngIf="fullForm.get('billingAddress').get('phoneNumber').hasError('required')">
                        {{'error.missing.phone' | translate}}
                      </small>
                      <small class="delayed-message" *ngIf="fullForm.get('billingAddress').get('phoneNumber').hasError('pattern')">
                        {{'error.format.phone' | translate}}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="col-sm-8 col-lg-8 col-xl-6">
                  <div class="form-floating">
                    <input type="number"
                           class="form-control"
                           maxlength="4"
                           autocorrect="off"
                           autocomplete="off"
                           id="billingZip"
                           placeholder="Irányítószám"
                           formControlName="postalCode">
                    <label for="billingZip">Irányítószám</label>

                    <div *ngIf="fullForm.get('billingAddress').get('postalCode').invalid &&
        fullForm.get('billingAddress').get('postalCode').errors &&
        (fullForm.get('billingAddress').get('postalCode').dirty || fullForm.get('shippingAddress').get('postalCode').touched)">
                      <small class="message" *ngIf="fullForm.get('billingAddress').get('postalCode').hasError('required')">
                        {{'error.missing.postal.code' | translate}}
                      </small>
                      <small class="delayed-message" *ngIf="fullForm.get('billingAddress').get('postalCode').hasError('pattern')">
                        {{'error.need.4.number' | translate}}
                      </small>
                      <small class="delayed-message" *ngIf="fullForm.get('billingAddress').get('postalCode').hasError('minlength')">
                        {{'error.need.4.number' | translate}}
                      </small>
                    </div>
                  </div>
                </div>

                <div [ngClass]="companyChecked ? 'col-sm-16 col-xl-18' : 'col-sm-16 col-xl-12'">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="billingCity" placeholder="Telepűlés" formControlName="city">
                    <label for="billingCity">Település</label>

                    <div *ngIf="fullForm.get('billingAddress').get('city').invalid &&
        fullForm.get('billingAddress').get('city').errors &&
        (fullForm.get('billingAddress').get('city').dirty || fullForm.get('billingAddress').get('city').touched)">
                      <small class="message" *ngIf="fullForm.get('billingAddress').get('city').hasError('required')">
                        {{'error.missing.city' | translate}}
                      </small>
                      <small class="delayed-message" *ngIf="fullForm.get('billingAddress').get('city').hasError('minlength')">
                        {{'error.min.2.character' | translate}}
                      </small>
                    </div>

                  </div>
                </div>

                <div class="col-24">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="billingAddress" placeholder="Cím (utca, házszám)" formControlName="address">
                    <label for="billingAddress">Cím (utca, házszám)</label>
                    <div *ngIf="fullForm.get('billingAddress').get('address').invalid &&
        fullForm.get('billingAddress').get('address').errors &&
        (fullForm.get('billingAddress').get('address').dirty || fullForm.get('billingAddress').get('address').touched)">
                      <small class="message" *ngIf="fullForm.get('billingAddress').get('address').hasError('required')">
                        {{'error.missing.address' | translate}}
                      </small>
                      <small class="delayed-message" *ngIf="fullForm.get('billingAddress').get('address').hasError('minlength')">
                        {{'error.min.2.character' | translate}}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="hstack gap-3 d-flex justify-content-center mt-5" *ngIf="user && companyChecked">
                  <button class="btn btn-outline-dark" *ngIf="fullForm.get('company').disabled" (click)="enableUpdateCompany()">Szerkesztés</button>
                  <button class="btn btn-outline-primary" *ngIf="fullForm.get('company').enabled" (click)="cancelUpdateCompany()">Mégse</button>
                  <button class="btn btn-dark" type="submit" [disabled]="!fullForm.get('company').dirty || fullForm.get('company').invalid" (click)="updateCompany()">Mentés</button>
                </div>
              </div>

              <div class="d-flex justify-content-xxxl-between mt-5">
                <div>
                  <input class="form-check-input me-4" type="checkbox" id="isCardPaymentConfirmed" formControlName="isCardPaymentConfirmed" *ngIf="fullForm.get('paymentType').value === PaymentType.BANK_TRANSFER">
                  <label class="form-check-label" for="isCardPaymentConfirmed" *ngIf="fullForm.get('paymentType').value === PaymentType.BANK_TRANSFER">
                    Az <span (click)="openConfirmInfo()" class="text-primary"><u style="cursor:pointer">adattovábbítási nyilatkozatot</u></span> jóváhagyom.
                  </label>
                </div>
                <button matStepperNext class="btn btn-primary text-white flex-grow-1 flex-sm-grow-0" [disabled]="fullForm.get('paymentType').invalid || (companyChecked ? fullForm.get('company').invalid : (user ? fullForm.get('selectedBillingAddressId').invalid : fullForm.get('billingAddress').invalid)) || notInStockProduct || (fullForm.get('paymentType').value === PaymentType.BANK_TRANSFER ? fullForm.get('isCardPaymentConfirmed').value === false : false)"><i class="fa fa-chevron-right"></i> Tovább az összegzéshez
                </button>
              </div>
            </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Összegzés</ng-template>
          <div class="row g-4 mt-2">

            <div class="col-24">
              <div class="checkout-over text-truncate" (click)="move(0)" tooltip="Elérhetőség szerkesztése" [placement]="'top'">
                <h6><i class="fa fa-pencil"></i> Elérhetőség</h6>
                {{fullForm.value.personalData.name + ' ' + fullForm.value.personalData.email + ' ' + fullForm.value.personalData.phoneNumber}}
              </div>
            </div>
            <div class="col-24">
              <div class="checkout-over text-truncate" (click)="move(1)" tooltip="Szállítási cím szerkesztése" [placement]="'top'">
                <h6><i class="fa fa-pencil"></i> Szállítás</h6>
                {{user ? (getAddressById(fullForm.value.selectedShippingAddressId)?.postalCode + ' ' + getAddressById(fullForm.value.selectedShippingAddressId)?.city + ' ' + getAddressById(fullForm.value.selectedShippingAddressId)?.address) : (fullForm.value.shippingAddress.postalCode + ' ' + fullForm.value.shippingAddress.city + ' ' + fullForm.value.shippingAddress.address)}}
              </div>
            </div>

            <div class="col-24">
              <div class="checkout-over text-truncate" (click)="move(2)" tooltip="Fizetési és szállítási adatok szerkesztése" [placement]="'top'">
                <h6><i class="fa fa-pencil"></i> Fizetés</h6>
                {{companyChecked ? (user && profile.company ? profile.company.name + ' ' + profile.company.phoneNumber + ' ' + profile.company.taxNumber + ' ' + profile.company.postalCode + ' ' + profile.company.city + ' ' + profile.company.address : fullForm.value.company?.name + ' ' + fullForm.value.company?.phoneNumber + ' ' + fullForm.value.company?.taxNumber + ' ' + fullForm.value.company?.postalCode + ' ' + fullForm.value.company?.address) : (user ? getAddressById(fullForm.value.selectedBillingAddressId)?.name + ' ' + getAddressById(fullForm.value.selectedBillingAddressId)?.phoneNumber + ' ' + getAddressById(fullForm.value.selectedBillingAddressId)?.postalCode + ' ' + getAddressById(fullForm.value.selectedBillingAddressId)?.city + ' ' + getAddressById(fullForm.value.selectedBillingAddressId)?.address : fullForm.value.billingAddress.name + ' ' + fullForm.value.billingAddress.phoneNumber + ' ' + fullForm.value.billingAddress.postalCode + ' ' + fullForm.value.billingAddress.city + ' ' + fullForm.value.billingAddress.address)}}
              </div>
            </div>

          </div>
          <div class="shopping-cart" *ngIf="isLoaded">

<!--            <div *ngFor="let p of checkoutShoppingCartComponent.productsInCart" class="pt-4">-->
<!--              <div class="product">-->
<!--                <div class="product-details">-->
<!--                  <span class="mt-0 mb-0 product-title">{{checkoutShoppingCartComponent.models[p.parentProductId].name}}</span>-->
<!--                  -->
<!--                  -->
<!--                  {{p.amount}} x <span class="text-danger">-->
<!--                  {{checkoutShoppingCartComponent.productsById[p.productId].price}}-->
<!--                  Ft</span>-->
<!--                </div>-->

<!--                <div class="product-line-price text-nowrap">{{p.amount * checkoutShoppingCartComponent.productsById[p.productId].price}}</div>-->
<!--              </div>-->
<!--            </div>-->



            <div class="form-floating mt-5 mb-4">
              <textarea style="height:100px" class="form-control" placeholder="Üzenet" id="floatingTextarea" formControlName="message"></textarea>
              <label for="floatingTextarea">Üzenet</label>
            </div>


                <div class="row  mt-5">
                  <div class="col-18 text-end">Összeg:</div>
                  <div class="col text-end text-nowrap" id="cart-subtotal">{{checkoutShoppingCartComponent.subTotal | thousandSeparator}} Ft</div>

                  <div class="col-18 text-end">Házhozszállítás:</div>
                  <div class="col text-end text-nowrap">{{!checkoutShoppingCartComponent.isShippingFree ? ((settings.shippingPrice | thousandSeparator) + ' Ft') : 'Ingyenes'}}</div>

                  <div class="col-18 text-end mt-5"><b class="lead">Bruttó végösszeg:</b></div>
                  <div class="col text-end text-nowrap mt-5"><b class="lead">{{checkoutShoppingCartComponent.isShippingFree ? checkoutShoppingCartComponent.subTotal : checkoutShoppingCartComponent.subTotal + settings.shippingPrice | thousandSeparator}} Ft</b></div>

                </div>



            <span *ngIf="notInStockProduct">Egy vagy több termék nincs raktáron, ezeket a termékeket töröld ki előbb a kosárból, hogy le tudd adni a rendelést.</span>
            <button *ngIf="fullForm.value.paymentType !== PaymentType.BANK_TRANSFER" (click)="createOrder()" class="btn btn-lg btn-rounded btn-primary w-100 mt-5" [disabled]="notInStockProduct">Rendelés</button>
<!--            <button *ngIf="fullForm.value.paymentType !== PaymentType.BANK_TRANSFER" (click)="createOrder()" class="btn btn-lg btn-rounded btn-primary w-100 mt-5">Rendelés</button>-->
<!--            <div class="alert alert-warning" *ngIf="unsuccessfulPayment">-->
<!--              Sikertelen fizetés, az alábbi gombra kattintva próbálja meg újra!-->
<!--            </div>-->
            <button *ngIf="fullForm.value.paymentType == PaymentType.BANK_TRANSFER" (click)="createOrder()" class="btn btn-lg btn-primary w-100 mt-5" [disabled]="notInStockProduct">Rendelés és fizetés</button>
<!--            <button *ngIf="fullForm.value.paymentType == PaymentType.BANK_TRANSFER" (click)="createOrder()" class="btn btn-lg btn-primary w-100 mt-5">Rendelés és fizetés</button>-->
          </div>
        </mat-step>
      </mat-horizontal-stepper>

    </form>

    <div class="col-24 col-lg-10">
      <app-checkout-shopping-cart></app-checkout-shopping-cart>
    </div>
  </div><!-- row END -->
</div><!-- container-fluid END -->


<ng-template #addressTemplate let-form="form">
  <div class="col-24 flex-column mt-5" [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <input type="text" class="col-24 form-control" name="country" formControlName="country" [placeholder]="'address.country' | translate">
      </div>
      <div class="col-12 d-flex ps-0 pe-0">
        <div class="col-10">
          <input type="number" max="9999" class="form-control" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
        </div>
        <div class="col-14 ps-1">
          <input type="text" class="form-control" name="city" formControlName="city" [placeholder]="'address.city' | translate">
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-24">
        <input type="text" class="col-24 form-control" name="address" formControlName="address" [placeholder]="'address.address' | translate">
      </div>
    </div>
  </div>
</ng-template>


