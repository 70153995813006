import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CartState } from '../store/cart/CartReducer';
import { Store } from '@ngrx/store';
import { selectCartState } from '../store/cart/CartSelector';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuard implements CanActivate {

  constructor(private store: Store<CartState>,
              private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const cart = await this.store.select(selectCartState).toPromise();
    const isCartEmpty = Object.values(cart).length === 0;
    if (isCartEmpty) {
      this.router.navigateByUrl('');
      return false;
    }
    return true;
  }

}
