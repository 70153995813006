import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterState, getDedicatedFilters, getFilters, getOrderings } from './FilterReducer';

export const selectFilterState = createFeatureSelector<FilterState>('filter');

export const initFiltersSelector = createSelector(
  selectFilterState,
  getFilters
);

export const initDedicatedFiltersSelector = createSelector(
  selectFilterState,
  getDedicatedFilters
);

export const initOrderingsSelector = createSelector(
  selectFilterState,
  getOrderings
);
