<div class="pt-4 d-flex justify-content-between text-white shopping-cart-header">

  <div class="ms-4">
  <i class="fa fa-2x fa-shopping-cart"></i>
  <span class="ps-4 lead">{{ 'kosár' }}</span>
  </div>

  <h4 *ngIf="user?.discount" class="pt-2 text-warning mb-0 text-uppercase">
    Kedvezményed: {{user.discount}}%
  </h4>

  <div class="pe-5">
  <i class="fa fa-2x fa-times" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" (click)="cartSidenav.close()"></i>
  </div>

</div>

<div *ngIf="productsInCart.length > 0" class="sidecart-container">

  <div *ngFor="let p of productsInCart" class="product mt-1 " [ngClass]="{'not-enough-product': productsById[p.productId].count < p.amount, 'inactive-product': !models[p.parentProductId].active}">
    <div class="product-image">
      <img src="/api/product{{models[p.parentProductId].image.original}}" alt="{{models[p.parentProductId].name}}" appHideMissing>
    </div>

      <div class="product-details">
        <span class="mt-0 mb-0 product-title"><a (click)="cartSidenav.close()" [routerLink]="'/termek/' + models[p.parentProductId].link">{{models[p.parentProductId].name}}</a> - {{productsById[p.productId].originalColor && productsById[p.productId].originalColor.length > 0 ? productsById[p.productId].originalColor : ''}} {{productsById[p.productId].size && productsById[p.productId].size.length > 0 ? productsById[p.productId].size : ''}}</span>
        {{p.amount}} x <span class="text-danger">{{productsById[p.productId].price | thousandSeparator}} Ft
      </span>

        <span class="form-text" *ngIf="productsById[p.productId].count < p.amount">

          <span *ngIf="productsById[p.productId].count > 0"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i>Ebből a termékből csak {{productsById[p.productId].count}} db van raktáron!</span>

          <span *ngIf="productsById[p.productId].count === 0"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i>Ez a termék jelenleg nincs raktáron!</span>
        </span>

        <span class="form-text" *ngIf="!models[p.parentProductId].active">
          <span  *ngIf="productsById[p.productId].count > 0"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i>Ez a termék jelenleg nem kapható!</span>
        </span>

     </div>

    <div class="product-removal" (click)="removeProductFromCart(productsById[p.productId].id)" tooltip="Termék törlése" [adaptivePosition]="false" placement="left" [delay]="1000">
      <i class="fa fa-remove" aria-label="Termék törlése gomb"></i>
    </div>

        <div class="product-quantity">
        <div class="btn-group btn-quantity" role="group" aria-label="Mennyiseg">
          <button [disabled]="cart[p.productId].amount < 2" type="button" class="btn btn-sm btn-sub" (click)="decreaseAmount(p.productId)" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>
          <input disabled [max]="productsById[p.productId].count" class="form-control form-control-sm price-input bg-white" [(ngModel)]="cart[p.productId].amount" type="number" min="1" [ngClass]="productsById[p.productId].count < cart[p.productId].amount ? '.ng-invalid' : ''">
          <button [disabled]="productsById[p.productId].count <= cart[p.productId].amount || !models[p.parentProductId].active" type="button" class="btn btn-sm btn-add" (click)="increaseAmount(p.productId)" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>
        </div>
        </div>



<!--    <div>-->
<!--      <button >X</button>-->
<!--    </div>-->



<!--    <div class="media pt-4">-->

<!--      <div class="prod-image-container align-self-start me-3">-->
<!--      <img src="/api/{{productsById[p.productId].primaryPicture}}" alt="{{productsById[p.productId].name}}">-->
<!--      </div>-->

<!--      <div class="media-body">-->

<!--        <div>-->
<!--          <i class="fa fa-lg fa-trash" aria-label="Termék törlése gomb"-->
<!--             (click)="removeProductFromCart(productsById[p.productId].id)" tooltip="Termék törlése" [adaptivePosition]="false" placement="left" [delay]="1000"></i>-->
<!--        </div>-->

<!--        &lt;!&ndash;          <span class="float-right pt-3" (click)="removeProductFromCart(productsById[p.productId].articleNumber)" tooltip="Termék törlése" [delay]="1000"><i class="fa fa-trash text-danger"></i></span>&ndash;&gt;-->
<!--        -->
<!--        <h5 class="mt-0">{{productsById[p.productId].name}}</h5>-->
<!--&lt;!&ndash;        <p>Hossz m 20 / Mérete 50</p>&ndash;&gt;-->
<!--        {{p.amount}} x <span class="text-danger">{{productsById[p.productId].grossPriceCarriage || productsById[p.productId].grossPrice}}-->
<!--        Ft</span>-->



<!--        <div class="btn-group btn-quantity" role="group" aria-label="Mennyiseg">-->
<!--          <button  type="button" class="btn btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>-->
<!--          <input class="form-control form-control-sm price-input" [(ngModel)]="cart[p.productId].amount" type="number" (click)="$event.target.select()" min="1">-->
<!--          <button type="button" class="btn btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>-->
<!--        </div>-->



<!--      </div>-->

<!--    </div>&lt;!&ndash; media END&ndash;&gt;-->
  </div><!-- ngif END-->

  <p>Összesen {{numberOfProducts}} termék a kosárban.</p>

  <div class="sidecart-bottom fixed-bottom">
    <hr>
    <div class="d-flex justify-content-between w-100" *ngIf="user?.discount">
      <div class="lead">{{user.discount}}% Kedvezmény:</div>
      <div class="text-danger">- {{discount | thousandSeparator}} Ft</div>
    </div>
    <div class="d-flex justify-content-between w-100">
      <div class="lead">Részösszeg:</div>
      <div class="text-danger">{{subTotal | thousandSeparator}} Ft</div>
    </div>
<!--    <hr>-->

    <button class="btn btn-primary btn-rounded w-100 text-white" *ngIf="numberOfProducts > 0" (click)="navigateToCheckout()">
      <i class="fa fa-chevron-right"></i> {{'cart.toCheckout' | translate}}
    </button>

  </div>

<!--  <div *ngIf="(productsInCart | keyvalue).length === 0">-->
<!--    {{"cart.empty" | translate}}-->
<!--  </div>-->

</div>
<!--sidecart-container END-->

