<div class="modal-header">

  <h4 class="modal-title">Vásárlási információk (ÁSZF)</h4>
  <i class="fa fa-2x fa-times" (click)="cancelTermsInfoModal()"></i>
</div>

<div class="modal-body modal-info">
  <p>Jelen Általános Szerződési Feltételek (ÁSZF) a</p>
  <p>cégnév: Conte Magyarország Kft.</p>
  <p>székhelye: 2030 Érd, Földmunkás utca 82.</p>
  <p>adószáma: 25067101-2-13</p>
  <p>cégjegyzékszáma: 01-09-197803; bejegyezte: Fővárosi Bíróság Cégbírósága</p>
  <p>e-mail címe: info@conte.hu</p>
  <p>telefonszáma: +36-30-44-800-63</p>
  <p>webshop elnevezése: Conte Magyarország webáruház</p>
  <p>Adatkezelési nyilvántartási szám: NAIH-80293/2014.</p>
  <p>által nyújtott elektronikus kereskedelmi szolgáltatásokkal kapcsolatos tudnivalókat, a vásárlás feltételeit, a webáruházunkra és a Vevőinkre vonatkozó szabályokat, jogokat és kötelezettségeket tartalmazza.</p>
  <p>Jelen ÁSZF minden olyan adásvételre és szolgáltatás igénybevételre kiterjed, melyet Vevőink a Conte Magyarország webáruházból történő megrendeléssel kezdeményeznek. A Conte Magyarország webáruházban történő vásárlás elsősorban elektronikusan, internet igénybevételével lehetséges.  A vásárlásra a webáruházban az internetes vásárlási folyamat lépéseinek elvégzésével van mód.</p>
  <p>A vevőinknek lehetősége van telefonon, vagy e-mailben is jelezni a vásárlási szándékot, ezekben az esetekben a megrendelést az ügyfélszolgálatunk rögzíti, és erről – mint minden egyéb megrendelésről is -  e-mailben visszaigazolást küldünk. A vásárlási folyamat ezt követően megegyezik az internetes megrendelés utáni folyamattal, ezért jelen ÁSZF minden cégünknél kezdeményezett vásárlásra egyaránt vonatkozik.</p>
  <p>A Conte Magyarország webáruházban való böngészés nyitott minden érdeklődő számára, akár az eladásra kínált termékek, akár a webáruházba feltöltött egyéb típusú internetes tartalmak tekintetében. A vásárlási folyamat megindításához azonban a vevők részéről szükséges legalább a 6. ) pontban felsorolt adataik megadása. A vevőink az áruházunkban az adataik megadásával elfogadják a jelen ÁSZF feltételeit.</p>
  <p>A megrendelés leadása után a rendszerünk automatikusan visszaigazolja a Vevőnek megrendelés tényét, ami által szerződés jön létre. A Felek között az áru megvásárlásával szerződés jön létre, amely egyenrangú azzal, mintha írásba foglalt szerződés jött volna létre.</p>
  <p><b>A megrendelések teljesítési ideje:</b> A rendelések szállítási ideje alapesetben 2-5 munkanap, de ettől a tényleges kiszállítási idő a raktárkészlet állapota és az adott időszak ( pl. karácsonyi ünnepek ) függvényében eltérhet. Minden ilyen esetben a vevő e-mailben tájékoztatást kap a szállítási időről.</p>
  <p><b>Árak:</b> Az áruházban feltüntetett árak magyar forintban értendők és ÁFA-val növelt bruttó fogyasztói árak.</p>
  <p>A vásárláshoz minimálisan az alábbi valós adatok megadása szükséges :</p>
  <ul>
    <li>a vevő neve</li>
    <li>a vevő szállítási címe</li>
    <li>a vevő e-mail címe</li>
    <li>a vevő telefonos elérhetősége</li>
  </ul>
  <p>A regisztráció során a rendszerbe való feliratkozáskor bejelentkezési nevet és jelszót kell választania. A rendszeres termékkiszállításhoz minden esetben regisztrációra van szükség. Felhívjuk figyelmét arra, hogy a regisztrációkor megadott jelszavát gondosan válassza meg és bizalmasan kezelje, ne adja át illetékteleneknek, a lehetséges problémák, visszaélések elkerülése érdekében. A jelszóval való visszaélésből fakadó felelősséget nem vállalunk.</p>
  <p>A Vevőink a fentiek mellett egyéb, a honlapon megjelölt adatot is megadhatnak magukról – önkéntes módon. Vevőink regisztrált adatait titkosan kezeljük, azokat biztonságos, zárt adatbázisban tároljuk, harmadik fél felé nem adjuk ki, kivétel ez alól a megrendelt termékek kiszállítását végző cég ( futárszolgálat ), amelynek átadjuk a termékek célba juttatásához szükséges információkat. Ez a futárcég az adatbázisunkból átadott személyes adatokat a kiszállításon kívüli egyéb célra semmilyen módon nem jogosult felhasználni, illetve további személyeknek átadni.</p>
  <p>Az önkéntes alapon megadott adatokat kizárólag a Vevőinknek személyre szabottan nyújtott ajánlatok kidolgozásához használjuk fel.</p>
  <p>Amennyiben a Vevő részéről írásbeli kérés érkezik a regisztrált adatai törlésére, akkor azokat az adatbázisunkból 5 munkanapon belül töröljük. Az adatok felhasználása az Adatvédelmi törvény hatályos szabályozásának megfelelően történik.</p>
  <p>Az adatok megadását követően a rendelések feladása azonnal megtörténhet, emellett a regisztrált vásárlók számára számos kényelmi funkció is elérhetővé válik a ”FIÓKOM”  menüpontban. Ilyen kényelmi szolgáltatások a rendelés teljesítési folyamatának nyomon követése, akciós darabok áttekintése, korábbi rendelések áttekintése stb.</p>
  <p>Adatkezelési azonosítónk:NAIH-80293/2014.</p>
  <p>A Conte Magyarország webáruház adatbázisában kizárólag a Vevő által megadott számlázási és szállítási adatok szerepelnek. Az esetleges módosítás kizárólag a Vevő telefonos, vagy e-mailes kérése alapján történik. Az adatok módosítására a rendszerben a Bejelentkezést követően az „Adatok” menüpontban is lehetőség van, a felületen a Vevő módosíthatja a regisztrációs adatait.</p>
  <p>Az adatok pontossága elengedhetetlen a korrekt szállításhoz. A Vevő által hibásan megadott adatok miatt keletkező problémákért felelősséget nem vállalunk. </p>
  <p>Webáruházunkban vevőink széles választékot harisnyákból, zoknikból. A folyamatosan bővülő kínálatunkkal arra törekszünk, hogy a Vevőinket teljes körűen ki tudjuk szolgálni, ebbe beleértve az olcsóbb árfekvésű árukat kedvelő és a legmagasabb igényszintű termékeket választó partnereinket is.</p>
  <p>Termékeink közül többféle szűrést alkalmazva tudják kiválasztani a számukra megfelelő harisnyát / zoknit a Vevőink. A képernyő oldalán található  kategórialistából kereshetnek színek, felhasználási lehetőségek, illetve szezonalitás szerint.</p>
  <p>A kínálatunkban szereplő árucikkek minden esetben fotóval illusztráltak, a megtekintéskor lehetőség van nagyító használatára az egér termékre húzásával, hogy a termékjellemzők jobban megfigyelhetők legyenek. Minden termékünkhöz részletes leírást, színkódokat és méret választási lehetőséget mellékeltünk, hogy az áruházunkat meglátogató érdeklődők az tételek egyedi jellemzőit részletesen megismerhessék.</p>
  <p>A termékeink áraként a bruttó ( ÁFA-val növelt ) eladási árat tüntettük fel. Az árak magyar forintban ( HUF ) értendők.</p>
  <p>A megrendelés történhet vendégként vagy regisztrált partnerként. A vendég vásárló nem regisztrál a rendszerünkbe, tehát nem kap egyedi fiókot. Ilyenkor csak a szállításhoz szükséges adatok elküldése szükséges a futárszolgálat munkájához. Regisztrálni olyan vásárlóknak van lehetőségük, akik felhasználói nevükkel és jelszavukkal lépnek be az áruházba - a rendszer automatikusan saját fiókot nyit számukra. Feliratkozáskor lehetőség van kiválasztani, hogy a HÍRLEVÉL szolgáltatás igénybe kívánják-e venni. A hírlevélben szezonális akciókat, kedvezményeket és kupon lehetőségeket kínálunk fel a hűséges vásárlók között.</p>
  <p>A megrendelés feladása előtt a megvásárolni tervezett termékeket - a felületen ikon formában megtalálható - kosárba kell gyűjteni. A rendelt mennyiségeket a darabszám megadásával lehet bevinni a rendszerbe. A kiválasztott termékeket külön-külön kell a kosárba helyezni. A megrendelés lépéseinek utolsó fázisaként „ a Megrendelés jóváhagyása „ gombra kattintva lehet véglegesíteni a rendelést.</p>
  <p>A rendelés korrigálása a következőképpen működik :</p>
  <p>Amennyiben valamelyik bevitt adatot módosítani kívánja, vissza kell lépnie a korrekcióval érintett adatra és felül kell írnia a kívánt tartalommal.</p>
  <p>A rendszerben a véglegesítés előtt ellenőrizni szükséges az összes bevitt adatot és amennyiben hibátlan a berögzítés, le lehet adni a rendelést.</p>
  <p>A postára adás után módosítást, lemondást nem áll módunkban elfogadni.</p>
  <p>Az átvételi módok tekintetében több lehetőség közül választhatnak Vásárlóink.</p>
  <p>A szállítási díjat a megrendelés feladása előtt a szoftver automatikusan jelzi, így erről a rendelés közben folyamatosan  meg lehet győződni. A házhozszállítás mellett lehetőség van a <b>több mint 3100 átvételi pont egyikén történő átvételre is:</b> az átvételi pontok egyrészt a Magyar Posta postahivatalaiban találhatók, az ország szinte minden településén, a nagyobb városokban több helyen (mintegy 2500 postahivatalban), benzinkutakon, valamint a Posta Pont hálózatban. Ezekben az átvételi pontokban Ön személyesen is átveheti a megrendelt csomagot.</p>
  <p>Kérjük házhozszállításnál vagy postai átvételnél a csomag sértetlenségét a kézbesítő előtt szíveskedjék ellenőrizni! Ha sérülést lát, vetessen fel jegyzőkönyvet, és ne vegye át a csomagot. Utólagos reklamációt a csomag fizikai sérüléséről jegyzőkönyv nélkül nem áll módunkban elfogadni. </p>
  <p>Elérhetőségeink a következők: Conte Magyarország Kft<br>
    Székhely: 2030 Érd, Földmunkás utca 82.<br>
    Raktár: 2045 Törökbálint, DEPO II.<br>
    Tel: +36-30-44-800-63<br>
    Email:<a href="mailto:info@conte.hu">info@conte.hu</a>,<a href="mailto:harisnyaoutlet@gmail.com">harisnyaoutlet@gmail.com</a>.</p>
  <p>Kérdés, kérés esetén a fenti elérhetőségek valamelyikén rendelkezésre állunk.</p>
  <h3>Fizetési módok:</h3>
  <ul>
 <li>
  <p><b>Készpénzben átvételkor (utánvéttel):</b> Fizetés az áru átvételekor készpénzben.</p>
 </li>
 <li><b>Pay Pal:</b> internetes alapú fizetési rendszeren a Pay Pal számlával rendelkezők részére fenntartott fizetési mód.</li>
 <li>
  <p><b>Előre utalás:</b> banki átutalás útján a csomag megküldését megelőzően történik a fizetés a Vevő által</p>
 </li>
 <li>
  <p><b>Számlázás</b></p>
 </li>
  </ul>
  <p>A számlát a Conte Magyarország Kft. állítja ki e-számla formában, a regisztráció, vagy az egyszeri regisztráció nélküli vásárlás során megadott névre és címre. A számlát Vevőink az árut tartalmazó csomagban találják meg.</p>
  <p><b>Kedvezmények</b></p>
  <p>a Conte Magyarország webáruházban folyamatosan vannak akciós termékek, amelyeket az állandó listaárukhoz képest jelentős árkedvezménnyel lehet megvásárolni.</p>
  <p>Állandó akciónk: Vevőink a bruttó fogyasztói árakból 10% kedvezményben részesülnek bármely, legalább 10 termék megvásárlása esetén és a bruttó fogyasztói árakból 20% kedvezményben részesülnek bármely legalább 20 termék megvásárlása után. Ezen akciók más kedvezményekkel kombináltan (ingyenes kiszállítás) igénybe vehetők.</p>
  <p>A 12.000 Ft felett vásárlóknak a teljes szállítási költséget elengedjük.</p>
  <p><b>A megrendelések teljesítési ideje:</b> A raktárkészlet függvényében a tényleges teljesítési idő ettől különböző mértékben eltérhet, mivel erről Vevőt minden esetben értesítjük e-mailben, ezért e-mailes válaszában jelezheti, hogy számára elfogadható-e ez. A megrendelések állapotáról telefonon tud tájékozódni.</p>
  <p>A webshop megindításakor a Conte Magyarország webáruház vezetése azt a célkitűzést fogalmazta meg, hogy törekedjünk a Vevőink teljes körű megelégedettségének kivívására. A termékpalettánkkal, a webshopunk kialakításával és a tájékoztatásunk minőségével egyaránt szeretnénk ezt a célt elérni. A Vevőink visszajelzése fontos számunkra akár pozitív, akár negatív véleményt fogalmaznak meg. Mivel folyamatosan szeretnénk fenntartani, illetve növelni a szolgáltatásaink színvonalát, ezért fontosak számunkra a vevői vélemények, ötletek, hozzászólások, amelyekből visszacsatolást kapunk a tevékenységünkről. A jelzésekre rövid időn belül (max. 2-3 munkanap) igyekszünk reagálni. E-mail címünk : info@conte.hu</p>
  <p>Reméljük, hogy áruházunk választékával, minden Vevőnk elégedett lesz, találnak nálunk olyan árucikkeket, amelyeket megelégedéssel használnak és visszatérnek hozzánk.</p>
  <p><b>Jótállás, szavatosság</b></p>
  <p>Cégünk mindenben be kívánja tartani a hatályos jogszabályok előírásait. Ennek érdekében minden, a fogyasztót védő szabályozást kötelezően betartunk, azokat beépítettünk az ÁSZF-be.</p>
  <p>Az eladó szavatossági és jótállási felelősségére a következő jogszabályok vonatkoznak:</p>
  <p>151/2003. (IX. 22.) Korm. rendelet</p>
  <p>72/2005. ( IV. 21. ) Korm. rendelet</p>
  <p>19/2014. (IV.29.) GKM rendelet</p>
  <p>45/2014. (II. 26.) Korm. rendelet</p>
  <p>2013. évi V. törvény A Polgári Törvénykönyvről</p>
  <p>Szavatossági és jótállási igényéről minden esetben jegyzőkönyvet veszünk fel, amelyből egy példányt a Vevő részére átadunk.</p>
  <p>Munkatársunk felveszi az Ön igényét a szavatosság / jótállás tekintetében. Szíveskedjen a nem megfelelő terméket a lehetőségekhez képest azonnal, de legkésőbb a garancia lejártáig elküldeni címünkre: 1121 Budapest, Melinda út  17.. fsz.1. A biztonságos csomagolás és áruvédelem érdekében, kérjük, lehetőség szerint a készülék eredeti csomagolásában juttassa vissza hozzánk a terméket.</p>
  <p>A garanciális igények intézésénél legegyszerűbb a telefonon történő előzetes bejelentéssel kezdeni. Telefonszámunk : 06-20-279-54-39 A hibás termék visszajuttatásának költsége a Vevőt terheli, utánvétes / portós küldeményeket nem áll módunkban átvenni. Amennyiben megállapítható, hogy a hiba a nem megfelelő használat eredménye, akkor az esetlegesen minden felmerülő költség a Vevőt terheli.</p>
  <p><i>Kellékszavatosság</i></p>
  <p>Ön a Conte Magyarország webáruház hibás teljesítése esetén a Conte Magyarország Kft-vel szemben kellékszavatossági igényt érvényesíthet a Polgári Törvénykönyv szabályai szerint.</p>
  <p>Ennek alapján – választása szerint - kijavítást vagy kicserélést kérhet, kivéve, ha az ezek közül az Ön által választott igény teljesítése lehetetlen vagy a vállalkozás számára más igénye teljesítéséhez képest aránytalan többletköltséggel járna. Ha a kijavítást vagy a termék becserélést nem kérte, illetve nem kérhette, úgy igényelheti az ellenszolgáltatás arányos leszállítását vagy a hibát a vállalkozás költségére Ön is kijavíthatja, illetve mással kijavíttathatja vagy – végső esetben – a szerződéstől is elállhat. Választott kellékszavatossági jogáról egy másikra is áttérhet, az áttérés költségét azonban Ön viseli, kivéve, ha az indokolt volt, vagy arra a vállalkozás adott okot.</p>
  <p>A hibát annak felfedezése után haladéktalanul, de nem később, mint a hiba felfedezésétől számított kettő hónapon belül kell közölni. A szerződés teljesítésétől számított két éves elévülési határidőn túl kellékszavatossági jogok már nem érvényesíthetőek.</p>
  <p>A teljesítéstől számított hat hónapon belül a kellékszavatossági igénye érvényesítésének a hiba közlésén túl nincs egyéb feltétele, ha Ön igazolja, hogy a terméket, illetve a szolgáltatást azConte Magyarország webáruház nyújtotta. A teljesítéstől számított hat hónap eltelte után azonban már a Vevő köteles bizonyítani, hogy a felismert hiba már a teljesítés időpontjában is megvolt.</p>
  <p><i>Termékszavatosság</i></p>
  <p>Az adásvétel tárgyát képező ingó dolog (termék) hibája esetén a Vevő az előzőekben taglalt kellékszavatossági jogot vagy termékszavatossági igényt érvényesíthet.</p>
  <p>Termékszavatossági igényként kizárólag a hibás termék kijavítását vagy kicserélését lehet kérni a termék forgalomba hozatalától számítva két éven belül.</p>
  <p>A termék hibás, ha az nem felel meg a forgalomba hozatalakor hatályos minőségi követelményeknek, vagy ha nem rendelkezik a gyártó által adott leírásban szereplő tulajdonságokkal.</p>
  <p>Termékszavatossági igényét kizárólag a termék gyártójával vagy forgalmazójával szemben gyakorolhatja. A termék hibáját termékszavatossági igény érvényesítése esetén Önnek kell bizonyítania.</p>
  <p>A gyártó (forgalmazó) akkor mentesülhet a termékszavatossági kötelezettsége alól, ha bizonyítani tudja, hogy
    <br>A.)  a terméket nem üzleti tevékenysége körében gyártotta, illetve hozta forgalomba, vagy
    <br>B.) a hiba a tudomány és a technika állása szerint a forgalomba hozatal időpontjában nem volt felismerhető vagy <br>C.)  termék hibája jogszabály vagy kötelező hatósági előírás alkalmazásából ered.</p>
  <p>Ugyanazon hiba miatt kellékszavatossági és termékszavatossági igényt egyszerre nem lehet érvényesíteni. Termékszavatossági igényének eredményes érvényesítése esetén azonban a kicserélt termékre, illetve kijavított részre vonatkozó kellékszavatossági igényét a gyártóval szemben érvényesítheti.</p>
  <p><i>Jótállás</i></p>
  <p>Hibás teljesítés esetén a  151/2003. (IX. 22.) Korm. rendelet, 19/2014. (IV.29.) GKM rendelet, 72/2005. (IV. 21.) Korm. rendelet és a 2013. évi V. törvény alapján a Conte Magyarország Kft. jótállásra köteles.</p>
  <p>A vállalkozás jótállási kötelezettsége alól csak abban az esetben mentesül, ha bizonyítja, hogy a hiba oka a teljesítés után keletkezett.</p>
  <p>Ugyanazon hiba miatt kellékszavatossági és jótállási igényt, illetve termékszavatossági és jótállási igényt egyszerre, egymással párhuzamosan nem szabad érvényesíteni.</p>
  <p><b>Elállás</b></p>
  <p>Minden fogyasztónak (természetes személynek) a termék átvételétől számított 14 (naptári) napon belül indoklás nélküli elállási joga van. Vásárlónk az elállás jogát attól a naptól kezdve gyakorolhatja, amikor az árut átvette. Az elállási jog részletei:</p>
  <p>Az elállási jog minden általunk forgalmazott termékre vonatkozik, ami hiánytalanul, hibamentesen, bontatlanul ( új állapotban ) visszaérkezik hozzánk. A hatályos szabályozás szerint a Vevő az elállási joga gyakorlása előtt a megkapott árut csak a kipróbáláshoz szükséges mértékben használhatja. Amennyiben ennél nagyobb mértékben használják a terméket, úgy, hogy az a termék állagát befolyásolja, akkor cégünk az elállási jog gyakorlását követően az érték csökkenésének megfelelő ellentételezésre tart igényt.</p>
  <p>A termék adásvétele esetén Vevőink az elállás jogát már a szerződés megkötése és a termék átvétele közötti időszakban is gyakorolhatják.</p>
  <p>Az elállási jog gyakorlása esetén a termék visszajuttatásáról a Vásárlónknak kell gondoskodnia és az elállás bejelentésétől számított legkésőbb 14 napon belül visszaküldeni részünkre. A cégünk ebben az esetben visszajuttatja a vételárat az eredeti szállítási költséggel ( annak normál tarifája szerint ) megnövelve. Kérjük, hogy az elállással érintett terméket utánvétellel ne küldje vissza részünkre, mivel utánvétes csomagokat cégünk nem vesz át.</p>
  <p>Elállásra vonatkozó igényét írásban, vagy személyesen kell bejelentenie. Írásban történő elállás esetén az elállási nyilatkozatot kell a termék átvételétől számított 14 (naptári) napon belül elküldeni részünkre.</p>
  <p>Az áru ellenértékét az elállásról való tudomásszerzést követő 14 napon belül fizetjük vissza, azzal a feltétellel, hogy a terméket a Vevő számunkra eddig az időpontig visszajuttatja. Ennek hiányában a termék visszaküldése után történik a visszafizetés.  A teljes vételár visszafizetése rózsaszín csekken történik (eredeti szállítási díjjal együtt), illetve igény szerint átutaljuk a vevő által megadott bankszámlára.</p>
  <p>Nem gyakorolhatja elállási jogát a Vevő:</p>
  <ul>
 <li>
  <p>a szolgáltatás nyújtására irányuló szerződés esetében a szolgáltatás egészének teljesítését követően</p>
 </li>
 <li>
  <p>olyan nem előre gyártott termék esetében, amelyet a fogyasztó utasítása alapján vagy kifejezett kérésére állítottak elő, vagy olyan termék esetében, amelyet egyértelműen a fogyasztó személyére szabtak;</p>
 </li>
 <li>
  <p>romlandó vagy minőségét rövid ideig megőrző termék tekintetében;</p>
 </li>
 <li>
  <p>olyan zárt csomagolású termék tekintetében, amely egészségvédelmi vagy higiéniai okokból az átadást követő felbontása után nem küldhető vissza;</p>
 </li>
 <li>
  <p>olyan termék tekintetében, amely jellegénél fogva az átadást követően elválaszthatatlanul vegyül más termékkel;</p>
 </li>
 <li>
  <p>lezárt csomagolású hang-, illetve képfelvétel, valamint számítógépes szoftver példányának adásvétele tekintetében, ha az átadást követően a fogyasztó a csomagolást felbontotta;</p>
 </li>
 <li>
  <p>a jogszabályok által meghatározott további esetekben.</p>
 </li>
  </ul>
  <p>Amennyiben a termékről felbontás után a futár jelenlétében derül ki, hogy bizonyítottan sérült, és a sérülés az áru átvételét megelőzően keletkezett, a termékre 14 napos cserelehetőséget biztosítunk. Ebben az esetben a sérülést a futár által felvett jegyzőkönyvvel igazolhatja.</p>
  <p>a Conte Magyarország webáruház akcióiról, és az aktuális információkról igyekszünk rendszeresen tájékoztatni vásárlóinkat, kétheti hírlevél vagy a Facebook profilunkon történő közzététel formájában. Vevőink figyelmébe ajánljuk ingyenes hírlevél szolgáltatásunkat, melyre az e-mail címe megadásával iratkozhat fel.</p>
  <p>Amennyiben a panaszkezelésünk során nem az Ön által kívánt eredmény született, <br>
 Békéltető testületi eljárás kezdeményezéséhez az Ön lakóhelye szerinti illetőségű Békéltető Testülethez, vagy cégünk székhelye szerinti Békéltető Testülethez fordulhat jogorvoslatért:<br>
 Székhelyünk szerint illetékes a</p>
  <p><b>Budapesti Békéltető Testület</b><br>
 Címe: 1016 Budapest, Krisztina krt. 99.<br>
 Telefonszáma: (1) 488-2131<br>
 Fax száma: (1) 488-2186<br>
 Név: Dr. Baranovszky György<br>
 E-mail cím: <a href="mailto:bekelteto.testulet@bkik.hu"><u>bekelteto.testulet@bkik.hu</u></a></p>
  <p><a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a></p>
  <p>Ezen felül jogorvoslatért az alábbi hatósághoz is fordulhat:</p>
  <p>Budapest Főváros Kormányhivatal Fogyasztóvédelmi Felügyelőség<br>
 1052 Budapest, Városház u. 7.<br>
 Postacím: 1364 Budapest, Pf.: 144.</p>
  <p><i>Jelen ÁSZF 2014. december 1-től érvényes</i></p>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelTermsInfoModal()">Bezár</button>
</div>
