<div class="row" *ngFor="let product of order.orderedProducts">
<!--  <div class="col-4">-->
<!--    <img class="col" src="{{product.}}" alt="">-->
<!--  </div>-->
  <div class="col-3">
    <div class="d-flex flex-column">
<!--      <div>-->
<!--        {{product.product.name}}-->
<!--      </div>-->
    </div>
  </div>
  <div class="col-6 d-flex justify-content-start align-items-end flex-column">
    <div><b>{{product.product.price | thousandSeparator}}</b></div>
    <div>{{product.quantity}} </div>
  </div>
</div>
<!--<mat-divider class="mt-4"></mat-divider>-->
<div class="row">
  <div class="col-13 d-flex justify-content-end">
    <div class="d-flex flex-column justify-content-start align-items-end">
      <div>Összesen</div>
      <div>44444 Ft</div>
    </div>
  </div>
</div>
