import { PersonalData } from './PersonalData';
import { Company } from './Company';
import { Address } from './Address';
import { PaymentType } from './PaymentType';

export class CreateOrder {
  cart: Array<OrderProduct>;
  personalData: PersonalData;
  companyData: Company;
  shippingAddress: number | Address;
  billingAddress: number | Address;
  paymentType: PaymentType;
  buyAsCompany: boolean;
  subscribeToNewsletter: boolean;
  message: string;
}

export class OrderProduct {
  productId: number;
  quantity: number;
}
