import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(private readonly progressSpinnerService: NgxSpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('/api/search?') && !req.url.includes('change-amount-in-cart')) {
      this.progressSpinnerService.show();
    }
    return next
      .handle(req).pipe(
        tap(event => {
            if (event instanceof HttpResponse) {
              this.progressSpinnerService.hide();
            }
          }, () => {
            this.progressSpinnerService.hide();
          }
        ));
  }
}
