import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";


@Component({
  selector: 'app-adtop',
  templateUrl: './adtop.component.html',
  animations: [
    trigger("closeAd", [
      state(
        "initial",
        style({
          opacity: 1, marginTop: '0',
        })
      ),
      state(
        "final",
        style({

          opacity: 0, marginTop: '-40px',
        })
      ),

      transition("initial=>final", [
        animate("0.5s 300ms ease-in")
      ]),
    ]),

  ]
})
export class AdtopComponent implements OnInit {


  currentState = 'initial';

  constructor() { }

  ngOnInit(): void {
  }


  closeAdTop() {
    this.currentState = "final";
  }

}
