// TODO
export class Constants {
  public static PRODUCT_PAGE_URL = 'termek';
  public static CHECKOUT_PAGE_URL = 'penztar';
  public static PROFILE_PAGE_URL = 'profil';
  public static SEARCH_PAGE_URL = 'kereses';
  public static FAVOURITE_PRODUCTS_PAGE_URL = 'kedvencek';
  public static MENU_LINKS: Array<string> = ['halak', 'felszerelesek', 'modszerek', 'vizek'];
  public static REVIEWS_PAGE_URL = 'ertekelesek';
  public static MY_ORDERS_PAGE_URL = 'rendeleseim';
  public static ACCOUNT_PAGE_URL = 'sajat-fiok';
  public static REGISTER_PAGE_URL = 'regisztracio';
  public static LOGIN_PAGE_URL = 'bejelentkezes';
  public static CONTACT_PAGE_URL = 'kapcsolat';
  public static NOT_FOUND_URL = 'not-found';

  // product query start
  public static PAGE_PARAM_TEXT = 'oldal';
  public static LIMIT_PAGE_PARAM_TEXT = 'oldal_meret';
  public static ORDER_TEXT = 'rendezes';
  public static ORDER_TYPES = {
    legdragabb: 'MAX_PRICE',
    legolcsobb: 'MIN_PRICE',
    nepszeru: 'POPULAR',
    nev: 'ALPHABETIC',
    ertekeles: 'BEST_REVIEW'
  };
  // product query end

  // public static _PAGE_URL = 'termek';
  // public static _PAGE_URL = 'termek';
}
