import { Action } from '@ngrx/store';

export enum FilterActionTypes {
  InitFilters = '[INIT_FILTERS]'
}

export class InitFilters implements Action {
  readonly type = FilterActionTypes.InitFilters;

  constructor() {
  }
}

export type FilterActions =
  InitFilters;
