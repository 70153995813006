<h2>Gyártók</h2>


<div class="row">
  <div class="col-12 col-md-6 col-lg-4 mb-5">
    <a href="#" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true" [matRippleRadius]="80"><img class="brand" src="/assets/images/brands/trabucco.png" alt=""></a>
  </div>

  <div class="col-12 col-md-6 col-lg-4 mb-5">
    <a href="#" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true" [matRippleRadius]="80"><img class="brand" src="/assets/images/brands/haldorado.jpg" alt=""></a>
  </div>

  <div class="col-12 col-md-6 col-lg-4 mb-5">
    <a href="#" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true" [matRippleRadius]="80"><img class="brand" src="/assets/images/brands/k-karp.jpg" alt=""></a>
  </div>

</div>

