import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortens'
})
export class ShortensPipe implements PipeTransform {

  transform(value: string, numberOfCharacters: number): string {
    if (value.length < 4) {
      return value;
    }
    if (value.length <= numberOfCharacters) {
      return value;
    }
    const index = numberOfCharacters - 3;
    return value.replace(/<br>/g, '').trim().substring(0, value[index] === ' ' ? numberOfCharacters - 4 : index) + '...';
  }

}
