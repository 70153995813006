<carousel [noPause]="false" class="main-carousel">
  <slide *ngFor="let slide of carouselSlides">
    <img src="/api/product/content/images/carousel/{{ slide.image }}" alt="{{ slide.title }}" class="img-fluid">
    <div class="carousel-caption">
      <h1><a href="{{ slide.url }}" class="stretched-link">{{ slide.title }}</a></h1>
      <p>{{ slide.description }}</p>
    </div>
  </slide>
</carousel>


<div class="container-fluid headline-block female-bg">
<h2><i class="fa fa-lg fa-female"></i> Újdonságok</h2>
</div>

<div class="product-list">
  <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 mb-5 d-flex" *ngFor="let m of homePageData.womenProducts">
        <div class="card" [routerLink]="'termek/' + m.link" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header d-flex justify-content-center">
            <img src="/api/product{{m.image.original}}" class="card-img-prod" alt="{{m.name}}" appHideMissing>
          </div>
          <div class="card-body">
            <h3 class="card-title">{{m.name}}</h3>
          </div>
          <div class="card-footer text-center">

            <p class="card-text"><span class="offerprice" *ngIf="m.price">{{m.price | thousandSeparator}} Ft</span></p>
            <!--          <button class="btn btn-outline-primary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>
      </div>
  </div>
</div>

<!--TODO  MIÉRT HIVATKOZIK EGY UNDEFINED VARIABLE-RE??-->
<div class="parallax-block" [style.background-image]="parallaxImage">
<h1>{{ ads.parallaxTitle }}</h1>
<a class="btn btn-lg btn-primary" href="{{ ads.parallaxUrl }}">{{ ads.parallaxBtnTxt }}</a>
</div>

<div class="container-fluid headline-block male-bg">
  <h2><i class="fa fa-lg fa-male"></i> Újdonságok</h2>
</div>

<div class="product-list">
  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 mb-5 d-flex" *ngFor="let m of homePageData.menProducts">
      <div class="card" [routerLink]="'termek/' + m.link" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header d-flex justify-content-center">
          <img src="/api/product{{m.image.original}}" class="card-img-prod" alt="{{m.name}}" appHideMissing>
        </div>
        <div class="card-body">
          <h3 class="card-title">{{m.name}}</h3>
        </div>
        <div class="card-footer text-center">

          <p class="card-text"><span class="offerprice" *ngIf="m.price">{{m.price | thousandSeparator}} Ft</span></p>
          <!--          <button class="btn btn-outline-primary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid headline-block kid-bg">
  <h2><i class="fa fa-lg fa-kid"></i> Újdonságok</h2>
</div>

<div class="product-list">
  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4 mb-5 d-flex" *ngFor="let m of homePageData.kidsProducts">
      <div class="card" [routerLink]="'termek/' + m.link" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header d-flex justify-content-center">
          <img src="/api/product{{m.image.original}}" class="card-img-prod" alt="{{m.name}}" appHideMissing>
        </div>
        <div class="card-body">
          <h3 class="card-title">{{m.name}}</h3>
        </div>
        <div class="card-footer text-center">

          <p class="card-text"><span class="offerprice" *ngIf="m.price">{{m.price | thousandSeparator}} Ft</span></p>
          <!--          <button class="btn btn-outline-primary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>
    </div>
  </div>
</div>
