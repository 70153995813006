import { Component, Input, OnInit } from '@angular/core';
import { Model } from '../../../model/Model';
import { RemoveFavouriteProduct } from '../../../store/user/UserActions';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { ProductService } from '../../../service/product.service';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-common-listing',
  templateUrl: './product-common-listing.component.html',
  styleUrls: ['./product-common-listing.component.scss']
})
export class ProductCommonListingComponent implements OnInit {

  @Input()
  products: Array<Model>;

  constructor(private readonly userStore: Store<UserState>,
              private readonly translateService: TranslateService,
              private readonly ngxBootstrapConfirmService: NgxBootstrapConfirmService,
              private readonly productService: ProductService) {
  }

  ngOnInit(): void {
  }

  calculateAverageRating(product): number {
    return Number((product.reviews.reduce((a, b) => a + b.rating, 0) / product.reviews.length).toFixed(2));
  }

  deleteFromFavorites(productId: number): void {
    const options = {
      title: this.translateService.instant('sure.want.delete.favourite.product'),
      confirmLabel: this.translateService.instant('button.yes'),
      declineLabel: this.translateService.instant('button.cancel')
    };
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.productService.removeFromFavouriteProducts(productId).subscribe(() => {
          this.userStore.dispatch(new RemoveFavouriteProduct(productId));
        });
      }
    });
  }
}
