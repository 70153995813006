import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Address } from '../model/Address';
import { Observable } from 'rxjs';
import { PersonalData } from '../model/PersonalData';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  updatePersonalData(data: PersonalData) {
    return this.http.post<void>('/api/user/profile/update', data);
  }

  removeAddress(addressId: number): Observable<void> {
    return this.http.post<void>('/api/user/address/remove', {addressId});
  }

  updateAddress(address: Address): Observable<void> {
    return this.http.post<void>('/api/user/address/update', address);
  }
}
