import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-delivery-info-modal',
  templateUrl: './delivery-info-modal.component.html'
})
export class DeliveryInfoModalComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelDeliveryInfoModal() {
    this.modalRef.hide();
  }

}
