
<!--  <div *ngIf="!modifyState" class="row d-flex flex-column">-->
<!--&lt;!&ndash;    <div>{{address.postalCode + ' ' + address.city}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div>{{address.address}}</div>&ndash;&gt;-->

<!--    <div class="col-sm-8 col-lg-7 col-xl-8">-->
<!--      <div class="form-floating">-->
<!--&lt;!&ndash;        <select class="form-select" aria-label="ország" id="countryInput">&ndash;&gt;-->
<!--&lt;!&ndash;          <option value="hu">Magyarország</option>&ndash;&gt;-->
<!--&lt;!&ndash;          <option value="sk">Szlovákia</option>&ndash;&gt;-->
<!--&lt;!&ndash;          <option value="ro">Románia</option>&ndash;&gt;-->
<!--&lt;!&ndash;        </select>&ndash;&gt;-->
<!--&lt;!&ndash;        <label for="countryInput">{{address.country}}</label>&ndash;&gt;-->
<!--        <span>{{address.country}}</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-sm-6 col-lg-6 col-xl-4">-->
<!--      <div class="form-floating">-->
<!--&lt;!&ndash;        <input type="text" class="form-control" id="billingZip" placeholder="Irányítószám">&ndash;&gt;-->
<!--&lt;!&ndash;        <label for="billingZip">Irányítószám</label>&ndash;&gt;-->
<!--        <span>{{address.postalCode}}</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-sm-10 col-lg-11 col-xl-12">-->
<!--      <div class="form-floating">-->
<!--&lt;!&ndash;        <input type="text" class="form-control" id="billingCity" placeholder="Város">&ndash;&gt;-->
<!--&lt;!&ndash;        <label for="billingCity">Város</label>&ndash;&gt;-->
<!--        <span>{{address.city}}</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-md-14 col-lg-13 col-xl-14">-->
<!--      <div class="form-floating">-->
<!--&lt;!&ndash;        <input type="text" class="form-control" id="billingAddress" placeholder="Cím (utca, házszám)">&ndash;&gt;-->
<!--&lt;!&ndash;        <label for="billingAddress">Cím (utca, házszám)</label>&ndash;&gt;-->
<!--        <span>{{address.address}}</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<form [formGroup]="addressForm">

        <div class="row pb-4">

          <div class="col-lg-12 col-xl-12">
            <div class="form-floating">
              <input class="form-control mb-5" data-lpignore="true" type="text" [class.invalid]="addressForm.get('name').invalid &&
        (addressForm.get('name').dirty || addressForm.get('name').touched)" name="name" formControlName="name" [placeholder]="'address.name' | translate">
              <label>Név</label>
            </div>
          </div>

          <div class="col-lg-12 col-xl-12">
            <div class="form-floating">
              <input type="text" class="form-control mb-5" data-lpignore="true" [class.invalid]="addressForm.get('phoneNumber').invalid &&
        (addressForm.get('phoneNumber').dirty || addressForm.get('phoneNumber').touched)" name="phoneNumber" formControlName="phoneNumber" [placeholder]="'address.phoneNumber' | translate" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
              <label>Telefonszám</label>
            </div>
          </div>

          <div class="col-sm-8 col-lg-6 col-xl-4">
            <div class="form-floating">
              <input class="form-control mb-5" data-lpignore="true" type="text" [class.invalid]="addressForm.get('postalCode').invalid &&
        (addressForm.get('postalCode').dirty || addressForm.get('postalCode').touched)" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
              <label>Irányítószám</label>
            </div>
          </div>

          <div class="col-sm-16 col-lg-8 col-xl-8">
            <div class="form-floating mb-5">
              <input type="text" class="form-control" [class.invalid]="addressForm.get('city').invalid &&
        (addressForm.get('city').dirty || addressForm.get('city').touched)" name="city" formControlName="city" [placeholder]="'address.city' | translate">
              <label>Település</label>
            </div>
          </div>


          <div class="col-lg-10 col-xl-12">
            <div class="form-floating mb-5">
              <input type="text" class="col-24 form-control" [class.invalid]="addressForm.get('address').invalid &&
        (addressForm.get('address').dirty || addressForm.get('address').touched)" name="address" formControlName="address" [placeholder]="'address.address' | translate">
              <label>Postacím</label>
            </div>
          </div>



        </div>



  <div class="hstack gap-3 d-flex justify-content-center mt-5">
    <button class="btn btn-outline-dark" *ngIf="!modifyState" (click)="enableForm()">Szerkesztés</button>
    <button class="btn btn-outline-primary" *ngIf="modifyState" (click)="disableForm()">Mégse</button>
    <button class="btn btn-dark" type="submit" *ngIf="modifyState" [disabled]="!addressForm.dirty || addressForm.invalid" (click)="saveAddress()">Mentés</button>
    <button class="btn btn-outline-primary" *ngIf="!modifyState" type="submit" (click)="deleteAddress()">Törlés</button>
  </div>

      </form>

<hr class="mt-5 mb-5">
