<div class="container-fluid product-list">

  <div class="row">
    <div class="col-xl-19">

      <div class="row">
        <div class="col-md-12 col-xxl-8 d-flex align-items-center justify-content-center justify-content-md-start order-1">
<!--          <app-site-navigation></app-site-navigation>-->
        </div>

        <div class="col-xxl-8 d-flex align-items-end justify-content-center order-3 order-lg-3 order-xxl-2">
          <h1>Akciós termékek</h1>
        </div>

        <div class="col-md-12 col-xxl-8 d-flex justify-content-center justify-content-md-end align-items-center btn-nav order-2 order-lg-2 order-xxl-3 pt-4 pt-xxl-0">
<!--          <app-order [values]="orderings" [initialValue]="orderBy.link" [(ngModel)]="orderBy"-->
<!--                     (ngModelChange)="updateParams(filter, activeFilter, $event.link);"></app-order>-->
<!--          <app-page-sizer *ngIf="pagination.totalItems > 12" [(ngModel)]="pagination.itemsPerPage" (ngModelChange)="updatePageSize($event)" class="ms-md-0 me-2 me-md-0"></app-page-sizer>-->
        </div>
      </div>

      <div class="d-flex align-items-end justify-content-center">
      </div>


      <div class="row">
        <app-product class="col-sm-12 col-md-8 col-lg-6 col-xl-8 col-xxl-6 mb-5 d-flex"
                     *ngFor="let p of products | paginate: pagination; trackBy:trackByIdentity"
                     [product]="p"
                     [link]="'termek'"
        ></app-product>

        <div class="col-24 text-center" *ngIf="pagination.totalItems > 12">
          <pagination-controls class="pt-5 pb-5"
                               nextLabel=""
                               previousLabel=""
                               responsive="true"
                               autoHide="true"
                               screenReaderPaginationLabel="Lapozó"
                               screenReaderPageLabel="oldal"
                               screenReaderCurrentLabel="aktív oldal"
                               (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

      </div>

      <!--BRANDS-------------------------------------->
      <!--      <div class="brands">-->
      <!--        <app-brand-list></app-brand-list>-->
      <!--      </div>-->


    </div>

    <div class="col-xl-5 aside">

      <div class="row">
        <!--FILTERS-------------------------------------->
        <div class="col-md-12 col-xl-24" *ngIf="filter">
          <app-filter [(ngModel)]="activeFilter" [filter]="filter" [initialValue]="activeFilter"
                      (ngModelChange)="updateParams(filter, $event, orderBy.link, 1)"></app-filter>
        </div>

        <!--SUBCATEGORIES-------------------------------->

      </div>


    </div><!--  row END-->

  </div><!--  aside END-->


</div>
<!--  container-fluid END-->


