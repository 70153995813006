import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Model } from '../model/Model';
import { Query } from '../model/Query';
import { Pagination } from '../model/Pagination';
import { OrderingType } from '../model/OrderingType';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }

  // getProductsByIds(ids: Array<number>) {
  //   // return this.httpClient.post('/api/products', ids);
  //   return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).filter(p => ids.includes(p.articleNumber)));
  // }

  getHomePageProducts(): Observable<Array<Model>> {
    return this.httpClient.get<any>(`/api/product/find-home-page-products`);
  }

  getProductsPreload(query: Query, pagination: Pagination, ordering: OrderingType, priceRange?: DoubleRange): Observable<any> {
    return this.httpClient.post<any>(`/api/product/find-products-preload`, {pagination: pagination, filter: {priceRange: priceRange ? priceRange : undefined, ...query}, order: ordering});
  }

  getProductsCommonVersion(query: Query, pagination: Pagination, ordering: OrderingType, priceRange?: DoubleRange): Observable<any> {
    return this.httpClient.post<any>(`/api/product/find-products`, {pagination: pagination, filter: {priceRange: priceRange ? priceRange : undefined, ...query}, order: ordering});
  }

  // getMissingProducts(ids: Array<number>): Observable<any> {
  //   return this.httpClient.post<any>(`/api/product/find-missing-products`, {productIds: ids});
  // }

  searchProducts(query: string) {
    const params = new HttpParams().set('query', query);
    return this.httpClient.get<Array<Model>>(`/api/search/products`, {params: params});
    // return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).slice(0, 6));
  }

  getProductById(link: string): Observable<Model> {
    // const params = new HttpParams().set('id', id);
    return this.httpClient.get<Model>(`/api/product/find-product`, {params: {id: link}});
  }
  // main category all products
  getProductsByCategory(categoryId: number): Observable<Array<Model>> {
    return this.httpClient.post<Array<Model>>(`/api/product`, {categoryId: categoryId});
    // return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)));
  }
  // getProductsByCategoryTest(category_id: number, query?: string): Observable<Array<Product>> {
  //   // return this.httpClient.get<Array<Product>>(`/api/product?category=${category_id}`);
  //   const random = Math.floor(Math.random() * 10);
  //   return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).slice(7 + random, 12 + random + (Math.floor(Math.random() * 10))));
  // }

  addToFavouriteProducts(modelId: number) {
    return this.httpClient.post('/api/favourite-product/add', {modelId});
  }

  removeFromFavouriteProducts(modelId: number) {
    return this.httpClient.post('/api/favourite-product/remove', {modelId});
  }

  getFavouriteProducts() {
    return this.httpClient.get('/api/favourite-product');
    // return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).filter((p, i) => i < 4));
    // return of([]);
  }

  getDiscountedProducts(pagination?: Pagination) {
    return this.httpClient.post('/api/product/discounted-products', {pagination});
  }

  subscribeToMissingProduct(command) {
    return this.httpClient.post('/api/product/subscribe-to-missing-product', command);
  }

  // getProductsAndFiltersByCategory(category_id: number, filter: boolean): Observable<ProductListState> {
  //   // return this.httpClient.get<Array<Product>>(`/api/product?category=${category_id}&filter=${filter}`);
  //   const properties = new FilterProperty();
  //   properties['u_anyaga'] = [
  //     'PE',
  //     'Nylon',
  //     'Gumi',
  //     'ALU',
  //     'carbon'
  //   ];
  //   properties['u_atmero'] = [
  //     '2,5 mm',
  //     '3,0 mm',
  //     '4 mm',
  //     '5 mm',
  //     '6 mm',
  //     '10 mm'
  //   ];
  //   properties['u_hossz'] = [
  //     '1 m',
  //     '3 m',
  //     '35 mm',
  //     '60 cm',
  //     '70 mm',
  //     '90 mm',
  //     '93 mm',
  //     '200 cm',
  //     '390 mm'
  //   ];
  //   return of({
  //     products: TEST_PRODUCTS.map(tp => Product.buildProduct(tp)),
  //     filter: new Filter({
  //       min: 255,
  //       max: 34578
  //     }, properties)
  //   });
  // }
}
