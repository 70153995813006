<div class="modal-header">

  <h4 class="modal-title">Hiányzó termék</h4>
  <i class="fa fa-2x fa-times" (click)="cancelMissingProductModal()"></i>
</div>

<div class="modal-body modal-info data-form" [formGroup]="form">

  <h2 class="mt-5">Töltsd ki az űrlapot és majd értesítést kapsz ha a termék raktáron lesz!</h2>

  <div class="form-floating mb-5" *ngIf="!user">
    <input type="text" class="form-control" id="floatingName" placeholder="Név" formControlName="name">
    <label for="floatingName">Név</label>
    <div *ngIf="form.get('name').invalid &&
        form.get('name').errors &&
        (form.get('name').dirty || form.get('name').touched)">
      <small
        *ngIf="form.get('name').hasError('required')">
        {{'error.missing.name' | translate}}
      </small>
    </div>
  </div>

  <div class="form-floating form-data">
    <input type="email" class="form-control" id="floatingInput" placeholder="E-mail" formControlName="email">
    <label for="floatingInput">E-mail</label>
    <div *ngIf="form.get('email').invalid &&
        form.get('email').errors &&
        (form.get('email').dirty || form.get('email').touched)">
      <small
        *ngIf="form.get('email').hasError('required')">
        {{'error.missing.email' | translate}}
      </small>
      <small
        *ngIf="form.get('email').hasError('email')">
        {{'error.invalid.email.format' | translate}}
      </small>
    </div>
  </div>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelMissingProductModal()">Bezár</button>
  <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="form.invalid">Elküld</button>
</div>
