import { Component, OnInit } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from '../../../service/form.service';
// import { SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { LoginUser } from '../../../store/user/UserActions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../../service/authentication.service';
import { SignUpParams } from '@aws-amplify/auth/src/types/Auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginEvent } from '../../../model/LoginEvent';
import { UtilsService } from '../../../service/utils.service';
import { CartState } from '../../../store/cart/CartReducer';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Cart } from '../../../model/Cart';
import { InitCart } from '../../../store/cart/CartActions';
import { CartService } from '../../../service/cart.service';
import { User } from '../../../model/User';
import { AddProduct } from '../../../store/product/ProductActions';
import { Json } from '../../../model/Json';
import { Model } from '../../../model/Model';
import { ProductState } from '../../../store/product/ProductReducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  forgetForm: FormGroup;

  // modalType: string;

  emailFocus: boolean = false;

  event: string = LoginEvent.LOGIN;

  loginEvent = LoginEvent;
  isLoginInProgress: boolean;

  hidePsw = true;
  cart: Cart;

  constructor(private readonly toastrService: ToastrService,
              private readonly translateService: TranslateService,
              private readonly formService: FormService,
              // private readonly authService: SocialAuthService,
              private readonly cartStore: Store<CartState>,
              private readonly productStore: Store<ProductState>,
              private readonly authenticationService: AuthenticationService,
              private readonly cartService: CartService,
              private readonly router: Router,
              private readonly fb: FormBuilder,
              private readonly dataexchangeService: DataExchangeService,
              private readonly userStore: Store<UserState>,
              public readonly bsModalRef: BsModalRef,
              private readonly progressSpinnerService: NgxSpinnerService) {
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
  //     .then((resp: SocialUser) => {
  //       const user = new User(resp.id, resp.name, true, resp.photoUrl);
  //       this.userStore.dispatch(new LoginUser(user));
  //       this.router.navigateByUrl('');
  //     }).catch(err => {
  //       console.log(err);
  //   });
  // }

  ngOnInit(): void {
    // this.loginForm = this.formService.getLoginForm();
    this.loginForm = this.fb.group({
      username: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(200)]),
      code: new FormControl({value: '', disabled: true}, [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\W]{8,63}$')]),
      newPassword: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(6), Validators.maxLength(200)]),
      newPasswordAgain: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(6), Validators.maxLength(200)])
    }, {validators: [UtilsService.passwordsMatched('newPassword', 'newPasswordAgain')]});
    this.registerForm = this.formService.getRegistrationForm();
    this.forgetForm = this.formService.getForgetPasswordForm();
    this.cartStore.select(initCartSelector).subscribe((cart) => {
      this.cart = cart;
    });
  }

  // login2() {
  //   if (this.isLoginInProgress || this.loginForm.get('username').invalid || this.loginForm.get('password').invalid) {
  //     return;
  //   }
  //   this.isLoginInProgress = true;
  //   const username = this.loginForm.value.username;
  //   const password = this.loginForm.value.password;
  //
  //   // console.log(username, password);
  //   // if (this.event === 'NEW_PASSWORD_REQUIRED') {
  //   //   Auth.completeNewPassword(user, 'ASDqwe123!Ks')
  //   //     .then((resp) => {
  //   //       console.log(resp);
  //   //       this.event = '';
  //   //     }).catch(err => {
  //   //       console.log(err);
  //   //   });
  //   //   return;
  //   // }
  //
  //   Auth.signIn({
  //     username,
  //     password
  //   }).then(async (user: any) => {
  //     let token = '';
  //     if (this.event === LoginEvent.LOGIN && user.challengeName === LoginEvent.NEW_PASSWORD_REQUIRED) {
  //       this.event = LoginEvent.NEW_PASSWORD_REQUIRED;
  //       this.loginForm.get('newPassword').enable();
  //       this.loginForm.get('newPasswordAgain').enable();
  //       this.isLoginInProgress = false;
  //       // Auth.completeNewPassword(user, 'ASDqwe123!Ks')
  //       //   .then((resp) => {
  //       //     console.log(resp);
  //       //   }).catch(err => {
  //       //     console.log(err);
  //       // })
  //       return;
  //     } else if (this.event === LoginEvent.NEW_PASSWORD_REQUIRED && user.challengeName === LoginEvent.NEW_PASSWORD_REQUIRED) {
  //       const resp = await this.completeNewPassword(user);
  //       token = resp.signInUserSession.accessToken.jwtToken;
  //     } else {
  //       token = user.signInUserSession.accessToken.jwtToken;
  //     }
  //
  //     this.authenticationService.login(token).subscribe((resp) => {
  //       localStorage.setItem('currentUser', JSON.stringify(resp));
  //       this.router.navigateByUrl('');
  //     }, err => {
  //       this.isLoginInProgress = false;
  //       console.log(err);
  //       this.loginForm.reset();
  //       this.toastrService.error(err.error);
  //     });
  //   }).catch(err => {
  //     this.isLoginInProgress = false;
  //     console.log(err);
  //     this.toastrService.error(err.message);
  //   });
  // }

  async completeNewPassword(user): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(user, this.loginForm.get('newPassword').value)
        .then((resp) => {
          console.log(resp);
          this.event = LoginEvent.LOGIN;
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  login(): void {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    this.progressSpinnerService.show();

    Auth.signIn({
      username,
      password
    }).then(async (user: any) => {
      let token;
      if (this.event === LoginEvent.LOGIN && user.challengeName === LoginEvent.NEW_PASSWORD_REQUIRED) {
        this.event = LoginEvent.NEW_PASSWORD_REQUIRED;
        this.loginForm.get('newPassword').enable();
        this.loginForm.get('newPasswordAgain').enable();
        this.isLoginInProgress = false;
        this.progressSpinnerService.hide();
        // Auth.completeNewPassword(user, 'ASDqwe123!Ks')
        //   .then((resp) => {
        //     console.log(resp);
        //   }).catch(err => {
        //     console.log(err);
        // })
        return;
      } else if (this.event === LoginEvent.NEW_PASSWORD_REQUIRED && user.challengeName === LoginEvent.NEW_PASSWORD_REQUIRED) {
        const resp = await this.completeNewPassword(user);
        token = resp.signInUserSession.accessToken.jwtToken;
      } else {
        token = user.signInUserSession.accessToken.jwtToken;
      }

      this.authenticationService.login(token).subscribe((resp) => {
        const cartValues = Object.values(this.cart);
        if (cartValues.length > 0) {
          this.cartService.mergeCart(cartValues.map(cv => {
            return {
              productId: cv.productId,
              quantity: cv.amount
            };
          })).subscribe((cart) => {
            const json: Json<number, Model> = new Json<number, Model>(cart.map(c => c.product.model));
            this.productStore.dispatch(new AddProduct(json));
            this.cartStore.dispatch(new InitCart(cart.map(c => {
              return {
                parentProductId: c.product.model.id,
                amount: c.quantity,
                productId: c.product.id
              };
            })));
            this.completeLogin(resp);
          });
        } else {
          const json: Json<number, Model> = new Json<number, Model>(resp.cart.map(c => c.product.model));
          this.productStore.dispatch(new AddProduct(json));
          const initCart = resp.cart.map(c => {
            return {
              productId: c.product.id,
              parentProductId: c.product.model.id,
              amount: c.quantity
            };
          });
          this.cartStore.dispatch(new InitCart(initCart));
          this.completeLogin(resp);
        }

        // console.log(resp);
        // const authenticatedUser = new User(user.signInUserSession.accessToken.payload.sub, user.signInUserSession.accessToken.payload.username);

      }, err => {
        console.log(err);
        this.toastrService.error(this.translateService.instant(err.error.message));
        this.progressSpinnerService.hide();
      });
    }).catch(err => {
      console.log(err);
      this.progressSpinnerService.hide();
      this.toastrService.error(this.translateService.instant('login.error.invalidEmailOrPassword'));
    });
  }

  completeLogin(user: User): void {
    this.toastrService.success('Sikeres belépés');
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.userStore.dispatch(new LoginUser(user));
    this.progressSpinnerService.hide();
    this.bsModalRef.hide();
  }

  forgotPassword(): void {
    if (this.forgetForm.get('email').invalid) {
      return;
    }

    Auth.forgotPassword(this.forgetForm.get('email').value).then((data) => {
      console.log(data);
      this.event = LoginEvent.FORGOT_PASSWORD_SUBMIT;
      this.forgetForm.get('code').enable();
      this.forgetForm.get('password').enable();
      this.forgetForm.get('newPasswordAgain').enable();
      this.toastrService.success('Email kiküldve az új jelszó igényléséhez');
      // this.bsModalRef.hide();
    }).catch(err => {
      let message = err.message;
      switch (err.message) {
        case 'Attempt limit exceeded, please try after some time.': message = 'Limit túllépve, próbáld újra kicsit később. '; break;
      }
      this.toastrService.error(message);
      // console.log(err)
    });
  }

  forgotPasswordSubmit(): void {
    const email = this.forgetForm.get('email').value;
    const code = this.forgetForm.get('code').value;
    const password = this.forgetForm.get('password').value;
    Auth.forgotPasswordSubmit(email, code, password).then((data) => {
      this.event = LoginEvent.LOGIN;
      this.toastrService.success(this.translateService.instant('password.successfully.changed'));
      this.forgetForm.reset();
      this.forgetForm.get('code').disable();
      this.forgetForm.get('password').disable();
      this.forgetForm.get('newPasswordAgain').disable();
    }).catch(err => {
      // console.log(err)
      let message = err.message;
      switch (err.message) {
        case 'Invalid verification code provided, please try again.': message = 'Hibás kód, próbáld újra'; break;
      }
      this.toastrService.error(message);
    });
  }

  // forgotPassword(): void {
  //   // this.forgetForm.
  //   Auth.forgotPassword(this.forgetForm.value.email)
  //     .then((resp) => {
  //       console.log(resp);
  //       this.toastrService.success('A jelszó megvátoztatásához a kód emailben elküldve.');
  //       this.bsModalRef.hide();
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }

  setModalType(event: LoginEvent): void {
    this.event = event;
    // this.modalType = modalType;
  }

  register(): void {
    const user: SignUpParams = {
      username: this.registerForm.value.username,
      password: this.registerForm.value.password,
      attributes: {
        email: this.registerForm.value.email,
        'custom:newsletter': this.registerForm.value.newsletter.toString()
      }
    };
    Auth.signUp(user).then((createdUser => {
      this.toastrService.success('Sikeres regisztráció! Kattints az emailben kapott linkre a regisztráció megerősítéséhez!');
      this.bsModalRef.hide();
    })).catch((err) => {
      let errorMessage = '';
      switch (err.message) {
        case 'User already exists': errorMessage = 'error.user.already.exists'; break;
        case 'PreSignUp failed with error A user with the same email address exists.': errorMessage = 'error.email.already.used'; break;
        case 'Invalid email address format.': errorMessage = 'error.invalid.email.format'; break;
        case 'Password did not conform with policy: Password not long enough': errorMessage = 'error.password.length.too.short'; break;
        case 'Password did not conform with policy: Password must have numeric characters': errorMessage = 'error.password.must.contain.numeric.characters'; break;
        case 'Password did not conform with policy: Password must have symbol characters': errorMessage = 'error.password.must.contain.symbol.character'; break;
      }
      this.toastrService.error(this.translateService.instant(errorMessage));
    });
    // this.registerService.register(this.registerForm.value).subscribe((resp) => {
    //   console.log(resp);
    // }, err => {
    //   console.log(err);
    // });
  }

  validateUsernameKeypress(event: KeyboardEvent): void {
    UtilsService.validateUsernameKeypress(event);
  }


  checkEmail($event: any){
    alert($event)
  }


  log() {
    console.log(this.registerForm)
  }
}
