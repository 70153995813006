import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { ChangeContext } from '@angular-slider/ngx-slider/change-context';

@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriceFilterComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class PriceFilterComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input()
  minPrice: number;
  @Input()
  maxPrice: number;

  value: DoubleRange;

  isDisabled: boolean;

  sliderOptions: Options;
  actualValue: number;

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  bit: boolean = true;

  propagateChange: any = () => {};
  propagateTouch: any = () => {};

  constructor() { }

  ngOnInit(): void {
    this.sliderOptions = {
      floor: Math.floor(this.minPrice),
      ceil: Math.ceil(this.maxPrice),
      ariaLabelHigh: 'Ft',
      ariaLabelledBy: 'Ft',
      ariaLabelledByHigh: 'Ft',
      translate:  this.translateFunction,
      step: 1,
      animate: true,
      getSelectionBarColor: (min, max) => max > 10000 ? '#CB7677' : '#79B1CC'
    };
    this.actualValue = this.minPrice;
  }

  translateFunction(value: number, label: LabelType) {
    return value + ' Ft';
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  valueChanged(event) {
    this.value.min = event;
  }

  highValueChanged(event) {
    this.value.max = event;
  }

  userChangeEnd(event: ChangeContext) {
    this.value.min = event.value;
    this.value.max = event.highValue;
    this.propagateChange(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value) {
      if (changes.minPrice || changes.maxPrice) {
        this.bit = false;
      }
      if (changes.minPrice) {
        // this.valueChanged(changes.minPrice.currentValue);
        this.sliderOptions.floor = changes.minPrice.currentValue;
        this.actualValue = Math.floor(changes.minPrice.currentValue);
      }
      if (changes.maxPrice) {
        this.highValueChanged(changes.maxPrice.currentValue);
        this.sliderOptions.ceil = Math.ceil(changes.maxPrice.currentValue);
      }
      if (changes.minPrice || changes.maxPrice) {
        // console.log('CHANGED')
        // this.manualRefresh.emit();
        setTimeout(() => {
          // this.bit = false;
          setTimeout(() => {
            this.bit = true;
          }, 100);
        }, 50);
      }
      // console.log(this.actualValue)
      // console.log(this.sliderOptions)
      // console.log(this.value)
      // setTimeout(() => {
      //   console.log(this.value)
      // }, 1000)
    }
  }
}
