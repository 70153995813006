import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-shopping-info',
  templateUrl: './shopping-info.component.html'
})
export class ShoppingInfoComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelShoppingInfoModal() {
    this.modalRef.hide();
  }

}
