import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MergeCart } from '../model/MergeCart';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private httpClient: HttpClient) {
  }

  addProductToCart(productId: string, amount: number): Observable<void> {
    return this.httpClient.post<void>('/api/user/cart/add', {productId, amount});
  }

  removeProductFromCart(productId: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/cart/remove', {productId});
  }

  updateAmountOfProduct(productId: number, quantity: number): Observable<void> {
    return this.httpClient.post<void>('/api/user/cart/change-amount-in-cart', {productId, quantity});
  }

  mergeCart(cart: Array<MergeCart>): Observable<any> {
    return this.httpClient.post<any>('/api/user/cart/merge', {cart});
  }

  // emptyCart(productId: number, amount: number): Observable<void> {
  //   return this.httpClient.post<void>('/api/user/cart/change-amount-in-cart', {productId, amount});
  // }
}
