import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../model/Category';

@Pipe({
  name: 'categoryOrder'
})
export class CategoryOrderPipe implements PipeTransform {
  transform(array: Array<Category>): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    return array.filter(c => c.active).sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      } else {
        return 0;
      }
    });
    // return array;
  }

}
