import { Action } from '@ngrx/store';
import { PaginationInstance } from 'ngx-pagination';

export enum PaginationActionTypes {
  InitPagination = 'Init pagination'
}

export class InitPagination implements Action {
  readonly type = PaginationActionTypes.InitPagination;

  constructor(public paginationInstance: PaginationInstance) {}
}


export type PaginationActions =
  InitPagination;
