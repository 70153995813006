import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SessionExpiredInterceptor implements HttpInterceptor {

  constructor(private readonly router: Router,
              private readonly spinner: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(catchError((err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 498 || err.status === 401) {
          localStorage.removeItem('currentUser');
          // this.router.navigateByUrl('');
        }
        return throwError(err);
      }));
  }
}
