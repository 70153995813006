import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../model/Address';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModifyAddressComponent } from '../../user/modify-address/modify-address.component';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss']
})
export class CheckoutAddressComponent implements OnInit {

  @Input()
  address: Address;

  @Input()
  isUpdateEnabled: boolean;

  constructor(private readonly modalService: BsModalService) { }

  ngOnInit(): void {
  }

  openUpdateModal(): void {
    const initialState = {
      address: this.address
    };
    this.modalService.show(ModifyAddressComponent, {
      initialState,
      class: 'modal-dialog-centered modal-lg',
    });
  }
}
