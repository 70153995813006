import { User } from './User';
import { Filters } from './Filters';
import { Category } from './Category';
import { WebshopSettings } from './WebshopSettings';
import { Model } from './Model';
import { CarouselSchema } from './CarouselSchema';
import { AdsSchema } from './AdsSchema';

export class Preload {
  categories: Array<Category>;
  filters: Filters;
  user?: User;
  settings: WebshopSettings;
  products: Array<Model>;
  carousel: CarouselSchema;
  ads: AdsSchema;
}
