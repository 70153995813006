import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../model/Order';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) {
  }

  getOrders(): Observable<Array<Order>> {
    return this.httpClient.get<Array<Order>>('/api/order');
  }

  getOrder(orderId: string) {
    let params = new HttpParams().set('orderId', orderId);
    return this.httpClient.get<Array<Order>>('/api/order?', {params: params});
  }

  createOrder(command) {
    return this.httpClient.post<Order>('/api/order/create', command);
  }

  downloadOrder(invoiceId) {
    return this.httpClient.post('/api/order/download-invoice', {invoiceId}, {responseType: 'blob'});
  }
}
