

<!--  <ngx-avatar [size]="170" *ngIf="!user.isFacebookUser" class="col-6" src="assets/images/avatar.png"></ngx-avatar>-->
  <!--    <ngx-avatar [size]="170" [facebookId]="user.userId" class="col-6"></ngx-avatar>-->
    <app-personal-data></app-personal-data>

<hr class="mt-5">
    <h2 class="mb-5 mt-5 text-center">Szállítási címek</h2>

    <div class="mb-5 text-center">
      <button class="btn btn-info text-white" *ngIf="!addNewAddressState" (click)="changeAddNewAddressState()"><i class="fa fa-plus"></i> Új cím hozzáadása</button>
    </div>

    <form *ngIf="addNewAddressState" class="row border pb-5 pt-5 mb-5 data-form" [formGroup]="addressForm">

      <div class="col-24"><h3 class="mb-3"><i class="fa fa-plus"></i> Új szállítási cím</h3></div>

      <div class="col-lg-12">
        <div class="form-floating">
          <input type="text"
                 id="nameInput"
                 class="form-control"
                 data-lpignore="true"
                 [class.invalid]="addressForm.get('name').invalid &&
        (addressForm.get('name').dirty || addressForm.get('name').touched)" name="name" formControlName="name" placeholder="'address.name' | translate">
          <label for="nameInput">
            {{'address.name' | translate}}
          </label>

          <div *ngIf="addressForm.get('name').invalid &&
        addressForm.get('name').errors &&
        (addressForm.get('name').dirty || addressForm.get('name').touched)">
            <small class="message"
              *ngIf="addressForm.get('name').hasError('required')">
              {{'error.missing.name' | translate}}
            </small>
          </div>

        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-floating">
          <input type="text" maxlength="20" id="phoneNumber" class="form-control" data-lpignore="true" [class.invalid]="addressForm.get('phoneNumber').invalid &&
        (addressForm.get('phoneNumber').dirty || addressForm.get('phoneNumber').touched)" name="phoneNumber" formControlName="phoneNumber" placeholder="'address.phoneNumber' | translate" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
          <label for="phoneNumber">Telefonszám</label>
          <div *ngIf="addressForm.get('phoneNumber').invalid &&
        addressForm.get('phoneNumber').errors &&
        (addressForm.get('phoneNumber').dirty || addressForm.get('phoneNumber').touched)">
            <small
              *ngIf="addressForm.get('phoneNumber').hasError('required')">
              {{'error.missing.phone' | translate}}
            </small>
            <small
              *ngIf="addressForm.get('phoneNumber').hasError('minlength')">
              {{'error.length.phone' | translate}}
            </small>
            <small
              *ngIf="addressForm.get('phoneNumber').hasError('maxlength')">
              {{'error.length.phone' | translate}}
            </small>
            <small
              *ngIf="addressForm.get('phoneNumber').hasError('pattern')">
              {{'error.format.phone' | translate}}
            </small>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-floating">
          <input maxlength="4" type="number" id="postalCodeInput" class="form-control addressInput" data-lpignore="true" [class.invalid]="addressForm.get('postalCode').invalid &&
        (addressForm.get('postalCode').dirty || addressForm.get('postalCode').touched)" name="postalCode" formControlName="postalCode" placeholder="'address.postalCode' | translate">
          <label for="postalCodeInput">Irányítószám</label>
          <div *ngIf="addressForm.get('postalCode').invalid &&
        addressForm.get('postalCode').errors &&
        (addressForm.get('postalCode').dirty || addressForm.get('name').touched)">
            <small
              *ngIf="addressForm.get('postalCode').hasError('required')">
              {{'error.missing.postal.code' | translate}}
            </small>
            <small
              *ngIf="addressForm.get('postalCode').hasError('pattern')">
              {{'error.need.4.number' | translate}}
            </small>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-floating">
          <input type="text" id="cityInput" class="form-control" data-lpignore="true" [class.invalid]="addressForm.get('city').invalid &&
        (addressForm.get('city').dirty || addressForm.get('city').touched)" name="city" formControlName="city" placeholder="'address.city' | translate">
          <label for="cityInput">Település</label>

          <div *ngIf="addressForm.get('city').invalid &&
        addressForm.get('city').errors &&
        (addressForm.get('city').dirty || addressForm.get('city').touched)">
            <small
              *ngIf="addressForm.get('city').hasError('required')">
              {{'error.missing.city' | translate}}
            </small>
          </div>

        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-floating">
          <input type="text" id="addressInput" class="form-control" data-lpignore="true" [class.invalid]="addressForm.get('address').invalid &&
        (addressForm.get('address').dirty || addressForm.get('address').touched)" name="address" formControlName="address" placeholder="'address.address' | translate">
          <label for="addressInput">{{ 'address.address' | translate }}</label>
          <div *ngIf="addressForm.get('address').invalid &&
        addressForm.get('address').errors &&
        (addressForm.get('address').dirty || addressForm.get('address').touched)">
            <small
              *ngIf="addressForm.get('address').hasError('required')">
              {{'error.missing.address' | translate}}
            </small>
          </div>
        </div>
      </div>




        <div class="d-flex justify-content-center mt-5 mb-5">
          <button class="btn btn-primary me-4" type="submit" [disabled]="!addressForm.dirty || addressForm.invalid || !isSubmitEnabled"  (click)="addNewAddress()">Hozzáadás</button>
          <button class="btn btn-outline-primary" (click)="closeAddNewAddress()">Mégsem</button>
        </div>

    </form>
    <app-address *ngFor="let a of profile.addresses" [address]="a"></app-address>


    <h2 class="mb-5 text-center">Cégadatok</h2>
    <app-company></app-company>


