import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CategoryState } from '../store/category/CategoryReducer';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private readonly store: Store<CategoryState>) { }
}
