<!--<form [formGroup]="personalDataForm" (submit)="savePersonalData()">-->

<!--  <div class="row w-100 d-flex">-->
<!--    <div class="col-sm-18 order-2 order-sm-1">-->
<!--  <h3>Személyes adataim</h3>-->

<!--&lt;!&ndash;  TODO Ha cégként regisztrált akkor ne lehessen ezen változtatni? Az emailen, neven lehessen? [disabled]="companyChecked"&ndash;&gt;-->

<!--  <div class="form-check mb-4">-->
<!--    <input class="form-check-input" type="checkbox" id="companyCheck" [checked]="companyChecked" (click)="companyCheck()">-->
<!--    <label class="form-check-label" for="companyCheck">-->
<!--      <span>Cégként vagyok regisztrálva</span>-->
<!--&lt;!&ndash;      <span *ngIf="!companyChecked">Nem cégként vagyok regisztrálva</span>&ndash;&gt;-->
<!--    </label>-->
<!--  </div>-->
<!--    </div>-->

<!--    <div class="col-sm-6 d-flex align-items-center justify-content-end order-1 order-sm-2">-->
<!--        <ngx-avatar [size]="70" src="assets/images/avatar.png"></ngx-avatar>-->
<!--&lt;!&ndash;    <ngx-avatar [size]="170" *ngIf="!user.isFacebookUser" src="assets/images/avatar.png"></ngx-avatar>&ndash;&gt;-->
<!--  &lt;!&ndash;    <ngx-avatar [size]="170" [facebookId]="user.userId" class="col-6"></ngx-avatar>&ndash;&gt;-->
<!--   </div>-->

<!--  </div>-->

<!--  <div class="row g-4 mb-3">-->

<!--    <div class="col-24">-->
<!--      <accordion [isAnimated]="true">-->
<!--        <accordion-group [isOpen]="companyChecked" heading="Cégadatok" [panelClass]="customCompanyPanelClass">-->

<!--          <div class="row g-4">-->
<!--            <div class="col-lg-24 col-xl-12">-->
<!--              <div class="form-floating mb-1">-->
<!--                <input type="text" class="form-control" id="companyNameInput" placeholder="Cégnév">-->
<!--                <label for="companyNameInput">Cégnév</label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-12 col-xl-6">-->
<!--              <div class="form-floating">-->
<!--                <select class="form-select" aria-label="adószám" id="taxInput">-->
<!--                  <option value="hu">Magyar adószám</option>-->
<!--                  <option value="eu">EU adószám</option>-->
<!--                </select>-->
<!--                <label for="taxInput">Adószám típus</label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-12 col-xl-6">-->
<!--              <div class="form-floating">-->
<!--                <input type="text" class="form-control" id="taxNumber" placeholder="Adószám">-->
<!--                <label for="taxNumber">Adószám</label>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </accordion-group>-->
<!--      </accordion>-->
<!--    </div>-->

<!--    <div class="col-24 col-md-8">-->
<!--      <div class="form-floating mb-2">-->
<!--        <input formControlName="name" type="text" class="form-control" id="nameInput" placeholder="Név">-->
<!--        <label for="nameInput">Név</label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-md-8">-->
<!--      <div class="form-floating mb-2">-->
<!--        <input formControlName="email" type="email" class="form-control" id="emailInput" placeholder="E-mail cím">-->
<!--        <label for="emailInput">E-mail cím</label>-->
<!--      </div>-->
<!--    </div>-->

<!--      <div class="col-24 col-md-8">-->
<!--        <div class="form-floating">-->
<!--          <input formControlName="phoneNumber" type="text" class="form-control" id="phoneInput" placeholder="Telefonszám">-->
<!--          <label for="phoneInput">Telefonszám</label>-->
<!--        </div>-->
<!--      </div>-->

<!--    <div class="col-sm-8 col-lg-7 col-xl-8">-->
<!--      <div class="form-floating">-->
<!--        <select class="form-select" aria-label="ország" id="countryInput">-->
<!--          <option value="hu">Magyarország</option>-->
<!--          <option value="sk">Szlovákia</option>-->
<!--          <option value="ro">Románia</option>-->
<!--        </select>-->
<!--        <label for="countryInput">Ország</label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-sm-6 col-lg-6 col-xl-4">-->
<!--      <div class="form-floating">-->
<!--        <input type="text" class="form-control" id="billingZip" placeholder="Irányítószám">-->
<!--        <label for="billingZip">Irányítószám</label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-sm-10 col-lg-11 col-xl-12">-->
<!--      <div class="form-floating">-->
<!--        <input type="text" class="form-control" id="billingCity" placeholder="Város">-->
<!--        <label for="billingCity">Város</label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-md-14 col-lg-13 col-xl-14">-->
<!--      <div class="form-floating">-->
<!--        <input type="text" class="form-control" id="billingAddress" placeholder="Cím (utca, házszám)">-->
<!--        <label for="billingAddress">Cím (utca, házszám)</label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24 col-md-10 col-lg-11 col-xl-10">-->
<!--      <div class="form-floating">-->
<!--        <input type="text" class="form-control" id="additionalBillingAddress" placeholder="Emelet, ajtó, egyéb (opcionális)">-->
<!--        <label for="additionalBillingAddress">Emelet, ajtó, egyéb (opcionális)</label>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-24  col-lg-18 form-check pt-5">-->
<!--      <input class="form-check-input" type="checkbox"  id="newsletterCheck">-->
<!--      <label class="form-check-label" for="newsletterCheck">-->
<!--        Szeretnék hírlevelet kapni az akciókról, újdonságokról.-->
<!--      </label>-->
<!--    </div>-->

<!--    <div class="col-24 col-lg-6 pt-5 d-flex justify-content-end">-->
<!--      <button class="btn btn-outline-primary me-2" [disabled]="!personalDataForm.dirty" (click)="resetPersonalData()">Mégse</button>-->

<!--      <button class="btn btn-primary text-white d-inline" type="submit" [disabled]="!personalDataForm.dirty && !personalDataForm.invalid || personalDataForm.dirty && personalDataForm.invalid"> Mentés</button>-->
<!--    </div>-->

<!--  </div>-->

<!--</form>-->

<h2 class="text-center">Adatok</h2>

<form [formGroup]="personalDataForm" (submit)="savePersonalData()" class="data-form">
  <div class="row justify-content-center">

    <div class="col-lg-8">
      <div class="form-floating">
        <input class="form-control" id="nameInput" formControlName="name" data-lpignore="true" type="text"
               placeholder="Név">
        <label for="nameInput">Név</label>
        <div *ngIf="personalDataForm.get('name').invalid &&
        personalDataForm.get('name').errors &&
        (personalDataForm.get('name').dirty || personalDataForm.get('name').touched)">
          <small
            *ngIf="personalDataForm.get('name').hasError('required')">
            {{'error.missing.name' | translate}}
          </small>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="form-floating">
    <input class="form-control" id="emailInput" data-lpignore="true" type="email" formControlName="email" placeholder="'E-mail'">
      <label for="emailInput">E-mail</label>
        <div *ngIf="personalDataForm.get('email').invalid &&
        personalDataForm.get('email').errors &&
        (personalDataForm.get('email').dirty || personalDataForm.get('email').touched)">
          <small
            *ngIf="personalDataForm.get('email').hasError('required')">
            {{'error.missing.email' | translate}}
          </small>
          <small
            *ngIf="personalDataForm.get('email').hasError('email')">
            {{'error.invalid.email.format' | translate}}
          </small>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="form-floating">
        <input class="form-control" maxlength="20" id="phoneInput" data-lpignore="true" type="text" formControlName="phoneNumber" placeholder="'Telefonszám'" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
        <label for="phoneInput">Telefonszám</label>
        <div *ngIf="personalDataForm.get('phoneNumber').invalid &&
        personalDataForm.get('phoneNumber').errors &&
        (personalDataForm.get('phoneNumber').dirty || personalDataForm.get('phoneNumber').touched)">
          <small
            *ngIf="personalDataForm.get('phoneNumber').hasError('required')">
            {{'error.missing.phone' | translate}}
          </small>
          <small
            *ngIf="personalDataForm.get('phoneNumber').hasError('minlength')">
            {{'error.length.phone' | translate}}
          </small>
          <small
            *ngIf="personalDataForm.get('phoneNumber').hasError('maxlength')">
            {{'error.length.phone' | translate}}
          </small>
          <small
            *ngIf="personalDataForm.get('phoneNumber').hasError('pattern')">
            {{'error.format.phone' | translate}}
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="hstack gap-3 d-flex justify-content-center mt-5">
    <button class="btn btn-outline-dark" *ngIf="!modifyState" (click)="enableForm()">Szerkesztés</button>
    <button class="btn btn-outline-primary" *ngIf="modifyState" (click)="disableForm()">Mégse</button>
    <button class="btn btn-primary" type="submit" *ngIf="modifyState" [disabled]="!personalDataForm.dirty || personalDataForm.invalid">Mentés</button>
  </div>

  <div class="row">
    <div>

    </div>
    <div>

    </div>
  </div>
</form>

<!--  <div class="row d-flex flex-column form-group">-->
<!--    <input class="col-6 form-control form-control-sm mb-4" data-lpignore="true" type="text" formControlName="name" placeholder="'Name'">-->
<!--    <input class="col-6 form-control form-control-sm mb-4" data-lpignore="true" type="text" formControlName="email" placeholder="'Email'">-->
<!--    <input class="col-6 form-control form-control-sm mb-4" data-lpignore="true" type="text" formControlName="phoneNumber" placeholder="'Phone number'">-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div>-->
<!--      <button class="btn btn-primary me-5" type="submit" [disabled]="!personalDataForm.dirty && !personalDataForm.invalid || personalDataForm.dirty && personalDataForm.invalid">Mentés</button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button class="btn btn-danger ms-5" [disabled]="!personalDataForm.dirty" (click)="resetPersonalData()">Mégse</button>-->
<!--    </div>-->
<!--  </div>-->

