import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../service/form.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddAddress } from '../../../store/user/UserActions';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-add-new-address',
  templateUrl: './add-new-address.component.html'
})
export class AddNewAddressComponent implements OnInit {

  form: FormGroup;

  constructor(private readonly fb: FormBuilder,
              private readonly toastrService: ToastrService,
              private readonly userService: UserService,
              private readonly formService: FormService,
              private readonly userStore: Store<UserState>,
              public readonly modalRef: BsModalRef) {
  }

  ngOnInit(): void {
    this.form = this.formService.getAddressForm();
  }

  save(): void {
    this.userService.addAddress(this.form.getRawValue()).subscribe((address) => {
      this.userStore.dispatch(new AddAddress(address));
      this.toastrService.success('Cím hozzáadva');
      this.modalRef.hide();
    });
  }

  setPhonePrefix(event) {
    if(event.target['value'] == '') {
      event.target['value'] = event.target['value'] + "+36";
    }
  }

  checkPhoneNumber(event) {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
    const tempValue = event.target['value'];
    if (validkeys.indexOf(event.key) < 0) {
      event.target['value'] = tempValue.replace(/[^0-9+]/g, "");
    }
    this.setPhonePrefix(event);
  }
}
