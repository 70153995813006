<div class="form-inline  text-center">
  <label class="small-label w-100 ps-2">termék/oldal</label>
  <div class="btn-group ms-4" btnRadioGroup [(ngModel)]="radioModel">
    <label class="btn btn btn-sm btn-primary"
           btnRadio="12"
           tooltip="12 termék / oldal"
           [delay]="1000"
           matRipple
           [matRippleUnbounded]="false"
           [matRippleCentered]="true"
           (click)="changePageSize(12)">12
    </label>

    <label class="btn btn btn-sm btn-primary"
           btnRadio="24"
           tooltip="24 termék / oldal"
           [delay]="1000"
           matRipple
           [matRippleUnbounded]="false"
           [matRippleCentered]="true"
           (click)="changePageSize(24)">24
    </label>
    <label class="btn btn btn-sm btn-primary"
           btnRadio="32"
           tooltip="32 termék / oldal"
           [delay]="1000"
           matRipple
           [matRippleUnbounded]="false"
           [matRippleCentered]="true"
           (click)="changePageSize(32)">32
    </label>

    <!--                <span class="form-text">termék  / oldal</span>-->
    <!--                <label class="btn btn btn-sm btn-outline-info">termék / oldal</label>-->
  </div>
</div>
