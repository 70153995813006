<div class="modal-header">
  <h4 class="modal-title">Új cím hozzáadása</h4>
  <i class="fa fa-2x fa-times" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body ps-0 pe-0">
  <form class="row g-3 data-form" [formGroup]="form">
    <div class="col-lg-12">
      <div class="form-floating">
        <input type="text" class="form-control mb-4" placeholder="Név" formControlName="name" data-lpignore="true">
        <label>Név</label>

        <div *ngIf="form.get('name').invalid &&
        form.get('name').errors &&
        (form.get('name').dirty || form.get('name').touched)">
          <small class="message" *ngIf="form.get('name').hasError('required')">
            {{'error.missing.name' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="form.get('name').hasError('minlength')">
            {{'error.min.3.character' | translate}}
          </small>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-floating">
        <input type="text" class="form-control mb-4" placeholder="Telefonszám" formControlName="phoneNumber" data-lpignore="true" maxlength="20" (click)="setPhonePrefix($event)" (keyup)="checkPhoneNumber($event)">
        <label>Telefonszám</label>

        <div *ngIf="form.get('phoneNumber').invalid &&
        form.get('phoneNumber').errors &&
        (form.get('phoneNumber').dirty || form.get('phoneNumber').touched)">
          <small class="message" *ngIf="form.get('phoneNumber').hasError('required')">
            {{'error.missing.phone' | translate}}
          </small>
          <small class="delayed-message" *ngIf="form.get('phoneNumber').hasError('minlength')">
            {{'error.length.phone' | translate}}
          </small>
          <small class="message" *ngIf="form.get('phoneNumber').hasError('maxlength')">
            {{'error.length.phone' | translate}}
          </small>
          <small class="delayed-message" *ngIf="form.get('phoneNumber').hasError('pattern')">
            {{'error.format.phone' | translate}}
          </small>
        </div>

      </div>
    </div>
    <div class="col-sm-8 col-lg-6 col-xl-4">
      <div class="form-floating">
        <input type="number" class="form-control mb-4 mb-lg-0" placeholder="Irányítószám" formControlName="postalCode" data-lpignore="true" maxlength="4">
        <label>Irányítószám</label>
        <div *ngIf="form.get('postalCode').invalid &&
        form.get('postalCode').errors &&
        (form.get('postalCode').dirty || form.get('postalCode').touched)">
          <small class="message" *ngIf="form.get('postalCode').hasError('required')">
            {{'error.missing.postal.code' | translate}}
          </small>
          <small class="delayed-message" *ngIf="form.get('postalCode').hasError('pattern')">
            {{'error.need.4.number' | translate}}
          </small>
          <small class="delayed-message" *ngIf="form.get('postalCode').hasError('minlength')">
            {{'error.need.4.number' | translate}}
          </small>
          <small class="delayed-message" *ngIf="form.get('postalCode').hasError('maxlength')">
            {{'error.need.4.number' | translate}}
          </small>
        </div>

      </div>
    </div>
    <div class="col-sm-16 col-lg-8 col-xl-8">
      <div class="form-floating">
        <input type="text" class="form-control mb-4  mb-lg-0" placeholder="Település" formControlName="city" data-lpignore="true">
        <label>Település</label>

        <div *ngIf="form.get('city').invalid &&
        form.get('city').errors &&
        (form.get('city').dirty || form.get('city').touched)">
          <small class="message" *ngIf="form.get('city').hasError('required')">
            {{'error.missing.city' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="form.get('city').hasError('minlength')">
            {{'error.min.2.character' | translate}}
          </small>
        </div>

      </div>
    </div>
    <div class="col-lg-10 col-xl-12">
      <div class="form-floating">
        <input type="text" class="form-control" placeholder="Cím (utca, házszám)" formControlName="address" data-lpignore="true">
        <label>Cím (utca, házszám)</label>

        <div *ngIf="form.get('address').invalid &&
        form.get('address').errors &&
        (form.get('address').dirty || form.get('address').touched)">
          <small class="message" *ngIf="form.get('address').hasError('required')">
            {{'error.missing.address' | translate}}
          </small>
          <small class="delayed-message" *ngIf="form.get('address').hasError('minlength')">
            {{'error.min.2.character' | translate}}
          </small>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">Mégsem</button>
  <button type="submit" class="btn btn-primary" (click)="save()">Hozzáadás</button>
</div>
