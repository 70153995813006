import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-size-chart',
  templateUrl: './size-chart.component.html'
})
export class SizeChartComponent implements OnInit {

  @Input()
  multi: false;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelDeliveryModal() {
    this.modalRef.hide();
  }

}

