import { catchError } from 'rxjs/operators';
import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(private readonly injector: Injector,
              private readonly ngZone: NgZone) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((error: HttpErrorResponse) => {
          const router = this.injector.get(Router);
          if (error.status === 401) {
            sessionStorage.removeItem('currentUser');
            this.ngZone.run(() => {
              return router.navigate(['']);
            });
          }

          return throwError(error);
        })
      );
  }
}
