import {Component, ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

/** @title Sidenav with custom escape and backdrop click behavior */
@Component({
  selector: 'sidenav',
  templateUrl: 'sidenav-left.component.html'
})
export class SidenavLeftComponent{
  @ViewChild('sidenav') sidenav: MatSidenav;

  reason = '';

  close(reason: string) {
    this.reason = reason;
   // this.sidenav.close();
  }
}
