import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Order } from '../model/Order';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class MyOrderResolverService implements Resolve<any> {

  constructor(private readonly orderService: OrderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Order>> {
    return this.orderService.getOrders();
  }

  // private static getTestOrders(): Array<Order> {
  //   return [
  //     {
  //       orderId: 'asd',
  //       status: 'DONE',
  //       address: {
  //         id: 56,
  //         country: 'HU',
  //         address: 'Address',
  //         city: 'Miskolc',
  //         postalCode: 3525
  //       },
  //       billingAddress: {
  //         id: 28,
  //         country: 'HU',
  //         address: 'Address',
  //         city: 'Miskolc',
  //         postalCode: 3525
  //       },
  //       creationDate: new Date(),
  //       products: [],
  //       total: 34550,
  //       comment: 'comment'
  //     },
  //     {
  //       orderId: 'qwe',
  //       status: 'NEW',
  //       address: {
  //         id: 232,
  //         country: 'HU',
  //         address: 'Address',
  //         city: 'Miskolc',
  //         postalCode: 3525
  //       },
  //       billingAddress: {
  //         id: 111,
  //         country: 'HU',
  //         address: 'Address',
  //         city: 'Miskolc',
  //         postalCode: 3525
  //       },
  //       creationDate: new Date(),
  //       products: [],
  //       total: 24550,
  //       comment: 'comment'
  //     }
  //   ]
  // }
}
