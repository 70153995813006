<div class="container-fluid ad-top" [@closeAd]=currentState>

  <div class="row">
  <div class="col-2"></div>


    <div class="col-20 justify-content-center">
  <p>adtop works!</p>
    </div>

      <div class="col-2 justify-content-end">
  <i class="fa fa-times" (click)="closeAdTop()"></i>
      </div>
  </div>

</div>
