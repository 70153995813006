import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from './filter.service';

@Pipe({
  name: 'baseFilters'
})
export class BaseFiltersPipe implements PipeTransform {

  transform(value: any[]): unknown {
    return value.filter(v => !FilterService.baseProperties.includes(v.key)).filter(v => v.value.length > 1);
  }

}
