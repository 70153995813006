export class ProductInCart {
  productId: number;
  parentProductId: number;
  amount: number;

  constructor(productId: number, parentProductId: number, amount: number) {
    this.productId = productId;
    this.parentProductId = parentProductId;
    this.amount = amount;
  }
}
