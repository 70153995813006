import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../model/Address';
import { PersonalData } from '../model/PersonalData';
import { Company } from '../model/Company';
import { UtilsService } from './utils.service';
import { Json } from '../model/Json';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private fb: FormBuilder) {
  }

  // getLoginForm(): FormGroup {
  //   return this.fb.group({
  //     ...this.getUsernameFormControl(),
  //     password: new FormControl('', [Validators.required])
  //   });
  // }

  // getLoginForm(): FormGroup {
  //   return this.fb.group({
  //     ...this.getUsernameFormControl(),
  //     code: new FormControl({value: '', disabled: true}, [Validators.required]),
  //     password: new FormControl('', [Validators.required, Validators.maxLength(200)]),
  //     newPassword: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(6), Validators.maxLength(200)]),
  //     newPasswordAgain: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(6), Validators.maxLength(200)])
  //   }, {validators: [UtilsService.passwordsMatched('newPassword', 'newPasswordAgain')]});
  // }

  getRegistrationForm(): FormGroup {
    return this.fb.group({
      ...this.getUsernameFormControl(),
      ...this.getEmailFormControl(),
      ...this.getPasswordForm(),
      newsletter: new FormControl(false)
    }, {validators: [UtilsService.passwordsMatched('password', 'passwordAgain')]});
  }

  getForgetPasswordForm(): FormGroup {
    return this.fb.group({
      ...this.getEmailFormControl(),
      code: new FormControl({value: '', disabled: true}, [Validators.required]),
      password: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
      newPasswordAgain: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(6), Validators.maxLength(30)])
    }, {validators: [UtilsService.passwordsMatched('password', 'newPasswordAgain')]});
  }

  getRegistrationPersonalData(): FormGroup {
    return this.fb.group({
      ...this.getUsernameFormControl(),
      ...this.getEmailFormControl(),
      // email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)])
    });
  }

  getCheckoutForm(): FormGroup {
    return this.fb.group({
      personalData: this.getPersonalDataForm(),
      company: this.getCompanyForm(),
      shippingAddress: this.getAddressForm(),
      selectedShippingAddressId: new FormControl(null, [Validators.required]),
      billingAddress: this.getAddressForm(),
      selectedBillingAddressId: new FormControl(null, [Validators.required]),
      paymentType: new FormControl(null, [Validators.required]),
      message: new FormControl(''),
      isCardPaymentConfirmed: new FormControl(false)
    });
  }

  getPasswordForm(): Json<string, FormControl> {
    return {
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\W]{8,63}$')]),
      passwordAgain: new FormControl('', [Validators.required])
    };
  }

  getAddressForm(address?: Address): FormGroup {
    return this.fb.group({
      id: new FormControl({value: address ? address.id : '', disabled: true}),
      ...this.getAddressFormControls(address)
    });
  }

  getPersonalDataForm(personalData?: PersonalData): FormGroup {
    return this.fb.group({
      name: new FormControl(personalData ? personalData.name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      // email: new FormControl(personalData ? personalData.email : '', [Validators.required]),
      ...this.getSubscribeToNewsletterFormControl(personalData ? personalData.subscribeToNewsletter : false),
      ...this.getEmailFormControl(personalData?.email),
      ...this.getPhoneNumberFormControl(personalData ? personalData.phoneNumber : '')
    });
  }

  getSubscribeToNewsletterFormControl(subscribe: boolean): Json<string, FormControl> {
    return {
      subscribeToNewsletter: new FormControl(subscribe ? subscribe : false)
    };
  }

  getCompanyForm(company?: Company): FormGroup {
    return this.fb.group({
      name: new FormControl(company ? company.name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      taxNumber: new FormControl(company ? company.taxNumber : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      // ...this.getCountryFormControl(company?.country),
      ...this.getPostalCodeFormControl(company?.postalCode),
      ...this.getCityFormControl(company?.city),
      ...this.getAddressFormControl(company?.address),
      ...this.getPhoneNumberFormControl(company?.phoneNumber)
    });
  }

  getReviewForm(productId: number): FormGroup {
    return this.fb.group({
      modelId: new FormControl({value: productId, disabled: true}),
      rating: new FormControl(null, Validators.required),
      review: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(500)])
    });
  }

  getAddressFormControls(address?: Address): Json<string, FormControl> {
    return {
      // ...this.getCountryFormControl(address?.country),
      ...this.getNameFormControl(address?.name),
      ...this.getPostalCodeFormControl(address?.postalCode),
      ...this.getCityFormControl(address?.city),
      ...this.getAddressFormControl(address?.address),
      ...this.getPhoneNumberFormControl(address ? address.phoneNumber : '')
    };
  }

  // getCountryFormControl(country: string): Json<string, FormControl> {
  //   return {
  //     country: new FormControl(country, [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
  //   };
  // }

  getPostalCodeFormControl(postalCode: number): Json<string, FormControl> {
    return {
      postalCode: new FormControl(postalCode, [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(/^\d\d\d\d$/)]),
    };
  }

  getNameFormControl(name: string): Json<string, FormControl> {
    return {
      name: new FormControl(name, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    };
  }

  getCityFormControl(city: string): Json<string, FormControl> {
    return {
      city: new FormControl(city , [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    };
  }

  getAddressFormControl(address: string): Json<string, FormControl> {
    return {
      address: new FormControl(address, [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    };
  }

  getPhoneNumberFormControl(phoneNumber: string): Json<string, FormControl> {
    return {
      phoneNumber: new FormControl(phoneNumber, [Validators.required, Validators.pattern('[+][0-9]*$'), Validators.minLength(12), Validators.maxLength(20)])
    };
  }

  getUsernameFormControl(username?: string): Json<string, FormControl> {
    return {
      username: new FormControl(username ? username : '', [Validators.required, Validators.minLength(3), Validators.maxLength(12), UtilsService.usernameValidator])
    };
  }

  getEmailFormControl(email?: string): Json<string, FormControl> {
    return {
      email: new FormControl(email ? email : '', [Validators.required, Validators.email, Validators.maxLength(50), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
    };
  }
}
