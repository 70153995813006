import { CartActions, CartActionTypes } from './CartActions';
import { ProductInCart } from '../../model/ProductInCart';
import { Cart } from '../../model/Cart';
import { Json } from '../../model/Json';
import { CartProduct } from '../../model/User';

export interface CartState {
  productsInCart: Cart;
}

export const initialState: CartState = {
  productsInCart: {}
};

export function cartReducer(state: CartState = initialState, action: CartActions): CartState {
  switch (action.type) {
    case CartActionTypes.InitCart: {
      return {
        ...state,
        productsInCart: new Json<number, ProductInCart>(action.payload, 'productId')
      };
    }
    case CartActionTypes.MergeCart: {
      return {
        ...state,
        productsInCart: mapCart(state.productsInCart, action.payload)
      };
    }
    case CartActionTypes.AddProductToCart: {
      const existingProductInCart: ProductInCart = state.productsInCart[action.productId];
      return {
        ...state,
        productsInCart: {
          ...state.productsInCart,
          [action.productId]: existingProductInCart ? {
            ...existingProductInCart,
            amount: existingProductInCart.amount + action.productInCart.amount
          } : action.productInCart
        }
      };
    }
    case CartActionTypes.RemoveProductFromCart: {
      const { [action.productId]: toRemoved, ...rest} = state.productsInCart;
      return {
        ...state,
        productsInCart: {
          ...rest
        }
      };
    }
    case CartActionTypes.ChangeAmountOfProduct: {
      return {
        ...state,
        productsInCart: {
          ...state.productsInCart,
          [action.productId]: {
            ...state.productsInCart[action.productId],
            amount: action.amount
          }
        }
      };
    }
    case CartActionTypes.EmptyCart: {
      return {
        ...state,
        productsInCart: {}
      };
    }
  }
  return state;
}

function mapCart(cart: Cart, productsInCart: Array<CartProduct>): Cart {
  productsInCart.forEach(p => {
    if (cart[p.product.id]) {
      cart[p.product.id].amount = cart[p.product.id].amount + p.quantity;
    } else {
      cart[p.product.id] = {
        productId: p.product.id,
        amount: p.quantity,
        parentProductId: p.product.model.id
      };
    }
  });
  return cart;
}

export const initCart = (state: CartState) => state.productsInCart;

