export class FilterPropertyState {
  label: string;
  isChecked: boolean;
  data: any;

  constructor(label: string, isChecked: boolean, data?: any) {
    this.label = label;
    this.isChecked = isChecked;
    this.data = data;
  }
}

// export class FilterPropertyState {
//   label: string;
//   isChecked: boolean;
//   data: any;
//   query: any;
//
//   constructor(label: string, isChecked: boolean, query: any, data?: any) {
//     this.label = label;
//     this.isChecked = isChecked;
//     this.query = query;
//     this.data = data;
//   }
// }
