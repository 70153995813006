<div class="modal-header">

  <h4 class="modal-title">Írj nekünk!</h4>
  <i class="fa fa-2x fa-times" (click)="cancelContactFormModal()"></i>
</div>

<div class="modal-body modal-info">
<!--  <form method="post" action="http://cmsforms.rootor.eu/contactform.php">-->
  <form #form="ngForm">
  <div class="form-floating mb-5">
    <input type="neved" class="form-control" name="name" id="nameInput" placeholder="Neved">
    <label for="nameInput">Neved</label>
  </div>

  <div class="form-floating mb-5">
    <input type="email" class="form-control" name="email" id="emailInput" placeholder="E-mail címed">
    <label for="emailInput">E-mail címed</label>
  </div>

  <div class="form-floating mb-5">
    <textarea class="form-control" placeholder="Üzeneted" name="message" id="messageInput"></textarea>
    <label for="messageInput">Üzeneted</label>
  </div>

    <div class="d-flex justify-content-center">
    <re-captcha
      id="recaptcha"
      name="recaptcha"
      #recaptcha="ngModel"
      [(ngModel)]="token"
      required
      [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
    </re-captcha>

    <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
      <div *ngIf="recaptcha.errors?.required">This field is required.</div>
    </div>
    </div>


  <button (click)="send(form)" class="btn btn-primary btn-rounded w-100 mt-5">Elküld</button>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelContactFormModal()">Bezár</button>
</div>
