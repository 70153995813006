<div class="modal-header">

  <h4 class="modal-title">{{ 'sizechart.sizetables' | translate}}</h4>
  <i class="fa fa-2x fa-times" (click)="cancelDeliveryModal()"></i>
</div>

<div class="modal-body modal-info">

<!--  <h2>Méretek címe</h2>-->
<!--  <p>Kattints ..... bevezető szöveg.</p>-->


  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.bathrobes.for.women' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.blouses.for.women' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered sizechart">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.body.for.women' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="5">158 / 164 / 170</td>
        </tr>
        </thead>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.bras' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>AAA</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.bustier.for.women' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.capris.for.kids' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td>62-74</td>
          <td>80-86</td>
          <td>92-98</td>
          <td>104-110</td>
          <td>116-122</td>
          <td>128-134</td>
          <td>140-146</td>
          <td>150-152</td>
          <td>152-158</td>
          <td>158-164</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }}</th>
          <td>12</td>
          <td>14</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>22</td>
          <td>23</td>
          <td>25</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age' | translate }}</th>
          <td>0-12 m</td>
          <td colspan="2">1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="4">14-18</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.cardigans.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.dresses.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.footlets.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.socks.size' | translate }}</th>
          <td>23</td>
          <td>25</td>
          <td>27</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }} (EU)</th>
          <td>36-37</td>
          <td>38-39</td>
          <td>40-41</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.hoodies.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jeans.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jeggings.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td>62-74</td>
          <td>80-86</td>
          <td>92-98</td>
          <td>104-110</td>
          <td>116-122</td>
          <td>128-134</td>
          <td>140-146</td>
          <td>150-152</td>
          <td>152-158</td>
          <td>158-164</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }}</th>
          <td>12</td>
          <td>14</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>22</td>
          <td>23</td>
          <td>25</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age' | translate }}</th>
          <td>0-12 m</td>
          <td colspan="2">1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="4">14-18</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jeggings.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
          <td>5XL</td>
          <td>6XL</td>
          <td>7XL</td>
          <td>8XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
          <td>52</td>
          <td>54</td>
          <td>56</td>
          <td>58</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
          <td>122</td>
          <td>126</td>
          <td>130</td>
          <td>134</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="12">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jumpers.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="9">{{ 'sizechart.size.chart' | translate }}</th>
        </tr>
        </thead>
        <tbody> {{ 'sizechart.information.required' | translate }}</tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jumpers.for.men' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
          <td>5XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
          <td>52</td>
          <td>54</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
          <td>116</td>
          <td>120</td>
        </tr>
        <tr>
          <th>Waist circumference</th>
          <td>78</td>
          <td>82</td>
          <td>86</td>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
        </tr>
        <tr>
          <th rowspan="2">Height</th>
          <td colspan="9">170/ 176</td>
        </tr>
        <tr>
          <td colspan="9">182/ 188</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jumpers.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.jumpsuits.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
           {{ 'sizechart.knee.highs.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.socks.size' | translate }}</th>
          <td>8</td>
          <td>10</td>
          <td>12</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>24</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }}</th>
          <td>15</td>
          <td>16-17</td>
          <td>18-20</td>
          <td>21-23</td>
          <td>24-26</td>
          <td>27-29</td>
          <td>30-32</td>
          <td>33-35</td>
          <td>36-37</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age' | translate }}</th>
          <td colspan="3">0-12 m</td>
          <td>1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="2">14-18</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.knee.highs.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="4">{{ 'sizechart.knee.highs.for.women' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>{{ 'sizechart.knee.highs.size' | translate }}</th>
          <td>23</td>
          <td>25</td>
          <td>27</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }} (EU)</th>
          <td>36-37</td>
          <td>38-39</td>
          <td>40-41</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.leggings.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td>62-74</td>
          <td>80-86</td>
          <td>92-98</td>
          <td>104-110</td>
          <td>116-122</td>
          <td>128-134</td>
          <td>140-146</td>
          <td>150-152</td>
          <td>152-158</td>
          <td>158-164</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }}</th>
          <td>12</td>
          <td>14</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>22</td>
          <td>23</td>
          <td>25</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age' | translate }}</th>
          <td>0-12 m</td>
          <td colspan="2">1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="4">14-18</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.leggings.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
          <td>5XL</td>
          <td>6XL</td>
          <td>7XL</td>
          <td>8XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
          <td>52</td>
          <td>54</td>
          <td>56</td>
          <td>58</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
          <td>122</td>
          <td>126</td>
          <td>130</td>
          <td>134</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="12">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.pajamas.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.panties.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      AAA
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.pants.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td>62-74</td>
          <td>80-86</td>
          <td>92-98</td>
          <td>104-110</td>
          <td>116-122</td>
          <td>128-134</td>
          <td>140-146</td>
          <td>150-152</td>
          <td>152-158</td>
          <td>158-164</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }}</th>
          <td>12</td>
          <td>14</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>22</td>
          <td>23</td>
          <td>25</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age' | translate }}</th>
          <td>0-12 m</td>
          <td colspan="2">1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="4">14-18</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.pants.for.men' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>Waist circumference</th>
          <td>78-82</td>
          <td>86-90</td>
          <td>94-98</td>
          <td>102-106</td>
          <td>110-114</td>
          <td>118-122</td>
        </tr>
        </thead>
       </table>
    </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.peignoirs.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.shirts.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.socks.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.socks.size' | translate }}</th>
          <td>8</td>
          <td>10</td>
          <td>12</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>24</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }}</th>
          <td>15</td>
          <td>16-17</td>
          <td>18-20</td>
          <td>21-23</td>
          <td>24-26</td>
          <td>27-29</td>
          <td>30-32</td>
          <td>33-35</td>
          <td>36-37</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age' | translate }}</th>
          <td colspan="3">0-12 m</td>
          <td>1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="2">14-18</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.socks.for.men' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.socks.size' | translate }}</th>
          <td>23</td>
          <td>25</td>
          <td>27</td>
          <td>29</td>
          <td>31</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }} (EU)</th>
          <td>38-39</td>
          <td>40-41</td>
          <td>42-43</td>
          <td>44-45</td>
          <td>46-47</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.socks.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <tbody>
        <tr>
          <th>{{ 'sizechart.socks.size' | translate }}</th>
          <td>23</td>
          <td>25</td>
          <td>27</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.shoe.size' | translate }} (EU)</th>
          <td>36-37</td>
          <td>38-39</td>
          <td>40-41</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.stockings.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>cm x kg</th>
          <th>45</th>
          <th>50</th>
          <th>55</th>
          <th>60</th>
          <th>65</th>
          <th>70</th>
          <th>75</th>
          <th>80</th>
          <th>85</th>
          <th>90</th>
          <th>95</th>
          <th>100</th>
          <th>{{ 'sizechart.height' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>145/150</th>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <th>4' 10</th>
        <tr>
          <th>150/155</th>
          <td class="gray"></td>
          <td class="gray">1</td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <th>5' 0</th>
        <tr>
          <th>155/160</th>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray">2</td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <th>5' 2</th>
        <tr>
          <th>160/165</th>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray">3</td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td></td>
          <td></td>
          <td></td>
          <th>5' 4</th>
        <tr>
          <th>165/170</th>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray">4</td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td></td>
          <td></td>
          <th>5' 6</th>
        <tr>
          <th>170/175</th>
          <td></td>
          <td></td>
          <td></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray">5</td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <th>5' 8</th>
        <tr>
          <th>175/180</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray">6</td>
          <th>5' 10</th>
        <tr>
          <th>180/185</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <th>5' 12</th>
        </tbody>
        <tfoot>
          <tr>
            <th>{{ 'sizechart.weight' | translate }}</th>
            <th>100</th>
            <th>110</th>
            <th>120</th>
            <th>130</th>
            <th>140</th>
            <th>150</th>
            <th>160</th>
            <th>170</th>
            <th>180</th>
            <th>190</th>
            <th>200</th>
            <th>220</th>
            <th>lbls x in</th>
          </tr>
        </tfoot>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.sweatshirts.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.swimwear.for.boys' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="9">{{ 'sizechart.size.chart' | translate }}</th>
        </tr>
        </thead>
        <tbody> {{ 'sizechart.information.required' | translate }}</tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.swimwear.for.girls' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="9">{{ 'sizechart.size.chart' | translate }}</th>
        </tr>
        </thead>
        <tbody> {{ 'sizechart.information.required' | translate }}</tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.swimwear.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="9">{{ 'sizechart.size.chart' | translate }}</th>
        </tr>
        </thead>
        <tbody> {{ 'sizechart.information.required' | translate }}</tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.thermal.underwear.for.men' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
          <td>5XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
          <td>52</td>
          <td>54</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
          <td>116</td>
          <td>120</td>
        </tr>
        <tr>
          <th>Waist circumference</th>
          <td>78</td>
          <td>82</td>
          <td>86</td>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
        </tr>
        <tr>
          <th rowspan="2">{{ 'sizechart.height' | translate }}</th>
          <td colspan="9">170/ 176</td>
        </tr>
        <tr>
          <td colspan="9">182/ 188</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.thermal.tights.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="9">{{ 'sizechart.size.chart' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th style="width:20%">{{ 'sizechart.height' | translate }}</th>
          <td>62-74</td>
          <td>80-86</td>
          <td>92-98</td>
          <td>104-110</td>
          <td>116-122</td>
          <td>128-134</td>
          <td>140-146</td>
          <td>150-152</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>48</td>
          <td>48-52</td>
          <td>58</td>
          <td>60-62</td>
          <td>64-66</td>
          <td>68-72</td>
          <td>76-80</td>
          <td>84</td>
        </tr>
        <tr>
          <th>Foot length</th>
          <td>12</td>
          <td>14</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>22</td>
          <td>22</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.age.years' | translate }}</th>
          <td>0-1</td>
          <td>1-3</td>
          <td>1-3</td>
          <td colspan="2">3-7</td>
          <td>7-14</td>
          <td colspan="2">14-18</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>44-48</td>
          <td>48-52</td>
          <td>52-56</td>
          <td>56</td>
          <td>56-60</td>
          <td>60-64</td>
          <td>68-76</td>
          <td>72-80</td>
        </tr>
        </tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.tights.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>cm x kg</th>
          <th>45</th>
          <th>50</th>
          <th>55</th>
          <th>60</th>
          <th>65</th>
          <th>70</th>
          <th>75</th>
          <th>80</th>
          <th>85</th>
          <th>90</th>
          <th>95</th>
          <th>100</th>
          <th>{{ 'sizechart.height' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>145/150</th>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <th>4' 10</th>
        <tr>
          <th>150/155</th>
          <td class="gray"></td>
          <td class="gray">1</td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <th>5' 0</th>
        <tr>
          <th>155/160</th>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray">2</td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <th>5' 2</th>
        <tr>
          <th>160/165</th>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray">3</td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td></td>
          <td></td>
          <td></td>
          <th>5' 4</th>
        <tr>
          <th>165/170</th>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray">4</td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td></td>
          <td></td>
          <th>5' 6</th>
        <tr>
          <th>170/175</th>
          <td></td>
          <td></td>
          <td></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray">5</td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <th>5' 8</th>
        <tr>
          <th>175/180</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="gray"></td>
          <td class="lightgray"></td>
          <td class="lightgray">6</td>
          <th>5' 10</th>
        <tr>
          <th>180/185</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <td class="lightgray"></td>
          <th>5' 12</th>
        </tbody>
        <tfoot>
          <tr>
            <th>{{ 'sizechart.weight' | translate }}</th>
            <th>100</th>
            <th>110</th>
            <th>120</th>
            <th>130</th>
            <th>140</th>
            <th>150</th>
            <th>160</th>
            <th>170</th>
            <th>180</th>
            <th>190</th>
            <th>200</th>
            <th>220</th>
            <th>lbls x in</th>
          </tr>
        </tfoot>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.tops.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.trousers.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.tunics.for.kids' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered smalldigits">
        <thead>
        <tr>
          <th colspan="9">{{ 'sizechart.size.chart' | translate }}</th>
        </tr>
        </thead>
        <tbody>{{ 'sizechart.information.required' | translate }}</tbody>
       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.tunics.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.turtlenecks.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">158 / 164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'sizechart.vest.for.women' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
   <div class="table-responsive">
<table class="table table-bordered">
        <thead>
        <tr>
          <th>{{ 'sizechart.international.size' | translate }}</th>
          <td>ХS</td>
          <td>S</td>
          <td>M</td>
          <td>L</td>
          <td>XL</td>
          <td>XXL</td>
          <td>3XL</td>
          <td>4XL</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.european.size' | translate }}</th>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
          <td>50</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.chest.circumference' | translate }}</th>
          <td>84</td>
          <td>88</td>
          <td>92</td>
          <td>96</td>
          <td>100</td>
          <td>104</td>
          <td>108</td>
          <td>112</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.hip.circumference' | translate }}</th>
          <td>90</td>
          <td>94</td>
          <td>98</td>
          <td>102</td>
          <td>106</td>
          <td>110</td>
          <td>114</td>
          <td>118</td>
        </tr>
        <tr>
          <th>{{ 'sizechart.height' | translate }}</th>
          <td colspan="8">164 / 170</td>
        </tr>
        </thead>

       </table>
    </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelDeliveryModal()">Bezár</button>
</div>
