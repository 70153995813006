import { Action } from '@ngrx/store';
import { ProductKey } from '../../model/ProductKey';
import { Review } from '../../model/Review';

export enum ProductActionTypes {
  LoadProducts = 'Load products',
  AddProducts = 'Add products',
  AddReview = 'Add review'
}

export class LoadProducts implements Action {
  readonly type = ProductActionTypes.LoadProducts;

  constructor(public payload: ProductKey) {}
}

export class AddProduct implements Action {
  readonly type = ProductActionTypes.AddProducts;

  constructor(public payload: ProductKey) {}
}

export class AddReview implements Action {
  readonly type = ProductActionTypes.AddReview;

  constructor(public payload: Review) {}
}

export type ProductActions =
  LoadProducts |
  AddProduct |
  AddReview;
