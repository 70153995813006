<div class="container mt-5 mb-5">
<h1>Adatkezelési Tájékoztató GDPR</h1>

<p><strong>1. Az Adatkezelési Tájékoztató célja, hatálya, irányadó jogszabályok</strong></p>

<p>A jelen Tájékoztató célja, hogy rögzítse a Conte Magyarország Kft. által alkalmazott adatvédelmi és -kezelési elveket, és a társaság adatvédelmi és -kezelési politikáját, amelyet a társaság, mint adatkezelő magára nézve kötelező erővel ismer el.</p>

<p>A Tájékoztató rendelkezéseinek kialakításakor a társaság különös tekintettel vette figyelembe az Európai Parlament és a Tanács 2016/679 Rendeletében („Általános Adatvédelmi Rendelet” vagy „GDPR”), az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény („Infotv.”), a Polgári Törvénykönyvről szóló 2013. évi V. törvény („Ptk.”), továbbá a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény („Grtv.”) rendelkezéseit.</p>

<p>Jelen Adatkezelési Tájékoztató hatálya a www.conte.hu címen elérhető weboldalhoz (a továbbiakban: „Weboldal”) kapcsolódó adatkezelésekre terjed ki.</p>

<p>Ellenkező tájékoztatás hiányában a Tájékoztató hatálya nem terjed ki azon szolgáltatásokra és adatkezelésekre, melyek a Weboldalakon hirdető, vagy azon más módon megjelenő harmadik személyek promócióihoz, nyereményjátékaihoz, szolgáltatásaihoz, egyéb kampányaihoz, az általuk közzétett tartalomhoz kapcsolódnak. Ellenkező tájékoztatás hiányában a Tájékoztató hatálya nem terjed ki olyan weboldalak, szolgáltatók szolgáltatásaira és adatkezeléseire, melyekre a Weboldalakon található hivatkozás vezet. A Tájékoztató hatálya nem terjed ki azon személyek (szervezetek, cégek) adatkezeléseire, amelyek tájékoztatásából, hírleveléből, reklámleveléből a Felhasználó a Weboldalról értesült.</p>

<p><strong>2. Fogalom meghatározások</strong></p>

<p><strong>Adatkezelés</strong>: az alkalmazott eljárástól függetlenül a Személyes adatokon végzett bármely művelet vagy a műveletek összessége, így különösen a Személyes adatok gyűjtése, rögzítése, rendszerezése, tagolása, tárolása, átalakítása, megváltoztatása, felhasználása, lekérdezése, betekintése, közlése, továbbítása, terjesztése vagy egyéb módon hozzáférhetővé tétele, nyilvánosságra hozatala, összehangolása vagy összekapcsolása (ideértve a profilalkotást is), korlátozása,&nbsp;törlése és megsemmisítése.</p>

<p><strong>Adatkezelő</strong>: az a 3. pontban meghatározott személy, aki az Adatkezelés céljait és eszközeit – önállóan vagy másokkal együtt – meghatározza.</p>

<p>Személyes adat vagy adat: bármilyen adat vagy információ, amely alapján egy természetes személy Felhasználó – közvetett vagy közvetlen módon – azonosíthatóvá válik.</p>

<p><strong>Adatfeldolgozó</strong>: az a szolgáltató, aki az Adatkezelő nevében személyes adatokat kezel.</p>

<p><strong>Felhasználó</strong>: az a természetes személy, aki a Weboldalon regisztrál, és ennek keretében megadja az alábbi 8. és 9. pontokban felsorolt adatát.</p>

<p><strong>Külső szolgáltató</strong>: az Adatkezelő vagy a Weboldal üzemeltetője által, az egyes szolgáltatások biztosításához kapcsolódóan – akár közvetlenül, akár közvetetten – igénybe vett harmadik fél szolgáltató partnerek, amelyek számára a szolgáltatásaik biztosítása érdekében Személyes adatok továbbításra kerülnek vagy kerülhetnek, illetve akik az Adatkezelő részére Személyes adatokat továbbíthatnak. Külső szolgáltatónak minősülnek továbbá azon szolgáltatók is, amelyek nem állnak sem az Adatkezelővel sem a szolgáltatások üzemeltetőivel együttműködésben, azonban az által, hogy hozzáférnek a Weboldalhoz, a Felhasználókról adatokat gyűjtenek, amelyek akár önállóan, akár más adatokkal összekapcsolva alkalmasak lehetnek a Felhasználó azonosítására. Továbbá a tárhelyszolgáltatás biztosítása során az Adatkezelő Külső szolgáltatónak tekinti a Felhasználót is az általa használt tárhelyen folytatott adatkezelési tevékenység szempontjából.</p>

<p><strong>Tájékoztató</strong>: az Adatkezelő jelen adatkezelési tájékoztatója.</p>

<p><strong>3. Az adatkezelő személye és tevékenysége</strong></p>

<p>Név:&nbsp;Conte Magyarország&nbsp;Kft. (Conte Elegant Magyarországi Márkaképviselet)<br>
  Székhely: 2030 Érd, Földmunkás utca 82.<br>
  Cégjegyzékszám: 01-09-167835<br>
  A bejegyző bíróság megnevezése: Fővárosi Bíróság Cégbírósága<br>
  Adószám: 14564075-2-13</p>

<p>Telefon: +36-30-44-800-63<br>
  E-mail: info@conte.hu</p>

<p>Adatvédelmi felelős: Némethi F. Beatrix</p>

<p>Adatvédelmi felelős beosztása: ügyvezető</p>

<p>Az Adatkezelő Magyarországon bejegyzett gazdasági társaság.&nbsp;</p>

<p>Az Adatkezelő üzemelteti a Weboldalt, amely harisnyák, zoknik, leggingsek megismerését és adásvételét segíti elő. A Weboldal elsősorban webáruház.</p>

<p>A Weboldalon a Conte Magyarország&nbsp;Kft. ismerteti termékeit és vásárlásra kínálja fel a webáruházban meghirdetett termékeket.</p>

<p><strong>4. Az adatkezelés elvei, módja, az irányadó jogszabályok</strong></p>

<p>4.1. Az Adatkezelő a jóhiszeműség, a tisztesség és az átláthatóság követelményeinek megfelelően, a Felhasználókkal együttműködve jár el az adatkezelés során. Az Adatkezelő csak a törvényben meghatározott, vagy a Felhasználók által megadott adatokat kezeli, az alábbiakban meghatározott célokból.&nbsp; A kezelt Személyes adatok köre arányban áll az adatkezelés céljával, azon nem terjeszkedhet túl.</p>

<p>4.2. Minden olyan esetben, ha a Személyes adatokat az Adatkezelő az eredeti adatfelvétel céljától eltérő célra kívánja felhasználni, erről a Felhasználót tájékoztatja, és ehhez előzetes, kifejezett hozzájárulását megszerzi, illetőleg lehetőséget biztosít számára, hogy a felhasználást megtiltsa.</p>

<p>4.3. Adatkezelő a neki megadott Személyes adatokat nem ellenőrzi. A megadott Személyes adatok megfelelőségéért kizárólag az azt megadó személy felel.</p>

<p>4.4. A 16. életévét be nem töltött személy érintett Személyes adatai csak a felette szülői felügyeletet gyakorló nagykorú személy hozzájárulása esetén kezelhetők. Az Adatkezelőnek nem áll módjában a hozzájáruló személy jogosultságát, illetve nyilatkozatának tartalmát ellenőrizni, így a Felhasználó illetve a felette szülői felügyeletet gyakorló személy szavatol azért, hogy a hozzájárulás megfelel a jogszabályoknak. Hozzájáruló nyilatkozat hiányában az Adatkezelő 16. életévét be nem töltött érintettre vonatkozó Személyes adatot nem gyűjt.</p>

<p>4.5. Az Adatkezelő az általa kezelt Személyes adatokat a jelen Tájékoztatóban meghatározott Adatfeldolgozókon, valamint egyes – a jelen Tájékoztatóban hivatkozott – esetekben a Külső szolgáltatókon kívül harmadik félnek át nem adja.</p>

<p>A jelen pontban foglalt rendelkezés alól kivételt képez az adatok statisztikailag összesített formában történő felhasználása, mely az érintett Felhasználó beazonosítására alkalmas egyéb adatot semmilyen formában nem tartalmazhatja, ez által nem minősül Adatkezelésnek, sem adattovábbításnak. &nbsp;</p>

<p>Az Adatkezelő bizonyos esetekben – hivatalos bírósági, rendőrségi megkeresés, jogi eljárás szerzői-, vagyoni- illetve egyéb jogsértés vagy ezek alapos gyanúja miatt az Adatkezelő érdekeinek sérelme, a szolgáltatás biztosításának veszélyeztetése stb. – harmadik személyek számára hozzáférhetővé teszi az érintett Felhasználó elérhető Személyes adatait.</p>

<p>Az Adatkezelő jelen Adatkezelési tájékoztatóban felsorolt Adatfeldolgozói, illetve a Külső szolgáltatók 2018. május 25. napját követően a részükre az Adatkezelő által továbbított és általuk kezelt vagy feldolgozott Személyes adatokat a GDPR által előírt rendelkezésekkel összhangban rögzítik, kezelik, ill. dolgozzák fel és erről nyilatkozatot tesznek az Adatkezelő részére.</p>

<p>4.6. Az Adatkezelő az általa kezelt Személyes adat helyesbítéséről, korlátozásáról, ill. törléséről az érintett Felhasználót, továbbá mindazokat értesíti, akiknek korábban a Személyes adatot Adatkezelés céljára továbbította. Az értesítés mellőzhető, ha ez az Adatkezelés céljára való tekintettel a Felhasználó jogos érdekét nem sérti.</p>

<p>4.7. Tekintettel a GDPR vonatkozó rendelkezéseire az Adatkezelő nem köteles adatvédelmi tisztviselő kijelölésére, az Adatkezelő ugyanis nem minősül közhatalmi szervnek vagy közfeladatot ellátó szervnek, az Adatkezelő tevékenységei nem foglalnak magukban olyan műveletet, amely a Felhasználók rendszeres és szisztematikus, nagymértékű megfigyelését teszik szükségessé, továbbá az Adatkezelő nem kezel különleges adatot, illetve büntetőjogi felelősség megállapítására vonatkozó határozatokra és bűncselekményre vonatkozó személyes adatot.</p>

<p>4.8. Az Adatkezelő a személyes adatokat az irányadó jogszabályoknak megfelelően kezeli. Az adatkezelésre irányadó jogszabályok különösen:&nbsp;</p>

<p>az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (továbbiakban: „Infotv.”);</p>

<ul>
  <li>a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény (továbbiakban: „Grtv.”).</li>
  <li>az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény;</li>
  <li>a számvitelről szóló 2000. évi C. törvény 169. § (a bizonylatok megőrzését illetően).</li>
</ul>

<p><strong>5. Az adatkezelés jogalapja</strong></p>

<p>5.1. Figyelemmel az Adatkezelő tevékenységének jellegére, az adatkezelés jogalapja a Felhasználók önkéntes, megfelelő tájékoztatáson alapuló kifejezett hozzájárulása (Infotv. 5. § (1) bek. a) pont), továbbá profilalkotás esetén a Felhasználó megfelelő, a GDPR rendelkezéseinek megfelelő tájékoztatása, továbbá a GDPR 6. cikk (1) bekezdés f) pontja. A Felhasználó önként lépnek kapcsolatba az Adatkezelővel, önként regisztrálnak, önként veszi igénybe az Adatkezelő szolgáltatását. Az adatkezelő a Felhasználók hozzájárulásának a hiányában csak akkor kezel adatot, ha erre törvény egyértelműen felhatalmazza.</p>

<p>5.2. A Felhasználó jogosult arra, hogy hozzájárulását bármikor visszavonja. A hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a visszavonás előtti adatkezelés jogszerűségét.</p>

<p>5.3. Az Adatkezelő a Felhasználó által az egyes honlapokra történő belépéskor a Felhasználó IP címét a szolgáltatás nyújtásához kapcsolódóan, az Adatkezelő jogos érdekére tekintettel és a szolgáltatás jogszerű biztosítása okán (pl. jogellenes felhasználás ill. jogellenes tartalmak kiszűrése érdekében), a Felhasználó külön hozzájárulása nélkül is rögzíti.</p>

<p>5.4. A jelen Tájékoztató elfogadásával és a Személyes adatok kezeléséhez történő hozzájárulás megadásával a Felhasználó tudomásul veszi, hogy &nbsp;a Weboldal használata során esetlegesen felmerülő hibák kijavítása érdekében az Adatkezelő jogosult belépni a Felhasználó profiljába, amelynek során az Adatkezelő a Felhasználót megillető valamennyi jogosultsággal rendelkezik. &nbsp;Az Adatkezelő a hiba felmérése és elhárítása során a Személyes adatokat kizárólag a célhoz feltétlenül szükséges mértékben használja fel.</p>

<p>5.5. Jelen Tájékoztatóban meghatározott Adatfeldolgozók részére történő Adattovábbítás a Felhasználó külön hozzájárulása nélkül végezhető. Személyes adatok harmadik személynek vagy hatóságok számára történő kiadása – hacsak jogszabály ettől eltérően nem rendelkezik – kizárólag jogerős hatósági határozat alapján, vagy a Felhasználó előzetes, kifejezett hozzájárulása esetén lehetséges.</p>

<p>5.6. Bármely Felhasználó e-mail címének valamint a regisztráció során megadott adatainak (pl. felhasználó név, azonosító, jelszó stb.) megadásakor egyben felelősséget vállal azért, hogy a megadott e-mail címről ill. az általa megadott adatok felhasználásával kizárólag ő vesz igénybe szolgáltatást. E felelősségvállalásra tekintettel egy megadott e-mail címen és/vagy adatokkal történt belépésekkel összefüggő mindennemű felelősség kizárólag azt a Felhasználót terheli, aki az e-mail címet regisztrálta és az adatokat megadta.</p>

<p>5.7. Az adatkezelés jogalapja bizonyos esetekben jogszabály rendelkezése. A főbb, adatkezelést is előíró jogszabályok a 4.8. pontban meghatározott jogszabályok. Amennyiben a Felhasználó az Adatkezelő részére díjat fizet, a bizonylaton szereplő adatokat az Adatkezelő a számvitelről szóló törvényben meghatározottak szerint kezeli.</p>

<p>5.8. Az adatkezelés jogalapja lehet az Adatkezelő lényeges jogos érdeke, amely esetben a GDPR vonatkozó rendelkezéseivel összhangban az Adatkezelő elvégezte és a jövőben is elvégezheti az érdekmérlegelési tesztet, amely alátámasztja, hogy az adott adatkezelés az Adatkezelő jogos érdekeinek érvényesítéséhez szükséges, és ezen érdekekkel szemben nem élveznek elsőbbséget az érintett olyan jogai és szabadságai, amelyek személyes adatok védelmét teszik szükségessé. Az Adatkezelő erre irányuló kérés esetén a jelen Tájékoztatóban írtak szerint tájékoztatást nyújt az érintett részére a jelen bekezdésben foglaltakkal kapcsolatban.</p>

<p><strong>6. Az adatkezelés célja</strong></p>

<p>Az Adatkezelő személyes adatot kizárólag meghatározott célból, jog gyakorlása és kötelezettség teljesítése érdekében kezel. Az adatkezelés minden szakaszában megfelel az adatkezelés céljának. Az adatok felvétele és kezelése tisztességesen és törvényesen történik. Az Adatkezelő törekszik arra, hogy csak olyan személyes adat kezelésére kerüljön sor, amely az adatkezelés céljának megvalósulásához elengedhetetlen, a cél elérésére alkalmas. A személyes adat csak a cél megvalósulásához szükséges mértékben és ideig kezelhető.&nbsp;</p>

<p>Az adatkezelés célja elsősorban a Weboldal üzemeltetése, és az Adatkezelő szolgáltatásainak nyújtása.</p>

<p>Az adatkezelés célja a fentiek alapján:&nbsp;</p>

<ul>
  <li>A Felhasználó azonosítása, a Felhasználóval való kapcsolattartás;</li>
  <li>Megvásárolt termék postázása a vásárló részére;</li>
  <li>Vásárló nevére számla kiállítása;</li>
  <li>Az Adatkezelőt terhelő kötelezettségek teljesítése, az Adatkezelőt megillető jogok gyakorlása;</li>
  <li>a Felhasználók jogainak védelme.</li>
</ul>

<p><strong>7. Az adatok forrása</strong></p>

<p>Az Adatkezelő kizárólag a Felhasználók által megadott Személyes adatokat kezeli, adatokat más forrásból nem gyűjt.</p>

<p>Az adatok megadása a Felhasználó regisztrációja során történik. A Felhasználó a regisztráció során megadja a nevét, e-mail címét, jelszavát.&nbsp;</p>

<p>Amennyiben a Felhasználó az Adatkezelő által szervezetett promóción regisztrál, és megadja adatait, az adott promóció tájékoztatójában foglaltak szerint hozzájárul személyes adatai kezeléséhez. Ebben az esetben az Adatkezelő kizárólag a promóció során megadott adatokat kezeli.</p>

<p><strong>8. A kezelt adatok köre</strong></p>

<p>Az Adatkezelő kizárólag a Felhasználók által megadott személyes adatokat kezeli. A kezelt adatok az alábbiak:&nbsp;</p>

<p>Az Adatkezelő által kezelt adatok az alábbi csoportokba sorolhatók az adatkezelés célja alapján:</p>

<ul>
  <li><strong>Regisztrációhoz szükséges adatok</strong>. A regisztráció során a Felhasználó megadja e-mail címét, amely a felhasználónév lesz, a Felhasználó szabadon választ jelszót. Az adatkezelés jogalapja a Felhasználó hozzájárulása, az adatkezelés elsődleges célja a regisztráció, a Weboldal szolgáltatásainak nyújtása.</li>
  <li><strong>Számlázási adatok.</strong>&nbsp;Amennyiben a Felhasználó díjfizetéshez kötött szolgáltatást vesz igénybe, az Adatkezelő kezeli a fizetéshez és a számlázáshoz kapcsolódó adatokat (fizetés módja, fizetéshez használt eszköz adatai, számlázás esetében a vásárló neve, címe, adószáma). Az adatkezelés jogalapja részben a Felhasználó hozzájárulása, részben az adózásra, számvitelre vonatkozó jogszabályok. Az adatkezelés célja a számlázás, díjak beszedése.</li>
  <li><strong>Hitelesítés során megadott adatok, iratok</strong>. A Felhasználóknak lehetőségük van arra, hogy hitelesítsék magukat, az alábbi 11. pontban írtak szerint. Az iratok kezelése a 11. pontban írtak szerint történik. Az adatkezelés célja a a vásárló személy azonosítása a termékek megküldése céljából.</li>
</ul>

<p>Amennyiben a Felhasználó regisztrál, és megadja adatait, lehetővé válik számára az, hogy hirdetést tegyen közzé. A fent írtak szerint a felhasználó maga határozza meg, hogy a hirdetésben milyen adatokat, képeket tesz közzé. A hirdetés iránt érdeklődők a felhasználóval a megadott adatok útján (e-mail, telefonszám) léphetnek kapcsolatba. Ebben az esetben a kapcsolatfelvétel akkor lehetséges, ha mindkét fél regisztrált felhasználó, vagy valamely fél megadja a kapcsolatfelvételhez szükséges adatait (pl. telefonszám, e-mail cím). A Felhasználó bármikor módosíthatja a megadott adatokat, törölheti az adatokat, és dönthet a teljes regisztrációja törléséről.</p>

<p>A fentieken túl az Adatkezelő kezeli a technikai adatokat, ezen belül az IP címet, a 14. pontban leírtak szerint.</p>

<p><strong>9. Az adatkezelési folyamat leírása</strong></p>

<p>Az adatok forrása a Felhasználó, aki az adatokat a regisztráció során, vagy később, a Weboldalra belépve adja meg. A regisztrációs űrlapon szereplő adatok megadása kötelező, kivéve, ha ennek ellenkezője kifejezetten fel van tüntetve.</p>

<p>A Felhasználó az adatokat önállóan adja meg, az Adatkezelő erre vonatkozóan semmilyen kötelező iránymutatást nem ad, tartalmi elvárásokat nem támaszt. A Felhasználó az általa megadott adatok kezeléséhez kifejezetten hozzájárul. A Felhasználó az Adatkezelő által kért adatokon kívül jogosult más adatok megadására a profiljában, az adatok kezelésének jogalapja ebben az esetben is a Felhasználó önkéntes hozzájárulása.</p>

<p>Amennyiben a Felhasználó regisztrál az Adatkezelő által szervezett promóción (pl. a facebook-on), és megadja az ott kért adatait, elfogadja az adott promócióhoz kapcsolódó adatkezelési tájékoztatót. Ebben az esetben az adatok megadásával a Felhasználó nem regisztrál a Weboldalon, de hozzájárul a megadott adatainak a promóció tájékoztatójában meghatározottak szerint történő kezeléséhez.</p>

<p><strong>10. Technikai adatok és cookie-k kezelése</strong></p>

<p>Az Adatkezelő rendszere automatikusan rögzíti a felhasználó számítógépének IP-címét, a látogatás kezdő időpontját, illetve egyes esetekben – a számítógép beállításától függően – a böngésző és az operációs rendszer típusát. Az így rögzített adatok egyéb személyes adatokkal nem kapcsolhatók össze. Az adatok kezelése kizárólag statisztikai célokat szolgál.&nbsp;</p>

<p>A cookie-k lehetővé teszik a Weboldal számára, hogy felismerje a korábbi látogatókat. A cookie-k az Adatkezelőt, mint a Weboldal üzemeltetőjét segítik a Weboldal optimalizálásában, abban, hogy a Weboldal szolgáltatásait a felhasználók szokásának megfelelően alakítsa ki. A cookie-k alkalmasak továbbá arra, hogy&nbsp;</p>

<ul>
  <li>megjegyezzék a beállításokat, így nem kell azokat a felhasználónak újra rögzítenie, ha egy új oldalra lép,</li>
  <li>emlékeznek a korábban bevitt adatokra, ezért azokat nem kell újra begépelni,</li>
  <li>elemzik a honlap használatát annak érdekében, hogy az így nyert információk felhasználásával végrehajtott fejlesztések eredményeként az a lehető legnagyobb mértékben a felhasználó elvárásai szerint működjön, a felhasználó könnyen megtalálja a keresett információt, és</li>
  <li>figyelemmel kísérik hirdetéseink hatékonyságát.</li>
</ul>

<p>Amennyiben a Weboldalon az Adatkezelő külső webes szolgáltatások segítségével jelenít meg különféle tartalmakat, az néhány olyan cookie tárolását eredményezheti, melyeket nem az Adatkezelő felügyel, így nincs befolyása arra, hogy ezek a weboldalak, illetve külső domainek milyen adatokat gyűjtenek. Ezen cookie-król az adott szolgáltatásra vonatkozó szabályzatok adnak tájékoztatást.&nbsp;</p>

<p>Az Adatkezelő a cookie-kat felhasználja arra, hogy a Felhasználók felé a Google és a Facebook útján reklámokat jelenítsen meg. Az adatkezelés emberi beavatkozás nélkül történik.&nbsp;</p>

<p>A felhasználó beállíthatja webes keresőjét úgy, hogy elfogadjon minden cookiet, elutasítsa mindet, vagy értesítse a felhasználót, ha cookie érkezik a gépére. A beállítási lehetőségek általában a böngésző „Opciók” vagy „Beállítások” menüpontjában találhatók. A cookie-k alkalmazásának tiltásával a Felhasználó tudomásul veszi, hogy cookie nélkül a Weboldal működése nem teljes értékű.</p>

<p>Az angol nyelvű&nbsp;www.aboutcookies.org&nbsp;weboldalon található részletes tájékoztató szintén segít a különböző böngészőkben történő beállításokban.</p>

<p><strong>11. Adattovábbítás</strong></p>

<p>Az Adatkezelő személyes adatot csak akkor továbbít harmadik személy részére, ha ahhoz a Felhasználó egyértelműen – a továbbított adatkör és az adattovábbítás címzettje ismeretében – hozzájárult, vagy az adattovábbításra törvény felhatalmazást ad.&nbsp;</p>

<p>Az Adatkezelő jogosult és köteles minden olyan rendelkezésére álló és általa szabályszerűen tárolt Személyes adatot az illetékes hatóságoknak továbbítani, amely Személyes adat továbbítására őt jogszabály vagy jogerős hatósági kötelezés kötelezi. Ilyen Adattovábbítás, valamint az ebből származó következmények miatt az Adatkezelő nem tehető felelőssé</p>

<p>Az Adatkezelő az adattovábbításokat minden esetben dokumentálja, és az adattovábbításokról nyilvántartást vezet.</p>

<p><strong>12. Adatfeldolgozás</strong></p>

<p>Az Adatkezelő a tevékenysége ellátásához jogosult adatfeldolgozót igénybe venni. Az adatfeldolgozók önálló döntést nem hoznak, kizárólag az Adatkezelővel kötött szerződés, és a kapott utasítások szerint jogosultak eljárni. Az Adatkezelő ellenőrzi az adatfeldolgozók munkáját. Az adatfeldolgozók további adatfeldolgozó igénybe vételére csak az Adatkezelő hozzájárulásával jogosultak.&nbsp;</p>

<p>Az Adatkezelő az igénybe vett adatfeldolgozókat a jelen Tájékoztatóban megjelöli.&nbsp;</p>

<p>Az Adatkezelő által igénybe vett adatfeldolgozók:&nbsp;</p>

<ul>
  <li>A Conte.hu webáruház a Rootor Hungary Kft.-nél van futtatva; cím: 1132 Budapest, Victor Hugo u. 18-32.; www.rootor.hu;(tárhely szolgáltatás)</li>
</ul>

<ul>
  <li>Google, Mountain View, Kalifornia, Egyesült Államok (céges e-mail szolgáltatás, Google Drive adattárolás, Google Analytics aggregált adatelemzés, Google Adwords online hirdetés, Google Tagmanager mérőkód elhelyezés, Google+ és Youtube social felületek használata, kérdőív kiküldés, Hangouts chat alkalmazás, stb.)</li>
</ul>

<p><strong>13. Külső szolgáltatók</strong></p>

<p>A Weboldal üzemeltetése során az Adatkezelő Külső szolgáltatókat vesz igénybe, amely Külső szolgáltatókkal az Adatkezelő együttműködik.</p>

<p>A Külső szolgáltatók rendszereiben kezelt Személyes adatok tekintetében a Külső szolgáltatók saját adatvédelmi szabályzatában foglaltak az irányadók. Az Adatkezelő minden tőle telhetőt megtesz annak érdekében, hogy a Külső szolgáltató a részére továbbított Személyes adatokat a jogszabályoknak megfelelőn kezelje, és azokat kizárólag a Felhasználó által meghatározott vagy a jelen Tájékoztatóban alább rögzített célra használja fel.</p>

<p>Az Adatkezelő a Külső szolgáltatók számára végzett adattovábbításról a jelen Tájékoztató keretében tájékoztatja a Felhasználókat.</p>

<p>Külső szolgáltatók:</p>

<ul>
  <li>Google, Mountain View, Kalifornia, Egyesült Államok (Céges e-mail szolgáltatás, Google Drive adattárolás, Google Analytics aggregált adatelemzés, Google Adwords online hirdetés, Google Tagmanager mérőkód elhelyezés, Google+ és Youtube social felületek használata, kérdőív kiküldés, Hangouts chat alkalmazás, stb.)</li>
  <li>Google Ireland Limited; Ireland, Dublin, Barrow Street 4, Gordon House (Google Adwords online hirdetés)</li>
  <li>Facebook Limited., 4 Grand Canal Square, Dublin Ireland (marketing, közösségi kommunikáció, remarketing hirdetések, Instagram, Facebook)</li>
  <li>IOVOX LTD, 99C Talbot Road, Notting Hill, London W11 2AT UK (átírányított hívás kezelés)</li>
</ul>

<p><strong>14. Adatbiztonság, az adatok megismerése</strong></p>

<p>Az Adatkezelő gondoskodik az adatok biztonságáról, megteszi azokat a technikai és szervezési intézkedéseket és kialakítja azokat az eljárási szabályokat, amelyek az irányadó jogszabályok, adat- és titokvédelmi szabályok érvényre juttatásához szükségesek. Az Adatkezelő az adatokat megfelelő intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.&nbsp;</p>

<p>Az Adatkezelő az általa kezelt adatokat az irányadó jogszabályoknak megfelelően tartja nyilván, biztosítva, hogy az adatokat csak azok a munkavállalók, és egyéb az Adatkezelő érdekkörében eljáró személyek (adatfeldolgozók) ismerhessék meg, akiknek erre munkakörük, feladatuk ellátása érdekében szükségük van. Az adatokat a munkavállaló szervezetén belül csak naplózás mellett lehet megismerni. Az adatkezelő munkavállalói egyedi kereséseket, az adatokon egyedi műveleteket csak a Felhasználó kérésére végeznek, vagy abban az esetben, ha ez a szolgáltatás nyújtása érdekében szükséges.&nbsp;</p>

<p>Az Adatkezelő az adatok biztonságát szolgáló intézkedések meghatározásakor és alkalmazásakor tekintettel van a technika mindenkori fejlettségére. Az Adatkezelő több lehetséges adatkezelési megoldás közül azt választja, amely a személyes adatok magasabb szintű védelmét biztosítja, kivéve, ha az aránytalan nehézséget jelentene.&nbsp;</p>

<p>Az Adatkezelő az informatikai védelemmel kapcsolatos feladatai körében gondoskodik különösen:&nbsp;<br>
  A jogosulatlan hozzáférés elleni védelmet biztosító intézkedésekről, ezen belül a szoftver és hardver eszközök védelméről, illetve a fizikai védelemről (hozzáférés védelem, hálózati védelem);</p>

<ul>
  <li>Az adatállományok helyreállításának lehetőségét biztosító intézkedésekről, ezen belül a rendszeres biztonsági mentésről és a másolatok elkülönített, biztonságos kezeléséről (tükrözés, biztonsági mentés);</li>
  <li>Az adatállományok vírusok elleni védelméről (vírusvédelem);</li>
  <li>Az adatállományok, illetve az azokat hordozó eszközök fizikai védelméről, ezen belül a tűzkár, vízkár, villámcsapás, egyéb elemi kár elleni védelemről, illetve az ilyen események következtében bekövetkező károsodások helyreállíthatóságáról (archiválás, tűzvédelem).</li>
</ul>

<p>A munkavállalók, és egyéb, az Adatkezelő érdekében eljáró személyek az általuk használt, vagy birtokukban lévő, személyes adatokat is tartalmazó adathordozókat, függetlenül az adatok rögzítésének módjától, kötelesek biztonságosan őrizni, és védeni a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés ellen.&nbsp;<br>
  <br>
  Az Adatkezelő az elektronikus nyilvántartást informatikai program útján üzemelteti, amely megfelel az adatbiztonság követelményeinek. A program biztosítja, hogy az adatokhoz csak célhoz kötötten, ellenőrzött körülmények között csak azon személyek férjenek hozzá, akiknek a feladataik ellátása érdekében erre szükségük van.</p>

<p><strong>15. Az adatkezelés időtartama</strong></p>

<p>Az Adatkezelő a személyes adatot törli, ha&nbsp;</p>

<p>a) kezelése jogellenes;</p>

<p>Amennyiben kiderül, hogy az adatok kezelése jogellenesen történik, az Adatkezelő a törlést haladéktalanul végrehajtja.&nbsp;</p>

<p>b) a Felhasználó kéri (a jogszabályon alapuló adatkezelések kivételével);&nbsp;</p>

<p>A Felhasználó önkéntes hozzájárulása alapján kezelt adatok törlését a Felhasználó kérheti. Ez esetben az Adatkezelő az adatokat törli. A törlés csak akkor tagadható meg, ha az adatok kezelésére jogszabály felhatalmazást ad. A törlési kérelem megtagadásáról, és az adatkezelést lehetővé tevő jogszabályról az Adatkezelő minden esetben tájékoztatást ad.&nbsp;</p>

<p>c) az adat hiányos vagy téves – és ez az állapot jogszerűen nem orvosolható –, feltéve, hogy a törlést törvény nem zárja ki;</p>

<p>d)&nbsp;az adatkezelés célja megszűnt, vagy az adatok tárolásának törvényben meghatározott határideje lejárt;&nbsp;</p>

<p>A törlés megtagadható (i) a véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása céljából, vagy (ii) ha a Személyes adatok kezelésére jogszabály felhatalmazást ad; valamint (iii) jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez.</p>

<p>A törlési kérelem megtagadásáról az Adatkezelő minden esetben tájékoztatja a Felhasználót, megjelölve a törlés megtagadásának indokát. Személyes adat törlésére irányuló igény teljesítését követően a korábbi (törölt) adatok már nem állíthatók helyre.</p>

<p>Az Adatkezelő által küldött hírlevelek az azokban található leiratkozás linken keresztül mondhatók le. Leiratkozás esetén az adatkezelő a hírlevél adatbázisában a Felhasználó Személyes adatait törli.</p>

<p>Mivel a Felhasználó részére az Adatkezelő folyamatos szolgáltatást nyújt, a felek kapcsolata időhatárhoz nem kötött. Mindezek alapján – a Felhasználó kérésének hiányában – az Adatkezelő mindaddig kezeli az adatokat, amíg az Adatkezelő és a Felhasználó közötti kapcsolat fennáll, és ameddig az adatkezelő a Felhasználó számára szolgáltatást nyújthat.&nbsp;</p>

<p>Minden egyéb adatot az Adatkezelő töröl, ha nyilvánvaló, hogy az adatok felhasználására a jövőben nem kerül sor, vagyis az adatkezelés célja megszűnt.&nbsp;</p>

<p>e)&nbsp;azt a bíróság vagy a Nemzeti Adatvédelmi és Információszabadság Hatóság elrendelte&nbsp;</p>

<p>Amennyiben bíróság vagy a Nemzeti Adatvédelmi és Információszabadság jogerősen elrendeli az adatok törlését, a törlést az Adatkezelő végrehajtja.&nbsp;</p>

<p>Törlés helyett az Adatkezelő – a Felhasználó tájékoztatása mellett – zárolja a személyes adatot, ha a Felhasználó ezt kéri, vagy ha a rendelkezésére álló információk alapján feltételezhető, hogy a törlés sértené a Felhasználó jogos érdekeit. Az így zárolt személyes adat kizárólag addig kezelhető, ameddig fennáll az az adatkezelési cél, amely a személyes adat törlését kizárta. Az Adatkezelő megjelöli az általa kezelt személyes adatot, ha a Felhasználó vitatja annak helyességét vagy pontosságát, de a vitatott személyes adat helytelensége vagy pontatlansága nem állapítható meg egyértelműen.&nbsp;</p>

<p>A jogszabály által elrendelt adatkezelések esetében az adatok törlésére a jogszabály rendelkezése az irányadó.&nbsp;</p>

<p>A törlés esetén az Adatkezelő az adatokat személyazonosításra alkalmatlanná teszi. Amennyiben jogszabály azt előírja, az Adatkezelő a személyes adatot tartalmazó adathordozót megsemmisíti.</p>

<p><strong>16. A Felhasználók jogai és érvényesítésük</strong></p>

<p>16.1. Az Adatkezelő a Felhasználót a kapcsolatfelvétellel egyidejűleg tájékoztatja az adatok kezeléséről. A Felhasználó emellett bármikor jogosult arra, hogy az adatkezelésről tájékoztatást kérjen.&nbsp;</p>

<p>A Felhasználó kérelmére az Adatkezelő tájékoztatást ad a Felhasználó általa kezelt, illetve az általa vagy rendelkezése szerint megbízott adatfeldolgozó által feldolgozott adatairól, azok forrásáról, az adatkezelés céljáról, jogalapjáról, időtartamáról, az adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő tevékenységéről, az adatvédelmi incidens körülményeiről, hatásairól és az elhárítására megtett intézkedésekről, továbbá – a Felhasználó személyes adatainak továbbítása esetén – az adattovábbítás jogalapjáról és címzettjéről. Az Adatkezelő köteles a kérelem benyújtásától számított legrövidebb idő alatt, legfeljebb azonban 25 napon belül, közérthető formában, a Felhasználó erre irányuló kérelmére írásban megadni a tájékoztatást. A tájékoztatás ingyenes, ha a tájékoztatást kérő a folyó évben azonos adatkörre vonatkozóan tájékoztatási kérelmet még nem nyújtott be. Egyéb esetekben költségtérítés állapítható meg. A már megfizetett költségtérítést vissza kell téríteni, ha az adatokat jogellenesen kezelték, vagy a tájékoztatás kérése helyesbítéshez vezetett.&nbsp;</p>

<p>16.2. A Felhasználó kérheti, hogy a tévesen szereplő személyes adatát az Adatkezelő helyesbítse. Abban az esetben, ha a helyesbítendő adatok alapján rendszeres adatszolgáltatás történik, az Adatkezelő szükség esetén a helyesbítésről tájékoztatja az adatszolgáltatás címzettjét, illetve a Felhasználó figyelmét felhívja arra, hogy a helyesbítést más adatkezelőnél is kezdeményeznie kell.&nbsp;</p>

<p>16.3. A Felhasználó a jogszabályban elrendelt adatkezelések kivételével kérheti a személyes adatai törlését. Az Adatkezelő a Felhasználót a törlésről tájékoztatja.&nbsp;</p>

<p>16.4. A Felhasználó az Infotv.-ben meghatározottak szerint tiltakozhat személyes adatai kezelése ellen.&nbsp;</p>

<p>16.5. A Felhasználó tájékoztatás, helyesbítés, törlés iránti kérelmét előterjesztheti írásban, az Adatkezelő székhelyére, telephelyére címzett levélben, vagy az Adatkezelőnek a <em>harisnyaoutlet@gmail.com</em> címre küldött e-mailben. </p>

<p>16.6. A Felhasználó kérheti, hogy Személyes adatainak kezelését az Adatkezelő korlátozza, ha a Felhasználó vitatja a kezelt Személyes adatok pontosságát. Ebben az esetben ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az Adatkezelő ellenőrizze a Személyes adatok pontosságát. Az Adatkezelő megjelöli az általa kezelt Személyes adatot, ha a Felhasználó vitatja annak helyességét vagy pontosságát, de a vitatott Személyes adat helytelensége vagy pontatlansága nem állapítható meg egyértelműen.</p>

<p>A Felhasználó kérheti, hogy Személyes adatainak kezelését az Adatkezelő korlátozza akkor is, ha az Adatkezelés jogellenes, de a Felhasználó ellenzi a kezelt Személyes adatok törlését, és ehelyett kéri azok felhasználásának korlátozását.</p>

<p>A Felhasználó továbbá akkor is kérheti, hogy Személyes adatainak kezelését az Adatkezelő korlátozza, ha az Adatkezelés célja megvalósult, de a Felhasználó igényli azok Adatkezelő általi kezelését jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez.</p>

<p>16.7. A Felhasználó kérheti, hogy az Adatkezelő a Felhasználó által rendelkezésére bocsátott és a Felhasználó által automatizált módon kezelt Személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban részére átadja és/vagy azokat egy másik adatkezelő részére továbbítsa.</p>

<p>16.8. Ha az adatkezelő a Felhasználó helyesbítés, zárolás vagy törlés iránti kérelmét nem teljesíti, a kérelem kézhezvételét követő 25 napon belül írásban közli a helyesbítés, zárolás vagy törlés iránti kérelem elutasításának indokait. A helyesbítés, törlés vagy zárolás iránti kérelem elutasítása esetén az adatkezelő tájékoztatja a Felhasználót a bírósági jogorvoslat, továbbá a Nemzeti Adatvédelmi és Információszabadság Hatósághoz fordulás lehetőségéről.&nbsp;</p>

<p>16.9. A Felhasználó a fenti, jogainak gyakorlásával kapcsolatos nyilatkozatokat megteheti az adatkezelő 2. pontban írt elérhetőségein.</p>

<p>16.10. A Felhasználó panaszával fordulhat közvetlenül a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.; telefon: +36-1-391-1400; e-mail: ugyfelszolgalat@naih.hu; honlap:&nbsp;www.naih.hu) is. A Felhasználó jogosult jogainak megsértése esetén az Infotv. 22. § (1) bekezdése alapján bírósághoz fordulni. A per elbírálása a törvényszék hatáskörébe tartozik. A per – a Felhasználó választása szerint – a Felhasználó lakóhelye vagy tartózkodási helye szerinti törvényszék előtt is megindítható. Az Adatkezelő kérésre a Felhasználó részletesen tájékoztatja a jogorvoslat lehetőségéről és eszközeiről.</p>

<p><strong>17. Az Adatkezelési Tájékoztató módosítása</strong></p>

<p>17.1. Az Adatkezelő fenntartja magának a jogot, hogy a jelen Tájékoztatót egyoldalú döntésével bármikor módosítsa. Az Adatkezelő a jelen Tájékoztató módosítása esetén rendszerüzenet küldése útján jogosult (de nem köteles) a Felhasználókat a módosításról tájékoztatni. Az értesítésben foglalt tájékoztatás alapján a Felhasználó a jelen Tájékoztatóban és a mindenkor hatályos jogszabályokban írt módon jogosult az adatkezeléssel kapcsolatos jogait gyakorolni.</p>

<p>17.2. A Felhasználó következő belépéssel elfogadja a Tájékoztató mindenkor hatályos rendelkezéseit, ezen túlmenően az egyes Felhasználók beleegyezésének kikérésére nincs szükség.</p>
</div>
