<div class="container-fluid product-list">
  <div class="row">
    <div class="col-lg-19">
      <div class="row">
        <div class="col-md-12 col-xxl-8 d-flex align-items-center justify-content-center justify-content-md-start order-1">
          <app-site-navigation></app-site-navigation>
        </div>

        <div class="col-xxl-8 d-flex align-items-end justify-content-center order-3 order-lg-3 order-xxl-2">
          <h1>{{'nav.' + title | translate}}</h1>
        </div>

        <div class="col-md-12 col-xxl-8 d-flex justify-content-center justify-content-md-end align-items-center btn-nav order-2 order-lg-2 order-xxl-3 pt-4 pt-xxl-0">
          <app-order [values]="orderings" [initialValue]="orderBy.link" [(ngModel)]="orderBy" (ngModelChange)="updateParams(filter, activeFilter, $event.link);"></app-order>
          <app-page-sizer *ngIf="pagination.totalItems > 12" [(ngModel)]="pagination.itemsPerPage" (ngModelChange)="updatePageSize($event)"></app-page-sizer>
        </div>
      </div>

      <div class="mt-4 pb-4 subcats-container d-flex justify-content-center flex-wrap">
        <app-sub-category-card *ngFor="let c of subCategories" [category]="c">
        </app-sub-category-card>
      </div>



      <hr>


      <div class="row mt-5">
        <app-product class="col-24 col-sm-12 col-md-8 col-lg-6 col-xl-8 col-xxl-6 mb-5 d-flex"
                     *ngFor="let p of products | paginate: pagination"
                     [product]="p"
                     [link]="'/' + mainCategory.link + '/' + subCategory.link"
        ></app-product>

        <div class="col-24" *ngIf="pagination.totalItems > 12">
          <pagination-controls class="pt-5 pb-5 text-center"
                               nextLabel=""
                               previousLabel=""
                               responsive="true"
                               autoHide="true"
                               screenReaderPaginationLabel="Lapozó"
                               screenReaderPageLabel="oldal"
                               screenReaderCurrentLabel="aktív oldal"
                               (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

      </div>
    </div>

    <div class="col-lg-5 aside">

      <!--SUBCATEGORIES-------------------------------------->
      <div class="list-group">
        <app-filter [(ngModel)]="activeFilter"
                    [initialValue]="activeFilter"
                    [filter]="filter"
                    (ngModelChange)="updateParams(filter, $event, orderBy.link, 1)"></app-filter>
        <!--        <app-sub-categories-list [category]="category" [activeSubcategory]="subCategoryParam"></app-sub-categories-list>-->
      </div>


    </div><!--  row END-->

  </div><!--  aside END-->
</div>
