
<div [ngClass]="!(thirdLevelCategories2.length > 0 || products.length > 0) ? 'search-nofind' : 'search-container'">

  <div class="pt-1 pb-0" *ngIf="!(thirdLevelCategories2.length > 0 || products.length > 0)">
    <h3 class="text-center">Nincs találat!</h3>
  </div>

  <div class="row" *ngIf="thirdLevelCategories2.length > 0 || products.length > 0">
    <div class="order-2 order-lg-1 col-lg-6">
      <h3>Kategóriák</h3>
      <ul class="list-group">
        <li *ngFor="let c of thirdLevelCategories2 | search:query"  [routerLink]="c.link" class="list-group-item list-group-item-action">
          {{c.name}}
        </li>
      </ul>

<!--      <dd >-->
<!--        {{c.value}} - {{c.key}}-->
<!--&lt;!&ndash;        {{c.name}}&ndash;&gt;-->
<!--&lt;!&ndash;        <ng-container&ndash;&gt;-->
<!--&lt;!&ndash;          [ngTemplateOutlet]="link.type == 'complex' ?complexLink : simpleLink"&ndash;&gt;-->
<!--&lt;!&ndash;          [ngTemplateOutletContext]="{link:link}">&ndash;&gt;-->
<!--&lt;!&ndash;        </ng-container>&ndash;&gt;-->
<!--      </dd>-->
    </div>

    <div class="order-1 order-lg-2 col-lg-18 product-list-search">

      <div class="d-flex justify-content-between pt-5 pt-lg-0">
      <h3>Termék találatok</h3>
      <i class="fa fa-2x fa-times text-dark" (click)="closeEvent()"></i>
      </div>
      <div class="row ps-4">
        <app-product class="col-sm-12 col-lg-6 col-xxl-4 mb-5 d-flex pe-0"
                     *ngFor="let p of products;"
                     [product]="p"
                     [link]="'termek'"
        ></app-product>
      </div>
    </div>

  </div> <!--    row END-->
</div>

<div class="search-overlay"></div>

<!--<ng-template #categoryTemplate let-category="category">-->

<!--</ng-template>-->
