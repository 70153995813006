<div class="modal-header">

  <h4 class="modal-title">Garancia</h4>
  <i class="fa fa-2x fa-times" (click)="cancelWarrantyInfoModal()"></i>
</div>

<div class="modal-body modal-info">


  Az ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK 16. pontjában részletesen kitérünk a jótállás,
  a szavatosság és az elállás témaköröke.


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelWarrantyInfoModal()">Bezár</button>
</div>
