<div class="modal-header">

  <h4 class="modal-title">Szállítási információk</h4>
  <i class="fa fa-2x fa-times" (click)="cancelDeliveryInfoModal()"></i>
</div>

<div class="modal-body modal-info">


  <p>Tisztelt Vásárlóink!</p>
  <p><strong>Bruttó 25 000 Ft feletti vásárlás esetén ingyenes szállítás</strong>t tudunk biztosítani.</p>
  <h3>GLS szállítás</h3>

  <blockquote><p>0-25.000 Ft vásárlás esetén 2500,- Ft<br>25.001 Ft- tól ingyenes</p></blockquote>

  <p>Szállítási idő: a leadott rendelések után <strong>2-4 munkanapon belül</strong> a megadott címre ér a csomag. Kivétel, ha a megrendelt termék nincs raktáron, beszerzése 8-10 munkanapot is igénybe vehet.</p>

  <p>Szállítási díjaink a vásárlás összértékének függvényében változnak.</p>
  <p>Utánvétes lehetőséggel szállítunk: azaz, a megrendelt csomag árát a kézbesítéskor kell majd kifizetni a futárnak vagy a megjelölt postán az ügyintézőnek.</p>

  <p>Az ország bármely településére kiszállítunk.</p>
  <p>A szállítási és fizetés díjaink belföldre vonatkoznak.</p>
  <p>Kérjük házhozszállításnál a csomag sértetlenségét a kézbesítő előtt szíveskedjék ellenőrizni! Ha sérülést lát, vetessen fel jegyzőkönyvet, és ne vegye át a csomagot. Utólagos reklamációt a csomag fizikai sérüléséről jegyzőkönyv nélkül nem áll módunkban elfogadni.</p>
  <p>Feltüntetett áraink magyar forintban értendők és tartalmazzák az ÁFÁ-t.</p>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelDeliveryInfoModal()">Bezár</button>
</div>
