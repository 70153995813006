<div class="modal-header">

  <h4 class="modal-title">Területi képviselők elérhetőségei</h4>
  <i class="fa fa-2x fa-times" (click)="cancelRegionalInfoModal()"></i>
</div>

<div class="modal-body modal-info">


  <h1>Conte Elegant Webáruház</h1>

  <p>Áruházunk internetes webáruház és nagyker. Kérem tekintse meg a <strong>FEHÉRNEMŰ&nbsp;ÜZLETEK </strong>és a<strong> DM ÜZLETEK&nbsp;</strong>menüpontokat. Az eredeti&nbsp;Conte Elegant&nbsp;termékeket kizárólag az előbbi menüpontban felsorolt üzletekben forgalmazzuk, minden máshol esetlegesen árultak hamisítványok.</p>

  <h4>Ügyfélszolgálat</h4>

  <p><a href="mailto:info@conte.hu">info@conte.hu</a></p>
  <p>Központi Tel: <a href="tel:+36304480063">Tel:  06-30-44-800-63</a></p>

<!--  <h4>Közép-magyarországi területi képviselő:</h4>-->
   <p>Földes János: <a href="tel:+36304176655">Tel: +36/30/417-66-55</a></p>

<!--   <h4>Kelet-magyarországi területi képviselő:</h4>-->
<!--   <p>Dobi Zsolt: <a href="tel:+36306555041">+36/30/655-50-41</a></p>-->

   <p><strong>A Conte Elegant Magyarországi képviselete</strong></p>

  <p><img alt="Conte" src="assets/images/logo.png"></p>

  <p>Conte Magyarország Kft.</p>

  <p>Székhely: 2030 Érd, Földmunkás utca 82, Raktár: 2045 Törökbálint, DEPO II.</p>

  <p>Az üzletek menüpont segít megtalálni, hogy hol van Hozzád legközelebb a Conte Elegant márka, keress minket a boltokban!</p>

  <p><strong><img alt="" src="http://harisnyaoutlet.hu/image/data/partner képek/Aranypók/IMG_20150622_142322.jpg" style="width: 500px; height: 667px;" /></strong></p>

  <p><strong>Weisz Fanni Conte harisnya reklámvideó</strong></p>

  <div class="ratio ratio-16x9">
    <iframe src="https://www.youtube.com/embed/SAs1AnuxKEc?rel=0" title="YouTube video" allowfullscreen></iframe>
  </div>

  <p><strong>Conte harisnya a TV2-n Weisz Fannival (Több mint TestŐr műsor)</strong></p>

  <p><iframe allowfullscreen="yes" frameborder="0" height="360" scrolling="no" src="http://tv2.hu/neoplayer/tv2/embed/226066_weisz-fanni-szerint-a-harisnyakkal-is-kommunikalhatunk.html" width="640"></iframe></p>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelRegionalInfoModal()">Bezár</button>
</div>

