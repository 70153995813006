import { Component, OnDestroy, OnInit } from '@angular/core';
import { SiteNavigationModel } from '../../../model/SiteNavigationModel';
import { ActivatedRoute, Router } from '@angular/router';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ProductKey } from '../../../model/ProductKey';
import { Subscription } from 'rxjs';
import { FilterState } from '../../../store/filter/FilterReducer';
import { Json } from '../../../model/Json';

@Component({
  selector: 'app-site-navigation',
  templateUrl: './site-navigation.component.html'
})
export class SiteNavigationComponent implements OnInit, OnDestroy {

  navigations: Array<SiteNavigationModel>;

  allCategories: Category[] = [];
  categories: {[k: string]: Category};
  subCategories: {[k: string]: Category};
  subSubCategories: Json<string, Category>;

  // dedicatedFilters: any;

  products: ProductKey;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private readonly filterStore: Store<FilterState>,
              private readonly categoryStore: Store<CategoryState>) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
        this.allCategories = categories;
        this.categories = {};
        this.subCategories = {};
        this.subSubCategories = {};
        this.allCategories.forEach(c =>  {
          this.categories[c.link] = c;
          c.children.forEach(sc => {
            this.subCategories[sc.link] = this.allCategories.find(ac => ac.id === sc.id);
            this.subCategories[sc.link].children.forEach(ssc => {
              this.subSubCategories[ssc.link] = ssc;
            });
          });
        });
      })
    );
    this.subscriptions.push(
      this.activatedRoute.url.subscribe((res) => {
        if (res[0].path === 'kereses') {
          return;
        }
        if (res.length === 1) {
          this.navigations = [new SiteNavigationModel(this.allCategories.find(c => c.link === res[0].path).name, '')];
          return;
        }
        if (res.length === 2) {
          if (/\d/.test(res[1].path)) {
            const mainCategory = this.categories[res[0].path];
            this.navigations = [
              new SiteNavigationModel(mainCategory.link, '')
            ];
          } else {
            this.navigations = [
              new SiteNavigationModel(this.categories[res[0].path].link, ''),
              // new SiteNavigationModel(this.subCategories[res[1].path].name, '')
            ];
          }
          return;
        }

        if (res.length === 3) {
          const mainCategory = this.categories[res[0].path];
          const subCategory = this.subCategories[res[1].path];
          const subSubCategory = this.subSubCategories[res[2].path];
          this.navigations = [
            new SiteNavigationModel(mainCategory.link, ''),
            new SiteNavigationModel(subCategory.link, mainCategory.link + '/' + subCategory.link)
            // new SiteNavigationModel(subSubCategory.name, '')
          ];
          return;
        }

        if (res.length === 4) {
          const mainCategory = this.categories[res[0].path];
          const subCategory = this.subCategories[res[1].path];
          const subSubCategory = this.subSubCategories[res[2].path];
          this.navigations = [
            new SiteNavigationModel(mainCategory.link, ''),
            new SiteNavigationModel(subCategory.link, mainCategory.link + '/' + subCategory.link),
            new SiteNavigationModel(subSubCategory.name, mainCategory.link + '/' + subCategory.link + '/' + res[2].path)
          ];
          return;
        }
      })
    );
  }
}
