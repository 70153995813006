import { PaginationInstance } from 'ngx-pagination';
import { ActiveFilter } from '../../../model/ActiveFilter';
import { Filter } from '../../../model/Filter';
import { Model } from '../../../model/Model';
import { Category } from '../../../model/Category';
import { OrderByProperty } from '../../../model/OrderByProperty';
import { ActivatedRoute, Params } from '@angular/router';
import { FilterService } from '../../../service/filter.service';
import { Subscription } from 'rxjs';
import { OrderingType } from '../../../model/OrderingType';
import { ProductKey } from '../../../model/ProductKey';
import { AddProduct } from '../../../store/product/ProductActions';
import { ProductState } from '../../../store/product/ProductReducer';
import { Store } from '@ngrx/store';

export abstract class AbstractProductListingComponent {

  isFirstLoading: boolean = true;

  activeFilter: ActiveFilter;
  allFilters: { [k: number]: Filter };
  filter: Filter;

  products: Array<Model>;

  categories: Array<Category>;

  orderings: Array<OrderByProperty>;
  orderBy: OrderByProperty;

  pagination: PaginationInstance;
  DEFAULT_PAGE_SIZE: number = 12;

  queryParams: string = '';
  queryParamsChanged: boolean;

  paramsChanged: boolean;

  query: string;

  protected subscriptions: Array<Subscription> = new Array<Subscription>();

  protected constructor(readonly filterService: FilterService,
              readonly productStore: Store<ProductState>,
              readonly activatedRoute: ActivatedRoute) {
  }

  initPagination(): void {
    const currentPage = this.activatedRoute.snapshot.data['preload'].pagination.currentPage;
    const itemsPerPage = this.activatedRoute.snapshot.data['preload'].pagination.itemsPerPage;
    const totalItems = this.activatedRoute.snapshot.data['preload'].pagination.totalItems;
    this.reInitPagination(totalItems, itemsPerPage, currentPage);
  }

  reInitPagination(totalItems: number, itemsPerPage: number = this.DEFAULT_PAGE_SIZE, currentPage: number = 1): void {
    this.pagination = {
      totalItems,
      itemsPerPage,
      currentPage
    };
  }

  updatePagination(params: Params): void {
    if (params.oldal_meret) {
      this.pagination.itemsPerPage = Number(params.oldal_meret);
    } else {
      this.pagination.itemsPerPage = this.DEFAULT_PAGE_SIZE;
    }
    if (params.oldal) {
      this.pagination.currentPage = Number(params.oldal);
    } else {
      this.pagination.currentPage = 1;
    }
  }

  pageChanged(event): void {
    this.updateParams(this.filter, this.activeFilter, this.orderBy.link, event);
  }

  updatePageSize(value: number): void {
    let currentPage = this.pagination.currentPage;
    while (currentPage * value > this.pagination.totalItems && (currentPage - 1) * value > this.pagination.totalItems && currentPage !== 1) {
      currentPage--;
    }
    this.updateParams(this.filter, this.activeFilter, this.orderBy.link, currentPage, value);
  }

  updateParams(filter: Filter, activeFilter: ActiveFilter, orderBy: string = this.orderBy.link, currentPage: number = this.pagination.currentPage, itemsPerPage: number = this.pagination.itemsPerPage): void {
    this.queryParams = this.filterService.getQueryParams(filter, activeFilter, orderBy, currentPage, itemsPerPage, this.query);
    this.navigate();
  }

  abstract navigate(): void;

  getProperties() {
    const properties = {};
    Object.keys(this.activeFilter).filter(k => !FilterService.baseProperties.includes(k)).forEach(k => {
      if (k === 'manufacturers') {
        return;
      }
      const checkedProps = this.activeFilter[k].filter(p => p.isChecked);
      if (checkedProps.length > 0) {
        properties[k] = checkedProps.map(p => {
          switch (k) {
            case 'brands': return p.data.name;
            case 'colors': return p.data.icon;
            default: return p.label;
          }
        });
      }
    });
    return Object.keys(properties).length > 0 ? properties : undefined;
  }

  getPriceRangeQuery() {
    if (this.activeFilter.priceRange.min !== this.filter.priceRange.min || this.activeFilter.priceRange.max !== this.filter.priceRange.max) {
      return this.activeFilter.priceRange;
    }
    return undefined;
  }

  getOrderingType(): OrderingType {
    return this.orderBy.value;
  }

  addProductsToStore(products: Array<Model>) {
    const productsByKeys: ProductKey = {};
    products.forEach(p => productsByKeys[p.id] = p);
    this.productStore.dispatch(new AddProduct(productsByKeys));
  }
}
