import { Component, OnInit } from '@angular/core';
import { AbstractProductListingComponent } from '../abstract-product-listing/abstract-product-listing.component';
import { ProductService } from '../../../service/product.service';
import { Store } from '@ngrx/store';
import { PaginationState } from '../../../store/pagination/PaginationReducer';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { FilterState } from '../../../store/filter/FilterReducer';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductState } from '../../../store/product/ProductReducer';
import { FilterService } from '../../../service/filter.service';

@Component({
  selector: 'app-discount-product-listing',
  templateUrl: './discount-product-listing.component.html',
  styleUrls: ['./discount-product-listing.component.scss']
})
export class DiscountProductListingComponent extends AbstractProductListingComponent implements OnInit {

  title: string;
  trackByIdentity = (index: number, item: any) => item.id;

  constructor(private readonly productService: ProductService,
              private readonly paginationStore: Store<PaginationState>,
              private readonly categoryStore: Store<CategoryState>,
              private readonly filterStore: Store<FilterState>,
              private readonly router: Router,
              readonly productStore: Store<ProductState>,
              readonly filterService: FilterService,
              readonly activatedRoute: ActivatedRoute) {
    super(filterService, productStore, activatedRoute);
  }

  ngOnInit(): void {
    this.products = this.activatedRoute.snapshot.data['preload'].result;
    this.pagination = this.activatedRoute.snapshot.data['preload'].pagination;
  }

  navigate(): void {
  }

}
