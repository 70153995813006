import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {

  orderId: number;
  transactionId: number;

  constructor(private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    // console.log(this.activatedRoute.queryParams)
    this.orderId = this.activatedRoute.snapshot.queryParams.orderId;
    this.transactionId = this.activatedRoute.snapshot.queryParams.transactionId;
  }

}
