import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Category } from '../../../model/Category';
import { ActivatedRoute, Router } from '@angular/router';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  animations: [
    trigger("toggleSubCats", [
      state(
        "initial",
        style({
          transform: "translateY(88%)",
          height: "100%",
          overflow: "hidden"
        })
      ),
      state(
        "final",
        style({
          transform: "translateY(0)",
        })
      ),

      transition("initial=>final", [
        style({ transform: "translateY(88%)" }),
        animate("0.22s 100ms ease-in")
      ]),
      transition("final=>initial", [
        animate("0.22s ease-out",
          style({ transform: "translateY(88%)" }))
      ])
    ]),


    // trigger("toggleSubCatsLinks", [
    //   state("initial", style({ transform: "translateX(0)" })),
    //   transition(":enter", [
    //     style({ transform: "translateX(-100%)" }),
    //     animate("0.5s 300ms ease-in")
    //   ]),
    //   transition(":leave", [
    //     animate("0.3s ease-out", style({ transform: "translateX(100%)" }))
    //   ])
    // ])

  ]
})
export class CategoryCardComponent implements OnInit {

  @Input()
  category: Category;

  @Output()
  navigateEvent: EventEmitter<string> = new EventEmitter<string>();

  currentState = 'initial';

  image: string;

  urlParams: any;

  // Detect device
  deviceInfo = null;

  constructor(private router: Router,
              readonly deviceService: DeviceDetectorService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.image = '/assets/images/categories/cat1.jpg';

    this.activatedRoute.params.subscribe(params => {
      this.urlParams = params;
    });
  }

  openSubCats() {
    this.currentState = "final";
  }

  closeSubCats() {
    this.currentState = "initial";
  }

  navigateToSubcategory(category: string, subCategory: string) {
    this.navigateEvent.emit(category + '/' + subCategory);
  }

  navigateToCategory() {
    const category = this.urlParams['category'];
    if (category) {
      this.navigateEvent.emit(category + '/' + this.category.link);
    } else {
      this.navigateEvent.emit(this.category.link);
    }
  }
}
