import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Model } from '../../../model/Model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductState } from '../../../store/product/ProductReducer';
import { Store } from '@ngrx/store';
import { AddProductToCart } from '../../../store/cart/CartActions';
import { ProductInCart } from '../../../model/ProductInCart';
import { CartState } from '../../../store/cart/CartReducer';
import { PropertyState } from '../../../store/property/PropertyReducer';
import { RatingService } from '../../../service/rating.service';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { Meta, Title } from '@angular/platform-browser';
import { AddProduct, AddReview } from '../../../store/product/ProductActions';
import { User } from '../../../model/User';
import { Subscription } from 'rxjs';
import { Constants } from '../../../model/Constants';
import { initFavouriteProductsSelector, initUserSelector } from '../../../store/user/UserSelector';
import { UserState } from '../../../store/user/UserReducer';
import { ProductService } from '../../../service/product.service';
import { AddFavouriteProduct, AddUserReview, RemoveFavouriteProduct } from '../../../store/user/UserActions';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Lightbox } from 'ngx-lightbox';
import { DeliveryComponent } from '../delivery/delivery.component';
import { Json } from '../../../model/Json';
import { TranslateService } from '@ngx-translate/core';
import { MissingProductComponent } from '../missing-product/missing-product.component';
import { SizeChartComponent } from '../size-chart/size-chart.component';
import { Product } from '../../../model/Product';
import { Photo } from '../../../model/Photo';
import { SizeData } from '../../../model/SizeData';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Cart } from 'src/app/model/Cart';
import { UtilsService } from '../../../service/utils.service';
import { ProductKey } from '../../../model/ProductKey';
import { WebshopSettingsState } from '../../../store/settings/WebshopSettingsReducer';
import { initWebshopSettings } from '../../../store/settings/WebshopSettingsSelector';
import { WebshopSettings } from '../../../model/WebshopSettings';
import { CartService } from '../../../service/cart.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  @Input()
  product: Model;

  settings: WebshopSettings;
  imagePrefix = '/api/product';

  selectedProduct: Product;
  activeImage: Photo;
  fullSizeImage: string;

  // DATA IDEIGLENESEN A MÉRETEKNEK, SZÍNEKNEK
  sizeData: SizeData[] = [];
  //   {'name': '164-84-92/XS','available': true},
  //   {'name': '164-88-96/S','available': true},
  //   {'name': '164-92-100/M','available': true},
  //   {'name': '164-96-104/L','available': true},
  //   {'name': '176-84-92/XS','available': true},
  //   {'name': '176-88-96/S','available': true},
  //   {'name': '176-92-100/M','available': true},
  //   {'name': '176-96-104/L','available': false}
  // ];

  sizeSelected: SizeData;

  colorData: any = [];
  colorSelected: any;

  favouriteProducts: Array<number>;
  model: Model;

  amountByProduct: Json<number, number> = new Json<number, number>();

  reviewForm: FormGroup;
  averageRating: number;
  siteNavigation: boolean;

  // magnifier prefs
  imgWidth = '200';
  imgHeight = '200';
  resultWidth = '40';
  resultHeight = '25';
  resultTop = '8';
  resultRight = '22';

  resultHeightLg = '25';
  resultWidthLg = '60';
  resultRightLg = '2';

  deliveryModalRef: BsModalRef;
  missingProductModalRef: BsModalRef;
  sizeChartModalRef: BsModalRef;

  @ViewChild('deliveryModal', { static: false })
  deliveryModalTemplate: TemplateRef<any>;

  @ViewChild('missingProductModal', { static: false })
  missingProductModalTemplate: TemplateRef<any>;

  @ViewChild('sizeChartModal', { static: false })
  sizeChartModalTemplate: TemplateRef<any>;

  cart: Cart;
  productsInCart: Array<ProductInCart>;
  productsById: Json<number, Product> = new Json<number, Product>();
  subTotal: number = 0;
  models: ProductKey;

  private _albums: Array<any> = [
    {
      src: '../assets/images/Roycreates-red.jpg',
      caption: 'test',
      thumb: '../assets/images/Roycreates-red.jpg'

    },
    {
      src: '../assets/images/Roycreates-red.jpg',
      caption: 'test',
      thumb: '../assets/images/Roycreates-red.jpg'
    }
  ];

  user: User;
  private readonly subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly cartStore: Store<CartState>,
              private readonly activatedRoute: ActivatedRoute,
              private readonly productStore: Store<ProductState>,
              private readonly propertyStore: Store<PropertyState>,
              private readonly ratingService: RatingService,
              private readonly formService: FormService,
              private readonly userStore: Store<UserState>,
              private readonly productService: ProductService,
              private readonly toastrService: ToastrService,
              private readonly dataexchangeService: DataExchangeService,
              private readonly modalService: BsModalService,
              protected readonly router: Router,
              private readonly webshopSettingsStore: Store<WebshopSettingsState>,
              private readonly metaService: Meta,
              private readonly cartService: CartService,
              private readonly translateService: TranslateService,
              private readonly _lightbox: Lightbox,
              private readonly title: Title) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setActiveImage(p): void {
    this.activeImage = p;
    const splitted = this.activeImage.original.split('/');
    this.fullSizeImage = this.createFullSizeImagePath(this.activeImage.original);
    // splitted.forEach((s, i) => {
    //   const isLastElement = i + 1 === splitted.length;
    //   if (splitted.length === i + 2) {
    //     this.fullSizeImage += 'size__1000/';
    //     this.fullSizeImage += s + '/';
    //   } else {
    //     this.fullSizeImage += isLastElement ? s : (s + '/');
    //   }
    // });
  }

  createFullSizeImagePath(url: string): string {
    const splitted = url.split('/');
    let fullSizeUrl = '';
    // this.fullSizeImage = '';
    splitted.forEach((s, i) => {
      const isLastElement = i + 1 === splitted.length;
      if (splitted.length === i + 2) {
        fullSizeUrl += 'size__1000/';
        fullSizeUrl += s + '/';
      } else {
        fullSizeUrl += isLastElement ? s : (s + '/');
      }
    });
    return fullSizeUrl;
  }

  initModel(): void {
    if (this.model.photos.length === 0) {
      this.model = {
        ...this.model,
        photos: [
          {
            original: this.model.image.original
          }
        ]
      };
    }
    this.averageRating = 0;
    this.setActiveImage(this.model.photos[0]);
    this.metaService.addTags(
      [
        { name: 'description', property: this.model ? this.model.description.substring(0, 159) : ''},
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Conte.hu Webshop' },
        { property: 'og:title', content: this.model.name + ' ' + this.model.price + ' Ft'},
        { property: 'og:description', content: this.model.description.substring(0, 159) },
        { property: 'og:url', content: '/termek/' + this.model.link },
        { property: 'og:image', content: this.imagePrefix + this.model.image.original },
        { property: 'og:locale', content: 'hu_HU' }
      ]
    );

    this.title.setTitle(this.model.name + ' | ára: ' + (this.model.price) + ' Ft | Conte.hu');
    this.reviewForm = this.formService.getReviewForm(this.model.id);
    this.selectedProduct = this.model.products[0];
    this.calculateAverageRating();

    // this.metaService.updateTag(
    //   {name: 'description', content: this.model.description.substring(0, 159)}
    // );

    // const colors = new Set();

    this.colorData = [];
    this.model.products.forEach(item => {
      this.amountByProduct[item.id] = 1;
      const colorId = item.colorId || item.colorSrc;
      // if (colorId) {
      //   colors.add(item.colorId);
      // }

      if (colorId && !this.colorData.find(cd => cd.name === item.originalColor)) {
        this.colorData.push({
          icon: item.colorId,
          name: item.originalColor
        });
      }
    });
    // this.colorData = Array.from(colors).map(item => {
    //   return {
    //     name: item,
    //     icon: item
    //   };
    // });
    this.colorSelected = this.colorData[0];
    this.initSizes();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.webshopSettingsStore.select(initWebshopSettings).subscribe((settings) => {
        this.settings = settings;
      })
    );
    if (this.activatedRoute.routeConfig.path.includes(Constants.PRODUCT_PAGE_URL)) {
      this.siteNavigation = false;
    } else {
      this.siteNavigation = true;
    }
  //   TODO kivenni az url-ből a product id-t, betölteni a store-ból a productot, ha nincs ott, akkor a serverről


    this.model = this.activatedRoute.snapshot.data.preload;

    this.initModel();

    this.productStore.dispatch(new AddProduct({[this.model.id]: this.model}));

    this.subscriptions.push(
      this.userStore.select(initUserSelector).subscribe((user) => {
        this.user = user;
      })
    );

    this.subscriptions.push(
      this.productStore.select(initProductsSelector).subscribe((models) => {
        this.models = models;
      })
    );

    this.subscriptions.push(
      this.cartStore.select(initCartSelector).subscribe((cart) => {
        this.cart = cart;
        this.productsInCart = Object.values(cart);
        this.reinitProductsInCart();
      })
    );

    this.subscriptions.push(
      this.userStore.select(initFavouriteProductsSelector).subscribe((favouriteProducts) => {
        this.favouriteProducts = favouriteProducts;
      })
    );

    this.subscriptions.push(
      this.productStore.select(initProductsSelector).subscribe((models) => {
        this.model = models[this.model.id];
      })
    );

    this.subscriptions.push(this.activatedRoute.data.subscribe((data) => {
      this.model = data.preload;
      this.propertyStore.dispatch(new AddProduct({[this.model.id]: this.model}));
      this.initModel();
    }));
    // this.subscriptions.push(this.activatedRoute.url.subscribe((params) => {
    //   const productLink = params[params.length - 1].path;
    //   if (this.model.link !== productLink) {
    //     this.productService.getProductById(params[params.length - 1].path).subscribe((model) => {
    //       this.model = model;
    //       this.initModel();
    //     });
    //   }
    // }));

    if (!this.model) {
      return;
    }



    // this.sizeSelected = this.sizeData[0];
    // Array.from(sizes).forEach(s => {
    //
    // })


    for (let i = 1; i <= 4; i++) {
      const src = 'demo/img/image' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const thumb = 'demo/img/image' + i + '-thumb.jpg';
      const album = {
        src,
        caption,
        thumb
      };

      this._albums.push(album);
    }

  }

  reinitProductsInCart(): void {
    for (const k of Object.keys(this.cart)) {
      const product = this.models[this.cart[k].parentProductId].products.find(pv => pv.id === Number(k));
      if (!this.productsById[product.id]) {
        if (this.models[k]) {
          // main product, van ilyen?
          this.productsById[this.models[k].id] = this.models[k];
        } else {
          // variation
          this.productsById[product.id] = product;
        }
      }
    }
    this.productsInCart = Object.values(this.cart);
    this.calculateSubTotal();
  }

  calculateSubTotal(): void {
    this.subTotal = UtilsService.calculateSubTotal(this.productsInCart, this.productsById, this.user?.discount);
  }


  initSizes(): void {
    this.sizeData = [];
    const sizes = new Set();
    this.model.products.forEach(p => {
      if (this.colorSelected?.icon) {
        if (p.colorId === this.colorSelected?.icon) {
          if (p.size && p.size.length > 0) {
            sizes.add(p.size);
            const sizeData = this.sizeData.find(s => s.name === p.size);
            if (!sizeData) {
              this.sizeData.push({
                name: p.size,
                available: p.count > 0,
                availableColors: [p.colorId]
              });
            } else {
              if (p.count > 0) {
                sizeData.available = true;
                if (!sizeData.availableColors.find(c => c === p.colorId)) {
                  sizeData.availableColors.push(p.colorId);
                }
              }
            }
          }
        }
      } else {
        if (p.size && p.size.length > 0) {
          sizes.add(p.size);
          const sizeData = this.sizeData.find(s => s.name === p.size);
          if (!sizeData) {
            this.sizeData.push({
              name: p.size,
              available: p.count > 0,
              availableColors: [p.colorId]
            });
          } else {
            if (p.count > 0) {
              sizeData.available = true;
              if (!sizeData.availableColors.find(c => c === p.colorId)) {
                sizeData.availableColors.push(p.colorId);
              }
            }
          }
        }
      }
    });
    this.sizeSelected = this.sizeData[0];
    if (this.colorSelected) {
      this.selectedProduct = this.model.products.find(p => p.colorId === this.colorSelected.icon && (this.sizeSelected ? p.size === this.sizeSelected.name : true));
    } else {
      this.selectedProduct = this.model.products[0];
    }
  }

  calculateAverageRating(): void {
    this.averageRating = Number((this.model.reviews.reduce((a, b) => a + b.rating, 0) / this.model.reviews.length).toFixed(2));
  }

  addToCart(): void {
    this.cartStore.dispatch(new AddProductToCart(this.selectedProduct.id, new ProductInCart(this.selectedProduct.id, this.model.id, this.amountByProduct[this.selectedProduct.id])));
    if (this.user) {
      this.cartService.updateAmountOfProduct(this.selectedProduct.id, this.amountByProduct[this.selectedProduct.id]).subscribe();
    }
    this.dataexchangeService.setAddProduct(this.selectedProduct);
  }

  increaseAmountForProduct(productId: number): void {
    this.amountByProduct[productId] += 1;
  }

  decreaseAmountForProduct(productId: number): void {
    this.amountByProduct[productId] -= 1;
  }

  submitReview(): void {
    this.ratingService.reviewProduct(this.reviewForm.getRawValue()).subscribe(() => {
      this.productStore.dispatch(new AddReview({
        modelId: this.model.id,
        rating: this.reviewForm.value.rating,
        review: this.reviewForm.value.review,
        name: this.user.username,
        creationDate: new Date()
      }));
      this.userStore.dispatch(new AddUserReview(this.model.id));
      this.reviewForm.reset();


      //  TODO letiltani az értékelést ehhez a termékhez
      //  hozzáadni a legújabbat
      this.calculateAverageRating();
    }, err => {
      console.log(err);
      this.toastrService.error(this.translateService.instant(err.error.message));
    });
  }

  addToFavouriteProducts(): void {
    this.productService.addToFavouriteProducts(this.model.id).subscribe(() => {
      this.toastrService.success(this.translateService.instant('label.product.added.to.favourite.products'));
      this.productStore.dispatch(new AddFavouriteProduct(this.model.id));
    });
  }

  deleteFromFavouriteProducts(): void {
    this.productService.removeFromFavouriteProducts(this.model.id).subscribe(() => {
      this.toastrService.success(this.translateService.instant('label.product.removed.from.favourite.products'));
      this.productStore.dispatch(new RemoveFavouriteProduct(this.model.id));
    });
  }


  openDeliveryModal(): void {
    this.deliveryModalRef = this.modalService.show(DeliveryComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-lg'
    });
  }

  cancelDeliveryModal(): void {
    this.deliveryModalRef.hide();
    this.deliveryModalRef = null;
  }

  openMissingProductModal(item: SizeData): void {
    const product = this.model.products.find(p => p.size === item.name && p.colorId === this.colorSelected.icon);
    const initialState = {
      productId: product.id,
      user: this.user
    };
    this.missingProductModalRef = this.modalService.show(MissingProductComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-lg',
      initialState
    });
  }

  openSizeChartModal(): void {
    this.missingProductModalRef = this.modalService.show(SizeChartComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-xl'
    });
  }

  cancelMissingProduct(): void {
    this.missingProductModalRef.hide();
    this.missingProductModalRef = null;
  }

  navigateToCheckout(): void {
    this.router.navigateByUrl('penztar');
  }

  open(): void {
    // open lightbox
    // this._lightbox.open(item);
    this._lightbox.open(this.model.photos.map(p => {
      return {
        // src: this.imagePrefix + p.original,
        src: this.imagePrefix + this.createFullSizeImagePath(p.original),
        // caption?: item,
        thumb: this.imagePrefix + p.original
      };
    }), this.model.photos.indexOf(this.activeImage));
    // this._lightbox.open([{
    //   src: item,
    //   // caption?: item,
    //   thumb: item
    // },
    //   {
    //     src: item,
    //     // caption?: item,
    //     thumb: item
    //   },
    //   {
    //     src: item,
    //     // caption?: item,
    //     thumb: item
    //   }
    // ]);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  setSize(item): void {
    this.sizeSelected = item;
    this.selectedProduct = this.model.products.find(p => p.size === this.sizeSelected.name && p.colorId === this.colorSelected.icon);
  }

  setColor(item): void {
    this.colorSelected = item;
    this.initSizes();
  }


}
