import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-terms-info-modal',
  templateUrl: './terms-info-modal.component.html'
})
export class TermsInfoModalComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }


  cancelTermsInfoModal() {
    this.modalRef.hide();
  }

}
