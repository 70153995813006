import { Injectable } from '@angular/core';
import { Model } from '../model/Model';
import { ProductKey } from '../model/ProductKey';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ProductInCart } from '../model/ProductInCart';
import { Json } from '../model/Json';
import { Product } from '../model/Product';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  static REGEX_PATTERN = /[a-zA-Z0-9]/;
  static usernameRegex = new RegExp(UtilsService.REGEX_PATTERN);

  constructor() { }

  static convertProductsToJson(products: Array<Model>): ProductKey {
    const json = {};
    products.forEach(p => json[p.id] = p);
    return json;
  }

  static passwordsMatched(firstPasswordLabel: string, secondPasswordLabel: string) {
    return (form: FormGroup): {[key: string]: boolean} | null => {
      const newPassword = form.controls[firstPasswordLabel];
      const newPasswordAgain = form.controls[secondPasswordLabel];
      if (newPassword.value !== newPasswordAgain.value) {
        return {
          passwordsMatched: false
        };
      }
      return null;
    };
  }

  // static convertProductsToJsonByArticleNumber(products: Array<Model>): {[k: string]: Model} {
  //   const json = {};
  //   products.forEach(p => {
  //     json[p.articleNumber] = p;
  //     if (p.products && p.products.length > 0) {
  //       p.products.forEach(pv => {
  //         json[pv.articleNumber] = pv;
  //       });
  //     }
  //   });
  //
  //   return json;
  // }

  static isNotUndefinedOrNull(value: any): boolean {
    return value !== undefined && value !== null;
  }

  static calculateSubTotal(productsInCart: Array<ProductInCart>, productsById: Json<number, Product>, discount: number): number {
    let subTotal = 0;
    productsInCart.forEach(pc => {
      subTotal += productsById[pc.productId].price * pc.amount;
    });
    if (discount) {
      return Math.ceil(subTotal * (1 - (discount / 100)));
    }
    return subTotal;
  }

  static calculateDiscount(subTotal: number, productsInCart: Array<ProductInCart>, productsById: Json<number, Product>, discount: number): number {
    return this.calculateSubTotal(productsInCart, productsById, null) - subTotal;
  }

  static validateUsernameKeypress(event: KeyboardEvent): void {
    if (!UtilsService.usernameRegex.test(event.key) || event.code === 'Space') {
      event.preventDefault();
    }
  }

  static usernameValidator(control: AbstractControl): any {
    if (control.value.includes(' ') || !UtilsService.usernameRegex.test(control.value) || control.value.includes('.')) {
      return {invalidUsername: true};
    }
    return null;
  }

  static nest<T>(items: Array<T>, id = null, idKey = 'id', link = 'parentId'): any {
    return items
      .filter(item => item[link] === id)
      .map(item => ({...item, children: this.nest(items, item[idKey], idKey, link)}));
  }


}
