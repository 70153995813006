import { FilterActions, FilterActionTypes } from './FilterActions';
import { Filter } from '../../model/Filter';
import { OrderByProperty } from '../../model/OrderByProperty';
import { OrderingType } from '../../model/OrderingType';

export interface FilterState {
  filters: { [k: number]: Filter };
  dedicatedFilters: {[k: number]: Array<string>};
  orderings: Array<OrderByProperty>;
}

export const initialState: FilterState = {
  filters: {},
  dedicatedFilters: {},
  orderings: []
};

export function filterReducer(state: FilterState = initialState, action: FilterActions): FilterState {
  switch (action.type) {
    case FilterActionTypes.InitFilters:  {
      return {
        ...state,
        // filters: action.filters,
        // dedicatedFilters: action.dedicatedFilters,
        orderings: orderingValues
      };
    }
  }
  return state;
}

export const getFilters = (state: FilterState) => state.filters;
export const getDedicatedFilters = (state: FilterState) => state.dedicatedFilters;
export const getOrderings = (state: FilterState) => state.orderings;

const orderingValues: Array<OrderByProperty> = [
  {
    link: 'nepszeru',
    label: 'Népszerűbbek elöl',
    value: OrderingType.POPULAR
  },
  {
    link: 'legolcsobb',
    label: 'Legolcsóbb elöl',
    value: OrderingType.MIN_PRICE
  },
  {
    link: 'legdragabb',
    label: 'Legdrágább elöl',
    value: OrderingType.MAX_PRICE
  },
  {
    link: 'ertekeles',
    label: 'Legjobb értékelés',
    value: OrderingType.BEST_REVIEW
  },
  {
    link: 'nev',
    label: 'A termék neve',
    value: OrderingType.ALPHABETIC
  },
];
