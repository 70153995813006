<div *ngIf="loginEvent.LOGIN === event || loginEvent.NEW_PASSWORD_REQUIRED === event">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Belépés</h4>
    <i class="fa fa-2x fa-times pull-right" aria-label="Bezár" tooltip="Bezár" [delay]="500" (click)="bsModalRef.hide()"></i>
  </div>
  <div class="modal-body">
    <form class="form-box data-form" [formGroup]="loginForm" (submit)="login()" name="form" autocomplete="off">
      <div class="login-input-container">

        <div class="form-floating">
          <input type="email"
                 class="form-control"
                 autocapitalize="none"
                 autocorrect="off"
                 autocomplete="off"
                 data-lpignore="true"
                 formControlName="username"
                 name="username"
                 required
                 id="emailInput"
                 placeholder="E-mail"
                 [class.invalid]="loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">
          <label for="emailInput">E-mail vagy felhasználónév</label>

        <div *ngIf="loginForm.get('username').invalid &&
        loginForm.get('username').errors &&
        (loginForm.get('username').dirty || loginForm.get('username').touched)">
          <small class="message"
                 *ngIf="loginForm.get('username').hasError('required')">
            {{'error.required' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="loginForm.get('username').hasError('minlength')">
            {{'error.minimum.length' | translate}} {{loginForm.get('username').errors.minlength.requiredLength}} {{'error.minimum.length.character' | translate}}
          </small>
          <small class="message"
                 *ngIf="loginForm.get('username').hasError('maxlength')">
            {{'error.maximum.length' | translate}} {{loginForm.get('username').errors.maxlength.requiredLength}} {{'error.maximum.length.character' | translate}}
          </small>

        </div>
        </div>

        <div class="form-floating">
          <input type="password"
                 class="form-control"
                 formControlName="password"
                 name="password"
                 maxlength="200"
                 autocapitalize="none"
                 autocorrect="off"
                 autocomplete="off"
                 data-lpignore="true"
                 required
                 id="pswInput"
                 placeholder="Jelszó">
          <label for="pswInput">Jelszó</label>

          <div *ngIf="loginForm.get('password').invalid &&
        loginForm.get('password').errors &&
        (loginForm.get('password').dirty || loginForm.get('password').touched)">
            <small class="message"
                   *ngIf="loginForm.get('password').hasError('required')">
              {{'error.password.missing' | translate}}
            </small>
            <small class="delayed-message"
                   *ngIf="loginForm.get('password').hasError('minlength')">
              {{'error.password.length.too.short' | translate}}
            </small>
            <small class="delayed-message"
                   *ngIf="loginForm.get('password').hasError('pattern')">
              {{'error.password.format' | translate}}
            </small>
          </div>

        </div>

        <div class="form-floating" *ngIf="event === loginEvent.NEW_PASSWORD_REQUIRED || event === loginEvent.FORGOT_PASSWORD_SUBMIT">
          <input type="password"
                 class="form-control"
                 formControlName="newPassword"
                 maxlength="200"
                 name="password"
                 autocapitalize="none"
                 autocorrect="off"
                 autocomplete="off"
                 data-lpignore="true"
                 required
                 id="newPswInput"
                 placeholder="Jelszó">
          <label for="newPswInput">Jelszó</label>
          <div *ngIf="loginForm.get('newPassword').invalid &&
        loginForm.get('newPassword').errors &&
        (loginForm.get('newPassword').dirty || loginForm.get('newPassword').touched)">
            <small class="message"
                   *ngIf="loginForm.get('newPassword').hasError('required')">
              {{'error.password.missing' | translate}}
            </small>
            <small class="delayed-message"
                   *ngIf="loginForm.get('newPassword').hasError('minlength')">
              {{'error.password.length.too.short' | translate}}
            </small>
            <small class="delayed-message"
                   *ngIf="loginForm.get('newPassword').hasError('pattern')">
              {{'error.password.format' | translate}}
            </small>
          </div>
        </div>


        <div class="form-floating" *ngIf="event === loginEvent.NEW_PASSWORD_REQUIRED || event === loginEvent.FORGOT_PASSWORD_SUBMIT">
          <input type="password"
                 class="form-control"
                 formControlName="newPasswordAgain"
                 maxlength="200"
                 name="password"
                 autocapitalize="none"
                 autocorrect="off"
                 autocomplete="off"
                 data-lpignore="true"
                 required
                 id="newPswAgainInput"
                 placeholder="Új jelszó megerősítése">
          <label for="newPswAgainInput">Új jelszó megerősítése</label>

          <div *ngIf="loginForm.get('newPasswordAgain').invalid &&
        loginForm.get('newPasswordAgain').errors &&
        (loginForm.get('newPasswordAgain').dirty || loginForm.get('newPasswordAgain').touched)">
            <small class="message"
                   *ngIf="loginForm.get('newPasswordAgain').hasError('required')">
              {{'error.password.missing' | translate}}
            </small>
            <small class="delayed-message"
                   *ngIf="loginForm.get('newPasswordAgain').hasError('minlength')">
              {{'error.password.length.too.short' | translate}}
            </small>
            <small class="delayed-message"
                   *ngIf="loginForm.get('newPasswordAgain').hasError('pattern')">
              {{'error.password.format' | translate}}
            </small>
          </div>
        </div>

      </div>

      <div class="form-group text-center">
        <button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid"><i class="fa fa-chevron-right"></i> Tovább</button>
      </div>
    </form>

    <p>
      Még nem regisztráltál? <span (click)="setModalType(loginEvent.REGISTER)">Regisztálj!</span>
    </p>
    <p>
      <span (click)="setModalType(loginEvent.FORGOT_PASSWORD)">Elfelejtetted a jelszavad?</span>
    </p>
  </div>

<!--  <div class="modal-footer d-flex justify-content-center">-->
<!--    <p>Beléphetsz a Facebook felhasználó neveddel is.<br>Kattints az ikonra!-->
<!--      <i class="fa fa-3x fa-facebook" (click)="signInWithFB()"></i></p>-->
<!--  </div>-->
</div>

<div *ngIf="loginEvent.REGISTER === event">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Regisztráció új felhasználóknak</h4>
    <i class="fa fa-2x fa-times pull-right" aria-label="Bezár" tooltip="Bezár" [delay]="500" (click)="bsModalRef.hide()"></i>
  </div>
  <div class="modal-body">
    <p class="text-start">Az űrlapon levő adatok kitöltése a regisztrációhoz kötelező.<br><br>
      A felhasználóneved minimum 2, maximum 12 karakter hosszúságú lehet és nem tartalmazhat szóközt. Ha terméket értékelsz akkor a felhasználóneved mindenki által láthatóvá válik.</p>

    <p class="text-start">A jelszavad legalább 8 karakter hosszú legyen, tartalmazzon legalább egy számot, egy kis- és nagy betűt, és egy speciális karaktert!</p>

    <form class="form-box data-form" [formGroup]="registerForm" autocomplete="off">

      <div class="form-floating">
        <input type="text"
               name="username"
               class="form-control"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               formControlName="username"
               maxlength="12"
               placeholder="{{'contact.username' | translate}}"
               required
               id="userInput"
               (keydown)="validateUsernameKeypress($event)">
        <label for="userInput">{{'contact.username' | translate}}</label>
      <div *ngIf="registerForm.get('username').invalid &&
        registerForm.get('username').errors &&
        (registerForm.get('username').dirty || registerForm.get('username').touched)">
        <small class="message"
          *ngIf="registerForm.get('username').hasError('required')">
          {{'error.username.missing' | translate}}
        </small>
        <small class="delayed-message"
          *ngIf="registerForm.get('username').hasError('minlength')">
          {{'error.username.minlength' | translate}}
        </small>
        <small class="delayed-message"
               *ngIf="registerForm.get('username').hasError('pattern')">
          {{'error.username.invalid' | translate}}
        </small>
      </div>
      </div>

      <div class="form-floating">
        <input type="email"
               class="form-control"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               formControlName="email"
               maxlength="50"
               name="email"
               required
               id="addressEmailInput"
               placeholder="{{'address.email' | translate}}">

        <label for="addressEmailInput">{{'address.email' | translate}}</label>

        <div *ngIf="registerForm.get('email').invalid &&
        registerForm.get('email').errors &&
        (registerForm.get('email').dirty || registerForm.get('email').touched)">
          <small class="message"
            *ngIf="registerForm.get('email').hasError('required')">
            {{'error.missing.email' | translate}}
          </small>
          <small class="delayed-message"
            *ngIf="registerForm.get('email').hasError('email')">
            {{'error.invalid.email.format' | translate}}
          </small>
        </div>
      </div>

      <div class="form-floating">
        <input [type]="hidePsw ? 'password' : 'text'"
               class="form-control"
               formControlName="password"
               name="password"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               required
               id="regPswInput"
               placeholder="{{'register.password' | translate}}">
        <label for="regPswInput">{{'register.password' | translate}}</label>
        <div *ngIf="registerForm.get('password').invalid &&
        registerForm.get('password').errors &&
        (registerForm.get('password').dirty || registerForm.get('password').touched)">
          <small class="message"
                 *ngIf="registerForm.get('password').hasError('required')">
            {{'error.password.missing' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="registerForm.get('password').hasError('minlength')">
            {{'error.password.length.too.short' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="registerForm.get('password').hasError('pattern')">
            {{'error.password.format' | translate}}
          </small>
        </div>
      </div>

<!--        <i  (click)="hidePsw = !hidePsw" class="fa {{hidePsw ? 'fa-angle-down' : 'fa-angle-up'}}"></i>-->


      <div class="form-floating">
        <input type="password"
               class="form-control"
               formControlName="passwordAgain"
               name="passwordAgain"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               required
               id="regPswAgainInput"
               placeholder="{{'register.passwordAgain' | translate}}">
        <label for="regPswAgainInput">{{'register.passwordAgain' | translate}}</label>

        <div *ngIf="registerForm.get('passwordAgain').invalid &&
        registerForm.get('passwordAgain').errors &&
        (registerForm.get('passwordAgain').dirty || registerForm.get('passwordAgain').touched)">
          <small class="message"
                 *ngIf="registerForm.get('passwordAgain').hasError('required')">
            {{'error.password.missing' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="registerForm.get('passwordAgain').hasError('minlength')">
            {{'error.password.minlength' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="registerForm.get('passwordAgain').hasError('pattern')">
            {{'error.password.invalid' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="registerForm.get('passwordAgain').hasError('missmatch')">
            {{'error.password.mismatch' | translate}}
          </small>
        </div>
      </div>

      <div class="col-24 form-check mt-5">
        <input class="form-check-input" type="checkbox" id="newsletterCheck" formControlName="newsletter">
        <label class="form-check-label" for="newsletterCheck">
          Szeretnék hírlevelet kapni az akciókról, újdonságokról.
        </label>
      </div>


      <div class="text-center d-flex justify-content-center mt-5">

        <button class="btn btn-primary w-100" type="submit" (click)="register()" [disabled]="registerForm.invalid"><i class="fa fa-chevron-right"></i> {{'register.submit' | translate}}</button>
      </div>

    </form>
  </div>

  <div class="modal-footer d-flex justify-content-center mb-5">
    <p>Már regisztráltál? <span (click)="setModalType(loginEvent.LOGIN)">Lépj be itt!</span></p>
  </div>

</div>

<!--<div *ngIf="modalType === 'REG_COMPLETE'">-->
<!--</div>-->

<div *ngIf="loginEvent.FORGOT_PASSWORD === event || loginEvent.FORGOT_PASSWORD_SUBMIT === event">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Elfelejtett jelszó</h4>
    <i class="fa fa-2x fa-times pull-right" aria-label="Bezár" tooltip="Bezár" [delay]="500" (click)="bsModalRef.hide()"></i>
  </div>
  <div class="modal-body">

    <p>Írd be az e-mail címedet és kattints a gombra!<br>A jelszavadat e-mailen fogjuk elküldeni a megadott címre.</p>

    <form class="form-box data-form" [formGroup]="forgetForm" name="form" autocomplete="off">

      <div class="form-floating">
        <input type="email"
               class="form-control"
               maxlength="30"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               formControlName="email"
               name="email"
               required
               id="addressEmail2Input"
               placeholder="{{'address.email' | translate}}">
        <label for="addressEmail2Input">{{'address.email' | translate}}</label>

        <div *ngIf="forgetForm.get('email').invalid &&
        forgetForm.get('email').errors &&
        (forgetForm.get('email').dirty || forgetForm.get('email').touched)">

          <small class="delayed-message"  *ngIf="forgetForm.get('email').hasError('email') && !forgetForm.hasError('required')">
            {{'error.invalid.email.format' | translate}}
          </small>

          <small class="message"
                 *ngIf="forgetForm.get('email').hasError('required')">
            {{'error.missing.email' | translate}}
          </small>
        </div>

      </div>




      <div class="form-floating" *ngIf="event === loginEvent.FORGOT_PASSWORD_SUBMIT">
        <input type="text"
               class="form-control"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               formControlName="code"
               [class.invalid]="forgetForm.controls['code'].invalid && (forgetForm.controls['code'].dirty || forgetForm.controls['code'].touched)"
               name="code"
               required
               id="codeInput"
               placeholder="{{'placeholder.code' | translate}}">
        <label for="codeInput">{{'placeholder.code' | translate}}</label>

            <div *ngIf="forgetForm.get('code').invalid &&
        forgetForm.get('code').errors &&
        (forgetForm.get('code').dirty || forgetForm.get('code').touched)">
              <small class="message"  *ngIf="forgetForm.hasError('required')">
                {{'error.code' | translate}}
              </small>
      </div>
      </div>

      <div class="form-floating" *ngIf="event === loginEvent.FORGOT_PASSWORD_SUBMIT">
        <input type="password"
               class="form-control"
               [class.invalid]="forgetForm.controls['password'].invalid && (forgetForm.controls['password'].dirty || forgetForm.controls['password'].touched)"
               formControlName="password"
               name="newPassword"
               autocapitalize="none"
               autocorrect="off"
               autocomplete="off"
               data-lpignore="true"
               required
               id="code2Input"
               placeholder="{{'register.password' | translate}}">
        <label for="code2Input">{{'register.password' | translate}}</label>

        <div *ngIf="forgetForm.get('password').invalid &&
        forgetForm.get('password').errors &&
        (forgetForm.get('password').dirty || forgetForm.get('password').touched)">
          <small class="message"
                 *ngIf="forgetForm.get('password').hasError('required')">
            {{'error.password.missing' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="forgetForm.get('password').hasError('minlength')">
            {{'error.password.length.too.short' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="forgetForm.get('password').hasError('pattern')">
            {{'error.password.format' | translate}}
          </small>
        </div>

      </div>


<!--        <div class="alert alert-danger" *ngIf="forgetForm.get('password').invalid &&-->
<!--        forgetForm.get('password').errors &&-->
<!--        (forgetForm.get('password').dirty || forgetForm.get('password').touched)">-->
<!--          <small class="text-danger"-->
<!--                 *ngIf="forgetForm.get('password').hasError('required')">-->
<!--            {{'error.password' | translate}}-->
<!--          </small>-->
<!--        </div>-->



      <div class="form-floating mt-3" *ngIf="event === loginEvent.FORGOT_PASSWORD_SUBMIT">
        <input class="form-control"
               type="password"
               name="password"
               id="password"
               placeholder="{{'placeholder.password' | translate}}"
               formControlName="newPasswordAgain"
               autocapitalize="none"
               autocomplete="off"
               data-lpignore="true"
               [class.invalid]="forgetForm.controls['password'].invalid && (forgetForm.controls['password'].dirty || forgetForm.controls['password'].touched)"
               required>
        <label for="password">{{'placeholder.password' | translate}}</label>
        <div *ngIf="forgetForm.get('newPasswordAgain').invalid &&
        forgetForm.get('newPasswordAgain').errors &&
        (forgetForm.get('newPasswordAgain').dirty || forgetForm.get('newPasswordAgain').touched)">
          <small class="message"
                 *ngIf="forgetForm.get('newPasswordAgain').hasError('required')">
            {{'error.password.missing' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="forgetForm.get('newPasswordAgain').hasError('minlength')">
            {{'error.password.length.too.short' | translate}}
          </small>
          <small class="delayed-message"
                 *ngIf="forgetForm.get('newPasswordAgain').hasError('pattern')">
            {{'error.password.format' | translate}}
          </small>
        </div>
      </div>

      <div class="form-group text-center">
<!--       <button mat-raised-button color="primary" type="submit" [disabled]="forgetForm.invalid"><i class="fa fa-chevron-right"></i> Tovább</button>-->
       <button class="btn w-100 btn-primary" type="submit" [disabled]="forgetForm.invalid" *ngIf="event === loginEvent.FORGOT_PASSWORD" (click)="forgotPassword()">
         {{'button.continue' | translate}}
       </button>
       <button class="btn w-100 btn-primary"  type="submit" [disabled]="forgetForm.invalid" *ngIf="event === loginEvent.FORGOT_PASSWORD_SUBMIT" (click)="forgotPasswordSubmit()">
         {{'button.submit.forgot.password' | translate}}
       </button>
<!--        <button class="w-100 btn btn-success text-uppercase" *ngIf="event === loginEvent.FORGOT_PASSWORD" (click)="forgotPassword()">{{'button.submit' | translate}}</button>-->
<!--        <button class="w-100 btn btn-success text-uppercase" *ngIf="event === loginEvent.FORGOT_PASSWORD_SUBMIT" (click)="forgotPasswordSubmit()">{{'button.submit.forgot.password' | translate}}</button>-->
      </div>

    </form>
  </div>
  <div class="modal-footer d-flex justify-content-center pb-5">
    <p><span (click)="setModalType(loginEvent.REGISTER)">Regisztráció</span> / <span (click)="setModalType(loginEvent.LOGIN)">Belépés</span></p>
  </div>
</div>
