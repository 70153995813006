import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-regional-info-modal',
  templateUrl: './regional-info-modal.component.html'
})
export class RegionalInfoModalComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelRegionalInfoModal() {
    this.modalRef.hide();
  }

}
