import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalData } from '../../../model/PersonalData';
import { FormService } from '../../../service/form.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { UpdatePersonalData } from '../../../store/user/UserActions';
import { ProfileService } from '../../../service/profile.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { initProfileSelector } from '../../../store/user/UserSelector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html'
})
export class PersonalDataComponent implements OnInit, OnDestroy {

  personalData: PersonalData;

  modifyState: boolean;

  personalDataForm: FormGroup;
  companyChecked = false;
  customCompanyPanelClass = 'company-panel';
  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly formService: FormService,
              private readonly toastrService: ToastrService,
              private readonly translateService: TranslateService,
              private readonly profileService: ProfileService,
              private readonly userStore: Store<UserState>) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userStore.select(initProfileSelector).subscribe((profile) => {
        this.personalData = profile.personalData;
      })
    );
    this.personalDataForm = this.formService.getPersonalDataForm(this.personalData);
    this.personalDataForm.disable();
  }

  savePersonalData(): void {
    this.profileService.updatePersonalData(this.personalDataForm.value).subscribe(() => {
      this.toastrService.success(this.translateService.instant('personal.data.updated'));
      this.userStore.dispatch(new UpdatePersonalData(this.personalDataForm.value));
      this.disableForm();
    }, err => {
      console.log(err);
    });
  }

  resetPersonalData(): void {
    this.personalDataForm.reset(this.personalData);
  }

  companyCheck(): void {
    this.companyChecked = this.companyChecked !== true;
  }

  enableForm(): void {
    this.modifyState = true;
    this.personalDataForm.enable();
  }

  disableForm(): void {
    this.modifyState = false;
    this.personalDataForm.reset(this.personalData);
    this.personalDataForm.disable();
  }

  setPhonePrefix(event) {
    if(event.target['value'] == '') {
      event.target['value'] = event.target['value'] + "+36";
    }
  }

  checkPhoneNumber(event) {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
    const tempValue = event.target['value'];
    if (validkeys.indexOf(event.key) < 0) {
      event.target['value'] = tempValue.replace(/[^0-9+]/g, "");
    }
    this.setPhonePrefix(event);
  }
}
