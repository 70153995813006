<div class="modal-header">

  <h4 class="modal-title">Adatfeldolgozási tájékoztató</h4>
  <i class="fa fa-2x fa-times" (click)="cancel()"></i>
</div>

<div class="modal-body modal-info">

  <p> Tudomásul veszem, hogy a(z) Conte Magyarország Kft. (2030 Érd, Földmunkás utca 82.) adatkezelő által a [conte.hu] felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: Név, email cím, telefonszám, számlázási cím.</p>

  <p>Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg: <a href="http://simplepay.hu/vasarlo-aff" target="_blank">http://simplepay.hu/vasarlo-aff</a>.</p>

</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancel()">Bezár</button>
</div>
