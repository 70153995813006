import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-page-sizer',
  templateUrl: './page-sizer.component.html',
  styleUrls: ['./page-sizer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PageSizerComponent),
      multi: true
    }
  ]
})
export class PageSizerComponent implements OnInit, ControlValueAccessor {

  radioModel = '12';

  propagateChange: any = () => {};

  constructor() { }

  ngOnInit(): void {
  }

  changePageSize(value) {
    this.propagateChange(Number(value));
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: number): void {
    if (obj) {
      this.radioModel = obj.toString();
    }
  }
}
