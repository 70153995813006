import { Category } from '../../model/Category';
import { CategoryActions, CategoryActionTypes } from './CategoryActions';

export interface CategoryState {
  categories: Array<Category>;
}

export const initialState: CategoryState = {
  categories: []
};

export function categoryReducer(state: CategoryState = initialState, action: CategoryActions): CategoryState {
  switch (action.type) {
    case CategoryActionTypes.InitCategories:
      return {
        ...state,
        categories: action.categories
      };
  }
  return state;
}

export const initCategories = (state: CategoryState) => state.categories;
