import { Action } from '@ngrx/store';

export enum ContentActionTypes {
  InitContent = 'InitContent'
}

export class InitContent implements Action {
  readonly type = ContentActionTypes.InitContent;

  constructor(public payload: any) {
  }
}

export type ContentActions =
  InitContent;
