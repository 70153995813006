import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameToUrl'
})
export class NameToUrlPipe implements PipeTransform {

  transform(value: string): string {
    return value
      .toLowerCase()
      .trim()
      .replace('á', 'a')
      .replace('é', 'e')
      .replace('í', 'i')
      .replace('ó', 'o')
      .replace('ö', 'o')
      .replace('ő', 'o')
      .replace('ú', 'u')
      .replace('ü', 'u')
      .replace('ű', 'u')
      .replace(/ /g, '');
  }

}
