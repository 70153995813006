import {Component, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TermsInfoModalComponent } from '../../shopping/shopping-info/terms-info-modal.component';
import { DeliveryInfoModalComponent } from '../../shopping/shopping-info/delivery-info-modal.component';
import { WarrantyInfoModalComponent } from '../../shopping/shopping-info/warranty-info-modal.component';
import { RegionalInfoModalComponent } from '../../shopping/shopping-info/regional-info-modal.component';
import { ContactFormModalComponent } from "../../shopping/shopping-info/contact-form.component";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  scrollBottomClass = false;


  @HostListener('window:scroll', ['$event'])

  handleScroll(): void {
    this.scrollBottomClass = window.scrollY > 200;
  }

  modalType: string;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered',
    animated: false
  };

  privacyInfoModalRef: BsModalRef;
  @ViewChild('privacyInfoModal', { static: false })
  privacyInfoModalTemplate: TemplateRef<any>;

  termsInfoModalRef: BsModalRef;
  @ViewChild('termsInfoModal', { static: false })
  termsInfoModalTemplate: TemplateRef<any>;

  deliveryInfoModalRef: BsModalRef;
  @ViewChild('deliveryInfoModal', { static: false })
  deliveryInfoModalTemplate: TemplateRef<any>;

  warrantyInfoModalRef: BsModalRef;
  @ViewChild('warrantyInfoModal', { static: false })
  warrantyInfoModalTemplate: TemplateRef<any>;

  regionInfoModalRef: BsModalRef;
  @ViewChild('warrantyInfoModal', { static: false })
  regionInfoModalTemplate: TemplateRef<any>;

  contactFormModalRef: BsModalRef;
  @ViewChild('contactFormModal', { static: false })
  contactFormModalTemplate: TemplateRef<any>;


  constructor(
    private readonly modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  openTermsInfoModal(): void {
    this.termsInfoModalRef = this.modalService.show(TermsInfoModalComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-lg'
    });
  }

    openDeliveryInfoModal(): void {
      this.deliveryInfoModalRef = this.modalService.show(DeliveryInfoModalComponent, {
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        class: 'modal-shadow modal-lg'
      });
    }

      openWarrantyInfoModal(): void {
        this.warrantyInfoModalRef = this.modalService.show(WarrantyInfoModalComponent, {
          animated: true,
          ignoreBackdropClick: true,
          backdrop: false,
          class: 'modal-shadow modal-lg'
        });
    }

  openRegionalInfoModal(): void {
    this.regionInfoModalRef = this.modalService.show(RegionalInfoModalComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-lg'
    });
  }

  openContactFormModal(): void {
    this.regionInfoModalRef = this.modalService.show(ContactFormModalComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow'
    });
  }

}
