<!--  DESKTOP CARD ------------------------- -->

<div class="card w-100 mb-4" (mouseover)="openSubCats()" *ngIf="deviceService.isDesktop()">
  <img src="{{category.image}}" alt="alt">
  <div class="card-img-overlay d-flex" (mouseleave)="closeSubCats()" >
    <div class="align-self-end w-100" [@toggleSubCats]=currentState>
      <h2 class="card-title" (click)="navigateToCategory()">{{category.name}}</h2>
      <div class="card-text">
        <ul class="list-inline">
          <li *ngFor="let sc of category.children" (click)="navigateToSubcategory(category.link, sc.link)">{{sc.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</div><!-- card END -->


<!--  TABLET CARD ------------------------- -->
<div class="card w-100 mb-4" *ngIf="deviceService.isTablet()">
  <img src="{{category.image}}" alt="alt">
<div class="card-img-overlay d-flex">
  <div class="align-self-end w-100" [@toggleSubCats]=currentState>
    <h2 class="card-title" (click)="openSubCats()" *ngIf="currentState == 'initial'">{{category.name}}</h2>
    <h2 class="card-title" (click)="closeSubCats()" *ngIf="currentState == 'final'">{{category.name}}</h2>
    <div class="card-text">
      <ul class="list-inline">
        <li *ngFor="let sc of category.children" (click)="navigateToSubcategory(category.link, sc.link)">{{sc.name}}</li>
      </ul>
    </div>
  </div>
</div>
</div><!-- card END -->



<!--  MOBILE CARD ------------------------- -->
<div class="card w-100 mb-4" *ngIf="deviceService.isMobile()">
  <img src="{{category.image}}" alt="alt">
  <div class="card-img-overlay d-flex">
    <div class="align-self-end w-100">
      <h2  class="card-title" (click)="navigateToCategory()">{{category.name}}</h2>
    </div>
  </div>
</div><!-- card END -->




<!--SUBCATEGORIES-->
<!--<div class="card card-sub w-100 mb-4">-->
<!--  <img src="{{category.image}}" alt="alt">-->

<!--  <div class="card-img-overlay d-flex">-->

<!--    <div class="align-self-end w-100">-->
<!--      <h2 class="card-title" (click)="navigateToCategory()">{{category.name}}</h2>-->
<!--      <div class="card-text">-->
<!--        <ul class="list-inline">-->
<!--          <li *ngFor="let sc of category.subCategories" (click)="navigateToSubcategory(category.link, sc.link)">{{sc.name}}</li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>&lt;!&ndash; card END &ndash;&gt;-->


