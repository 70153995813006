import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html'
})
export class ContactFormModalComponent implements OnInit {

  token: string|undefined;

  constructor(public modalRef: BsModalRef) {
    this.token = undefined;
  }

  ngOnInit(): void {
  }

  cancelContactFormModal() {
    this.modalRef.hide();
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    console.debug(`Token [${this.token}] generated`);
  }
}
