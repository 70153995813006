import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html'
})
export class BrandListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
