<div class="modal-header">

  <h4 class="modal-title">Szállítási információk</h4>
  <i class="fa fa-2x fa-times" (click)="cancelDeliveryModal()"></i>
</div>

<div class="modal-body modal-info">

  <h2>Csomag átvétele</h2>
  <p>Vásárlóink az alábbi átvételi módok közül választhatnak:</p>
  <ul>
    <li><b>csomag kiszállítása</b> futárszolgálattal a vásárló által megadott címre</li>
    <li><b>csomag kiszállítása</b> futárszolgálattal egy - a vásárló által megadott - átvételi pontra</li>
  </ul>

  <h2>Csomag kiszállítása futárszolgálattal a vásárló által megadott címre vagy átvételi pontra</h2>

  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group heading="Kit várjak, ki fogja a csomagomat kiszállítani?">
      <p>A megrendelt termékek kiszállítását a GLS- Hungary végzi.</p>
    </accordion-group>

    <accordion-group heading="A csomaggal, a szállítással kapcsolatban hogyan tudom felvenni a kapcsolatot a futárszolgálattal?">
      <p>A GLS Hungaryt a csomaggal, kiszállítással kapcsolatos információkért az alábbi telefonszámok valamelyikén érheti el:</p>

      <ul>
        <li>06-29-88-67-00</li>
        <li>+36-20-890-0660</li>
      </ul>
    </accordion-group>
    <accordion-group heading="Mikor kapom meg a megrendelt csomagot?">
      <p>A csomagot a megrendelését beérkezését követö <b>1-3 munkanapon belül</b> fogja megkapni.</p>
      <p>Az itt leírtak az <b>utánvétes</b> és <b>sikeres bankkártyás (paypal)</b> fizetési módra vonatkoznak. A <b>banki utalásos</b> fizetési mód választásakor csak annyiban térünk el, hogy a csomagot akkor adjuk át a GLS-nek, ha a teljes összeg megérkezett a bankszámlánkra.</p>
    </accordion-group>


    <accordion-group heading="Van- e a csomag méretének korlátja?">
      <p>A GLS-Hungary maximum 30kg –ig szállítja ki a csomagokat. Ezért ha az Ön rendelése ezt a súlyhatárt meghaladja, akkor azt csak több csomagban – csomagonként megfizetett szállítási díj ellenében - tudjuk az Ön számára elküldeni.</p>
    </accordion-group>

    <accordion-group heading="Kapok-e visszajelzést a csomagszállításról?">
      <p><b>Igen</b>, sőt két üzenet fog kapni. Az üzeneteket már a GLS fogja elküldeni.<br>
        Miután a GLS átvette tőlünk az Ön csomagját, elküldi az <b>első</b> üzenetet. Az üzenet formája: email.</p>

      <p>Ebben tájékoztat:</p>

      <ul>
        <li>arról, hogy a következő munkanap a csomag kiszállításra kerül</li>
        <li>a <b>csomagszámról</b></li>
        <li>az esetleges utánvét összegéről</li>
      </ul>

      <p>A csomag kiszállításának napján (reggelén) a GLS küld egy újabb emailt, amiben tájékoztat:</p>

      <ul>
        <li>a csomag érkezés várható 3 órás intervallumáról</li>
        <li>valamint tartalmazza a futár telefonszámát.</li>
      </ul>
    </accordion-group>

    <accordion-group heading="Mit kell tudnom a csomag átadás-átvételéről?">
      <p>Az előzetesen megjelölt időszakban a csomag kiszállításra kerül. Az átadás személyesen, családtagnak, vagy adott esetben szomszédnak (amennyiben erre hajlandó) is lehetséges. Utánvétes csomag átadása csak a teljes összeg kiegyenlítését követően történhet meg. Az átvevő aláírásával igazolja a csomag átvételét.</p>
    </accordion-group>

    <accordion-group heading="Mit tegyek, ha nem tudtam átvenni a csomagot?">
      <p>A szomszéd általi átvétel esetén az átadás tényéről egy értesítőt hagy a futár az átvevő postaládájában.</p>

      <p>Amennyiben sem a címzett, sem a családtagja, sem a szomszéd nem tudta átvenni a csomagot, a futár egy értesítőt hagy a postaládában, a csomagot visszaszállítja a depóba és megkisérli a csomag kiszállítását a következő munkanap.</p>

      <p>Ezzel együtt a nap folyamán a GLS email-t küld, amiben tájékoztat az átvételi adatok (hely és idő) módosításának lehetőségéről.</p>

      <p>Az email tartalmaz egy linket, amiben a csomagszám segítségével módosítható at átvétel ideje, illetve helye. Lehetőség van másik cím megadására is, de lehetőség van GLS Csomagpontba irányítani a csomagot. Ennek a megoldásnak az az előnye, hogy annak nyitvatartási idejében a csomag bármikor átvehető.
      </p>

      <p>Az adatok módosításakor az alábbi lehetőségek közül választhat:</p>

      <ul>
        <li>Kézbesítés ugyanarra címre egy másik napon.</li>
        <li>Kézbesítés egy másik címre.</li>
        <li>Kézbesítés munkaidő után 25.00 és 20.00 óra között (csak GLS depóvárosokban).</li>
        <li>Kézbesítés egy kiválasztott GLS CsomagPontba.</li>
        <li>Személyes átvétel egy GLS depóban.Az átvétel visszautasítása.</li>
      </ul>

      <p> Amennyiben nem történik meg az adatok módosítása, úgy a futárszolgálat a következő munkanap  - illetve ha szükséges - harmadik alkalommal is megkisérli a csomag átadást.</p>
    </accordion-group>

    <accordion-group heading="Tudom követni a csomag útját?">
      <p>Igen. Az emailban megküldött csomagazonosító segítségével <a href="https://gls-group.eu/HU/hu/csomagkovetes" target="_blank">ide kattintva</a> megtekinthető a csomagszállítás aktuális állapota.</p>
    </accordion-group>

  </accordion>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelDeliveryModal()">Bezár</button>
</div>
