<ngx-slider
  *ngIf="value && bit"
  [value]="actualValue"
  [(highValue)]="value.max"
  [options]="sliderOptions"
  [manualRefresh]="manualRefresh"
  (userChangeEnd)="userChangeEnd($event)"
  (valueChange)="valueChanged($event)"
  (highValueChange)="highValueChanged($event)"
>
</ngx-slider>
