import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { PropertyState } from './store/property/PropertyReducer';
import { DataExchangeService } from './service/data-exchange.service';
import { UserState } from './store/user/UserReducer';
import { CartState } from './store/cart/CartReducer';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProductState } from './store/product/ProductReducer';
import { slideInAnimation } from './service/route-animation';
import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcInitializeEvent,
  NgcStatusChangeEvent
} from "ngx-cookieconsent";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'minta';
  // cart: Cart;
  // products: any;
  private subscriptions: Array<Subscription> = new Array<Subscription>();


  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(private readonly translateService: TranslateService,
              private readonly dataExchangeService: DataExchangeService,
              private readonly userStore: Store<UserState>,
              private readonly cartStore: Store<CartState>,
              private readonly productStore: Store<ProductState>,
              private readonly activatedRoute: ActivatedRoute,
              private readonly propertyStore: Store<PropertyState>,
              private ccService: NgcCookieConsentService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());

    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  ngOnInit(): void {

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });




    this.translateService.use('hu_HU').subscribe((res) => {
    });

    // const user = localStorage.getItem('currentUser');
    // if (user) {
    //   this.userStore.dispatch(new LoginUser(JSON.parse(user)));
    // }

    // this.subscriptions.push(
    //   this.productStore.select(initCartSelector).subscribe((products) => {
    //     this.products = products;
    //   })
    // );
    //
    // this.subscriptions.push(
    //   this.cartStore.select(initCartSelector).subscribe((cart) => {
    //     this.cart = cart;
    //   })
    // );
    //
    // console.log(this.activatedRoute.snapshot.url);
  }



}
