<div class="modal-header">

  <h4 class="modal-title">Vásárlási információk</h4>
  <i class="fa fa-2x fa-times" (click)="cancelShoppingInfoModal()"></i>
</div>

<div class="modal-body modal-info">


  <h2 class="mt-5">Kedvezmény!</h2>

  <p>Legalább 10 termék megrendelése esetén 10% kedvezményt, legalább 20 termék megrendelése esetén 20%kedvezményt biztosítunk árainkból!</p>

  <p>Ezen kedvezményeket a számla végösszegéből levonjuk, Önnek a kedvezményes összeget kell csak kifizetnie, de a kosár tartalmában a kedvezmény érvényesítése automatikusan nem szerepel.</p>

  <p>A kedvezmények (10%-20%) az ingyenes kiszállítással együtt is igénybe vehetőek.</p>

  <p>Utaláshoz:<br>
    Conte Magyarország Kft.<br>
    Számlavezető Bank neve: Raiffeisen Bank Zrt.<br>
    Számlaszámunk: 12010154-01479301-00100008
  </p>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelShoppingInfoModal()">Bezár</button>
</div>
