import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckoutService } from './checkout.service';
import { CartState } from '../store/cart/CartReducer';
import { Store } from '@ngrx/store';
import { initCartSelector } from '../store/cart/CartSelector';
import { CheckoutProduct } from '../model/CheckoutProduct';

@Injectable({
  providedIn: 'root'
})
export class CheckoutResolver implements Resolve<any> {

  constructor(private readonly checkoutService: CheckoutService,
              private readonly cartStore: Store<CartState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const productsWQuantity: Array<CheckoutProduct> = new Array<CheckoutProduct>();
    this.cartStore.select(initCartSelector).subscribe((cart) => {
      Object.keys(cart).forEach(k => {
        const checkoutProduct = new CheckoutProduct();
        checkoutProduct.productId = Number(k);
        checkoutProduct.quantity = cart[k].amount;
        productsWQuantity.push(checkoutProduct);
      });
    });
    return this.checkoutService.checkout({products: productsWQuantity});
  }

}
