import { Component, OnDestroy, OnInit } from '@angular/core';
import { Model } from '../../../model/Model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductService } from '../../../service/product.service';
import { PaginationInstance } from 'ngx-pagination';
import { Store } from '@ngrx/store';
import { PaginationState } from '../../../store/pagination/PaginationReducer';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { initDedicatedFiltersSelector, initFiltersSelector, initOrderingsSelector } from '../../../store/filter/FilterSelector';
import { FilterState } from '../../../store/filter/FilterReducer';
import { FilterService } from '../../../service/filter.service';
import { Meta, Title } from '@angular/platform-browser';
import { ProductState } from '../../../store/product/ProductReducer';
import { AddProduct } from '../../../store/product/ProductActions';
import { UtilsService } from '../../../service/utils.service';
import { AbstractProductListingComponent } from '../abstract-product-listing/abstract-product-listing.component';
import { Constants } from '../../../model/Constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class ProductListComponent extends AbstractProductListingComponent implements OnInit, OnDestroy {

  pagination: PaginationInstance;

  title: string;
  mainCategory: Category;
  subCategory: Category;
  subSubCategory: Category;

  categoryParam: string;
  subCategoryParam: string;
  subSubCategoryParam: string;

  dedicatedFilter: any[];
  dedicatedFilters: any;
  dedicatedFilterValue: string = 'all';
  selectedDedicatedFilterId: number;

  Constants = Constants;

  trackByIdentity = (index: number, item: any) => item.id;

  constructor(private readonly productService: ProductService,
              private readonly paginationStore: Store<PaginationState>,
              private readonly categoryStore: Store<CategoryState>,
              private readonly filterStore: Store<FilterState>,
              private readonly router: Router,
              private readonly metaService: Meta,
              private readonly metaTitle: Title,
              private readonly translateService: TranslateService,
              readonly productStore: Store<ProductState>,
              readonly filterService: FilterService,
              readonly activatedRoute: ActivatedRoute) {
    super(filterService, productStore, activatedRoute);
  }

  addProductsToStore(products: Array<Model>): void {
    this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(products)));
  }

  ngOnInit(): void {
    // console.log(this.activatedRoute.snapshot.queryParams.query)
    this.query = this.activatedRoute.snapshot.queryParams.query;
    this.isFirstLoading = true;

    this.subscriptions.push(
      this.filterStore.select(initFiltersSelector).subscribe((allFilters) => {
        this.allFilters = allFilters;
      })
    );

    this.subscriptions.push(
      this.activatedRoute.data.subscribe((data) => {
        this.products = data.preload.result;
        this.reInitPagination(data.preload.pagination.totalItems, data.preload.pagination.itemsPerPage, data.preload.pagination.currentPage);
        this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(data.preload.result)));
      })
    );
    this.subscriptions.push(
      this.filterStore.select(initOrderingsSelector).subscribe((orderings) => {
        this.orderings = orderings;
        this.orderBy = this.orderings[0];
      })
    );

    this.subscriptions.push(
      this.filterStore.select(initDedicatedFiltersSelector).subscribe((filters) => {
        this.dedicatedFilters = filters;
      })
    );

    this.subscriptions.push(
      this.categoryStore.select(initCategoriesSelector).subscribe((categories) => {
        this.categories = categories;
      })
    );

    this.products = this.activatedRoute.snapshot.data['preload'].result;
    this.addProductsToStore(this.products);

    this.initPagination();
    this.initCategoriesAndFilters(this.activatedRoute.snapshot.params);

    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        if (this.isFirstLoading) {
          return;
        }
        this.initCategoriesAndFilters(params);
        this.paramsChanged = true;
      })
    );

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (this.activatedRoute.snapshot.queryParams.query) {
          if (this.query !== this.activatedRoute.snapshot.queryParams.query) {
            // TODO reload nélkül újrahúzni
            location.reload();
          }
          this.query = this.activatedRoute.snapshot.queryParams.query;
        }
        if (!this.isFirstLoading) {
          this.queryParamsChanged = true;
        }
        this.updatePagination(params);
        if (this.isFirstLoading) {
          this.isFirstLoading = false;
          return;
        }
        if (Object.keys(params).length > 0) {
          // this.subSubCategoryParam = params['subSubCategory'];
          // this.initSubCategory();
          console.log('QUERY PARAMS CHANGED')
          // TODO in case of searching, call this.productService.searchProducts
          const query = this.title !== 'search' ? {categoryId: this.subSubCategory.id, ...this.getProperties()} : {search: this.activatedRoute.snapshot.queryParams.query, ...this.getProperties()};
          this.productService.getProductsCommonVersion(query, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage},
            this.getOrderingType(),
            this.getPriceRangeQuery()).subscribe((resp) => {
              this.products = resp.result;
              this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
          });
        } else if (!this.paramsChanged && this.subCategoryParam === this.subCategory.link) {
          // this.subSubCategoryParam = params['subSubCategory'];
          this.initSubCategory();
          console.log('NO PARAMS')
          // TODO in case of searching, call this.productService.searchProducts
          const query = this.title !== 'search' ? {categoryId: this.subSubCategory.id} : {search: this.activatedRoute.snapshot.queryParams.query};
          this.productService.getProductsCommonVersion(query, {
            currentPage: this.pagination.currentPage,
            itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
            this.products = resp.result;
            this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
            // this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
          });
        }

        this.paramsChanged = false;
      })
    );

    let metaTitle = this.translateService.instant('nav.' + this.categoryParam) + ' ' + this.translateService.instant('nav.' + this.subCategoryParam).toLowerCase() + ' ' + this.translateService.instant('nav.' + this.subSubCategoryParam);

    if (!this.subSubCategoryParam) {
      metaTitle = this.translateService.instant('nav.' + this.categoryParam) + ' ' + this.translateService.instant('nav.' + this.subCategoryParam).toLowerCase();
    } else if (!this.subCategoryParam) {
      metaTitle = this.translateService.instant('nav.' + this.categoryParam);
    }


    let metaURL = '/' + this.categoryParam + '/' + this.subCategoryParam + '/' + this.subSubCategoryParam;
    if (!this.subSubCategoryParam) {
       metaURL = '/' + this.categoryParam + '/' + this.subCategoryParam;
    } else if (!this.subCategoryParam) {
       metaURL = '/' + this.categoryParam;
    }

    this.metaService.addTags(
      [
        { name: 'description', property: metaTitle + ' a Conte.hu webáruházból'},
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Conte.hu Webshop' },
        { property: 'og:title', content: metaTitle + ' | Conte.hu' },
        { property: 'og:description', content: 'Termék kategória: ' + metaTitle },
        { property: 'og:url', content: metaURL },
        { property: 'og:image', content: '/assets/images/og-image.png' },
        { property: 'og:locale', content: 'hu_HU' }
      ]
    );

    this.metaTitle.setTitle(metaTitle + ' | Conte.hu');

  }

  initSubCategory() {
    this.subCategoryParam = this.activatedRoute.snapshot.params['subCategory'];
    this.subCategory = this.mainCategory.children.find(sc => sc.link === this.subCategoryParam);
    this.subCategory = this.categories.find(c => c.id === this.subCategory.id);
    this.subSubCategoryParam = this.activatedRoute.snapshot.params['subSubCategory'];
    // console.log(this.subCategoryParam)
    this.subSubCategory = this.subCategory.children.find(ssc => ssc.link === this.subSubCategoryParam);
    if (!this.subSubCategory) {
      console.log(this.subCategory)
      console.log(this.subSubCategoryParam)
    }
    this.title = this.subSubCategory.link;
  }

  initSubcategoryAndFilter(): void {
    this.initSubCategory();
    // this.subCategory = this.mainCategory.children.find(sc => sc.link === this.subCategoryParam);
    // this.subCategory = this.categories.find(c => c.id === this.subCategory.id);
    // this.subSubCategory = this.subCategory.children.find(ssc => ssc.link === this.subSubCategoryParam);
    // this.title = this.subSubCategory.link;
    this.initFilter();
    // this.dedicatedFilter = this.dedicatedFilters[this.subCategory.id];
  }

  initFilter() {
    this.filter = this.activatedRoute.snapshot.data.preload.filters;
    this.activeFilter = this.filterService.initActiveFilter(this.filter, this.activatedRoute.snapshot.queryParams);
  }

  initCategoriesAndFilters(params: Params): void {
    this.categoryParam = params['category'];
    this.subCategoryParam = params['subCategory'];
    this.subSubCategoryParam = params['subSubCategory'];
    if (params['subSubCategory']) {
      this.dedicatedFilterValue = params['subSubCategory'];
    }

    if (this.activatedRoute.snapshot.queryParams['rendezes']) {
      this.orderBy = this.orderings.find(o => o.link === this.activatedRoute.snapshot.queryParams['rendezes']);
    }

    this.mainCategory = this.categories.find(c => c.link === this.categoryParam);

    if (this.isFirstLoading) {
      // console.log('FIRST LOADING')
      // this.initSubcategoryAndFilter();
      // if (!(this.categoryParam && this.subCategoryParam)) {
      //   this.title = 'Keresés'
      // }
      if (this.categoryParam && this.subCategoryParam) {
        this.initSubcategoryAndFilter();
      } else {
        // console.log('OKKK')
        this.initFilter();
        this.title = 'search';
      }
      this.queryParamsChanged = false;
      return;
    }

    // if (this.categoryParam && this.subCategoryParam) {
    this.initSubcategoryAndFilter();
    this.updatePagination(params);

    if (!this.queryParamsChanged && !this.isFirstLoading) {
      // console.log('CATEGORY CHANGED')
      // // TODO cancel request if the filters changed in a while
      // this.subscriptions.push(
      //   this.productService.getProductsCommonVersion({
      //     categoryId: this.subSubCategory.id,
      //     dedicatedFilterOption: UtilsService.isNotUndefinedOrNull(this.selectedDedicatedFilterId) ? this.selectedDedicatedFilterId : undefined
      //   }, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
      //     this.products = resp.result;
      //     this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
      //     this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
      //   })
      // );
    } else {
      this.queryParamsChanged = false;
    }
    return;
    // }

    // const manufacturer = params['manufacturer'];
    //
    // if (manufacturer) {
    //   console.log('manufacturer')
    //   this.subscriptions.push(
    //     // this.productService.searchProducts('manufacturer=' + manufacturer).subscribe((products) => {
    //     this.productService.getProductsCommonVersion({
    //       manufacturer: manufacturer,
    //       dedicatedFilterOption: UtilsService.isNotUndefinedOrNull(this.selectedDedicatedFilterId) ? this.selectedDedicatedFilterId : undefined
    //     }, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
    //       this.products = resp.result;
    //       this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
    //       this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
    //     })
    //   );
    //   this.title = 'results';
    //   return;
    // }
  }

  navigate(dedicatedFilterValue = this.dedicatedFilterValue): void {
    if (this.title !== 'search') {
      this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}/${this.subSubCategoryParam.replace('%', '%25')}${this.queryParams}`);
    } else {
      // console.log(this.queryParams)
      this.router.navigateByUrl(`kereses${this.queryParams}`);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
