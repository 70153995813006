import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../model/Address';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { ProfileService } from '../../../service/profile.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { DeleteAddress, ModifyAddress } from '../../../store/user/UserActions';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input()
  address: Address;

  modifyState: boolean;

  addressForm: FormGroup;

  constructor(private readonly formService: FormService,
              private readonly profileService: ProfileService,
              private readonly ngxBootstrapConfirmService: NgxBootstrapConfirmService,
              private readonly translateService: TranslateService,
              private readonly toastrService: ToastrService,
              private readonly userStore: Store<UserState>) {
  }

  ngOnInit(): void {
    this.addressForm = this.formService.getAddressForm(this.address);
    this.addressForm.disable();
  }

  deleteAddress(): void {
    const options = {
      title: this.translateService.instant('sure.want.delete.address'),
      confirmLabel: 'Igen',
      declineLabel: 'Mégsem'
    };
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.profileService.removeAddress(this.address.id).subscribe(() => {
          this.userStore.dispatch(new DeleteAddress(this.address.id));
        });
      }
    });
  }

  saveAddress(): void {
    if (this.addressForm.invalid) {
      return;
    }
    this.profileService.updateAddress(this.addressForm.getRawValue()).subscribe(() => {
      this.modifyState = false;
      this.userStore.dispatch(new ModifyAddress(this.addressForm.getRawValue()));
      this.toastrService.success(this.translateService.instant('address.data.updated'));
    });
  }

  enableForm(): void {
    this.modifyState = true;
    this.addressForm.enable();
  }

  disableForm(): void {
    this.modifyState = false;
    this.addressForm.reset(this.address);
    this.addressForm.disable();
  }

  setPhonePrefix(event) {
    if(event.target['value'] == '') {
      event.target['value'] = event.target['value'] + "+36";
    }
  }

  checkPhoneNumber(event) {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
    const tempValue = event.target['value'];
    if (validkeys.indexOf(event.key) < 0) {
      event.target['value'] = tempValue.replace(/[^0-9+]/g, "");
    }
    this.setPhonePrefix(event);
  }
}
