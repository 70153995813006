import { createFeatureSelector, createSelector } from '@ngrx/store';
import { initFavouriteProducts, initOrders, initProfile, initReviews, initUser, isLoggedIn, UserState } from './UserReducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const initUserSelector = createSelector(
  selectUserState,
  initUser
);

export const initProfileSelector = createSelector(
  selectUserState,
  initProfile
);

export const initOrderSelector = createSelector(
  selectUserState,
  initOrders
);

export const initReviewsSelector = createSelector(
  selectUserState,
  initReviews
);

export const initFavouriteProductsSelector = createSelector(
  selectUserState,
  initFavouriteProducts
);

export const initIsLoggedInSelector = createSelector(
  selectUserState,
  isLoggedIn
);
