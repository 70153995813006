import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})

export class ThousandSeparatorPipe implements PipeTransform {

  transform(nStr: any): any {
    if (nStr === '') { return ''; }
    let x, x1, x2, rgx, summa;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x[1];
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }

    summa =x1 + (x2 ? `.${x2}` : '');
    return summa;
  }
}

