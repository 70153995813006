import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NameToUrlPipe } from './name-to-url.pipe';
import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OutsideClickDirective } from './outside-click.directive';
import { RouterModule } from '@angular/router';
import { ShortensPipe } from './shortens.pipe';
import { SiteNavigationComponent } from './site-navigation/site-navigation.component';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ImgMagnifier } from 'ng-img-magnifier';
import { HideMissingDirective } from './hide-missing-directive.directive';
import { BaseFiltersPipe } from '../../service/base-filters.pipe';
import { SearchPipe } from '../../service/search.pipe';
import { ThousandSeparatorPipe } from '../../service/amounts.pipe';
import { MatDividerModule } from '@angular/material/divider';

const importExport = [
  BrowserAnimationsModule,
  BrowserAnimationsModule,
  BrowserModule,
  ButtonsModule,
  CommonModule,
  FormsModule,
  ImgMagnifier,
  LightboxModule,
  // MatButtonToggleModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatSidenavModule,
  MatStepperModule,
  MatTabsModule,
  NgxBootstrapConfirmModule,
  NgxInputStarRatingModule,
  NgxSliderModule,
  ReactiveFormsModule,
  RouterModule,
  ToastrModule,
  TooltipModule,
  MatDividerModule,
  TranslateModule,
  MatFormFieldModule
];

@NgModule({
  declarations: [
    SiteNavigationComponent,
    OutsideClickDirective,
    NameToUrlPipe,
    ShortensPipe,
    HideMissingDirective,
    BaseFiltersPipe,
    SearchPipe,
    ThousandSeparatorPipe
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    NgxPageScrollCoreModule,
    importExport
  ],
  exports: [
    BsDropdownModule,
    importExport,
    SiteNavigationComponent,
    OutsideClickDirective,
    NameToUrlPipe,
    ShortensPipe,
    HideMissingDirective,
    BaseFiltersPipe,
    SearchPipe,
    ThousandSeparatorPipe
  ],
  providers: [
    BsDropdownDirective,
    ThousandSeparatorPipe
  ]
})
export class SharedModule { }
