import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Model } from '../../../model/Model';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { ProductState } from '../../../store/product/ProductReducer';
import { AddProduct } from '../../../store/product/ProductActions';
import { Json } from '../../../model/Json';
import { Subscription } from 'rxjs';
import { initFavouriteProductsSelector } from '../../../store/user/UserSelector';
import { InitFavouriteProducts } from '../../../store/user/UserActions';

@Component({
  selector: 'app-favourite-products',
  templateUrl: './favourite-products.component.html',
  styleUrls: ['./favourite-products.component.scss']
})
export class FavouriteProductsComponent implements OnInit, OnDestroy {

  favouriteProducts: Array<Model>;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly userStore: Store<UserState>,
              private readonly productStore: Store<ProductState>) {
  }

  ngOnInit(): void {
    this.favouriteProducts = this.activatedRoute.snapshot.data.preload;
    this.userStore.dispatch(new InitFavouriteProducts(this.favouriteProducts.map(fp => fp.id)));
    this.subscriptions.push(
      this.userStore.select(initFavouriteProductsSelector).subscribe((fps) => {
        this.favouriteProducts = this.favouriteProducts.filter(fp => fps.includes(fp.id));
        // if (this.favouriteProducts && this.favouriteProducts.length > 0) {
        //   this.favouriteProducts = this.favouriteProducts.filter(fp => fps.includes(fp.id));
        // }
      })
    );
    const mapped: Json<number, Model> = {};
    this.favouriteProducts.forEach(fp => mapped[fp.id] = fp);
    this.productStore.dispatch(new AddProduct(mapped));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
