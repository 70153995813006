import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appHideMissing]',
})
export class HideMissingDirective {
  constructor(private el: ElementRef) {}

  @HostListener('error')
  private onError(): void {
    this.el.nativeElement.src = '/api/product/not-found.jpeg';
  }
}
