import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Category } from '../../../model/Category';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { CartState } from '../../../store/cart/CartReducer';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { ActivatedRoute, Router } from '@angular/router';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { User } from '../../../model/User';
import { Cart } from '../../../model/Cart';
import { UserState } from '../../../store/user/UserReducer';
import { initUserSelector } from '../../../store/user/UserSelector';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('cartSidenav') cartSidenav: MatSidenav;

  reason = '';

  categories: Array<Category>;

  cart: any;

  user: User;

  close(reason: string): void {
    this.reason = reason;
    // this.sidenav.close();
    this.cartSidenav.close();
  }

  constructor(private readonly categoryStore: Store<CategoryState>,
              private readonly cartStore: Store<CartState>,
              private readonly router: Router,
              private readonly userStore: Store<UserState>,
              private readonly activatedRoute: ActivatedRoute,
              private readonly dataexchangeService: DataExchangeService,
              private readonly modalService: BsModalService) {
  }

  ngOnInit(): void {
    // if (this.activatedRoute.snapshot.url[0]?.path === 'szallitas') {
    //   this.modalService.show(DeliveryInfoModalComponent, {
    //     animated: true,
    //     ignoreBackdropClick: true,
    //     backdrop: false,
    //     class: 'modal-shadow modal-lg'
    //   });
    // }
    this.dataexchangeService.openShoppingCartSidenav.subscribe((state) => {
      if (state) {
        this.cartSidenav.open();
      }
    });
    this.userStore.select(initUserSelector).subscribe((user) => {
      this.user = user;
    });
    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.categories = categories;
    });
    this.cartStore.select(initCartSelector).subscribe((cart: Cart) => {
      this.cart = cart;
      if (this.cartSidenav && Object.keys(cart).length === 0) {
        this.cartSidenav.close();
      }
    });
    this.dataexchangeService.addProduct.subscribe((product) => {
      if (product) {
        this.cartSidenav.open();
      }
    });
  }

  // prepareRoute(outlet: RouterOutlet) {
  //   return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  // }
}
