import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";


@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html'
})
export class DeliveryComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelDeliveryModal() {
    this.modalRef.hide();
    // this.deliveryModalRef.hide();
    // this.deliveryModalRef = null;
  }

}
