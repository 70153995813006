import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Model } from '../../../model/Model';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Router } from '@angular/router';
import { CategoryState } from '../../../store/category/CategoryReducer';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit, OnChanges {

  @Input()
  product: Model;
  @Input()
  link: string;

  minPrice: number;
  maxPrice: number;

  productLink: string;

  ngOnInit(): void {
    this.initLink();
    this.initPrices();
  }

  initPrices(): void {
    this.initMinPrice();
    this.initMaxPrice();
  }

  initLink(): void {
    this.productLink = `${this.link}/${this.product.link}`;
  }

  getLowestPrice(): void {
    return Math.min.apply(Math, this.product.products.map(p => p.price));
  }

  initMinPrice(): void {
    this.minPrice = Math.min.apply(Math, this.product.products.map(p => {
      // if (p.grossPriceCarriage > 0) {
      //   return p.grossPriceCarriage;
      // }
      return p.price;
    }));
  }

  initMaxPrice(): void {
    this.maxPrice = Math.max.apply(Math, this.product.products.map(p => {
      // if (p.grossPriceCarriage > 0) {
      //   return p.grossPriceCarriage;
      // }
      return p.price;
    }));
    // this.maxPrice = Math.max.apply(Math, this.product.variations.map(p => p.grossPrice));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initPrices();
    this.initLink();
  }

  // navigateToProduct() {
  //   this.router.navigateByUrl(`${this.link}/${this.product.articleNumber}`);
  // }
}
