import { Component, OnInit } from '@angular/core';
import { Order } from '../../../model/Order';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-order-details',
  templateUrl: './my-order-details.component.html'
})
export class MyOrderDetailsComponent implements OnInit {

  order: Order;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.order = this.activatedRoute.snapshot.data.preload;
  }
}
