import { CheckoutActions, CheckoutActionTypes } from './CheckoutActions';
import { CheckoutLocalStorage } from '../../model/CheckoutLocalStorage';

export interface CheckoutState {
  checkout: CheckoutLocalStorage;
}

export const initialState: CheckoutState = {
  checkout: null
};

export function checkoutReducer(state: CheckoutState = initialState, action: CheckoutActions): any {
  switch (action.type) {
    case CheckoutActionTypes.InitCheckout:
      return {
        ...state,
        checkout: action.payload
      };
    case CheckoutActionTypes.ResetCheckout:
      return {
        ...state,
        checkout: null
      };
  }
  return state;
}

export const initCheckout = (state: CheckoutState) => state.checkout;
