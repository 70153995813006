import { Action } from '@ngrx/store';
import { Category } from '../../model/Category';

export enum CategoryActionTypes {
  InitCategories = 'Init categories'
}

export class InitCategories implements Action {
  readonly type = CategoryActionTypes.InitCategories;


  constructor(public categories: Array<Category>) {
  }
}


export type CategoryActions =
  InitCategories;
