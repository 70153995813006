import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

export class ProfileNavigation {
  items: Array<ProfileNavItem>;

  constructor(items?: Array<ProfileNavItem>) {
    if (items) {
      this.items = items;
    } else {
      this.items = new Array<ProfileNavItem>();
    }
  }
}

export class ProfileNavItem {
  path: string;
  name: string;

  constructor(path: string, name: string) {
    this.path = path;
    this.name = name;
  }
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  navigation: ProfileNavigation;

  constructor(public router: Router) {

  }

  ngOnInit(): void {
    this.navigation = new ProfileNavigation();
    this.navigation.items = [
      {
        path: 'profil',
        name: 'Saját fiók'
      },
      {
        path: 'rendelesek',
        name: 'Rendelések'
      },
      {
        path: 'ertekelesek',
        name: 'Értékelések'
      }
    ];
  }

}
