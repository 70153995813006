<div class="container mt-5 text-center check-out-container">
  <h3 class="mt-5">
    Sikertelen tranzakció.
  </h3>
  <h4>
    SimplePay tranzakció azonosító: {{transactionId}}
  </h4>
  <p>
    Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása kapcsán kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
  </p>
  <p class="pb-5 mb-5">Megrendelését fogadtuk, de a tranzakció sikertelen, <a href="https://cmsforms.rootor.eu/payment-conte/payment.php?id={{orderId}}">ide</a> kattintva tudja megpróbálni újból a fizetést</p>
  <h2>Rendelés azonosító: <b>{{orderId}}</b></h2>
</div>
