import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Model } from '../../../model/Model';
import { Category } from '../../../model/Category';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { Subscription } from 'rxjs';
import { Json } from '../../../model/Json';
import { UtilsService } from '../../../service/utils.service';
import { TranslateService } from '@ngx-translate/core';

export class CategoryPair {
  name: string;
  link: string;
  // actualName: string;
}

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html'
})
export class SearchResultComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  products: Array<Model>;

  @Input()
  categories: Array<Category>;

  @Input()
  manufacturers: string[];

  @Input()
  query: string;

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  lastQuery: string;

  fullCategories: Json<string, string>;

  allCategories: Json<number, Category>;
  allCategories2: Array<Category>;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  tree: Array<Category>;
  thirdLevelCategories: Array<any>;
  thirdLevelCategories2: Array<any>;
  topLevelCategoriesString: Array<string>;

  // nest = (items, id = null, link = 'parentId') =>
  //   items
  //     .filter(item => item[link] === id)
  //     .map(item => ({ ...item, children: this.nest(items, item.id) }))

  constructor(private readonly categoryStore: Store<CategoryState>,
              private readonly translateService: TranslateService) {
  }

  buildTree(items: Array<Category>, id = null, link = 'parentId'): Array<Category> {
    return items
      .filter(item => item[link] === id)
      .map(item => ({ ...item, children: this.buildTree(items, item.id) }));
  }

  ngOnInit(): void {
    this.initCategories(this.categories);
  }


  initAllCategories(): void {
    this.subscriptions.push(
      this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
        this.allCategories = new Json<number, Category>(categories, 'id');
        this.allCategories2 = categories;
        // console.log(categories)
        this.tree = UtilsService.nest(categories);
        const pairs: Array<CategoryPair> = new Array<CategoryPair>();
        this.walkCategoriesTree(pairs, this.allCategories, this.tree[0].children);
        this.thirdLevelCategories2 = pairs;
        // UtilsService.nest2(this.tree)
        // this.tree = this.buildTree(categories);
        // this.thirdLevelCategories = categories.filter(c => c.fullPath.split('/').length === 4).map(c => {
        //   const cp = this.initCategory(c);
        //   return {
        //     ...c,
        //     link: cp.link,
        //     name: cp.name
        //   };
        // });
        // this.topLevelCategoriesString = categories.filter(c => c.fullPath.split('/').length === 4).map(c => this.translateService.instant('nav.' + c.link));
        // console.log(this.topLevelCategoriesString);
        // this.topLevelCategoriesString = this.thirdLevelCategories.map(c => c.link);
        // console.log(this.topLevelCategoriesString);
      })
    );
  }

  walkCategoriesTree(categoryPairs: Array<CategoryPair>, categoriesJson: Json<number, Category>, items: Array<Category>, parentIds?: Array<number>): void {
    items.forEach(i => {
      const actualParentIds: Array<number> = [];
      if (parentIds) {
        parentIds.forEach(p => actualParentIds.push(p));
      }
      // else {
      //   // parentIds = [i.id];
      //   actualParentIds.push(i.id);
      // }
      actualParentIds.push(i.id);
      if (i.children.length > 0) {
        this.walkCategoriesTree(categoryPairs, categoriesJson, i.children, actualParentIds);
      } else {
        let name = '';
        let link = '';
        actualParentIds.forEach((pi, index) => {
          name += this.translateService.instant('nav.' + categoriesJson[pi].link);
          link += categoriesJson[pi].link;
          if ((index + 1) !== actualParentIds.length) {
            name += '/';
            link += '/';
          }
        });
        categoryPairs.push({
          name,
          link
        });
        // console.log(result)
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories && this.allCategories) {
      this.initCategories(changes.categories.currentValue);
    }

    if (changes.query) {
      if (!this.allCategories2) {
        this.initAllCategories();
      }
      if (changes.query.currentValue.length !== 0) {
        this.lastQuery = changes.query.currentValue;
      }
      // this.initCategories2(changes.query.currentValue);
    }
  }

  // initCategories2(query: string) {
  //   this.thirdLevelCategories = this.allCategories2.filter(c => c.fullPath.split('/').length === 4).map(c => {
  //     const cp = this.initCategory(c);
  //     return {
  //       ...c,
  //       link: cp.link,
  //       name: cp.name
  //     };
  //   });
  // }

  initCategories(categories: Array<Category>): void {
    // this.fullCategories = new Json<string, string>();
    // categories.forEach(c => {
    //   const cp = this.initCategory(c, {link: c.link, name: c.name});
    //   this.fullCategories[cp.link] = cp.name;
    // });
  }

  initCategory(category: Category, pair?: CategoryPair): CategoryPair {
    const op = pair ? pair : new CategoryPair();
    if (UtilsService.isNotUndefinedOrNull(category.parentId) && category.parentId !== 1) {
      // op.name = this.translateService.instant('nav.' + this.allCategories[category.parentId].link.toLowerCase()) + '/' + (UtilsService.isNotUndefinedOrNull(pair) ? this.translateService.instant('nav.' + pair.actualName.toLowerCase()) : this.translateService.instant('nav.' + category.link.toLowerCase()));
      // console.log(pair)
      // op.name = this.translateService.instant('nav.' + (UtilsService.isNotUndefinedOrNull(pair) ? pair.name.toLowerCase() : category.name.toLowerCase()));
      // console.log(op.name);
      op.link = this.allCategories[category.parentId].link + '/' + (UtilsService.isNotUndefinedOrNull(pair) ? pair.link : category.link);
      // op.actualName = (UtilsService.isNotUndefinedOrNull(pair) ? pair.link : category.link);
      this.initCategory(this.allCategories[category.parentId], op);
      // this.initCategory(this.allCategories[category.parentId], {
      //   name: this.allCategories[category.id].name + '/' + pair.name,
      //   link: this.allCategories[category.id].link + '/' + pair.link
      // });
    } else {
      // op.name = this.translateService.instant()
    }
    // cp.link = UtilsService.isNotUndefinedOrNull(category.parentId) ? this.allCategories[category.id].link + '/' + pair.link : pair.link;
    // cp.name = UtilsService.isNotUndefinedOrNull(category.parentId) ? this.allCategories[category.id].name + '/' + pair.name : pair.name;
    return op;
    // let finalLink = UtilsService.isNotUndefinedOrNull(category.parentId) ? this.allCategories[category.id].link + '/' + link : link;
    // return finalLink;
    // if (UtilsService.isNotUndefinedOrNull(category.parentId)) {
    //   // const finalLink = this.initCategory(this.allCategories) + '/' + link;
    //   this.initCategory(category, this.allCategories[category.parentId] + '/' + link);
    // } else {
    //   return link;
    // }
  }

  // nestCategories(categories: Array<Category>) {
  //   const result: Array<CategoryPair> = new Array<CategoryPair>();
  //   for (const c of categories) {
  //
  //     if (c.children.length > 0) {
  //     }
  //   }
  // }

  // nestCategory(category: Category, pair: CategoryPair): Array<CategoryPair> {
  //
  // }

  // nestCategory(categories: Array<Category>, pair?: CategoryPair): Array<CategoryPair> {
  //   const result =
  //   for (const c of categories) {
  //     const
  //     if (c.children.length > 0) {
  //
  //     }
  //   }
  // }

  closeEvent() {
    this.close.emit();
  }
}
