<div class="list-group">
  <span class="list-group-item active">
    <i *ngIf="category.link == 'women'" class="fa fa-2x fa-female"></i>
    <i *ngIf="category.link == 'men'" class="fa fa-2x fa-male"></i>
    <i *ngIf="category.link == 'kids'" class="fa fa-2x fa-kid"></i>

    {{'nav.' + category.link | translate}}</span>
  <ng-container *ngFor="let sc of category.children">
    <a *ngIf="sc.link !== activeSubcategory" [routerLink]="'/' + category.link + '/' + sc.link" class="list-group-item list-group-item-action" matRipple [matRippleUnbounded]="false"
       [matRippleCentered]="true">{{'nav.' + sc.link | translate}}</a>
    <span *ngIf="sc.link === activeSubcategory" class="list-group-item disabled">{{'nav.' + sc.link | translate}}</span>
  </ng-container>
</div>
