import { PaginationActions, PaginationActionTypes } from './PaginationActions';
import { PaginationInstance } from 'ngx-pagination';


export interface PaginationState {
  pagination: PaginationInstance;
}

export const initialState: PaginationState = {
  pagination: {
    currentPage: 1,
    itemsPerPage: 10
  }
};

export function paginationReducer(state: PaginationState = initialState, action: PaginationActions): PaginationState {
  switch (action.type) {
    case PaginationActionTypes.InitPagination:
      return {
        ...state,
        pagination: action.paginationInstance
      };
  }
  return state;
}

export const initPagination = (state: PaginationState) => state.pagination;
