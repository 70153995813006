import { ProductActions, ProductActionTypes } from './ProductActions';
import { ProductKey } from '../../model/ProductKey';

export interface ProductState {
  products: ProductKey;
}

export const initialState: ProductState = {
  products: new ProductKey([])
};

export function productReducer(state: ProductState = initialState, action: ProductActions): ProductState {
  switch (action.type) {
    case ProductActionTypes.LoadProducts:
      return {
        ...state,
        products: action.payload
      };
    case ProductActionTypes.AddProducts:
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload
        }
      };
    case ProductActionTypes.AddReview:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.modelId]: {
            ...state.products[action.payload.modelId],
            reviews: [
              action.payload,
              ...state.products[action.payload.modelId].reviews
            ]
          }
        }
      };
  }
  return state;
}

export const initProducts = (state: ProductState) => state.products;
