import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Subscription } from 'rxjs';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ChangeAmountOfProduct, RemoveProductFromCart } from '../../../store/cart/CartActions';
import { ProductKey } from '../../../model/ProductKey';
import { ProductState } from '../../../store/product/ProductReducer';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { Cart } from '../../../model/Cart';
import { CartService } from '../../../service/cart.service';
import { ProductService } from '../../../service/product.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ProductInCart } from '../../../model/ProductInCart';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Product } from '../../../model/Product';
import { Json } from '../../../model/Json';
import { UtilsService } from '../../../service/utils.service';
import { initWebshopSettings } from '../../../store/settings/WebshopSettingsSelector';
import { WebshopSettingsState } from '../../../store/settings/WebshopSettingsReducer';
import { WebshopSettings } from '../../../model/WebshopSettings';
import { User } from '../../../model/User';
import { UserState } from '../../../store/user/UserReducer';
import { initUserSelector } from '../../../store/user/UserSelector';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html'
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

  @Input()
  sidenav: MatSidenav;
  @Input()
  cartSidenav: MatSidenav;
  cart: Cart;

  productsInCart: Array<ProductInCart>;

  @Output()
  closeCartEvent: EventEmitter<void> = new EventEmitter<void>();

  reason = '';

  models: ProductKey;
  productsById: Json<number, Product> = new Json<number, Product>();

  numberOfProducts: number;
  settings: WebshopSettings;
  subTotal: number = 0;
  discount: number = 0;
  isShippingFree: boolean;
  user: User;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(protected readonly cartStore: Store<CartState>,
              protected readonly categoryStore: Store<CategoryState>,
              protected readonly userStore: Store<UserState>,
              protected readonly productStore: Store<ProductState>,
              private readonly webshopSettingsStore: Store<WebshopSettingsState>,
              protected readonly cartService: CartService,
              protected readonly productService: ProductService,
              protected readonly router: Router,
              private readonly toastrService: ToastrService,
              protected readonly dataexchangeService: DataExchangeService) {
  }

  close(reason: string): void {
    this.reason = reason;
    this.sidenav.close();
    this.cartSidenav.close();
  }

  reinitProductsInCart(): void {
    for (const k of Object.keys(this.cart)) {
      const product = this.models[this.cart[k].parentProductId].products.find(pv => pv.id === Number(k));
      if (!this.productsById[product.id]) {
        if (this.models[k]) {
          // main product, van ilyen?
          this.productsById[this.models[k].id] = this.models[k];
        } else {
          // variation
          this.productsById[product.id] = product;
        }
      }
    }
    this.productsInCart = Object.values(this.cart);
    this.calculateSubTotal();
  }

  calculateSubTotal(): void {
    this.subTotal = UtilsService.calculateSubTotal(this.productsInCart, this.productsById, this.user?.discount);
    if (this.user?.discount) {
      this.discount = UtilsService.calculateDiscount(this.subTotal, this.productsInCart, this.productsById, this.user.discount);
    }
    this.isShippingFree = this.subTotal >= this.settings.freeShippingPrice;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userStore.select(initUserSelector).subscribe((user) => {
        this.user = user;
        if (this.productsInCart) {
          this.calculateSubTotal();
        }
      })
    );
    this.subscriptions.push(
      this.webshopSettingsStore.select(initWebshopSettings).subscribe((settings) => {
        this.settings = settings;
      })
    );
    this.subscriptions.push(
      this.productStore.select(initProductsSelector).subscribe((models) => {
        this.models = models;
        // need to update the count in products, except on the first init
        if (this.cart) {
          this.initProducts();
        }
      })
    );

    this.subscriptions.push(
      this.cartStore.select(initCartSelector).subscribe((cart) => {
        this.cart = cart;
        this.productsInCart = Object.values(this.cart);
        this.reinitProductsInCart();
      })
    );

    this.initProducts();

    this.subscriptions.push(
      this.dataexchangeService.numberOfProductsInCart.subscribe((value) => {
        this.numberOfProducts = value;
      })
    );
  }

  initProducts(): void {
    const missingProducts: Array<number> = new Array<number>();
    Object.keys(this.cart).forEach((k) => {
      if (!this.models[this.cart[k].parentProductId]) {
        missingProducts.push(this.cart[k].productId);
      } else {
        const variation = this.models[this.cart[k].parentProductId].products.find(pv => pv.id === Number(k));
        if (!variation) {
          this.removeProductFromCart(Number(k));
        } else {
          this.productsById[variation.id] = variation;
        }
      }
    });
    if (missingProducts.length > 0) {
      missingProducts.forEach(mp => {
        this.removeProductFromCart(mp);
      });
      this.toastrService.warning(missingProducts.length + ' terméket eltávolítottunk a kosaradból, mert jelenleg nem kapható' + (missingProducts.length > 1 ? 'k.' : '.'));
      // this.subscriptions.push(
      //   // this.productService.getProductsByIds(missingProducts).subscribe((products: Array<Product>) => {
      //   this.productService.getMissingProducts(missingProducts).subscribe((models: Array<Model>) => {
      //     const payload = new Json(models, 'id');
      //     this.productStore.dispatch(new AddProduct(payload));
      //   })
      // );
    }
  }

  navigateToCheckout(): void {
    this.cartSidenav.close();
    this.router.navigateByUrl('penztar');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  removeProductFromCart(productId: number): void {
    this.cartStore.dispatch(new RemoveProductFromCart(productId));
    if (this.user) {
      this.cartService.updateAmountOfProduct(productId, 0).subscribe();
    }
  }

  increaseAmount(productId: number): void {
    const amount = this.cart[productId].amount + 1;
    this.changeAmount(productId, amount);
    // if (this.user) {
    //   this.cartService.updateAmountOfProduct(productId, amount).subscribe();
    // }
  }

  decreaseAmount(productId: number): void {
    const amount = this.cart[productId].amount - 1;
    this.changeAmount(productId, amount);
    // if (this.user) {
    //   this.cartService.updateAmountOfProduct(productId, amount).subscribe();
    // }
  }

  changeAmount(productId: number, amount: number): void {
    if (this.user) {
      this.cartService.updateAmountOfProduct(productId, amount).subscribe(() => {
        this.cartStore.dispatch(new ChangeAmountOfProduct(productId, amount));
      });
    } else {
      this.cartStore.dispatch(new ChangeAmountOfProduct(productId, amount));
    }
  }
}
