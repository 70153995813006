import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { productReducer } from './store/product/ProductReducer';
import { CoreModule } from './modules/core/core.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CategoryService } from './service/category.service';
import { categoryReducer } from './store/category/CategoryReducer';
import { paginationReducer } from './store/pagination/PaginationReducer';
import { cartReducer } from './store/cart/CartReducer';
import { userReducer } from './store/user/UserReducer';
import { ApplicationService } from './service/application.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { propertyReducer } from './store/property/PropertyReducer';
import { filterReducer } from './store/filter/FilterReducer';
import { RouteReuseStrategy } from '@angular/router';
import { ReuseStrategyService } from './service/reuse.strategy.service';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './modules/shared/shared.module';
import { localStorageSync } from 'ngrx-store-localstorage';
import { RootState } from './model/RootState';
import { contentReducer } from './store/content/ContentReducer';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ProgressInterceptor } from './service/progress.spinner.service';
import { SessionExpiredInterceptor } from './service/session-expired.interceptor';
import { HttpErrorInterceptorService } from './service/http-error-interceptor.service';
import { webshopSettingsReducer } from './store/settings/WebshopSettingsReducer';
import { checkoutReducer } from './store/checkout/CheckoutReducer';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgxMaskModule } from 'ngx-mask';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';


export function initApplicationFactory(applicationService: ApplicationService) {
  return async (): Promise<void> => await applicationService.initApplication();
}

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const reducers: ActionReducerMap<RootState> = {
  productReducer,
  propertyReducer,
  filterReducer,
  categoryReducer,
  cartReducer,
  userReducer,
  paginationReducer,
  checkoutReducer
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'conte.hu', // domain: 'localhost' // or 'your.domain.com'
    expiryDays: 365
  },
  palette: {
    popup: {
      background: '#2A2A2A'
    },
    button: {
      background: '#52D2A4'
    }
  },
  theme: 'classic',   // edgeless
  position: "bottom-left",
  type: 'opt-in',
  // onStatusChange: function(status) {
  //   console.log(this.hasConsented() ?
  //     'enable cookies' : 'disable cookies');
  // },

  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
      <a aria-label="Tudj meg többet az Adatvédelmi nyilatkozatunkról" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}">{{privacyPolicyLink}}</a>
    </span>
    `,
  },

  content: {
    header: "A weboldal sütiket használ!",
    message: "A Conte.hu weboldal sütiket használ, hogy a legjobb felhasználói élményt nyújthassuk Neked.",
    dismiss: "Elvet",
    allow: "A sütik engedélyezése",
    deny: "Elutasítás",
    policy: "Adatvédelem",
    privacyPolicyLink: 'Tudj meg többet!',
    privacyPolicyHref: '/privacy'
  },
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['product', 'property', 'filter', 'category', 'cart', 'user', 'content', 'pagination', 'checkout'],
    // TODO
    // keys: [
    //   {
    //     product: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     property: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     filter: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     category: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     cart: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     user: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     pagination: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   }
    // ],
    rehydrate: true,
    storageKeySerializer: key => {
      return key;
    }
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxPaginationModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    StoreModule.forFeature('product', productReducer),
    StoreModule.forFeature('property', propertyReducer),
    StoreModule.forFeature('filter', filterReducer),
    StoreModule.forFeature('category', categoryReducer),
    StoreModule.forFeature('cart', cartReducer),
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('content', contentReducer),
    StoreModule.forFeature('pagination', paginationReducer),
    StoreModule.forFeature('webshopSettings', webshopSettingsReducer),
    StoreModule.forFeature('checkout', checkoutReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25 // Retains last 25 states
      // logOnly: environment.production, // Restrict extension to log-only mode
    }),
    HttpClientModule,
    FormsModule,
    MatExpansionModule,
    MatSidenavModule,
    SharedModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'hu_HU',
      useDefaultLang: true
    }),
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.GApropertyID),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplicationFactory,
      deps: [ApplicationService, CategoryService],
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: ReuseStrategyService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      multi: true,
      deps: [NgxSpinnerService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
