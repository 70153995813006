import { Component, OnDestroy, OnInit } from '@angular/core';
import { Company } from '../../../model/Company';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { CompanyService } from '../../../service/company.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { UpdateCompanyData } from '../../../store/user/UserActions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { initProfileSelector } from '../../../store/user/UserSelector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit, OnDestroy {

  company: Company;
  modifyState: boolean;

  companyForm: FormGroup;
  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly formService: FormService,
              private readonly companyService: CompanyService,
              private readonly toastrService: ToastrService,
              private readonly translateService: TranslateService,
              private readonly userStore: Store<UserState>) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userStore.select(initProfileSelector).subscribe((profile) => {
        this.company = profile.company;
      })
    );
    this.companyForm = this.formService.getCompanyForm(this.company);
    this.companyForm.disable();
  }

  save(): void {
    this.companyService.updateCompany(this.companyForm.value).subscribe(() => {
      this.userStore.dispatch(new UpdateCompanyData(this.companyForm.value));
      this.toastrService.success(this.translateService.instant('company.data.updated'));
      this.disableForm();
    }, err => {
      console.log(err);
    });
  }

  enableForm(): void {
    this.modifyState = true;
    this.companyForm.enable();
  }

  disableForm(): void {
    this.modifyState = false;
    this.companyForm.reset(this.company);
    this.companyForm.disable();
  }

  setPhonePrefix(event) {
    if(event.target['value'] == '') {
      event.target['value'] = event.target['value'] + "+36";
    }
  }

  checkPhoneNumber(event) {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
    const tempValue = event.target['value'];
    if (validkeys.indexOf(event.key) < 0) {
      event.target['value'] = tempValue.replace(/[^0-9+]/g, "");
    }
    this.setPhonePrefix(event);
  }
}
