import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<any> {

  constructor(private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.userService.getProfile();
    // return of(ProfileResolver.getTestProfile())
  }

  // private static getTestProfile(): Profile {
  //   return {
  //     personalData: {
  //       name: 'name',
  //       email: 'email',
  //       phoneNumber: '301234567'
  //     },
  //     company: {
  //       name: 'halmania',
  //       taxNumber: 'aksndjbfiuwehrf83',
  //       address: {
  //         id: 27,
  //         country: 'HU',
  //         streetAddress: 'Address',
  //         city: 'Miskolc',
  //         postalCode: 3525,
  //         email: 'email'
  //       },
  //     },
  //     addresses: [
  //       {
  //         id: 23,
  //         country: 'HU',
  //         streetAddress: 'Address',
  //         city: 'Miskolc',
  //         postalCode: 3525,
  //         email: 'email'
  //       },
  //       {
  //         id: 22,
  //         country: 'HU',
  //         streetAddress: 'Address',
  //         city: 'Ózd',
  //         postalCode: 3600,
  //         email: 'email'
  //       }
  //     ]
  //   }
  // }
}
