import { Action } from '@ngrx/store';
import { WebshopSettings } from '../../model/WebshopSettings';

export enum WebshopSettingsActionTypes {
  InitWebshopSettings = 'InitWebshopSettings'
}

export class InitWebshopSettings implements Action {
  readonly type = WebshopSettingsActionTypes.InitWebshopSettings;

  constructor(public payload: WebshopSettings) {
  }
}

export type WebshopSettingsActions =
  InitWebshopSettings;
