export enum OrderStatus {
  ORDERED = 'ORDERED',
  PAID = 'PAID',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
  UNDER_SHIPPING = 'UNDER_SHIPPING',
  SHIPPED = 'SHIPPED',
  STORNO = 'STORNO',
  DURING_RETURN = 'DURING_RETURN',
  RETURNED = 'RETURNED',
  RETURNED_AND_REPAID = 'RETURNED_AND_REPAID'
}
