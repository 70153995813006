import { Component, Input, OnInit } from '@angular/core';
import { Category } from '../../../model/Category';

@Component({
  selector: 'app-sub-categories-list',
  templateUrl: './sub-categories-list.component.html',
  styleUrls: ['./sub-categories-list.component.scss']
})
export class SubCategoriesListComponent implements OnInit {

  @Input()
  category: Category;

  @Input()
  activeSubcategory: string;

  constructor() { }

  ngOnInit(): void {
  }
}
