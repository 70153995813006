import { ContentActions, ContentActionTypes } from './ContentActions';
import { CarouselSchema } from '../../model/CarouselSchema';
import { AdsSchema } from '../../model/AdsSchema';

export interface ContentState {
  carousel: Array<CarouselSchema>;
  ads: AdsSchema;
}

export const initialState: ContentState = {
  carousel: null,
  ads: null

};

export function contentReducer(state: ContentState = initialState, action: ContentActions): ContentState {
  switch (action.type) {
    case ContentActionTypes.InitContent: {
      return {
        carousel: action.payload.carousel,
        ads: action.payload.ads
      };
    }
  }
  return state;
}

export const initCarousel = (state: ContentState) => state.carousel;
export const initAds = (state: ContentState) => state.ads;
// export const initRules = (state: ContentState) => state.rules;

