import { Injectable } from '@angular/core';
import { ActiveFilter } from '../model/ActiveFilter';
import { Filter } from '../model/Filter';
import { FilterPropertyState } from '../model/FilterPropertyState';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public static baseProperties = ['priceRange', 'categoryId'];

  DEFAULT_PAGE_SIZE: number = 12;
  DEFAULT_ORDER: string = 'nepszeru';

  constructor() {
  }

  initActiveFilter(filter: Filter, queryParams?: Params): ActiveFilter {
    const activeFilter = new ActiveFilter();

    // Object.keys(filter).filter(k => !FilterService.baseProperties.includes(k)).forEach(k => {
    //   activeFilter[k] = new Array<FilterPropertyState>();
    //   filter[k].forEach((p: any) => {
    //     let query = '';
    //     if (typeof p === 'string') {
    //       query = p;
    //     } else {
    //       switch (k) {
    //         case 'brands': query = p.id; break;
    //         case 'colors': query = p.icon;
    //       }
    //     }
    //
    //     activeFilter[k].push(new FilterPropertyState(typeof p === 'string' ? p : p.name, false, query, typeof p === 'string' ? undefined : p))
    //   });
    // });


    Object.keys(filter).filter(k => !FilterService.baseProperties.includes(k)).forEach(k => {
      activeFilter[k] = new Array<FilterPropertyState>();
      filter[k].forEach((p: any) => activeFilter[k].push(new FilterPropertyState(typeof p === 'string' ? p : p.name, false, typeof p === 'string' ? undefined : p)));
    });

    if (queryParams && queryParams['ar']) {
      const priceRange = queryParams['ar'].split('-');
      // TODO validations
      const minPrice = Number(priceRange[0]);
      const maxPrice = Number(priceRange[1]);
      this.initPriceRange(activeFilter, minPrice, maxPrice);
    } else {
      this.initPriceRange(activeFilter, filter.priceRange.min, filter.priceRange.max);
    }

    if (queryParams) {
      Object.keys(queryParams).forEach(p => {
        if (p === 'ar') {
          return;
        }
        const values = queryParams[p].split(',');
        values.forEach(v => {
          if (!activeFilter[p]) {
            return;
          }
          const property = activeFilter[p].find(param => param.label === v);
          if (property) {
            property.isChecked = true;
          }
        });
      });
    }

    return activeFilter;
  }

  getFilterIsEmpty(activeFilter: ActiveFilter, filter: Filter): boolean {
    if (activeFilter.priceRange.min !== filter.priceRange.min ||
      activeFilter.priceRange.max !== filter.priceRange.max ||
      Object.keys(activeFilter).filter(k => !FilterService.baseProperties.includes(k)).some(k => activeFilter[k].some(prop => prop.isChecked))) {
      return false;
    } else {
      return true;
    }
  }

  initPriceRange(activeFilter: ActiveFilter, min: number, max: number): void {
    activeFilter.priceRange.min = min;
    activeFilter.priceRange.max = max;
  }

  clearFilters(activeFilter: ActiveFilter): void {
    Object.keys(activeFilter).filter(k => !FilterService.baseProperties.includes(k)).forEach(k => {
      activeFilter[k].forEach(p => {
        p.isChecked = false;
      });
    });
  }

  getQueryParams(filter: Filter, activeFilter: ActiveFilter, orderBy: string, currentPage: number, itemsPerPage: number, query?: string): string {
    const properties = {};
    Object.keys(activeFilter).filter(k => !FilterService.baseProperties.includes(k)).forEach(k => {
      const props = [];
      activeFilter[k].forEach(f => {
        if (f.isChecked) {
          if (k === 'colors') {
            props.push(f.data.icon);
          } else {
            props.push(f.label);
          }
        }
      });
      if (props.length > 0) {
        properties[k] = props;
      }
    });
    const propKeys = Object.keys(properties);
    const isPriceQueried = filter.priceRange.min !== activeFilter.priceRange.min || filter.priceRange.max !== activeFilter.priceRange.max;
    const isPageDefault = itemsPerPage === this.DEFAULT_PAGE_SIZE && currentPage === 1;
    // let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
    let queryParam;

    if (isPriceQueried && isPageDefault) {
      queryParam = `?ar=${activeFilter.priceRange.min}-${activeFilter.priceRange.max}`;
    } else if (isPriceQueried && !isPageDefault) {
      queryParam = `${currentPage === 1 ? '' : `?oldal=${currentPage}`}${itemsPerPage !== this.DEFAULT_PAGE_SIZE ? (currentPage === 1 ? `?oldal_meret=${itemsPerPage}` : `&oldal_meret=${itemsPerPage}`) : '' } &ar=${activeFilter.priceRange.min}-${activeFilter.priceRange.max}`;
    } else if (!isPriceQueried && isPageDefault) {
      queryParam = '';
    } else if (!isPriceQueried && !isPageDefault) {
      queryParam = `${currentPage === 1 ? '' : `?oldal=${currentPage}`}${itemsPerPage !== this.DEFAULT_PAGE_SIZE ? (currentPage === 1 ? `?oldal_meret=${itemsPerPage}` : `&oldal_meret=${itemsPerPage}`) : '' }`;
    }

    if (propKeys.length > 0) {
      propKeys.forEach((pk, index) => {
        if ((!isPriceQueried && isPageDefault) && index === 0) {
          queryParam += '?';
        } else {
          queryParam += '&';
        }
        queryParam += pk + '=' + properties[pk].toString().split(',').join('%2c');
      });
    }

    if (orderBy && orderBy !== this.DEFAULT_ORDER) {
      if (queryParam.length > 0) {
        queryParam += `&rendezes=${orderBy}`;
      } else {
        queryParam += `?rendezes=${orderBy}`;
      }
    }

    if (query) {
      if (queryParam.length > 0) {
        queryParam += `&query=${query}`;
      } else {
        queryParam += `?query=${query}`;
      }
    }

    return queryParam;
  }
}
