import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-unsuccessful',
  templateUrl: './order-unsuccessful.component.html',
  styleUrls: ['./order-unsuccessful.component.scss']
})
export class OrderUnsuccessfulComponent implements OnInit {

  orderId: number;
  transactionId: string;

  constructor(private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    // console.log(this.activatedRoute.queryParams)
    this.orderId = this.activatedRoute.snapshot.queryParams.orderId;
    this.transactionId = this.activatedRoute.snapshot.queryParams.transactionId;
  }

}
