import { Component, OnInit } from '@angular/core';
import { FormService } from '../../../service/form.service';
import { FormGroup } from '@angular/forms';
import { RegisterService } from '../../../service/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form: FormGroup;

  selectedCountry: string = 'hu';

  constructor(private readonly formService: FormService,
              private registerService: RegisterService) {
  }

  ngOnInit(): void {
    this.form = this.formService.getRegistrationForm();
  }

  register() {
    this.registerService.register(this.form.value).subscribe((resp) => {
      console.log(resp);
    }, err => {
      console.log(err);
    })
  }
}
