import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../../../service/form.service';
import { Subscription } from 'rxjs';
import { PaymentService } from '../../../service/payment.service';
import { DOCUMENT } from '@angular/common';
import { LoginComponent } from '../../user/login/login.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserState } from '../../../store/user/UserReducer';
import { initProfileSelector, initUserSelector } from '../../../store/user/UserSelector';
import { User } from '../../../model/User';
import { CheckoutService } from '../../../service/checkout.service';
import { CheckoutProduct } from '../../../model/CheckoutProduct';
import { CheckoutShoppingCartComponent } from '../checkout-shopping-cart/checkout-shopping-cart.component';
import { OrderService } from '../../../service/order.service';
import { CreateOrder } from '../../../model/CreateOrder';
import { Cart } from '../../../model/Cart';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PaymentType } from '../../../model/PaymentType';
import { Address } from '../../../model/Address';
import { UserService } from '../../../service/user.service';
import { AddAddress, InitProfile, UpdateCompanyData } from '../../../store/user/UserActions';
import { Profile } from '../../../model/Profile';
import { AddNewAddressComponent } from '../../user/add-new-address/add-new-address.component';
import { CompanyService } from '../../../service/company.service';
import { MatStepper } from '@angular/material/stepper';
import { EmptyCart } from '../../../store/cart/CartActions';
import { WebshopSettings } from '../../../model/WebshopSettings';
import { WebshopSettingsState } from '../../../store/settings/WebshopSettingsReducer';
import { initWebshopSettings } from '../../../store/settings/WebshopSettingsSelector';
import { CartService } from '../../../service/cart.service';
import { InitWebshopSettings } from '../../../store/settings/WebshopSettingsActions';
import { CheckoutState } from '../../../store/checkout/CheckoutReducer';
import { initCheckoutSelector } from '../../../store/checkout/CheckoutSelector';
import { InitCheckout, ResetCheckout } from '../../../store/checkout/CheckoutActions';
import { StepperSelectionEvent } from '@angular/cdk/stepper/stepper';
import { CheckoutLocalStorage } from '../../../model/CheckoutLocalStorage';
import { HttpClient } from '@angular/common/http';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { ProductState } from '../../../store/product/ProductReducer';
import { AddProduct } from '../../../store/product/ProductActions';
import { Json } from '../../../model/Json';
import { ProductKey } from '../../../model/ProductKey';
import {CardPaymentConfirmComponent} from "../card-payment-confirm/card-payment-confirm.component";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html'
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  @ViewChild('checkoutForm')
  form: ElementRef;

  @ViewChild(MatStepper)
  stepper: MatStepper;

  @ViewChild(CheckoutShoppingCartComponent)
  checkoutShoppingCartComponent: CheckoutShoppingCartComponent;

  user?: User;
  profile?: Profile;
  addNewShippingAddress: boolean;
  settings: WebshopSettings;
  // @ViewChild('checkoutForm')
  // checkoutForm: any;

  PaymentType = PaymentType;
  bankTransferCheckbox: any;
  cashOnDeliveryCheckbox: any;
  deferredPaymentCheckbox: any;

  fullForm: FormGroup;
  isDeliveryAddressTheSame = false;
  isCompany: boolean;
  cart: Cart;
  selectedCountry = 'hu';
  selectedAddress: any = '';
  selectedPhoneCountry = '';

  companyChecked = false;
  deliveryIsChecked1 = false;
  deliveryIsChecked2 = false;
  deliveryIsChecked3 = false;
  customCompanyPanelClass = 'company-panel';

  // Accordion check out
  isFirstOpen = true;
  oneAtATime = true;
  customAccordion = 'custom-accordion';
  firstChecked = true;
  isOpen = [false, true, false];
  isDisabled = [false, false, false];

  isLoaded = false;
  isShippingAddressModified = false;
  isCompanyModified = false;
  isBillingAddressSame = false;

  storedCheckout: CheckoutLocalStorage;

  unsuccessfulPayment: boolean;

  notInStockProduct: boolean;
  models: ProductKey;

  @Output()
  cartClicked: EventEmitter<string> = new EventEmitter<string>();

  //   = Examples.find(e => {
  //   console.log(e)
  //   return e.name === 'hu'
  // });


  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly fb: FormBuilder,
              private readonly orderService: OrderService,
              private readonly userStore: Store<UserState>,
              private readonly companyService: CompanyService,
              private readonly modalService: BsModalService,
              private readonly cartStore: Store<CartState>,
              private readonly productStore: Store<ProductState>,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly checkoutService: CheckoutService,
              private readonly http: HttpClient,
              private readonly userService: UserService,
              private readonly paymentService: PaymentService,
              private readonly toastrService: ToastrService,
              private readonly cartService: CartService,
              private readonly translateService: TranslateService,
              private readonly formService: FormService,
              private readonly webshopSettingsStore: Store<WebshopSettingsState>,
              private readonly checkoutStore: Store<CheckoutState>,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnDestroy(): void {
    console.log('destroy');
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initPersonalDataFromUser(): void {
    if (this.storedCheckout) {
      return;
    }
    const personalDataForm = this.fullForm.get('personalData') as FormGroup;
    personalDataForm.get('email').setValue(this.profile.personalData.email);
    personalDataForm.get('name').setValue(this.profile.personalData.name);
    personalDataForm.get('phoneNumber').setValue(this.profile.personalData.phoneNumber);
  }

  initCompanyDataFromUser(): void {
    if (this.storedCheckout) {
      return;
    }
    const companyForm = this.fullForm.get('company') as FormGroup;
    companyForm.get('name').setValue(this.profile.company?.name);
    companyForm.get('taxNumber').setValue(this.profile.company?.taxNumber);
    companyForm.get('postalCode').setValue(this.profile.company?.postalCode);
    companyForm.get('city').setValue(this.profile.company?.city);
    companyForm.get('address').setValue(this.profile.company?.address);
    companyForm.get('phoneNumber').setValue(this.profile.company?.phoneNumber);
  }

  ngOnInit(): void {
    if (location.search.length > 0) {
      this.unsuccessfulPayment = true;
    }
    this.subscriptions.push(
      this.webshopSettingsStore.select(initWebshopSettings).subscribe((settings) => {
        this.settings = settings;
      })
    );
    this.fullForm = this.formService.getCheckoutForm();
    this.subscriptions.push(
      this.fullForm.get('shippingAddress').valueChanges.subscribe(value => {
        this.isShippingAddressModified = true;
      })
    );
    // this.subscriptions.push(
    //   this.fullForm.get('selectedShippingAddressId').valueChanges.subscribe(value => {
    //     console.log(value);
    //   })
    // );
    this.subscriptions.push(
      this.fullForm.get('company').valueChanges.subscribe(value => {
        this.isCompanyModified = true;
      })
    );

    this.userStore.dispatch(new InitProfile(this.activatedRoute.snapshot.data.preload.userData));
    this.webshopSettingsStore.dispatch(new InitWebshopSettings(this.activatedRoute.snapshot.data.preload.settings));
    this.subscriptions.push(
      this.userStore.select(initProfileSelector).subscribe((profile) => {
        this.profile = profile;
      })
    );

    this.productStore.dispatch(new AddProduct(new Json(this.activatedRoute.snapshot.data.preload.products, 'id')));

    this.subscriptions.push(
      this.cartStore.select(initProductsSelector).subscribe((models) => {
        this.models = models;
      })
    );

    this.subscriptions.push(
      this.cartStore.select(initCartSelector).subscribe((cart) => {
        this.cart = cart;
        if (Object.values(this.cart).length === 0) {
          this.router.navigateByUrl('');
        } else {
          this.checkNotInStockProducts();
        }
      })
    );
    this.subscriptions.push(
      this.userStore.select(initUserSelector).subscribe((user) => {
        this.user = user;
        if (user) {
          const productsWQuantity: Array<CheckoutProduct> = new Array<CheckoutProduct>();
          Object.keys(this.cart).forEach(k => {
            const checkoutProduct = new CheckoutProduct();
            checkoutProduct.productId = Number(k);
            checkoutProduct.quantity = this.cart[k].amount;
            productsWQuantity.push(checkoutProduct);
          });
          this.checkoutService.checkout({products: productsWQuantity}).subscribe((resp) => {
            this.userStore.dispatch(new InitProfile(resp.userData));
            this.initPersonalDataFromUser();
            this.initCompanyDataFromUser();
          });
        }
      })
    );

    this.subscriptions.push(
      this.checkoutStore.select(initCheckoutSelector).subscribe((checkout) => {
        if (checkout) {
          this.storedCheckout = checkout;
          this.fullForm.reset(checkout.form);
          if (this.storedCheckout) {
            switch (this.storedCheckout.form.paymentType) {
              case PaymentType.CASH_ON_DELIVERY: this.cashOnDeliveryCheckbox = this.storedCheckout.form.paymentType; break;
              case PaymentType.BANK_TRANSFER: this.bankTransferCheckbox = this.storedCheckout.form.paymentType; break;
              case PaymentType.DEFERRED_PAYMENT: this.deferredPaymentCheckbox = this.storedCheckout.form.paymentType; break;
            }
          }
          // this.stepper.selectedIndex = checkout.selectedIndex;
        }
      })
    );

    // setTimeout(() => {
    //   // console.log(this.form.nativeElement.submit());
    //   // const payload = {
    //   //   name: 'John',
    //   //   time: '2pm'
    //   // };
    //   const payload = this.fullForm.value;
    //   const form = document.createElement('form');
    //   form.style.visibility = 'hidden'; // no user interaction is necessary
    //   form.method = 'POST'; // forms by default use GET query strings
    //   form.action = 'https://cmsforms.rootor.eu/payment_dev.php';
    //   const input = document.createElement('input');
    //   input.name = 'data';
    //   input.value = JSON.stringify(this.createOrderCommand());
    //   form.appendChild(input);
    //   // for (const key of Object.keys(payload)) {
    //   //   const input = document.createElement('input');
    //   //   input.name = key;
    //   //   input.value = payload[key];
    //   //   form.appendChild(input); // add key/value pair to form
    //   // }
    //   document.body.appendChild(form); // forms cannot be submitted outside of body
    //   form.submit(); // send the payload and navigate
    // }, 3000)

    // setTimeout(() => {
    //   this.subscriptions.push(
    //     this.checkoutStore.select(initCheckoutSelector).subscribe((checkout) => {
    //       if (checkout) {
    //         console.log(checkout)
    //         this.fullForm.reset(checkout);
    //       }
    //     })
    //   );
    // }, 300)

  }

  checkNotInStockProducts(): void {
    this.notInStockProduct = false;
    Object.keys(this.cart).forEach(k => {
      if (this.models[this.cart[k].parentProductId].products.find(p => p.id === this.cart[k].productId).count < this.cart[k].amount || !this.models[this.cart[k].parentProductId].active) {
        this.notInStockProduct = true;
      }
    });
  }

  // post price, shipping cost, order reference number, számlázási adatok, email, language, redirecturl, (invoice data), shipping data
  continue(): void {
    console.log(this.fullForm);
    // this.paymentService.sendPayment(this.fullForm.value).subscribe((resp) => {
    //   console.log(resp);
    // });
    // this.router.navigate(['https://cmsforms.rootor.eu/payment_dev.php'], {
    //   queryParams: {
    //     address: 'valami'
    //   }
    // });
    // window.location.href = 'https://cmsforms.rootor.eu/payment_dev.php?address=valami'
    // console.log(this.checkoutForm);
    // this.checkoutForm.nativeElement.submit();
  }

  changeValue(e): void {
    this.isDeliveryAddressTheSame = e;
  }

  companyCheck(value): void {
    // this.companyChecked = !this.companyChecked;
    // if (value) {
    //   this.isBillingAddressSame = false;
    // }
    if (this.profile?.company) {
      this.fullForm.get('company').disable();
    }
  }

  deliveryFirstCheck(): void {
    this.firstChecked = this.firstChecked !== false;
  }

  deliveryCheck1(): void {
    this.deliveryIsChecked1 = true;
    this.deliveryIsChecked2 = false;
    this.deliveryIsChecked3 = false;
  }

  deliveryCheck2(): void {
    this.deliveryIsChecked2 = true;
    this.deliveryIsChecked1 = false;
    this.deliveryIsChecked3 = false;
  }

  deliveryCheck3(): void {
    this.deliveryIsChecked3 = true;
    this.deliveryIsChecked1 = false;
    this.deliveryIsChecked2 = false;
  }

  openGlsParcelshopMap(): void {
    console.log('opening GLS map');
  }

  isOpenChange(event, index: number): void {
    this.isDisabled[0] = false;
    this.isDisabled[1] = false;
    this.isDisabled[2] = false;

    this.isDisabled[index] = true;
    // if (this.isOpen[index]) {
    //   event.preventDefault();
    // }
  }

  openLogin(): void {
    this.modalService.show(LoginComponent, {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      animated: false
    });
  }

  // createOrderCommand(): CreateOrder {
  //   const command: CreateOrder = new CreateOrder();
  //   command.buyAsCompany = this.companyChecked;
  //   command.message = this.fullForm.get('message').value;
  //   command.cart = Object.values(this.cart).map(op => {
  //     return {
  //       productId: op.productId,
  //       quantity: op.amount
  //     };
  //   });
  //   command.paymentType = this.fullForm.get('paymentType').value;
  //
  //   command.personalData = {
  //     name: this.fullForm.get('personalData').get('name').value,
  //     email: this.fullForm.get('personalData').get('email').value,
  //     phoneNumber: this.fullForm.get('personalData').get('phoneNumber').value,
  //     subscribeToNewsletter: this.fullForm.get('personalData').get('subscribeToNewsletter').value
  //   };
  //
  //   if (!this.user) {
  //     command.shippingAddress = {
  //       name: this.fullForm.get('shippingAddress').get('name').value,
  //       postalCode: this.fullForm.get('shippingAddress').get('postalCode').value,
  //       city: this.fullForm.get('shippingAddress').get('city').value,
  //       address: this.fullForm.get('shippingAddress').get('address').value,
  //       phoneNumber: this.fullForm.get('shippingAddress').get('phoneNumber').value
  //     };
  //     if (this.companyChecked) {
  //       command.companyData = {
  //         name: this.fullForm.get('company').get('name').value,
  //         taxNumber: this.fullForm.get('company').get('taxNumber').value,
  //         postalCode: this.fullForm.get('company').get('postalCode').value,
  //         phoneNumber: this.fullForm.get('company').get('phoneNumber').value,
  //         city: this.fullForm.get('company').get('city').value,
  //         address: this.fullForm.get('company').get('address').value
  //       };
  //     } else {
  //       command.billingAddress = {
  //         // country: this.fullForm.get('billingAddress').get('country').value,
  //         name: this.fullForm.get('billingAddress').get('name').value,
  //         postalCode: this.fullForm.get('billingAddress').get('postalCode').value,
  //         city: this.fullForm.get('billingAddress').get('city').value,
  //         address: this.fullForm.get('billingAddress').get('address').value,
  //         phoneNumber: this.fullForm.get('billingAddress').get('phoneNumber').value
  //       };
  //     }
  //   } else {
  //     command.shippingAddress = this.fullForm.get('selectedShippingAddressId').value;
  //     if (this.companyChecked) {
  //       // command.billingAddress = this.fullForm.get('selectedBillingAddressId').value;
  //       command.buyAsCompany = true;
  //     } else {
  //       command.billingAddress = this.fullForm.get('selectedBillingAddressId').value;
  //     }
  //     // if (this.isShippingAddressModified) {
  //     //   command.shippingAddress = {
  //     //     name: this.fullForm.get('shippingAddress').get('name').value,
  //     //     postalCode: this.fullForm.get('shippingAddress').get('postalCode').value,
  //     //     city: this.fullForm.get('shippingAddress').get('city').value,
  //     //     address: this.fullForm.get('shippingAddress').get('address').value,
  //     //     phoneNumber: this.fullForm.get('shippingAddress').get('phoneNumber').value
  //     //   };
  //     // } else {
  //     //   command.shippingAddress = this.fullForm.get('shippingAddress').value.id;
  //     // }
  //     // if (this.isCompanyModified) {
  //     //   command.companyData = {
  //     //     name: this.fullForm.get('company').get('name').value,
  //     //     taxNumber: this.fullForm.get('company').get('taxNumber').value,
  //     //     postalCode: this.fullForm.get('company').get('postalCode').value,
  //     //     phoneNumber: this.fullForm.get('company').get('phoneNumber').value,
  //     //     city: this.fullForm.get('company').get('city').value,
  //     //     address: this.fullForm.get('company').get('address').value
  //     //   };
  //     // }
  //
  //   }
  //   return command;
  // }

  createOrder(): void {
    // if (this.fullForm.invalid) {
    //   return;
    // }
    const command: CreateOrder = new CreateOrder();
    command.buyAsCompany = this.companyChecked;
    command.message = this.fullForm.get('message').value;
    command.cart = Object.values(this.cart).map(op => {
      return {
        productId: op.productId,
        quantity: op.amount
      };
    });
    command.paymentType = this.fullForm.get('paymentType').value;

    command.personalData = {
      name: this.fullForm.get('personalData').get('name').value,
      email: this.fullForm.get('personalData').get('email').value,
      phoneNumber: this.fullForm.get('personalData').get('phoneNumber').value,
      subscribeToNewsletter: this.fullForm.get('personalData').get('subscribeToNewsletter').value
    };

    if (!this.user) {
      command.shippingAddress = {
        name: this.fullForm.get('shippingAddress').get('name').value,
        postalCode: this.fullForm.get('shippingAddress').get('postalCode').value,
        city: this.fullForm.get('shippingAddress').get('city').value,
        address: this.fullForm.get('shippingAddress').get('address').value,
        phoneNumber: this.fullForm.get('shippingAddress').get('phoneNumber').value
      };
      if (this.companyChecked) {
        command.companyData = {
          name: this.fullForm.get('company').get('name').value,
          taxNumber: this.fullForm.get('company').get('taxNumber').value,
          postalCode: this.fullForm.get('company').get('postalCode').value,
          phoneNumber: this.fullForm.get('company').get('phoneNumber').value,
          city: this.fullForm.get('company').get('city').value,
          address: this.fullForm.get('company').get('address').value
        };
      } else {
        command.billingAddress = {
          // country: this.fullForm.get('billingAddress').get('country').value,
          name: this.fullForm.get('billingAddress').get('name').value,
          postalCode: this.fullForm.get('billingAddress').get('postalCode').value,
          city: this.fullForm.get('billingAddress').get('city').value,
          address: this.fullForm.get('billingAddress').get('address').value,
          phoneNumber: this.fullForm.get('billingAddress').get('phoneNumber').value
        };
      }
    } else {
      command.shippingAddress = this.fullForm.get('selectedShippingAddressId').value;
      if (this.companyChecked) {
        // command.billingAddress = this.fullForm.get('selectedBillingAddressId').value;
        command.buyAsCompany = true;
      } else {
        command.billingAddress = this.fullForm.get('selectedBillingAddressId').value;
      }
      // if (this.isShippingAddressModified) {
      //   command.shippingAddress = {
      //     name: this.fullForm.get('shippingAddress').get('name').value,
      //     postalCode: this.fullForm.get('shippingAddress').get('postalCode').value,
      //     city: this.fullForm.get('shippingAddress').get('city').value,
      //     address: this.fullForm.get('shippingAddress').get('address').value,
      //     phoneNumber: this.fullForm.get('shippingAddress').get('phoneNumber').value
      //   };
      // } else {
      //   command.shippingAddress = this.fullForm.get('shippingAddress').value.id;
      // }
      // if (this.isCompanyModified) {
      //   command.companyData = {
      //     name: this.fullForm.get('company').get('name').value,
      //     taxNumber: this.fullForm.get('company').get('taxNumber').value,
      //     postalCode: this.fullForm.get('company').get('postalCode').value,
      //     phoneNumber: this.fullForm.get('company').get('phoneNumber').value,
      //     city: this.fullForm.get('company').get('city').value,
      //     address: this.fullForm.get('company').get('address').value
      //   };
      // }
    }

    this.orderService.createOrder(command).subscribe((o) => {
      // localStorage.removeItem('checkout');
      // this.fullForm = this.formService.getCheckoutForm();
      this.checkoutStore.dispatch(new ResetCheckout());
      this.cartStore.dispatch(new EmptyCart());
      if (command.paymentType !== PaymentType.BANK_TRANSFER) {
        this.router.navigate(['/sikeres-megrendeles'], {queryParams: {orderId: o.id}});
        // this.toastrService.success(this.translateService.instant('order.created'));
      } else {
        // location.href = 'https://cmsforms.rootor.eu/payment_dev.php?id=' + o.id;
        location.href = 'https://cmsforms.rootor.eu/payment-conte/payment.php?id=' + o.id;
        // this.router.navigate(['https://cmsforms.rootor.eu/payment_dev.php'], {state: {example: 'bar'}});
      }
    }, err => {
      this.toastrService.error(this.translateService.instant(err.error.message));
    });
  }

  setPaymentType(paymentType: PaymentType): void {
    this.fullForm.get('paymentType').setValue(paymentType);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoaded = true;
    }, 75);
  }

  ngAfterContentInit(): void {
  }

  setAddress(address: Address): void {
    this.isShippingAddressModified = false;
    const form: FormGroup = this.fullForm.get('shippingAddress') as FormGroup;
    if (!address) {
      form.reset();
      return;
    }
    form.get('id').setValue(address.id);
    form.get('postalCode').setValue(address.postalCode);
    form.get('city').setValue(address.city);
    form.get('address').setValue(address.address);
    form.get('phoneNumber').setValue(address.phoneNumber);
  }

  billingAddressCheck(value: boolean): void {
    if (this.companyChecked) {
      return;
    }
    const address: Address = this.user ? this.profile.addresses.find(a => a.id === this.fullForm.get('selectedShippingAddressId').value) : this.fullForm.get('shippingAddress').value;
    const billingAddress = this.fullForm.get('billingAddress') as FormGroup;
    // const billingAddress = this.companyChecked ? this.fullForm.get('company') : this.fullForm.get('billingAddress');
    if (!value) {
      this.fullForm.get('billingAddress').reset();
      return;
    }
    billingAddress.get('id').setValue(address.id);
    billingAddress.get('postalCode').setValue(address.postalCode);
    billingAddress.get('name').setValue(address.name);
    billingAddress.get('city').setValue(address.city);
    billingAddress.get('address').setValue(address.address);
    billingAddress.get('phoneNumber').setValue(address.phoneNumber);
  }

  addNewAddress(): void {
    this.userService.addAddress(this.fullForm.get('shippingAddress').value).subscribe((address) => {
      this.addNewShippingAddress = false;
      this.userStore.dispatch(new AddAddress(address));
      this.fullForm.get('shippingAddress').reset();
    });
  }

  openNewAddressModal(): void {
    this.modalService.show(AddNewAddressComponent, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  enableUpdateCompany(): void {
    this.fullForm.get('company').enable();
  }

  cancelUpdateCompany(): void {
    this.fullForm.get('company').disable();
    this.fullForm.get('company').reset(this.profile.company);
  }

  updateCompany(): void {
    this.companyService.updateCompany(this.fullForm.get('company').value).subscribe(() => {
      this.userStore.dispatch(new UpdateCompanyData(this.fullForm.get('company').value));
      this.toastrService.success(this.translateService.instant('company.data.updated'));
      this.fullForm.get('company').disable();
    }, err => {
      console.log(err);
    });
  }

  move(index: number): void {
    this.stepper.selectedIndex = index;
  }

  getAddressById(id: number): Address {
    if (!this.profile) {
      return null;
    }
    return this.profile.addresses.find(a => a.id === id);
  }

  nextStep(event: StepperSelectionEvent): void {
    this.checkoutStore.dispatch(new InitCheckout({form: this.fullForm.value, stepIndex: event.selectedIndex}));
  }

  setPhonePrefix(event) {
    if(event.target['value'] == '') {
      event.target['value'] = event.target['value'] + "+36";
    }
  }

  checkPhoneNumber(event) {
    const validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];
    const tempValue = event.target['value'];
    if (validkeys.indexOf(event.key) < 0) {
      event.target['value'] = tempValue.replace(/[^0-9+]/g, "");
    }
    this.setPhonePrefix(event);
  }

  openConfirmInfo() {
    this.modalService.show(CardPaymentConfirmComponent);
  }
}
