<!--<div class="row">-->
<!--  <div class="col-24">-->
<!--    <div class="row">-->
<!--      <span class="col-10">{{address.name}}</span> - <span class="col-10">{{address.phoneNumber}}</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-24">-->
<!--    <span>{{address.postalCode}}, {{address.city}} {{address.address}}</span>-->
<!--  </div>-->
<!--</div>-->

  <div class="d-flex justify-content-between">
    <div><h5 class="mb-0">{{address.name}} - {{address.phoneNumber}}</h5>
      {{address.postalCode}}, {{address.city}} {{address.address}}
    </div>

    <div *ngIf="isUpdateEnabled">
      <button class="btn btn-outline-dark mt-3" (click)="openUpdateModal()">Szerkesztés</button>
    </div>
  </div>

